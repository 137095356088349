import Button, { ButtonGroup } from 'components/Button';
import useEventItem from 'hooks/useEventItem';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppState } from 'redux/store';
import { regex } from 'utils/validations';
import * as XLSX from 'xlsx';

interface IItemFileUploadDiv {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  existingEventList?: any;
  setSaveResponse: any;
}

function ItemFileUploadDiv({
  title,
  isModalOpen,
  onClose,
  existingEventList,
  setSaveResponse,
}: IItemFileUploadDiv) {
  const { eventId = 0 } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const { fetchEventItemList, saveEvent } = useEventItem();

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleUploadFile = async (e: any) => {
    try {
      e.preventDefault();
      const file = e.target.files[0];
      const bufferData = await file.arrayBuffer();
      /* data is an ArrayBuffer */
      const workbook = XLSX.read(bufferData);
      const columnHeader: any = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: 1 },
      )[0];
      const columnData = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
      );

      // Checks if the excel table headers match the below names
      if (
        columnHeader[2] !== 'UPC' ||
        columnHeader[3] !== 'Item Number' ||
        columnHeader[4] !== 'Item Desc' ||
        columnHeader[11] !== 'Promo Pricing' ||
        columnHeader[20] !== 'Ad Location' ||
        columnHeader[21] !== 'Display Location' ||
        columnHeader[23] !== 'Division Comments'
      ) {
        throw new Error(
          'Invalid excel file format.  Please review column sequence.',
        );
      }

      // Check if Item Number or UPC is not empty and is in valid format
      columnData.forEach((col: any) => {
        const isItemNumEmpty =
          col['Item Number'] === undefined || col.UPC === undefined;
        if (isItemNumEmpty) {
          throw new Error('Item number OR UPC cannot be empty');
        }

        if (!regex.isNumber.test(col['Item Number'])) {
          throw new Error('Item number should be digits only');
        }
        if (!regex.isUPC.test(col.UPC)) {
          throw new Error('UPC should be digits with length 14');
        }
      });

      // Checks if the existing data and excel data length match
      if (existingEventList.length !== columnData.length) {
        throw new Error('Items does not match with this event.');
      }

      const modifiedData = existingEventList.map((item: any) => {
        const matchedData: any = columnData.find(
          (columnItem: any) => columnItem['Item Number'] === item.itemNumber,
        );

        return {
          ...item,
          promoPrice: matchedData['Promo Pricing'] ?? null,
          adLocation: matchedData['Ad Location'] ?? null,
          displayLoc: matchedData['Display Location'] ?? null,
          itemDivComment: matchedData['Division Comments'] ?? null,
        };
      });

      const res = (await saveEvent(modifiedData)) as any;

      // If server returns 500
      if (res.status === 500 || res?.message) {
        throw new Error('Failed to save the uploaded data');
      }

      if (res?.data?.status === 'success') {
        toast.success('Item upload completed successfully');
        fetchEventItemList({
          eventId: eventId.toString(),
          programSK: '',
          pogSk: '',
        });

        clearInput();
        onClose();
      } else {
        const mappedEventList = existingEventList
          .map((item: any) => {
            const errorItem = res.data.itemdDetailsList.find(
              (ErrItem: any) => ErrItem.itemNumber === item.itemNumber,
            );
            return {
              itemNumber: item.itemNumber,
              itemDesc: item.itemDesc,
              upc: item.upc,
              errorMessage: errorItem?.errorMessage ?? null,
            };
          })
          .filter((item: any) => item.errorMessage !== null);

        setSaveResponse(mappedEventList);
        clearInput();
        onClose();
        throw new Error(
          'Failed to save the uploaded data. Please check "Item Upload Errors" for details',
        );
      }
    } catch (error: any) {
      toast.error(
        error?.message ?? 'Failed to upload/read data from excel file',
      );
    }
  };

  const onCloseHandler = () => {
    clearInput();
    onClose();
  };

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl ">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button
            className=""
            type="button"
            onClick={onCloseHandler}
            data-testid="cross-button"
          >
            X
          </button>
        </div>
        <form onSubmit={handleUploadFile}>
          <div className="flex flex-row justify-center p-5 pt-[50px]">
            <div className="flex w-full flex-row content-center">
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                type="file"
                required
                accept=".xlsx"
                onChange={handleUploadFile}
                ref={inputRef}
                data-testid='input-file'
              />
            </div>
          </div>
          <ButtonGroup styles="justify-end pt-[25px] p-5">
            <Button text="Close" onClick={onCloseHandler} />
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = ({ eventList }: AppState) => ({
  existingEventList: eventList.eventItemNotModifiedList,
});

export default connect(mapStateToProps, null)(ItemFileUploadDiv);
