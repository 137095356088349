import { useCallback } from 'react';
import { storeListAction } from 'redux/actions/eventListAction/eventListAction';
import { useAppThunkDispatch } from 'redux/store';

interface UseStoreListProps {
  eventId?: string;
  distId: string;
  showClosedFlag?: string;
  programSK?: string;
}

const useStoreList = () => {
  const dispatch = useAppThunkDispatch();

  const fetchStoreList = useCallback((data: UseStoreListProps) => {
    dispatch(
      storeListAction({
        eventId: data?.eventId ?? '',
        distId: data?.distId ?? '',
        showClosedFlag: data?.showClosedFlag ?? '',
        programSK: data?.programSK ?? '',
      }),
    );
  }, []);

  return fetchStoreList;
};

export default useStoreList;
