import { useCallback, useEffect } from 'react';
import { divisionOrderTotalsProgrameAction } from 'redux/actions/divisionOrderTotalsProgrameAction';

import {
  CLEAR_ORDER_TABLES,
  DIVISION_ORDER_TOTALS_INITIALIZE,
  ORDER_TOTALS_CLEAR,
  ORDER_TOTALS_DIVNUMBER_DISTNUMBER_STORENUMBER_SAVE,
} from 'redux/actions/types';

import { useAppThunkDispatch } from 'redux/store';

interface IDivNumberDistNumberStoreNumberSave {
  prgmName: any | undefined;
  prgmNameFull: any | undefined;
  prgmYr: any | undefined;
  divNumber: string | undefined;
  distNumber: string | undefined;
  storeNumber: string | undefined;
  eventName?: Array<any>;
  itemNumber?: string;
  strNumber?: string;
}
const useOrderProgram = () => {
  const dispatch = useAppThunkDispatch();

  const fetchOrderList = useCallback(() => {
    dispatch(divisionOrderTotalsProgrameAction());
  }, []);

  const clearOrderList = useCallback(() => {
    dispatch({
      type: ORDER_TOTALS_CLEAR,
    });
  }, []);

  const clearOrderAll = useCallback(() => {
    dispatch({
      type: DIVISION_ORDER_TOTALS_INITIALIZE,
    });
  }, []);

  const clearOrderTables = useCallback(() => {
    dispatch({
      type: CLEAR_ORDER_TABLES,
    });
  }, []);

  const saveDivNumberDistNumberStoreNumber = useCallback(
    (value: IDivNumberDistNumberStoreNumberSave) => {
      dispatch({
        type: ORDER_TOTALS_DIVNUMBER_DISTNUMBER_STORENUMBER_SAVE,
        payload: value,
      });
    },
    [],
  );

  useEffect(() => {
    fetchOrderList();
  }, []);

  return {
    fetchOrderList,
    clearOrderList,
    saveDivNumberDistNumberStoreNumber,
    clearOrderAll,
    clearOrderTables
  };
};

export default useOrderProgram;
