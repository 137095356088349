/* eslint-disable no-nested-ternary */
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import Conatiner from 'components/Container/Container';
import DropDown from 'components/Dropdown/Dropdown';
import InputField from 'components/InputField';
import StoreComment from 'components/Modal/StoreComment';
import RTable from 'components/RTable';

import { tabHeight } from 'components/Tab/Tab';

import useEventList from 'hooks/useEventList';
import useNsmComment from 'hooks/useNsmComment';
import useStoreComment from 'hooks/useStoreComment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppState } from 'redux/store';
import { connect, useSelector } from 'react-redux';
import useEventItem from 'hooks/useEventItem';
import useResizeObserver from 'use-resize-observer';
import userRoleCheck, { findRole } from 'utils/role';
import roleColCapability from 'constants/roleColumnCapability';
import MultiComment from 'components/Modal/MultiComment';
import UILoader from 'components/UILoader';
import NoData from 'components/NoData';

export interface IEventList {
  eventItems?: any;
  programList?: any;
  storeCommentTxt?: any;
  roleCommentMap?: any;
}

function EventListForDist(props: IEventList) {
  const { eventItems, programList, storeCommentTxt, roleCommentMap } = props;

  const { fetchEventList, saveEventPrograme } = useEventList();
  const storeComment = useStoreComment();
  const { nsmComment } = useNsmComment();
  const { saveEventItem } = useEventItem();
  const { UserRoleColMaintenance } = userRoleCheck(roleColCapability);
  const { userRoleType } = findRole();

  const [selectedProgramValue, setSelectedProgramValue] = useState('');
  const [selectedEventValue, setSelectedEventValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const tabHeightUpdated = parseInt(`${tabHeight}`, 10);
  const [event, setEvent] = useState({
    programNumber: '',
    programYear: '',
    eventId: '',
  });

  const eventSaved = useSelector(
    (state: AppState) => state.eventList.event,
  );
  const programSaved = useSelector(
    (state: AppState) => state.eventList.program,
  );
  const programIDSaved = useSelector(
    (state: AppState) => state.eventList.programID,
  );
  const showClosedSaved = useSelector(
    (state: AppState) => state.eventList.showClosed,
  );

  const getProgramId = (programName: string) => {
    const programID = programList.find(
      (item: any) => item.value === programName,
    );
    return programID.id;
  };

  useEffect(() => {
    fetchEventList({
      event: eventSaved === undefined ? "" : eventSaved,
      program:
        programIDSaved === undefined ? '' : programIDSaved,
      showClosed: showClosedSaved ? 'true' : 'false',
    });
  }, []);

  useEffect(() => {
    setTableData(eventItems);
    setLoading(false);
    if (eventSaved) {
      setSelectedEventValue(eventSaved)
    }
    setIsChecked(showClosedSaved);
    if (programList?.length > 0) {
      if (programSaved) {
        setSelectedProgramValue(programSaved);
      }
    }
  }, [eventItems]);

  // useEffect(() => {
  //   fetchEventList({
  //     event: selectedEventValue,
  //     program:
  //       selectedProgramValue === '' ? '' : getProgramId(selectedProgramValue),
  //     showClosed: isChecked ? 'true' : 'false',
  //   });
  // }, [isChecked]);

  const [isModalOpen, setIsOpen] = useState(false);
  const [isModalStoreComment, setIsModalStoreComment] = useState(false);

  const handleClose = () => {
    setIsOpen(!isModalOpen);
  };
  const handleCloseStoreModal = () => {
    setIsModalStoreComment(!isModalStoreComment);
  };
  const handleStoreComment = (row: any) => {
    setIsModalStoreComment(!isModalStoreComment);
    storeComment({
      eventId: row?.cell?.row?.original.eventId,
      storeNumber: '',
      programSK: '',
    });
  };
  const handleComment = (row: any) => {
    setIsOpen(!isModalOpen);
    nsmComment({ eventId: row?.cell?.row?.original.eventId, programSK: '' });
    setEvent(row?.cell?.row?.original);
  };

  const handleSearch = (value: any) => {
    saveEventPrograme({
      event: selectedEventValue, program: selectedProgramValue,
      programID: selectedProgramValue === '' ? "" : getProgramId(selectedProgramValue),
      showClosed: isChecked,
    })
    fetchEventList(value);
  };

  const columns = [
    {
      Header: 'Event Name',
      accessor: 'eventName',
      width: 600,
      Cell: (row: any) => (
        <Link
          className="text-sky-500"
          to={`/events/event-list/1/eventItems-${userRoleType}/${row?.cell?.row?.original.eventId}/${row?.cell?.row?.original.seasProgramSk}/${row?.cell?.row?.original.eventName}/${isChecked}`}
          onClick={() => saveEventItem({ eventRow: row?.cell?.row?.original })}
          data-testid='event'
        >
          {row.value}
        </Link>
      ),
      show: true,
    },

    {
      Header: 'District Start Date',
      accessor: 'distStartDate',
    },
    {
      Header: 'District Cutoff Date',
      accessor: 'distEndDate',
    },
    {
      Header: 'Store Start Date',
      accessor: 'storeStartDate',
    },
    {
      Header: 'Store Cutoff Date',
      accessor: 'storeEndDate',
    },
    {
      Header: 'Event Status',
      accessor: 'status',
      width: 210,
    },
    {
      Header: 'Comments',
      accessor: 'comments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleComment(row)}
          data-testid="comments"
        >
          comments
        </button>
      ),
    },

    {
      Header: 'Store Comments',
      accessor: 'storeComment',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleStoreComment(row)}
          data-testid="storeComments"
        >
          comments
        </button>
      ),
    },
  ];

  return (
    <div>
      <div ref={ref} className="overflow-y-hidden pt-1">
        <Banner title="Event List">{/* <Button  text="export"/> */}</Banner>
        <section className="pl-4 pr-4 pt-4">
          <Accordion heading="Event List">
            <Conatiner styles="border-t">
              <div className="items start mt-2 mb-2 flex">
                <InputField
                  id="input-events"
                  name="events"
                  label="Event:"
                  styleClass="xl:w-96 lg:w-80 md:w-64 sm:w-48 pl-2"
                  handleChange={(e) => setSelectedEventValue(e.target.value)}
                  data-testid="events-input"
                  value={selectedEventValue}
                />
                <div className="items flex pl-4">
                  <DropDown
                    List={programList}
                    label="Program:"
                    onChange={setSelectedProgramValue}
                    value={selectedProgramValue}
                  />
                </div>
                <div className="flex items-center pl-4">
                  <CheckBox
                    id=""
                    label="Show closed events"
                    handleChange={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                  />
                </div>
                <div className="flex items-center pl-4">
                  <Button
                    type="contained"
                    text="Search"
                    onClick={() =>
                      handleSearch({
                        event: selectedEventValue,
                        program:
                          selectedProgramValue === ''
                            ? ''
                            : getProgramId(selectedProgramValue),
                        showClosed: isChecked,
                      })
                    }
                  />
                </div>
              </div>
            </Conatiner>
          </Accordion>

          <MultiComment
            nsmTitle="NSM Comments:"
            divisionTitle="Division Comments:"
            districtTitle="District Comments:"
            nsmDisable
            divisionDisable
            onClose={handleClose}
            isModalOpen={isModalOpen}
            nsmCmmentTxt={roleCommentMap ? roleCommentMap.nsm : ''}
            divisionCommentTxt={roleCommentMap ? roleCommentMap.division : ''}
            districtCommentTxt={roleCommentMap ? roleCommentMap.district : ''}
            event={event ? event.eventId : ''}
            data-testid="multi-comment"
          />

          <StoreComment
            title="Store Comments:"
            onClose={handleCloseStoreModal}
            isModalOpen={isModalStoreComment}
            storeCommentTxt={storeCommentTxt}
            data-testid="store-comment"
          />
        </section>
      </div>
      <div className="over pb-1 pl-4 pr-4">
        {tableData && tableData.length > 0 ? (
          <RTable
            columns={columns}
            isfilter
            data={tableData}
            height={height + tabHeightUpdated}
            hiddenColumns={UserRoleColMaintenance('Events', 'EventList')}
            data-testid="eventListTable"
          />
        ) :
          loading ? (
            <UILoader info="Please wait while loading..." />
          ) : (
            <NoData />
          )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ eventList }: AppState) => ({
  programList: eventList.programList,
  eventItems: eventList.eventList,
  storeCommentTxt: eventList.storeComment,
  roleCommentMap: eventList.roleCommentMap,
});
export default connect(mapStateToProps, null)(EventListForDist);
