import { GET_FAQ } from "redux/actions/types";

export const initialState = {
    faqList: [],
}
const faqReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_FAQ:
            return {
                ...state, faqList: action.payload
            }
            break;
    
        default:
            return state;
            break;
    }
}
export default faqReducer;