import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { deleteEventAction } from 'redux/actions/eventListAction/eventListAction';

import { useAppThunkDispatch } from 'redux/store';

type UseDeleteEventProps = {
  programNumber: string;
  programYear: string;
  eventId: string;
} | null;

const useDeleteEvent = () => {
  const dispatch = useAppThunkDispatch();

  const deleteEvent = useCallback(async (data: UseDeleteEventProps) => {
    const res = (await dispatch(
      deleteEventAction({
        eventId: data?.eventId ?? '',
        programNumber: data?.programNumber ?? '',
        programYear: data?.programYear ?? '',
      }),
    )) as AxiosResponse;
    return res;
  }, []);

  return deleteEvent;
};

export default useDeleteEvent;
