/* eslint-disable jsx-a11y/control-has-associated-label */
import Button, { ButtonGroup } from 'components/Button';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import uploadStoreItemsDistrict from 'redux/actions/eventListAction/eventListActionForDist';
import { useAppThunkDispatch } from 'redux/store';
import { findRole } from 'utils/role';
import { regex } from 'utils/validations';
import * as XLSX from 'xlsx';

interface IFileUploadModal {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  eventUploadFileAction?: (file: File) => any;
}

export default function Fileupload({
  title,
  isModalOpen,
  onClose,
  eventUploadFileAction,
}: IFileUploadModal) {
  const dispatch = useAppThunkDispatch();
  const [uploadFile, setUploadFile] = React.useState<FileList | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { userRoleType } = findRole();
  const { eventId = '' } = useParams();

  const clearInput = () => {
    setUploadFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleUploadFile = async (event: any) => {
    event.preventDefault();
    if (userRoleType === 'admin') {
      if (eventUploadFileAction && uploadFile) {
        dispatch(eventUploadFileAction(uploadFile[0]));
        clearInput();
      }
    } else if (userRoleType === 'dist') {
      if (uploadStoreItemsDistrict && uploadFile) {
        try {
          const file = uploadFile[0];
          const bufferData = await file.arrayBuffer();
          /* data is an ArrayBuffer */
          const workbook = XLSX.read(bufferData);
          const columnHeader: any = XLSX.utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]],
            { header: 1 },
          )[0];
          const columnData = XLSX.utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]],
          );

          // Checks if the excel table headers match the below names
          if (
            columnHeader[2] !== 'Store Number' ||
            columnHeader[3] !== 'SPA Item Code' ||
            columnHeader[15] !== 'Suggested District' ||
            columnHeader[17] !== 'Adjusted District Quantity'
          ) {
            throw new Error(
              'Invalid excel file format.  Please review column sequence.',
            );
          }

          // Check if columns not empty and is in valid format
          columnData.forEach((col: any) => {
            const hasEmptyValues =
              col['Store Number'] === undefined ||
              col['SPA Item Code'] === undefined;

            if (hasEmptyValues) {
              throw new Error(
                'Store Number OR SPA Item Code cannot have empty values',
              );
            }

            if (!regex.isNumber.test(col['Store Number'].toString().trim())) {
              throw new Error('Store Number should be digits only');
            }
            if (!regex.isNumber.test(col['SPA Item Code'].toString().trim())) {
              throw new Error('SPA Item Code should be digits only');
            }
            if (!regex.isUPC.test(col.UPC)) {
              throw new Error('UPC should be digits with length 14');
            }
          });
          dispatch(uploadStoreItemsDistrict(eventId, uploadFile[0]));
        } catch (error: any) {
          toast.error(
            error?.message ?? 'Failed to upload/read data from excel file',
          );
        }

        clearInput();
      }
    }
  };

  const onCloseHandler = () => {
    clearInput();
    onClose();
  };

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl ">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" type="button" onClick={onCloseHandler} data-testid='cross-button'>
            X
          </button>
        </div>
        <form onSubmit={handleUploadFile} data-testid='submit-form'>
          <div className="flex flex-row justify-center p-5 pt-[50px]">
            <div className="flex w-full flex-row content-center">
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                type="file"
                required
                accept=".xlsx"
                onChange={(e) => setUploadFile(e.target.files)}
                ref={inputRef}
                data-testid='input-file'
              />
            </div>
          </div>
          <ButtonGroup styles="justify-end pt-[25px] p-5">
            <Button text="Close" onClick={onCloseHandler} />
            {/* <Button type="contained" text="Upload" /> */}
            <button
              type="submit"
              className="font-sm h-9 rounded-md bg-primary px-6 text-base text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              Upload
            </button>
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
}
