import { AnyAction, Dispatch } from 'redux';
import { apiBaseURL } from 'utils/apiBaseUrl';
import axios from 'utils/interceptor';
import {
  STORE_GROUPS_SUCCESS,
  STORE_GROUPS_FAILURE,
  SAVE_STORE_GROUP_FAILURE,
  SAVE_STORE_GROUP_SUCCESS,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAILURE,
  STORE_GROUP_QUANTITY_SUCCESS,
  STORE_GROUP_QUANTITY_FAILURE,
} from './types';
import { toast } from 'react-toastify';
import { userId } from 'layouts/Header/Header';
import { ThunkAppDispatch } from 'redux/store';
import storeGroupReducer from 'redux/reducers/storeGroupReducer';

export const fetchStoreGroupsSuccess = (storeGroups: object) => ({
  type: STORE_GROUPS_SUCCESS,
  payload: storeGroups,
});

export const fetchStoreGroupsFailure = (error: object) => ({
  type: STORE_GROUPS_FAILURE,
  payload: error,
});

export const getStoreGroups = () => (dispatch: ThunkAppDispatch) => {
  axios
    .get(`${apiBaseURL}/ssa/getAllStoreGroup`, {
      headers: {
        userName: userId,
      },
    })
    .then((response) => {
      const storeGroups = response.data;
      dispatch(fetchStoreGroupsSuccess(storeGroups));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchStoreGroupsFailure(errorMsg));
    });
};

export const saveStoreGroupSuccess = (storeGroup: object) => ({
  type: SAVE_STORE_GROUP_SUCCESS,
  payload: storeGroup,
});

export const saveStoreGroupFailure = (error: object) => ({
  type: SAVE_STORE_GROUP_FAILURE,
  payload: error,
});

export const saveStoreGroup =
  (groupName: string, closeModal: () => void) =>
    (dispatch: ThunkAppDispatch) => {
      axios
        .post(
          `${apiBaseURL}/ssa/saveStoreGroup`,
          { storeGroupName: groupName, storeNumber: ['string'] },
          {
            headers: {
              userName: userId,
            },
          },
        )
        .then((response) => {
          const storeGroup = response.data;
          response.status === 201
            ? toast.success('Store Group added Successfully')
            : toast.error('Failed to add Store Group');

          dispatch(getStoreGroups());
          closeModal();
        })
        .catch((e) => {
          const error = JSON.parse(e?.message);
          const errorMsg = error?.errors[0]?.message;

          toast.error(errorMsg || 'Store Group API call failed');
          // dispatch(saveStoreGroupFailure(errorMsg))
        });
    };

export const deleteStoreGroup =
  (storeGroup: object) => (dispatch: ThunkAppDispatch) => {
    axios
      .delete(`${apiBaseURL}/ssa/deleteStoreGroup`, {
        data: storeGroup,
        headers: {
          userName: userId,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Store Group deleted Successfully');
          dispatch(getStoreGroups());
        } else {
          toast.error('Failed to delete Store Group');
        }
      })
      .catch((e) => {
        const error = JSON.parse(e?.message);
        const errorMsg = error?.errors[0]?.message;
        toast.error(errorMsg || 'Store Group API call failed');
      });
  };

export const getStoresSuccess = (stores: object) => ({
  type: STORE_LIST_SUCCESS,
  payload: stores,
});

export const getStoresFailure = (error: object) => ({
  type: STORE_LIST_FAILURE,
  payload: error,
});

export const getStores = () => (dispatch: ThunkAppDispatch) => {
  axios
    .get(`${apiBaseURL}/ssa/getStores`, {
      headers: {
        userName: userId,
      },
    })
    .then((response) => {
      const stores = response.data;
      dispatch(getStoresSuccess(stores));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(getStoresFailure(errorMsg));
    });
};

export const updateStoreGroup =
  (
    activityType: string,
    storeGroupId: string,
    storeGroupName: string,
    storeList: string[],
  ) =>
    (dispatch: ThunkAppDispatch) => {
      axios
        .post(
          `${apiBaseURL}/ssa/editStoreGroup`,
          {
            storeGroupId,
            storeGroupName,
            storeNumber: storeList,
          },
          {
            headers: {
              userName: userId,
              activityType,
            },
          },
        )
        .then((response) => {
          const storeGroup = response.data;
          const successMessage =
            activityType === 'add'
              ? 'Stores added Successfully'
              : 'Stores removed Successfully';

          const failMessage =
            activityType === 'add'
              ? 'Failed to add Stores'
              : 'Failed to remove Stores';

          if (response.status === 200) {
            dispatch(getStoreGroups());
            toast.success(successMessage);
          } else {
            toast.error(failMessage);
          }
        })
        .catch((e) => {
          const error = JSON.parse(e?.message);
          const errorMsg = error?.errors[0]?.message;
          toast.error(errorMsg || 'Edit Store Group API call failed');
        });
    };

export const fetchStoreGroupQuantitySuccess = (storeGroupQuantity: object) => ({
  type: STORE_GROUP_QUANTITY_SUCCESS,
  payload: storeGroupQuantity,
});

export const fetchStoreGroupQuantityFailure = (error: object) => ({
  type: STORE_GROUP_QUANTITY_FAILURE,
  payload: error,
});

export const getStoreGroupQuantity = (values: any = {}) => (dispatch: ThunkAppDispatch) => {

  axios
    .get(`${apiBaseURL}/ssa/getAllStoreGroupQty?eventId=${values?.eventId}&itemNumber=${values?.itemNumber}`, {
      headers: {
        userName: userId,
      },
    })
    .then((response) => {
      const storeGroupQuantity = response.data;
      if (storeGroupQuantity.length > 0) {

        const modifiedStoreGroupQuantity = storeGroupQuantity.map(
          (item: any) => ({
            ...item,
            value: item?.storeGroupName,
          }),);
        dispatch(fetchStoreGroupQuantitySuccess(modifiedStoreGroupQuantity));
      }
      else {
        dispatch(fetchStoreGroupQuantitySuccess(storeGroupQuantity));
      }

    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchStoreGroupQuantityFailure(errorMsg));
    });
};

export const saveStoreGroupQuantity =

  (value: any) => (dispatch: ThunkAppDispatch) => {
    axios
      .post(
        `${apiBaseURL}/ssa/saveEventItemStoreGroup`,
        {
          eventItemDetailSGP: value?.storeGroupQuantity,
        },
        {
          headers: {
            userName: userId,
          },
        },
      )
      .then((response) => {
        response.data.status === 'success'
          ? toast.success('Store Group Quantity added Successfully')
          : toast.error('Failed to add Store Group Quantity');

        dispatch(getStoreGroupQuantity(value));
      })
      .catch((e) => {
        const error = JSON.parse(e?.message);
        const errorMsg = error?.errors[0]?.message;
        toast.error(errorMsg || 'Store Group Quantity API call failed');
      });
  };