/* eslint-disable jsx-a11y/control-has-associated-label */
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import React, { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

interface IFileUploadModal {
  title: string;
  isModalOpen: boolean;
  distCommentTxt: any;
  onClose: () => void;
}

export default function DistrictComment({
  title,
  isModalOpen,
  distCommentTxt,
  onClose,
}: IFileUploadModal) {
  const { height } = useResizeObserver<HTMLDivElement>();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(distCommentTxt);
  }, [distCommentTxt]);

  const columns = [
    {
      Header: 'Districts',
      accessor: 'district',
    },
    {
      Header: 'Comments',
      accessor: 'commentText',
    },
  ];

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="max-h-[75%] min-h-[75%]  min-w-[50%] overflow-auto rounded-lg border bg-white shadow-2xl">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" type="button" onClick={onClose} data-testid='distModal-close'>
            X
          </button>
        </div>
        <div className="p-5 pt-[20px]">
          {tableData && tableData.length > 0 ? (
            <RTable
              columns={columns}
              isfilter
              data={tableData}
              isTotal
              height={height}
              hiddenColumns={[]}
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}
