import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

interface IDataExport {
  fileName: string;
  data: Array<any>;
  column: any;
}

const exportToXLSX = ({ fileName, data, column }: IDataExport) => {
  if (!data) {
    return;
  }
  try {
    const name = `${fileName}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(data);
    ws['!cols'] = column;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet');
    XLSX.writeFile(wb, name);
    toast.success('Export Completed.');
  } catch (error) {
    toast.error('Export Failed');
  }
};
export default exportToXLSX;
