/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { deleteIcon, editIcon, failedSVG, notStartedSVG, completedSVG, inprogressSVG, scheduledSVG } from 'assets';
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import Conatiner from 'components/Container/Container';
import DropDown from 'components/Dropdown/Dropdown';
import InputField from 'components/InputField';
import ConfirmModal from 'components/Modal/ConfirmModal';
import DistrictComment from 'components/Modal/DistrictComment';
import DivisionComment from 'components/Modal/DivisionComment';
import NSMComments from 'components/Modal/NSMComments';
import StoreComment from 'components/Modal/StoreComment';
import UpdateEvent from 'components/Modal/UpdateEvent';
import RTable from 'components/RTable';

import { tabHeight } from 'components/Tab/Tab';

import useDistComment from 'hooks/useDistComment';
import useDivComment from 'hooks/useDivComment';
import useEventList from 'hooks/useEventList';
import useNsmComment from 'hooks/useNsmComment';
import useStoreComment from 'hooks/useStoreComment';
import useUpdateEvent from 'hooks/useUpdateEvent';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppState } from 'redux/store';
import { connect, useSelector } from 'react-redux';
import useDeleteEvent from 'hooks/useDeleteEvent';
import { toast } from 'react-toastify';
import useEventItem from 'hooks/useEventItem';
import useResizeObserver from 'use-resize-observer';
import userRoleCheck, { findRole } from 'utils/role';
import roleColCapability from 'constants/roleColumnCapability';
import UpdateEventForDiv from 'components/Modal/UpdateEvent/UpdateEventForDiv';
import useUpdateEventForDiv from 'hooks/useUpdateEventForDiv';
import roleCapability from 'constants/roleCapability';
import MultiComment from 'components/Modal/MultiComment';
import UILoader from 'components/UILoader';
import NoData from 'components/NoData';

export interface IEventList {
  eventItems?: any;
  programList?: any;
  updateValue?: any;
  updateValueDiv?: any;
  storeCommentTxt?: any;
  nsmCommentTxt?: any;
  divCommentTxt?: any;
  distCommentTxt?: any;
  roleCommentMap?: any;
}

function EventList(props: IEventList) {
  const {
    eventItems,
    programList,
    updateValue,
    updateValueDiv,
    storeCommentTxt,
    nsmCommentTxt,
    divCommentTxt,
    distCommentTxt,
    roleCommentMap,
  } = props;

  const { fetchEventList, saveEventPrograme } = useEventList();
  const { updateEvent } = useUpdateEvent();
  const { updateEventForDiv } = useUpdateEventForDiv();
  const storeComment = useStoreComment();
  const { nsmComment } = useNsmComment();
  const divComment = useDivComment();
  const distComment = useDistComment();
  const deleteEvent = useDeleteEvent();
  const { saveEventItem } = useEventItem();
  const { UserRoleMaintenance } = userRoleCheck(roleCapability);
  const { UserRoleColMaintenance } = userRoleCheck(roleColCapability);
  const { userRoleType } = findRole();

  const [selectedProgramValue, setSelectedProgramValue] = useState('');
  const [selectedEventValue, setSelectedEventValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [isdeleteModal, setDeleteModal] = useState(false);
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const tabHeightUpdated = parseInt(`${tabHeight}`, 10);
  const [multiCommentModal, setMultiCommentModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const eventSaved = useSelector(
    (state: AppState) => state.eventList.event,
  );
  const programSaved = useSelector(
    (state: AppState) => state.eventList.program,
  );
  const programIDSaved = useSelector(
    (state: AppState) => state.eventList.programID,
  );
  const showClosedSaved = useSelector(
    (state: AppState) => state.eventList.showClosed,
  );

  const [selectedEvent, setselectedEvent] = useState({
    adminEndDate: '',
    adminStartDate: '',
    distEndDate: '',
    distId: '',
    distStartDate: '',
    divEndDate: '',
    divList: [],
    divStartDate: '',
    division: '',
    eventId: '',
    eventName: '',
    pgmStrSk: '',
    programNumber: '',
    programYear: '',
    rtlStrId: '',
    seasProgramSk: '',
    status: '',
    storeComment: '',
    storeCostTarget: '',
    storeEndDate: '',
    storeStartDate: '',
  });
  const [event, setEvent] = useState({
    programNumber: '',
    programYear: '',
    eventId: '',
  });

  const getProgramId = (programName: string) => {
    const programID = programList.find(
      (item: any) => item.value === programName,
    );
    return programID.id;
  };

  useEffect(() => {
    fetchEventList({
      event: eventSaved === undefined ? "" : eventSaved,
      program:
        programIDSaved === undefined ? '' : programIDSaved,
      showClosed: showClosedSaved ? 'true' : 'false',
    });
  }, []);

  useEffect(() => {
    setTableData(eventItems);
    setLoading(false);

    if (eventSaved) {
      console.log("eventSaved")
      setSelectedEventValue(eventSaved)
    }
    setIsChecked(showClosedSaved);
    if (programList?.length > 0) {
      if (programSaved) {
        setSelectedProgramValue(programSaved);
      }
    }
  }, [eventItems]);

  // useEffect(() => {
  //   saveEventPrograme({
  //     event: selectedEventValue, program: selectedProgramValue,
  //     programID: selectedProgramValue === '' ? "" : getProgramId(selectedProgramValue),
  //     showClosed: isChecked,
  //   })
  //   fetchEventList({
  //     event: selectedEventValue,
  //     program:
  //       selectedProgramValue === '' ? '' : getProgramId(selectedProgramValue),
  //     showClosed: isChecked ? 'true' : 'false',
  //   });
  // }, [isChecked]);

  const [isModalOpen, setIsOpen] = useState(false);
  const [isModalDivisionComment, setIsModalDivisionComment] = useState(false);
  const [isModalStoreComment, setIsModalStoreComment] = useState(false);
  const [isModalDistrictComment, setIsModalDistrictComment] = useState(false);
  const [isModalUpdateEvent, setIsModalUpdateEvent] = useState(false);
  const [isModalUpdateEventDiv, setIsModalUpdateEventDiv] = useState(false);

  const handleDeleteModal = () => {
    setDeleteModal(!isdeleteModal);
  };
  const handleClose = () => {
    setIsOpen(!isModalOpen);
  };

  const handleCloseMultiCommentModal = () => {
    setMultiCommentModal(!multiCommentModal);
  };
  const handleCloseDivisionCommentModal = () => {
    setIsModalDivisionComment(!isModalDivisionComment);
  };

  const handleCloseStoreModal = () => {
    setIsModalStoreComment(!isModalStoreComment);
  };
  const handleCloseDistrictModal = () => {
    setIsModalDistrictComment(!isModalDistrictComment);
  };
  const handleCloseUpdateEventModal = () => {
    if (UserRoleMaintenance('Events', 'DivDates')) {
      setIsModalUpdateEventDiv(!isModalUpdateEventDiv);
    } else if (UserRoleMaintenance('Events', 'AdminDates')) {
      setIsModalUpdateEvent(!isModalUpdateEvent);
    }
    fetchEventList({
      event: selectedEventValue,
      program:
        selectedProgramValue === '' ? '' : getProgramId(selectedProgramValue),
      showClosed: isChecked ? 'true' : 'false',
    });
  };
  const handleEdit = (row: any) => {
    setselectedEvent(row?.cell?.row?.original);
    if (UserRoleMaintenance('Events', 'DivDates')) {
      setIsModalUpdateEventDiv(!isModalUpdateEventDiv);
      updateEventForDiv({ eventNumber: row?.cell?.row?.original.eventId });
    } else if (UserRoleMaintenance('Events', 'AdminDates')) {
      setIsModalUpdateEvent(!isModalUpdateEvent);
      updateEvent({
        eventNumber: row?.cell?.row?.original.eventId,
        programNo: row?.cell?.row?.original.programNumber,
        programYr: row?.cell?.row?.original.programYear,
      });
    }

    setEvent(row?.cell?.row?.original);
  };
  const handleDelete = (row: any) => {
    setDeleteModal(true);
    setEvent(row?.cell?.row?.original);
  };
  const handleStoreComment = (row: any) => {
    setIsModalStoreComment(!isModalStoreComment);
    storeComment({
      eventId: row?.cell?.row?.original.eventId,
      storeNumber: '',
      programSK: '',
    });
  };
  const handleNsmComment = (row: any) => {
    setIsOpen(!isModalOpen);
    nsmComment({ eventId: row?.cell?.row?.original.eventId, programSK: '' });
    setEvent(row?.cell?.row?.original);
  };
  // Only For Division role
  const handleComment = (row: any) => {
    setMultiCommentModal(!multiCommentModal);
    nsmComment({ eventId: row?.cell?.row?.original.eventId, programSK: '' });
    setEvent(row?.cell?.row?.original);
  };
  const handleDivComment = (row: any) => {
    setIsModalDivisionComment(!isModalDivisionComment);
    divComment({
      eventId: row?.cell?.row?.original.eventId,
      division: row?.cell?.row?.original.division,
      programSK: '',
    });
  };
  const handleDistComment = (row: any) => {
    setIsModalDistrictComment(!isModalDistrictComment);
    distComment({
      eventId: row?.cell?.row?.original.eventId,
      district: row?.cell?.row?.original.distId,
      programSK: '',
    });
  };
  const handleSearch = (value: any) => {
    saveEventPrograme({
      event: selectedEventValue, program: selectedProgramValue,
      programID: selectedProgramValue === '' ? "" : getProgramId(selectedProgramValue),
      showClosed: isChecked,
    })
    fetchEventList(value);
  };

  const deleteSuccess = async () => {
    const res = await deleteEvent(event);
    handleDeleteModal();
    if (res?.data.status === 'success') {
      fetchEventList({
        event: selectedEventValue,
        program:
          selectedProgramValue === '' ? '' : getProgramId(selectedProgramValue),
        showClosed: isChecked.toString(),
      });
      toast.success('Event Deleted Successfully');
    } else {
      toast.error(res?.data.status);
    }
  };

  const columns = [
    {
      Header: 'Action',
      accessor: 'col1',
      width: 90,
      Cell: (row: any) => {
        const disabled =
          isChecked || row?.cell?.row?.original.divStartDate !== null;
        const isDisabledClass = disabled ? 'opacity-25' : 'opacity-100';
        return (
          <div className="flex items-center gap-4 p-2 pt-[5px]">
            <button type="button" onClick={() => handleEdit(row)} data-testid='edit-button'>
              <img src={editIcon} alt="edit" title="edit" />
            </button>
            {UserRoleMaintenance('Events', 'DeleteEvent') ? (
              <button
                type="button"
                onClick={() => handleDelete(row)}
                disabled={disabled}
                className={isDisabledClass}
                data-testid='delete-button'
              >
                <img src={deleteIcon} alt="delete" title="delete" />
              </button>
            ) : null}
          </div>
        );
      },
    },
    {
      Header: 'Sales Histroy Status',
      width: 100,
      accessor: 'col11',
      Cell: (row: any) => {
        let sourceIcon: any;
        let sourceIconLabel = '';
        if (row?.cell?.row?.original?.salesHistoryJobStatus !== undefined) {
          sourceIcon = row?.cell?.row?.original.salesHistoryJobStatus === "F" ? failedSVG : row?.cell?.row?.original.salesHistoryJobStatus === "P" ? inprogressSVG : row?.cell?.row?.original.salesHistoryJobStatus === "S" ? completedSVG : row?.cell?.row?.original.salesHistoryJobStatus === "N" ? scheduledSVG : notStartedSVG;
          sourceIconLabel =
            row?.cell?.row?.original.salesHistoryJobStatus === "F" ? "Failed" : row?.cell?.row?.original.salesHistoryJobStatus === "P" ? "In Progress" : row?.cell?.row?.original.salesHistoryJobStatus === "S" ? "Completed" : row?.cell?.row?.original.salesHistoryJobStatus === "N" ? "Scheduled" : "Not Run";
        }

        return (
          <div className="flex items-center" title={sourceIconLabel} >
            <img className="w-6 h-6 m-auto" src={sourceIcon} alt="Sales History Status" />
          </div>
        );
      },
    },
    {
      Header: 'Event Name',
      accessor: 'eventName',
      width: 600,
      Cell: (row: any) => (
        <Link
          className="text-sky-500"
          to={`/events/event-list/1/event-items-${userRoleType}/${row?.cell?.row?.original.eventId}/${row?.cell?.row?.original.eventName}/${isChecked}/${row?.cell?.row?.original.seasProgramSk}`}
          onClick={() => {

            saveEventItem({ eventRow: row?.cell?.row?.original })
          }}
          data-testid='event'
        >
          {row.value}
        </Link>
      ),
      show: true,
    },
    {
      Header: 'Division',
      accessor: 'div',
      Cell: (row: any) => {
        let strTemp = '';
        try {
          if (row?.cell?.row?.original?.divList?.length > 0) {
            for (
              let index = 0;
              index < row.cell.row.original.divList.length;
              index += 1
            ) {
              strTemp += `${row.cell.row.original.divList[index]}, `;
            }
          }
        } catch (error) {
          console.error('Error in Column divison');
        }

        return (
          <div className="text-sky-500" title={strTemp}>
            Division
          </div>
        );
      },
    },
    {
      Header: 'Admin Cutoff Date',
      accessor: 'adminEndDate',
    },
    {
      Header: 'Division Start Date',
      accessor: 'divStartDate',
    },
    {
      Header: 'Division Cutoff Date',
      accessor: 'divEndDate',
    },
    {
      Header: 'District Start Date',
      accessor: 'distStartDate',
    },
    {
      Header: 'District Cutoff Date',
      accessor: 'distEndDate',
    },
    {
      Header: 'Store Start Date',
      accessor: 'storeStartDate',
    },
    {
      Header: 'Store Cutoff Date',
      accessor: 'storeEndDate',
    },
    {
      Header: userRoleType === 'admin' ? 'Status' : 'Event Status',
      accessor: 'status',
      width: 180,
    },
    {
      Header: 'Comments',
      accessor: 'comments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleComment(row)}
          data-testid="comments"
        >
          comments
        </button>
      ),
    },
    {
      Header: 'NSM Comments',
      accessor: 'NSMComments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleNsmComment(row)}
          data-testid="nsmComments"
        >
          comments
        </button>
      ),
    },

    {
      Header: 'Division Comments',
      accessor: 'DivisionComments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleDivComment(row)}
          data-testid="divComments"
        >
          comments
        </button>
      ),
    },
    {
      Header: 'District Comments',
      accessor: 'DistrictComments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleDistComment(row)}
          data-testid="distComments"
        >
          comments
        </button>
      ),
    },
    {
      Header: 'Store Comments',
      accessor: 'storeComment',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleStoreComment(row)}
          data-testid="storeComments"
        >
          comments
        </button>
      ),
    },
  ];

  return (
    <div>
      <div ref={ref} className="overflow-y-hidden pt-1">
        <Banner title="Event List">{/* <Button  text="export"/> */}</Banner>
        <section className="pl-4 pr-4 pt-4">
          <Accordion heading="Event List">
            <Conatiner styles="border-t">
              <div className="items start mt-2 mb-2 flex">
                <InputField
                  id="input-events"
                  name="events"
                  label="Event:"
                  styleClass="xl:w-96 lg:w-80 md:w-64 sm:w-48 pl-2"
                  handleChange={(e) => setSelectedEventValue(e.target.value)}
                  data-testid="events-input"
                  value={selectedEventValue}
                />
                <div className="items flex pl-4">
                  <DropDown
                    List={programList}
                    label="Program:"
                    onChange={setSelectedProgramValue}
                    value={selectedProgramValue}
                  />
                </div>
                <div className="flex items-center pl-4">
                  <CheckBox
                    id=""
                    label="Show closed events"
                    handleChange={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                  />
                </div>
                <div className="flex items-center pl-4">
                  <Button
                    type="contained"
                    text="Search"
                    onClick={() =>
                      handleSearch({
                        event: selectedEventValue,
                        program:
                          selectedProgramValue === ''
                            ? ''
                            : getProgramId(selectedProgramValue),
                        showClosed: isChecked,
                      })
                    }
                  />
                </div>
              </div>
            </Conatiner>
          </Accordion>

          <NSMComments
            title="NSM Comments:"
            onClose={handleClose}
            isModalOpen={isModalOpen}
            nsmCommentTxt={nsmCommentTxt ? nsmCommentTxt.commentText : ''}
            event={event ? event.eventId : ''}
          />
          <DivisionComment
            title="Division Comments:"
            onClose={handleCloseDivisionCommentModal}
            isModalOpen={isModalDivisionComment}
            divCommentTxt={divCommentTxt}
          />

          <ConfirmModal
            title="Confirm"
            onClose={handleDeleteModal}
            onSuccess={() => {
              deleteSuccess();
            }}
            isModalOpen={isdeleteModal}
            message="Are you sure you want to delete?"
          />
          <DistrictComment
            title="District Comments:"
            onClose={handleCloseDistrictModal}
            isModalOpen={isModalDistrictComment}
            distCommentTxt={distCommentTxt}
          />
          <StoreComment
            title="Store Comments:"
            onClose={handleCloseStoreModal}
            isModalOpen={isModalStoreComment}
            storeCommentTxt={storeCommentTxt}
          />
          <UpdateEvent
            title={`Update Event: ${selectedEvent?.eventName}`}
            onClose={handleCloseUpdateEventModal}
            isModalOpen={isModalUpdateEvent}
            updateEventDetails={updateValue}
            event={selectedEvent}
            data-testid='updateEvent-modal'
          />
          <UpdateEventForDiv
            title={`Update Event: ${selectedEvent?.eventName}`}
            onClose={handleCloseUpdateEventModal}
            isModalOpen={isModalUpdateEventDiv}
            updateEventDetails={updateValueDiv}
            event={selectedEvent}
          />
          <MultiComment
            nsmTitle="NSM Comments:"
            divisionTitle="Division Comments:"
            nsmDisable
            onClose={handleCloseMultiCommentModal}
            isModalOpen={multiCommentModal}
            nsmCmmentTxt={roleCommentMap ? roleCommentMap.nsm : ''}
            divisionCommentTxt={roleCommentMap ? roleCommentMap.division : ''}
            event={event ? event.eventId : ''}
          />
        </section>
      </div>
      <div className="over pb-1 pl-4 pr-4">
        {tableData && tableData.length > 0 ? (
          <RTable
            columns={columns}
            isfilter
            data={tableData}
            height={height + tabHeightUpdated}
            hiddenColumns={[userRoleType === "admin" ? [] : "col11", ...UserRoleColMaintenance('Events', 'EventList')]}
            data-testid="eventListTable"
          />
        ) : loading ? (
          <UILoader info="Please wait while loading..." />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ eventList }: AppState) => ({
  programList: eventList.programList,
  eventItems: eventList.eventList,
  updateValue: eventList.updateEvent,
  updateValueDiv: eventList.updateEventForDiv,
  storeCommentTxt: eventList.storeComment,
  nsmCommentTxt: eventList.nsmComment,
  divCommentTxt: eventList.divComment,
  distCommentTxt: eventList.distComment,
  roleCommentMap: eventList.roleCommentMap,
});
export default connect(mapStateToProps, null)(EventList);