/* eslint-disable */
const includesAny = (arr: [] = [], values: [] = []) =>
  values.some((v) => arr.includes(v));
export const groupWithColorForM = (data: any) => {
  let isRed = true;
  const modifiedData = JSON.parse(JSON.stringify(data));

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < modifiedData.length; i++) {
    try {
      const itemA = modifiedData[i];
      const itemB = modifiedData[i + 1];

      const itemIsMatched =
        itemA.itemType.toLowerCase() === 'm' &&
        itemB &&
        itemB.itemType.toLowerCase() === 'm';

      if (
        (itemIsMatched && itemA.groupColor === undefined) ||
        itemA.groupColor === ''
      ) {
        const subRowUPC = itemA?.subRows?.map((subItem: any) => subItem.upc);

        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < modifiedData.length; j++) {
          if (modifiedData[j].itemType.toLowerCase() === 'm') {
            const nextSubRowUpc = modifiedData[j]?.subRows?.map(
              (subItem: any) => subItem.upc,
            );

            const hasMatchedUPC = includesAny(subRowUPC, nextSubRowUpc);

            if (hasMatchedUPC) {
              const setColorClass = isRed ? 'text-blue-600' : 'text-green-600';
              modifiedData[i].groupColor = hasMatchedUPC ? setColorClass : '';
              modifiedData[j].groupColor = hasMatchedUPC ? setColorClass : '';
              const commonElementsArray = subRowUPC.filter((element: any) =>
                nextSubRowUpc.includes(element),
              );

              commonElementsArray.map((commonElement: any) =>
                modifiedData[i]?.subRows?.map((item: any) =>
                  item.upc === commonElement
                    ? (item.groupColor = setColorClass)
                    : '',
                ),
              );

              commonElementsArray.map((commonElement: any) =>
                modifiedData[j]?.subRows?.map((item: any) =>
                  item.upc === commonElement
                    ? (item.groupColor = setColorClass)
                    : '',
                ),
              );
            } else {
              break;
            }
          } else {
            break;
          }
        }

        isRed = !isRed;
      }
    } catch (error) {
      console.error('Error in color group mapping');
    }
  }

  return modifiedData;
};
export const groupWithColorForO = (data: any) => {
  const modifiedData = data;
  let isPurple = true;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < modifiedData.length; i++) {
    try {
      // eslint-disable-next-line no-plusplus
      for (let j = i + 1; j < modifiedData.length; j++) {
        const itemIsMatched =
          modifiedData[i].itemType.toLowerCase() === 'o' &&
          modifiedData[j] &&
          modifiedData[j].itemType.toLowerCase() === 'o';
        if (itemIsMatched) {
          if (
            (modifiedData[i].upc === modifiedData[j].upc &&
              modifiedData[i].groupColor === undefined) ||
            modifiedData[i].groupColor === ''
          ) {
            const setColorClass = isPurple
              ? 'text-purple-600'
              : 'text-pink-600';
            modifiedData[i].groupColor = setColorClass;
            modifiedData[j].groupColor = setColorClass;
            isPurple = !isPurple;
          }
        }
      }
    } catch (error) {
      console.error('Error in color group mapping');
    }
  }
  return modifiedData;
};
