/* eslint-disable no-nested-ternary */
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import DropDown from 'components/Dropdown/Dropdown';
import DistrictComment from 'components/Modal/DistrictComment';
import DivisionComment from 'components/Modal/DivisionComment';
import NSMComments from 'components/Modal/NSMComments';
import StoreComment from 'components/Modal/StoreComment';
import RTable from 'components/RTable';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import useEventList from 'hooks/useEventList';
import { failedSVG, notStartedSVG, completedSVG, inprogressSVG, scheduledSVG } from 'assets';

import { tabHeight } from 'components/Tab/Tab';
import ConfirmModal from 'components/Modal/ConfirmModal';
import useUploadToSpa from 'hooks/useUploadToSpa';
import useResizeObserver from 'use-resize-observer';
import useStoreComment from 'hooks/useStoreComment';
import useNsmComment from 'hooks/useNsmComment';
import useDivComment from 'hooks/useDivComment';
import useDistComment from 'hooks/useDistComment';
import { IEventList } from './EventList/EventList';
import NoData from 'components/NoData';
import { findRole } from 'utils/role';
import Select, { OnChangeValue } from 'react-select';
import { ConsoleConstructorOptions } from 'console';


function UploadToSpa(props: IEventList) {
  const {
    eventItems,
    programList,
    storeCommentTxt,
    nsmCommentTxt,
    divCommentTxt,
    distCommentTxt,
  } = props;
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const tabHeightUpdated = tabHeight;

  const { userRoleType } = findRole();
  const fetchUploadSpaList = useUploadToSpa();
  const { fetchEventList } = useEventList();
  const storeComment = useStoreComment();
  const { nsmComment } = useNsmComment();
  const divComment = useDivComment();
  const distComment = useDistComment();
  const [isModal, setIsModal] = useState(false);
  const [selectedProgramValue, setSelectedProgramValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [uploadSpaTableData, setUploadSpaTableData] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState<any>('');

  const [event, setEvent] = useState({
    programNumber: '',
    programYear: '',
    eventId: '',
  });
  const getProgramId = (programName: string) => {
    const programID = programList.find(
      (item: any) => item.value === programName,
    );
    return programID.id;
  };
  const getProgramIds = (programNames: any) => {
    let Ids = "";
    if (programNames?.length > 0) {
      for (let index = 0; index < programNames.length; index += 1) {
        const element = programNames[index];
        if (Ids === '') {
          Ids = element?.id;
        }
        else {
          Ids += `,${element?.id}`;
        }
      }
    }
    return Ids;
  };
  const handleUploadSpaModal = () => {
    setIsModal(!isModal);
  };
  useEffect(() => {
    setUploadSpaTableData(eventItems);
  }, [eventItems]);
  useEffect(() => {
    fetchEventList({
      program:
        selectedPrograms === '' ? '' : getProgramIds(selectedPrograms),
      showClosed: isChecked.toString(),
    });
  }, [selectedPrograms, isChecked]);

  const [isModalOpen, setIsOpen] = useState(false);
  const [isModalDivisionComment, setIsModalDivisionComment] = useState(false);
  const [isModalStoreComment, setIsModalStoreComment] = useState(false);
  const [isModalDistrictComment, setIsModalDistrictComment] = useState(false);
  const handleClose = () => {
    setIsOpen(!isModalOpen);
  };
  const handleCloseDivisionCommentModal = () => {
    setIsModalDivisionComment(!isModalDivisionComment);
  };

  const handleCloseStoreModal = () => {
    setIsModalStoreComment(!isModalStoreComment);
  };
  const handleCloseDistrictModal = () => {
    setIsModalDistrictComment(!isModalDistrictComment);
  };
  const handleStoreComment = (row: any) => {
    setIsModalStoreComment(!isModalStoreComment);
    storeComment({
      eventId: row?.cell?.row?.original.eventId,
      storeNumber: '',
      programSK: '',
    });
  };
  const handleNsmComment = (row: any) => {
    setIsOpen(!isModalOpen);
    nsmComment({ eventId: row?.cell?.row?.original.eventId, programSK: '' });
    setEvent(row?.cell?.row?.original);
  };
  const onChange = (selectedOptions: OnChangeValue<ConsoleConstructorOptions, true>) => {
    setSelectedPrograms(selectedOptions);
  }

  const handleDivComment = (row: any) => {
    setIsModalDivisionComment(!isModalDivisionComment);
    divComment({
      eventId: row?.cell?.row?.original.eventId,
      division: row?.cell?.row?.original.division,
      programSK: '',
    });
  };
  const handleDistComment = (row: any) => {
    setIsModalDistrictComment(!isModalDistrictComment);
    distComment({
      eventId: row?.cell?.row?.original.eventId,
      district: row?.cell?.row?.original.distId,
      programSK: '',
    });
  };

  const columns = [
    {
      Header: 'Upload to SPA Status',
      width: 100,
      accessor: 'col1',
      Cell: (row: any) => {
        let sourceIcon: any;
        let sourceIconLabel = '';
        if (row?.cell?.row?.original?.spaJobStatus !== undefined) {
          sourceIcon = row?.cell?.row?.original.spaJobStatus === "F" ? failedSVG : row?.cell?.row?.original.spaJobStatus === "P" ? inprogressSVG : row?.cell?.row?.original.spaJobStatus === "S" ? completedSVG : row?.cell?.row?.original.spaJobStatus === "N" ? scheduledSVG : notStartedSVG;
          sourceIconLabel =
            row?.cell?.row?.original.spaJobStatus === "F" ? "Failed" : row?.cell?.row?.original.spaJobStatus === "P" ? "In Progress" : row?.cell?.row?.original.spaJobStatus === "S" ? "Completed" : row?.cell?.row?.original.spaJobStatus === "N" ? "Scheduled" : "Not Run";
        }

        return (
          <div className="flex items-center" title={sourceIconLabel} >
            <img className="w-6 h-6 m-auto" src={sourceIcon} alt="Sales History Status" />
          </div>
        );
      },
    },
    {
      Header: 'Event Name',
      accessor: 'eventName',
      width: 600,
      Cell: (row: any) => <span className="text-sky-500">{row.value}</span>,
    },
    {
      Header: 'Admin CutOff Date',
      accessor: 'adminEndDate',
    },
    {
      Header: 'Division Start Date',
      accessor: 'divStartDate',
    },
    {
      Header: 'Division CutOff Date',
      accessor: 'divEndDate',
    },
    {
      Header: 'Event Status',
      accessor: 'status',
    },
    {
      Header: 'NSM Comments',
      accessor: 'NSMComments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleNsmComment(row)}
          data-testid='nsmComments'
        >
          comments
        </button>
      ),
    },
    {
      Header: 'Division Comments',
      accessor: 'DivisionComments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleDivComment(row)}
          data-testid='divComments'

        >
          comments
        </button>
      ),
    },
    {
      Header: 'District Comments',
      accessor: 'DistrictComments',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleDistComment(row)}
          data-testid='distComments'

        >
          comments
        </button>
      ),
    },
    {
      Header: 'Store Comments',
      accessor: 'storeComment',
      Cell: (row: any) => (
        <button
          type="button"
          className="text-sky-500"
          onClick={() => handleStoreComment(row)}
          data-testid='storeComments'

        >
          comments
        </button>
      ),
    },
  ];

  return (
    <>
      <div ref={ref} className="pt-1">
        <Banner title="Upload to SPA">{/* <Button  text="export"/> */}</Banner>
        <section className="pt-4 pl-4 pr-4">
          <Accordion heading="Upload to SPA">
            <div className="flex items-center border-t-2 p-5">
              <div className="items flex">
                {/* <DropDown
                  List={programList}
                  isMandatory
                  label="Program:"
                  onChange={setSelectedProgramValue}
                /> */}
                <Select
                  className="w-[800px] z-20"
                  closeMenuOnSelect={false}
                  isOptionDisabled={() => selectedPrograms?.length > 9}
                  defaultValue={selectedPrograms}
                  onChange={onChange}
                  options={programList ?? []}
                  isSearchable
                  value={selectedPrograms}
                  getOptionLabel={(option) => `${option.value}`}
                  name='select-program'
                  isMulti
                  placeholder='Select Programs'
                  data-testid='Program Name-dropDown'
                />
              </div>
              <div className="items flex pl-8">
                <CheckBox
                  id="checkbox"
                  name="checkbox"
                  label="Show closed Events"
                  handleChange={() => setIsChecked(!isChecked)}
                  checked={isChecked}
                />
              </div>
              <div className="items start ml-8 flex ">
                <Button
                  type="contained"
                  text="Upload to SPA"
                  onClick={() => {
                    setIsModal(true);
                  }}
                  disabled={selectedPrograms?.length < 1}
                />
              </div>
            </div>
          </Accordion>
          <ConfirmModal
            title="Confirm"
            onClose={handleUploadSpaModal}
            onSuccess={() => {
              fetchUploadSpaList({
                program:
                  selectedPrograms === ''
                    ? ''
                    : getProgramIds(selectedPrograms),
              });

              handleUploadSpaModal();
            }}
            isModalOpen={isModal}
            message="Please confirm if the 'Division Purchase Report' has not been generated for the selected Program in Seasonal Planning Application ?"
          />
          <NSMComments
            title="NSM Comments:"
            onClose={handleClose}
            isModalOpen={isModalOpen}
            nsmCommentTxt={nsmCommentTxt ? nsmCommentTxt.commentText : ''}
            event={event ? event.eventId : ''}
          />
          <DivisionComment
            title="Division Comments:"
            onClose={handleCloseDivisionCommentModal}
            isModalOpen={isModalDivisionComment}
            divCommentTxt={divCommentTxt}
          />
          <DistrictComment
            title="District Comments:"
            onClose={handleCloseDistrictModal}
            isModalOpen={isModalDistrictComment}
            distCommentTxt={distCommentTxt}
          />
          <StoreComment
            title="Store Comments:"
            onClose={handleCloseStoreModal}
            isModalOpen={isModalStoreComment}
            storeCommentTxt={storeCommentTxt}
          />
        </section>
      </div>
      <div className="over pb-4 pl-4 pr-4">
        {uploadSpaTableData && uploadSpaTableData.length > 0 ? (
          <RTable
            columns={columns}
            isfilter
            data={uploadSpaTableData}
            height={height + tabHeightUpdated}
            hiddenColumns={[userRoleType === "admin" ? [] : "col1"]}
            data-testid="UploadToSpaTable"

          />
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ eventList }: AppState) => ({
  programList: eventList.programList,
  eventItems: eventList.eventList,
  storeCommentTxt: eventList.storeComment,
  nsmCommentTxt: eventList.nsmComment,
  divCommentTxt: eventList.divComment,
  distCommentTxt: eventList.distComment,
});

export default connect(mapStateToProps, null)(UploadToSpa);
