import { useCallback, useEffect } from 'react';
import { districtListAction } from 'redux/actions/eventListAction/eventListAction';
import { useAppThunkDispatch } from 'redux/store';

type UseDistrictListProps = {
  totalType?: string;
  prgmName?: string;
  prgmYr?: string;
  divNumber?: string;
  strNumber?: string;
  distNumber?: string;
  itemNumber?: string;
} | null;

const useDistrictList = () => {
  const dispatch = useAppThunkDispatch();

  const fetchDistrictList = useCallback((data: UseDistrictListProps) => {
    dispatch(
      districtListAction({
        totalType: 'totalByDistrict',
        prgmName: data?.prgmName ?? '',
        prgmYr: data?.prgmYr ?? '',
        divNumber: data?.divNumber ?? '',
        strNumber: data?.strNumber ?? '',
        distNumber: data?.distNumber ?? '',
        itemNumber: data?.itemNumber ?? '',
      }),
    );
  }, []);

  useEffect(() => {
    fetchDistrictList(null);
  }, []);

  return fetchDistrictList;
};

export default useDistrictList;
