import NoData from 'components/NoData';
import RTable from 'components/RTable';
import React from 'react';
import useResizeObserver from 'use-resize-observer';

const columns = [
  {
    Header: 'UPC',
    accessor: 'upc',
  },
  {
    Header: 'Item Number',
    accessor: 'itemNumber',
  },
  {
    Header: 'Description',
    accessor: 'itemDesc',
  },
  {
    Header: 'Error Message',
    accessor: 'errorMessage',
  },
];

type ItemUploadErrorType = {
  upc: string;
  itemNumber: string;
  itemDesc: string;
  errorMessage: string;
};

interface IItemUploadModal {
  title: string;
  data: ItemUploadErrorType[];
  isModalOpen: boolean;
  onClose: () => void;
}

function ItemUploadModal({
  title,
  data,
  isModalOpen,
  onClose,
}: IItemUploadModal) {
  const { height } = useResizeObserver<HTMLDivElement>();

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl ">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" data-testid='cross-button' type="button" onClick={onCloseHandler}>
            X
          </button>
        </div>
        <div className="py-8 px-6">
          <div className="over">
            {data.length === 0 ? (
              <NoData />
            ) : (
              <RTable
                columns={columns}
                isfilter={false}
                data={data as any}
                isTotal={false}
                height={height}
                hiddenColumns={[]}
              />
            )}
          </div>
          {/* <ButtonGroup styles="justify-end mt-4 mx-4">
            <Button text="Export" type="contained" onClick={onCloseHandler} />
          </ButtonGroup> */}
        </div>
      </div>
    </div>
  );
}

export default ItemUploadModal;
