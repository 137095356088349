/* eslint-disable jsx-a11y/control-has-associated-label */
import Button from 'components/Button';
import React from 'react';

interface IFileUploadModal {
  title: string;
  message: string;
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function ConfirmModal({
  title,
  isModalOpen,
  onClose,
  onSuccess,
  message,
}: IFileUploadModal) {
  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" type="button" onClick={onClose} data-testid='close-dltModal'>
            X
          </button>
        </div>
        <div className="flex flex-col justify-center p-5 pt-[10px]">
          <div className="flex h-full w-full flex-row content-center">
            {message}
          </div>
          <div className="flex h-full w-full flex-row-reverse content-center gap-4">
            <Button
              type="contained"
              text="Cancel"
              onClick={() => {
                onClose();
              }}
            />
            <Button
              text="Ok"
              type="contained"
              onClick={() => {
                onSuccess();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
