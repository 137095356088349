import { AnyAction, Dispatch } from 'redux';
import { TypeItemEventMap } from 'redux/reducers/uploadEventsReducer';
import { apiBaseURL } from 'utils/apiBaseUrl';
import axios from 'utils/interceptor';
import { UPLOAD_EVENT_FILE_SUCCESS } from './types';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';

let eventUploadErrorFile: null | File = null;

const eventUploadFileAction =
  (file: File) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(
        `${apiBaseURL}/ssa/uploadEventFile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      const { itemEventMap } = response.data.objUploadedDataDetail;
      const itemEventMapArray: TypeItemEventMap[] = Object.keys(
        itemEventMap,
      ).map((key) => itemEventMap[key]);

      const hasError = !!itemEventMapArray.find((item) => item.error !== null);
      eventUploadErrorFile = hasError ? file : null;

      dispatch({
        type: UPLOAD_EVENT_FILE_SUCCESS,
        payload: {
          itemEventMap: itemEventMapArray,
          uploadedEventList: response.data.objUploadedDataDetail,
          hasError,
          eventUploadResObj: response.data,
        },
      });
      toast.success('File uploaded successfully');

      return response;
    } catch {
      toast.error('File upload failed');
      return new Error(`uploadEventFile API call failed`);
    }
  };

export const exportErrorEventUpload =
  (eventUploadResObj: null | Record<string, any> | undefined) => async () => {
    try {
      const formData = new FormData();
      formData.append('file', eventUploadErrorFile ?? '');
      formData.append(
        'uploadeddata',
        new Blob([JSON.stringify(eventUploadResObj)], {
          type: 'application/json',
        }),
      );

      const response = await axios.post(
        `${apiBaseURL}/ssa/exportUploadedData`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'arraybuffer',
        },
      );

      if (response.status === 200) {
        try {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          });
          FileSaver.saveAs(blob, 'export_event_data.xlsx');
        } catch (error) {
          console.error(error);
        }
      }
      return response;
    } catch {
      toast.error('File export failed');
      return new Error(`exportUploadedData API call failed`);
    }
  };

export default eventUploadFileAction;
