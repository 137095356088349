import { Path } from 'assets';
import React, { ReactNode, useState } from 'react';

interface IProps {
  children: ReactNode;
  heading?: string;
}
export default function Accordion({ children, heading }: IProps) {
  const [open, setOpen] = useState(true);

  return (
    <div
      className="accordion  mb-4 flex w-full flex-col rounded-md bg-white"
      id="accordionExample"
    >
      <div className=" accordion-item flex justify-between ">
        <h1
          className="text-md font-nunito py-3 px-4 font-semibold text-black"
          data-testid={`${heading ??  'noLabel'}-accordion`}

        >
          {heading}
        </h1>
        <button type="button" onClick={() => setOpen(!open)} data-testid='arrow-btn'>
          <img
            alt="arrow"
            className={`cursor-pointer
         py-3 px-4
         ${open && 'rotate-180'}`}
            src={Path}
          />
        </button>
      </div>
      <div className={`${open ? 'block' : 'hidden'}`}>{children}</div>
    </div>
  );
}
