import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { toast } from 'react-toastify';
import { DateFormatmmddyyyy } from 'utils/dateFormatter';
import useUpdateEventForDiv from 'hooks/useUpdateEventForDiv';
import useUpdateEvent from 'hooks/useUpdateEvent';

export interface ITypeEventItem {
  adminClDate: string;
  adminOpDate: string;
  corpId: string;
  divClDate: string;
  divName: string;
  divOpDate: string;
  divsion: string;
  salesHistoryEndDate: string;
  salesHistoryStartDate: string;
}
interface IUpdateDateForDiv {
  adminCloseDt: null;
  adminOpenDt: null;
  corp: null;
  distCloseDt: string;
  distOpenDt: string;
  divCloseDtMaster: string;
  divList: null;
  divOpenDtMaster: string;
  eventId: null;
  storeCloseDt: string;
  storeOpenDt: string;
}
interface IFileUploadModal {
  title: string;
  isModalOpen: boolean;
  updateEventDetails: IUpdateDateForDiv;
  event: any;
  onClose: () => void;
}

export default function UpdateEventForDiv({
  title,
  isModalOpen,
  updateEventDetails,
  event,
  onClose,
}: IFileUploadModal) {
  const { updateEventForDiv } = useUpdateEventForDiv();
  const { updateEventDates } = useUpdateEvent();

  const { ref } = useResizeObserver<HTMLDivElement>();

  const [storeCloseDate, setStoreCloseDate] = useState('');
  const [storeOpenDate, setStoreOpenDate] = useState('');
  const [distCloseDate, setDistCloseDate] = useState('');
  const [distOpenDate, setDistOpenDate] = useState('');
  const [divOpenDateMaster, setDivOpenDateMaster] = useState('');
  const [divCloseDateMaster, setDivCloseDateMaster] = useState('');

  useEffect(() => {
    if (updateEventDetails) {
      const {
        storeOpenDt,
        storeCloseDt,
        distCloseDt,
        distOpenDt,
        divOpenDtMaster,
        divCloseDtMaster,
      } = updateEventDetails;

      setStoreCloseDate(storeCloseDt);
      setStoreOpenDate(storeOpenDt);
      setDistCloseDate(distCloseDt);
      setDistOpenDate(distOpenDt);
      setDivOpenDateMaster(divOpenDtMaster);
      setDivCloseDateMaster(divCloseDtMaster);
    }
  }, [updateEventDetails]);

  const onSaveHandler = async () => {
    let isValid = true;

    if (distOpenDate < divOpenDateMaster || distOpenDate > divCloseDateMaster) {
      toast.warning(
        ` District Open Date should be in between Division Open Date and Division Close Date`,
      );
      isValid = false;
    } else if (distCloseDate < distOpenDate) {
      toast.warning(
        ` District Close Date should be greater than District Open Date`,
      );
      isValid = false;
    } else if (distCloseDate > divCloseDateMaster) {
      toast.warning(
        ` District Close Date should be less than Division close Date`,
      );
      isValid = false;
    } else if (storeOpenDate < distOpenDate || storeOpenDate > distCloseDate) {
      toast.warning(
        ` Store Open Date should be in between Dist close and Dist open Date`,
      );
      isValid = false;
    } else if (storeCloseDate < storeOpenDate) {
      toast.warning(` Store Close Date should be greater than Store Open Date`);
      isValid = false;
    } else if (storeCloseDate > distCloseDate) {
      toast.warning(
        ` Store Close Date should be less than District close Date`,
      );
      isValid = false;
    }
    if (isValid) {
      const res = await updateEventDates({
        distCloseDt: DateFormatmmddyyyy(distCloseDate),
        distOpenDt: DateFormatmmddyyyy(distOpenDate),
        eventId: event.eventId,
        storeCloseDt: DateFormatmmddyyyy(storeCloseDate),
        storeOpenDt: DateFormatmmddyyyy(storeOpenDate),
      });
      if (res?.status === 200) {
        updateEventForDiv({
          eventNumber: event.eventId,
        });
      } else {
        toast.error('Event items save failed..');
      }
    }
  };

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="max-h-[58%] min-h-[58%] min-w-[30%] overflow-auto rounded-lg border bg-white shadow-2xl">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" type="button" onClick={onClose}>
            X
          </button>
        </div>
        <div ref={ref} className="pt-[22px] ">
          <div className="flex-col items-start space-y-4 pl-3 pr-8">
            <div className="ml-4 flex justify-between border-b pb-3">
              <p className="text-gray-500 " data-testid="divOpenDate">
                Division Open Date: {divOpenDateMaster}
              </p>
              <p className="text-gray-500" data-testid="divCloseDate">
                Division Close Date: {divCloseDateMaster}
              </p>
            </div>
            <p className="ml-4 text-gray-500">
              Change District Dates prior to changing Store Dates
            </p>

            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="dist-open-date">
                District Open Date:
              </label>
              <input
                className=" block
                rounded
                border
                border-solid
                border-[#B2B4BB] px-4"
                type="date"
                id="dist-open-date"
                name="dist-open-date"
                min={divOpenDateMaster}
                max={divCloseDateMaster}
                value={distOpenDate}
                onChange={(e) => setDistOpenDate(e.target.value)}
                data-testid="dist-open-date-input"
              />
            </div>
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="dist-close-date">
                District Close Date:
              </label>
              <input
                className=" block
                rounded
                border
                border-solid
                border-[#B2B4BB] px-4"
                type="date"
                id="dist-close-date"
                name="dist-close-date"
                value={distCloseDate}
                min={distOpenDate}
                max={divCloseDateMaster}
                onChange={(e) => setDistCloseDate(e.target.value)}
                data-testid="dist-close-date-input"
              />
            </div>
          </div>
          <div className="flex-col items-start space-y-4 pl-3 pr-8 pt-4">
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="store-open-date">
                Store Open Date:
              </label>
              <input
                className=" ml-2
                block
                rounded
                border
                border-solid border-[#B2B4BB] px-4"
                type="date"
                id="store-open-date"
                name="store-open-date"
                min={distOpenDate}
                max={distCloseDate}
                value={storeOpenDate}
                onChange={(e) => setStoreOpenDate(e.target.value)}
                data-testid="store-open-date-input"
              />
            </div>
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="store-close-date">
                Store Close Date:
              </label>
              <input
                className=" ml-2
                block
                rounded
                border
                border-solid border-[#B2B4BB] px-4"
                type="date"
                id="store-close-date"
                name="store-Close-date"
                min={storeOpenDate}
                max={distCloseDate}
                value={storeCloseDate}
                onChange={(e) => setStoreCloseDate(e.target.value)}
                data-testid="store-Close-date-input"
              />
            </div>
          </div>
        </div>
        <div className="flex h-9 justify-end pt-7 pr-8">
          {' '}
          <Button
            type="contained"
            text="Save"
            onClick={() => {
              onSaveHandler();
            }}
          />
        </div>
      </div>
    </div>
  );
}
