import React from 'react';
import Tab from 'components/Tab';
import ItemOrderTotals from './ItemOrderTotals';
import { useParams } from 'react-router-dom';
import StoreOrderTotals from './StoreOrderTotals';
import DistrictOrderTotals from './DistrictOrderTotals';
import DivisionOrderTotals from './DivisionOrderTotals';
import useOrderProgram from 'hooks/useOrderProgram';
import userRoleCheck from 'utils/role';
import roleCapability from 'constants/roleCapability';

const { UserRoleMaintenance } = userRoleCheck(roleCapability);
const orderTabsData = [
  {
    index: 0,
    label: 'Division Order Totals',
    route: '/orders/division-order-totals/0',
    content: () => <DivisionOrderTotals />,
    visible: UserRoleMaintenance('OrderTotals', 'DivisionOrder'),
  },
  {
    index: 1,
    label: 'District Order Totals',
    route: '/orders/district-order-totals/1',
    content: () => <DistrictOrderTotals />,
    visible: UserRoleMaintenance('OrderTotals', 'DistrictOrder'),
  },
  {
    index: 2,
    label: 'Store Order Totals',
    route: '/orders/store-order-totals/2',
    content: () => <StoreOrderTotals />,
    visible: UserRoleMaintenance('OrderTotals', 'StoreOrder'),
  },
  {
    index: 3,
    label: 'Item Order Totals',
    route: '/orders/item-order-totals/3',
    content: () => <ItemOrderTotals />,
    visible: UserRoleMaintenance('OrderTotals', 'ItemOrder'),
  },
];

export default function Order() {
  const { tabIndex = 0 } = useParams();
  const { clearOrderAll } = useOrderProgram();
  return (
    <div>
      <Tab
        data={orderTabsData}
        activeIndex={Number(tabIndex)}
        onTabClick={() => {
          clearOrderAll();
        }}
      />
    </div>
  );
}
