import { AnyAction } from 'redux';
import {
  UPLOAD_EVENT_FILE_INIT,
  UPLOAD_EVENT_FILE_SUCCESS,
  UPLOAD_EVENT_CLEAR,
} from 'redux/actions/types';
import { AppState } from 'redux/store';

export type TypeItemEventMap = {
  itemName: string;
  upc: string;
  itemNumber: string;
  itemSeq: string;
  eventPlanogramList: [];
  error: null | string;
  count: number;
};

export type TypeUploadedEventList = {
  programNumber: string;
  programYear: string;
  itemEventMap: TypeItemEventMap[];
  errorMsg: [];
};

type uploadEventsStateInit = {
  loading: boolean;
  itemEventMap: TypeItemEventMap[] | null;
  uploadedEventList: TypeUploadedEventList | null;
  hasError: boolean;
  errorFile: File | null;
  eventUploadResObj: null | Record<string, any>;
};

export const initialState: uploadEventsStateInit = {
  loading: false,
  itemEventMap: null,
  uploadedEventList: null,
  hasError: false,
  errorFile: null,
  eventUploadResObj: null,
};

const uploadEventReducer=(state = initialState, action: AnyAction): AppState => {
  switch (action.type) {
    case UPLOAD_EVENT_FILE_INIT:
      return {
        ...state,
        loading: true,
      };

    case UPLOAD_EVENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        itemEventMap: action.payload.itemEventMap,
        uploadedEventList: action.payload.uploadedEventList,
        hasError: action.payload.hasError,
        eventUploadResObj: action.payload.eventUploadResObj,
      };

    case UPLOAD_EVENT_CLEAR:
      return initialState;

    default:
      return state;
  }
};
export default uploadEventReducer