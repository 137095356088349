import React, { useEffect, useState } from 'react';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import DropDown from 'components/Dropdown/Dropdown';
import RTable from 'components/RTable';
import Conatiner from 'components/Container/Container';
import Banner from 'components/Banner';
import useOrderProgram from 'hooks/useOrderProgram';
import useOrderList from 'hooks/useOrderList';
import { useSelector } from 'react-redux';
import { AppState, useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { tabHeight } from 'components/Tab/Tab';
import { toast } from 'react-toastify';
import exportToXLSX from 'utils/exportToXLSX';
import { wscolsItemOrder } from './constantsOrder';
import { getEventNameAction } from 'redux/actions/divisionOrderTotalsProgrameAction';
import userRoleCheck, { findRole } from 'utils/role';
import roleColCapability from 'constants/roleColumnCapability';
import NoData from 'components/NoData';
import applyLocaleString from 'utils/applyLocaleString';
import Select, { OnChangeValue } from 'react-select';
import { ConsoleConstructorOptions } from 'node:console';

export default function ItemOrderTotals() {
  const dispatch = useAppThunkDispatch();
  const { clearOrderList } = useOrderProgram();
  const fectOrderList = useOrderList();
  const { UserRoleColMaintenance } = userRoleCheck(roleColCapability);
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const tabHeightUpdated = parseInt(`${tabHeight}`, 10);
  const { userRoleType } = findRole();
  const { saveDivNumberDistNumberStoreNumber } = useOrderProgram();

  const programList = useSelector(
    (state: AppState) => state.divisionOrderTotals.programList,
  );
  const programYearList = useSelector(
    (state: AppState) => state.divisionOrderTotals.programYearList,
  );
  const itemOrderTotalsList = useSelector(
    (state: AppState) => state.divisionOrderTotals.orderTotalsList,
  );
  const divNumber = useSelector(
    (state: AppState) => state.divisionOrderTotals.divNumber,
  );
  const distNumber = useSelector(
    (state: AppState) => state.divisionOrderTotals.distNumber,
  );
  const strNumber = useSelector(
    (state: AppState) => state.divisionOrderTotals.storeNumber,
  );
  const prgmName = useSelector(
    (state: AppState) => state.divisionOrderTotals.prgmName,
  );
  const prgmNameFull = useSelector(
    (state: AppState) => state.divisionOrderTotals.prgmNameFull,
  );
  const prgmYr = useSelector(
    (state: AppState) => state.divisionOrderTotals.prgmYr,
  );
  const eventName = useSelector(
    (state: AppState) => state.divisionOrderTotals.eventName,
  );
  const eventNameList = useSelector(
    (state: AppState) => state.divisionOrderTotals.eventNameList,
  );

  const [selectedProgramValue, setSelectedProgramValue] = useState('');
  const [arrOrderTotals, setArrOrderTotals] = useState([]);
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [programYear, setProgramYear] = useState([]);
  const [selectedEventNames, setSelectedEventNames] = useState<any>();
  const [isPageInitialLoad, setIsPageInitialLoad] = useState(true);

  const columns = [
    {
      Header: 'Item Number',
      accessor: 'itemNo',
      width: 40,
      Footer: <span>Total</span>,
    },
    {
      Header: 'UPC',
      accessor: 'upc',
    },
    {
      Header: 'Item Description',
      accessor: 'itemDes',
      width: 600,
    },
    {
      Header: 'Pack',
      accessor: 'itemPackTxt',
    },
    {
      Header: 'Size',
      accessor: 'itemSizeTxt',
    },
    {
      Header: 'CF',
      accessor: 'vcf',
    },
    {
      Header: 'Total Packs Ordered',
      accessor: 'totalPack',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum: any, row: any) => row.values.totalPack + sum,
              0,
            ),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Total Each Ordered',
      accessor: 'totalEachOrdered',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum: any, row: any) => row.values.totalEachOrdered + sum,
              0,
            ),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Extended Cost',
      accessor: 'extendedVendCostwith$',
      // Cell: (row: any) =>
      //   `$${
      //     parseFloat(row?.row?.original?.extendedVendCost) === 0
      //       ? '0.00'
      //       : row?.row?.original?.extendedVendCost
      //   }`,
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            applyLocaleString(
              info.rows
                .reduce(
                  (sum: any, row: any) => row.original.extendedVendCost + sum,
                  0,
                )
                .toFixed(2),
            ),
          [info],
        );
        return <span>${total}</span>;
      },
    },
    {
      Header: 'Total Case Cube',
      accessor: 'totalCubeWithFixed',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows
              .reduce(
                (sum: any, row: any) =>
                  Number(row.values.totalCubeWithFixed) + sum,
                0,
              )
              .toFixed(2),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Total Case Weight',
      accessor: 'totalWeightWithFixed',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows
              .reduce(
                (sum: any, row: any) =>
                  Number(row.values.totalWeightWithFixed) + sum,
                0,
              )
              .toFixed(2),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Cost Target',
      accessor: 'costTarget',
    },
  ];
  const getProgramId = (programName: string) => {
    const programID = programList.find(
      (item: any) => item.value === programName,
    );
    return programID?.id;
  };

  const getEventIds = (tempArr: Array<any>) => tempArr?.map((item: { id: { toString: () => any; }; }) => item?.id.toString());

  useEffect(() => {
    clearOrderList();
    setSelectedEventNames([]);
    setArrOrderTotals([]);
    if (strNumber) {
      fectOrderList({
        divNumber,
        distNumber,
        strNumber,
        prgmName,
        prgmYr,
        totalType: 'totalByItem',
        eventId: eventName ? getEventIds(eventName) : 'all',
      });
    }
  }, []);

  useEffect(() => {
    if (strNumber) {
      setProgramYear(programYearList[getProgramId(prgmNameFull)]);
      setSelectedProgramValue(prgmNameFull);

      if (programYear) {
        setSelectedYearValue(prgmYr);
        setSelectedEventNames(eventName);
      }
    }
  }, [programList]);
  useEffect(() => {
    if (itemOrderTotalsList && itemOrderTotalsList.length > 0) {
      const uniqueItem = [
        ...Array.from(
          new Set(itemOrderTotalsList.map((item: any) => item.itemNo)),
        ),
      ];

      const uniqueItemOrder = uniqueItem.map((itemNo: any) => {
        const matchItem = itemOrderTotalsList.filter(
          (item: any) => item.itemNo === itemNo,
        );
        return {
          totalPack: matchItem
            .map((commonItemNo: any) => commonItemNo.totalPack)
            .reduce((a: any, b: any) => b + a, 0),
          extendedVendCost: matchItem
            .map((commonItemNo: any) => commonItemNo.extendedVendCost)
            .reduce((a: any, b: any) => b + a, 0),
          totalCube: matchItem
            .map((commonItemNo: any) => commonItemNo.totalCube)
            .reduce((a: any, b: any) => b + a, 0),
          totalWeight: matchItem
            .map((commonItemNo: any) => commonItemNo.totalWeight)
            .reduce((a: any, b: any) => b + a, 0),
          totalEachOrdered: matchItem
            .map((commonItemNo: any) => commonItemNo.totalEachOrdered)
            .reduce((a: any, b: any) => b + a, 0),
          costTarget: matchItem[0].costTarget,
          distName: matchItem[0].distName,
          divName: matchItem[0].divName,

          divId: matchItem[0].divId,
          divisionSuggDifference: matchItem[0].divisionSuggDifference,
          eventId: matchItem[0].eventId,
          eventName: matchItem[0].eventName,
          extendedIBC: matchItem[0].extendedIBC,
          itemDes: matchItem[0].itemDes,
          itemNo: matchItem[0].itemNo,
          itemNumber: matchItem[0].itemNumber,
          itemPackTxt: matchItem[0].itemPackTxt,
          itemSizeTxt: matchItem[0].itemSizeTxt,
          orderDeficit: matchItem[0].orderDeficit,
          storeNumber: matchItem[0].storeNumber,
          suggDivisionCost: matchItem[0].suggDivisionCost,
          totalDivisionSuggPacks: matchItem[0].totalDivisionSuggPacks,

          upc: matchItem[0].upc,
          vcf: matchItem[0].vcf,
        };
      });

      const orderTotalItem: any =
        selectedEventNames?.length < 1 ?
          [...uniqueItemOrder]
          : [...itemOrderTotalsList];

      const result = orderTotalItem.map((item: any) => ({
        ...item,
        extendedVendCostwith$:
          parseFloat(item?.extendedVendCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.extendedVendCost)}`,
        totalCubeWithFixed: item.totalCube.toFixed(2),
        totalWeightWithFixed: item.totalWeight.toFixed(2),
      }));

      setArrOrderTotals(result);
    }
  }, [itemOrderTotalsList]);

  useEffect(() => {
    if (programList) {
      setSelectedYearValue('');
      setProgramYear(programYearList[getProgramId(selectedProgramValue)]);
    }
  }, [selectedProgramValue]);
  useEffect(() => {
    // event name dropdown api call
    if (selectedProgramValue && selectedYearValue) {
      dispatch(
        getEventNameAction({
          programYear: selectedYearValue,
          programName: getProgramId(selectedProgramValue),
        }),
      );
    }
  }, [selectedYearValue]);
  const handleGoClick = () => {
    saveDivNumberDistNumberStoreNumber({
      prgmName: String(getProgramId(selectedProgramValue)),
      prgmNameFull: selectedProgramValue,
      eventName: selectedEventNames,
      prgmYr: selectedYearValue,
      divNumber: undefined,
      distNumber: undefined,
      storeNumber: undefined,
      itemNumber: '',
      strNumber: ''
    });
    fectOrderList({
      prgmName: String(getProgramId(selectedProgramValue)),
      prgmYr: selectedYearValue,
      totalType: 'totalByItem',
      eventId: selectedEventNames?.length > 0 ? getEventIds(selectedEventNames) : 'all',
    });
  };
  const getExportArray = (data: Array<any>) => {
    if (userRoleType === 'dist') {
      try {
        const output = data.map((item) => ({
          'Item Number': item.itemNo,
          UPC: item.upc,
          'Item Description': item.itemDes,
          Pack: item.itemPackTxt,
          Size: item.itemSizeTxt,
          CF: item.vcf,
          'Total Packs Ordered': `${item.totalPack}`,
          'Total Each Ordered': item.totalEachOrdered,
          'Extended Cost': `$${applyLocaleString(item.extendedVendCost)}`,
          'Total Case Cube': `${item.totalCube}`,
          'Total Case Weight': `${item.totalWeight}`,
          'Cost Target': item?.costTarget,
        }));

        let totalPack = 0;
        let totalExtndCost = 0;
        let totalCaseCube = 0;
        let totalCaseWt = 0;
        let totalEachOrdered = 0;

        data.forEach((item) => {
          totalPack += parseFloat(item.totalPack);
          totalExtndCost += parseFloat(item.extendedVendCost);
          totalCaseCube += parseFloat(item.totalCube);
          totalCaseWt += parseFloat(item.totalWeight);
          totalEachOrdered += parseFloat(item.totalEachOrdered);
        });

        output.push({
          'Item Number': 'Total',
          UPC: '',
          Pack: '',
          Size: '',
          CF: '',
          'Item Description': '',
          'Total Packs Ordered': totalPack.toFixed(),
          'Total Each Ordered': totalEachOrdered.toFixed(),
          'Extended Cost': `$${applyLocaleString(totalExtndCost.toFixed(2))}`,
          'Total Case Cube': totalCaseCube.toFixed(2),
          'Total Case Weight': totalCaseWt.toFixed(2),
          'Cost Target': '',
        });

        return output;
      } catch (error) {
        toast.error('Error in export');
        return [];
      }
    } else {
      try {
        const output = data.map((item) => ({
          'Item Number': item.itemNo,
          UPC: item.upc,
          'Item Description': item.itemDes,
          Pack: item.itemPackTxt,
          Size: item.itemSizeTxt,
          CF: item.vcf,
          'Total Packs Ordered': `${item.totalPack}`,
          'Total Each Ordered': item.totalEachOrdered,
          'Extended Cost': `$${applyLocaleString(item.extendedVendCost)}`,
          'Total Case Cube': `${item.totalCube}`,
          'Total Case Weight': `${item.totalWeight}`,
        }));

        let totalPack = 0;
        let totalExtndCost = 0;
        let totalCaseCube = 0;
        let totalCaseWt = 0;
        let totalEachOrdered = 0;

        data.forEach((item) => {
          totalPack += parseFloat(item.totalPack);
          totalEachOrdered += parseFloat(item.totalEachOrdered);
          totalExtndCost += parseFloat(item.extendedVendCost);
          totalCaseCube += parseFloat(item.totalCube);
          totalCaseWt += parseFloat(item.totalWeight);
        });

        output.push({
          'Item Number': 'Total',
          UPC: '',
          Pack: '',
          Size: '',
          CF: '',
          'Item Description': '',
          'Total Packs Ordered': totalPack.toFixed(),
          'Total Each Ordered': totalEachOrdered.toFixed(),
          'Extended Cost': `$${applyLocaleString(totalExtndCost.toFixed(2))}`,
          'Total Case Cube': totalCaseCube.toFixed(2),
          'Total Case Weight': totalCaseWt.toFixed(2),
        });

        return output;
      } catch (error) {
        toast.error('Error in export');
        return [];
      }
    }
  };
  const handleExportClick = () => {
    if (arrOrderTotals && arrOrderTotals.length > 0) {
      exportToXLSX({
        fileName: `${prgmNameFull || selectedProgramValue
          }-Order Total Report By Items`,
        data: getExportArray(arrOrderTotals),
        column: wscolsItemOrder,
      });
    } else {
      toast.warning('No Data');
    }
  };

  const onChange = (selectedOptions: OnChangeValue<ConsoleConstructorOptions, true>) => {
    setSelectedEventNames(selectedOptions);
  }

  const handleChangeProgramYear = (ev: any) => {
    if (isPageInitialLoad) {
      setIsPageInitialLoad(false);
      setSelectedEventNames(eventName);
    } else {
      setSelectedEventNames([]);
    }
    setSelectedYearValue(ev);
  }

  return (
    <>
      <div ref={ref} className="pt-1">
        <Banner title="Order Totals By Items">
          <Button
            text="Export"
            onClick={handleExportClick}
            disabled={!arrOrderTotals || arrOrderTotals.length < 1}
            data-testid="export-button"
          />
        </Banner>
        <section className="pt-4 pl-4 pr-4">
          <Accordion heading="Order Totals By Items">
            <Conatiner styles="border-t">
              <div className="items start mt-2 mb-2 flex">
                <DropDown
                  isMandatory
                  List={programList}
                  label="Program:"
                  value={selectedProgramValue}
                  onChange={setSelectedProgramValue}
                  data-testid="Program"
                />
                <div className="items flex pl-2">
                  <DropDown
                    isMandatory
                    List={programYear}
                    label="Program Year:"
                    value={selectedYearValue}
                    customStyle='ml-3 w-28'
                    onChange={handleChangeProgramYear}
                    data-testid="ProgramYear"
                  />
                </div>
                <div className='items items-center flex pl-4'>
                  <label htmlFor="event-name"
                    className='font-nunito text-center text-sm font-semibold text-black'>Event Name:
                  </label>
                </div>
                <div
                  className={`${selectedYearValue === ''
                    ? 'items flex pointer-events-none opacity-60 items-center pl-2 '
                    : 'items flex pl-2 items-center'
                    }`}
                >
                  {' '}
                  <Select
                    className="w-[600px] z-20"
                    closeMenuOnSelect={false}
                    data-testid="EventNameList"
                    defaultValue={selectedEventNames}
                    onChange={onChange}
                    options={eventNameList ?? []}
                    isSearchable
                    value={selectedEventNames}
                    getOptionLabel={(option) => `${option.value}`}
                    name='id'
                    isMulti
                    placeholder='Select Events'
                  />
                </div>
                <div className="flex items-center pl-4">
                  <Button
                    type="contained"
                    text="Go"
                    onClick={handleGoClick}
                    disabled={
                      selectedYearValue === '' || selectedProgramValue === ''
                    }
                    data-testid="Go"
                  />
                </div>
              </div>
            </Conatiner>
          </Accordion>
        </section>
      </div>

      <div className="over pb-1 pl-4 pr-4">
        {arrOrderTotals && arrOrderTotals.length > 0 ? (
          <RTable
            columns={columns}
            isfilter
            data={arrOrderTotals}
            isTotal
            height={height + tabHeightUpdated}
            hiddenColumns={UserRoleColMaintenance('OrderTotals', 'ItemOrder')}
            data-testid="ItemOrderTotalsTable"
          />
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}
