import {
  STORE_GROUPS_SUCCESS,
  STORE_GROUPS_FAILURE,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAILURE,
  STORE_GROUP_QUANTITY_SUCCESS,
  STORE_GROUP_QUANTITY_FAILURE,
} from '../actions/types';

export const initialState = {
  storeGroups: [],
  storeList: [],
  storeGroupQuantityList: [],
  errorMsg: '',
};

const storeGroupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_GROUPS_SUCCESS:
      return {
        ...state,
        storeGroups: action.payload,
      };
    case STORE_GROUPS_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case STORE_LIST_SUCCESS:
      return {
        ...state,
        storeList: action.payload,
      };
    case STORE_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case STORE_GROUP_QUANTITY_SUCCESS:
      return {
        ...state,
        storeGroupQuantityList: action.payload,
      };
    case STORE_GROUP_QUANTITY_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default storeGroupReducer;
