const roleColumnCapability = [
  {
    roleSid: 1,
    roleName: 'FOSS Admin',
    menuList: [
      {
        menuSid: 1,
        menuName: 'Events',
        functionList: [
          {
            functionSid: 1,
            functionName: 'EventList',
            functionDespTxt: ' Event list page',
            functionPermit: [
              'distStartDate',
              'distEndDate',
              'storeStartDate',
              'storeEndDate',
              'comments',
            ],
          },
          {
            functionSid: 2,
            functionName: 'EventItem',
            functionDespTxt: ' Event Item page',
            functionPermit: [
              'promoPrice',
              'aggrSalesHistQty',
              'adLocation',
              'displayLoc',
              'itemDivComment',
            ],
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 2,
        menuName: 'OrderTotals',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivDistStoreOrder',
            functionDespTxt: ' Div/dist/store order page',
            functionPermit: [
              'totalDivisionSuggPacks',
              'suggDivisionCostWith$',
              'divisionSuggDifferenceWith$',
              'costTarget',
            ],
          },
          {
            functionSid: 1,
            functionName: 'ItemOrder',
            functionDespTxt: 'ItemOrder page',
            functionPermit: ['costTarget'],
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 3,
        menuName: 'ViewsAs',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivisionOrder',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
        ],
        menuPermit: false,
      },
    ],
  },
  {
    roleSid: 2,
    roleName: 'FOSS Division',
    menuList: [
      {
        menuSid: 1,
        menuName: 'Events',
        functionList: [
          {
            functionSid: 1,
            functionName: 'EventList',
            functionDespTxt: ' Event list page',
            functionPermit: [
              'div',
              'adminEndDate',
              'divStartDate',
              'NSMComments',
              'DivisionComments',
            ],
          },
          {
            functionSid: 2,
            functionName: 'EventItem',
            functionDespTxt: ' Event Item page',
            functionPermit: ['adjAdminQty', 'suggestedAdminQty'],
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 2,
        menuName: 'OrderTotals',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivDistStoreOrder',
            functionDespTxt: ' Div/dist/store order page',
            functionPermit: [],
          },
          {
            functionSid: 1,
            functionName: 'ItemOrder',
            functionDespTxt: 'ItemOrder page',
            functionPermit: ['costTarget'],
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 3,
        menuName: 'ViewsAs',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivisionOrder',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
        ],
        menuPermit: false,
      },
    ],
  },
  {
    roleSid: 1,
    roleName: 'FOSS District',
    menuList: [
      {
        menuSid: 1,
        menuName: 'Events',
        functionList: [
          {
            functionSid: 1,
            functionName: 'EventItem',
            functionDespTxt: ' Event Item page',
            functionPermit: ['lockFlag', 'adjDisQty'],
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 2,
        menuName: 'OrderTotals',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivDistStoreOrder',
            functionDespTxt: ' Div/dist/store order page',
            functionPermit: [
              'totalDivisionSuggPacks',
              'suggDivisionCostWith$',
            ],
          },
          {
            functionSid: 1,
            functionName: 'ItemOrder',
            functionDespTxt: 'ItemOrder page',
            functionPermit: [],
          },
        ],
        menuPermit: true,
      },
    ],
  },
];
export default roleColumnCapability;
