/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactNode } from 'react';

interface IFileDisplayModal {
  message: ReactNode;
  isModalOpen: boolean;
  onClose: () => void;
}

export default function DisplayModal({
  isModalOpen,
  onClose,
  message,
}: IFileDisplayModal) {
  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-x-hidden overflow-y-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg  border bg-white shadow-2xl">
        <div className="flex flex-row-reverse content-center justify-between border-b p-5 pb-2">
          <button className="" type="button" onClick={onClose} data-testid='close-button'>
            X
          </button>
        </div>
        <div className="flex flex-col justify-center p-5 pt-[10px]">
          <div className="flex h-full w-full flex-row content-center justify-center p-4">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}
