import { AnyAction } from 'redux';
import {
  UPDATE_EVENT_ITEMS_FAIL,
  UPDATE_EVENT_ITEMS_INIT,
  UPDATE_EVENT_ITEMS_SUCCESS,
} from 'redux/actions/types';
import { AppState } from 'redux/store';

export type TypeOrderEventItem = {
  eventId: string;
  item_id: string;
  item_sl_no: string;
  upc: string;
  item_des: string;
  item_type: string;
  item_pack: string;
  item_size: string;
  vcf: string;
  vend_cost: string;
  strNo: null | string;
  modelUpc: null | string;
  suggestedRetail: string;
  inv_bill_cst: string;
  extended_cost:string;
};

type updateEventItemsStateInit = {
  loading: boolean;
  updateEventSearchItems: TypeOrderEventItem[] | null;
};

const initialState: updateEventItemsStateInit = {
  loading: false,
  updateEventSearchItems: null,
};

const updateEventItemReducer= (state = initialState, action: AnyAction): AppState => {
  switch (action.type) {
    case UPDATE_EVENT_ITEMS_INIT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_EVENT_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEventSearchItems: action.payload,
      };

    case UPDATE_EVENT_ITEMS_FAIL:
      return initialState;

    default:
      return state;
  }
};
export default updateEventItemReducer;
