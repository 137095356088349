/* eslint-disable no-nested-ternary */

const { protocol, hostname } = window.location;
const origin = `${protocol}//${hostname}`;

export const apiBaseURL = hostname.includes('localhost')
  ? `${process.env.REACT_APP_LOCAL_API_URL}/foss-api`
  : `${process.env.REACT_APP_URL}/foss-api`;

export const authBaseURL = hostname.includes('localhost')
  ? `${origin}:7000`
  : origin;
