import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ButtonGroup } from 'components/Button';
import { BackIcon } from 'assets';
import useOrderProgram from 'hooks/useOrderProgram';
import userRoleCheck from 'utils/role';
import roleCapability from 'constants/roleCapability';

type BannerPropType = {
  title: string;
  children?: React.ReactNode;
};

export const bannerHeight = 80;
export default function Banner({ title, children }: BannerPropType) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { clearOrderList } = useOrderProgram();
  const { UserRoleMaintenance } = userRoleCheck(roleCapability);

  const handleBackClick = () => {
    try {
      const arrTemp = pathname?.split('/');
      if (arrTemp?.length > 0 && arrTemp[1] === 'orders') {
        // clearOrderAll();
        clearOrderList();

        switch (pathname) {
          case '/orders/district-order-totals/1':
            if (UserRoleMaintenance('OrderTotals', 'DivisionOrder')) {
              navigate('/orders/division-order-totals/0');
            }
            break;
          case '/orders/store-order-totals/2':
            if (UserRoleMaintenance('OrderTotals', 'DistrictOrder')) {
              navigate('/orders/district-order-totals/1');
            }
            break;
          case '/orders/item-order-totals/3':
            if (UserRoleMaintenance('OrderTotals', 'StoreOrder')) {
              navigate('/orders/store-order-totals/2');
            }
            break;

          default:
            if (UserRoleMaintenance('OrderTotals', 'DivisionOrder')) {
              navigate('/orders/division-order-totals/0');
            }
            break;
        }

      }
      else {
        navigate(-1);
      }
    } catch (error) {
      navigate(-1);
    }


  };
  return (
    <div
      className={`flex h-[${bannerHeight}]px items-center justify-center bg-white pl-5 pr-4`}
    >
      <div className="mt-1 h-16">
        <button
          type="button"
          className="flex items-center"
          onClick={handleBackClick}
          data-testid='back-btn'
        >
          <img src={BackIcon} alt="Back icon" width="12" />
          <strong className="ml-2 text-[10px] uppercase tracking-widest text-[#1172CD] transition-colors hover:text-[#0e5eaa]">
            Back
          </strong>
        </button>
        <h1
          className="font-xl font-nunito-Regular w-fit text-xl text-black"
          title={`${title}`}
          data-testid={`${title}-banner`}
        >
          {title}
        </h1>
      </div>
      <ButtonGroup styles="ml-auto">{children}</ButtonGroup>
    </div>
  );
}
