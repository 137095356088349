import React, { useEffect, useRef } from 'react';

interface IDropdown {
  List: Array<any>;
  label?: string;
  value?: string;
  customStyle?: string;
  onChange: (value: string) => void;
  isMandatory?: boolean;
}
interface IListItem {
  id: string;
  value: string;
  year?: string;
}

export type DDListType = Pick<IListItem, 'id' | 'value'>;

export default function DropDown({
  List,
  label,
  isMandatory,
  value,
  customStyle,
  onChange,
}: IDropdown) {
  const DDRef = useRef<any>(null);

  useEffect(() => {
    try {
      if (DDRef?.current && List?.length > 0) {
        const isInList = List.find((item) => item.value === value);

        if (isInList) {
          DDRef.current.value = '';
          DDRef.current.value = value ?? '';
          onChange && onChange(value ?? '');
        } else {
          DDRef.current.value = '';
          onChange && onChange('');
        }
      } else if (DDRef?.current) {
        DDRef.current.value = '';
      }
    } catch (error) {
      console.error(error);
    }
  }, [value]);

  return (
    <div className="flex items-center">
      <label
        className={`font-nunito ml-2 text-center text-sm font-semibold text-black ${isMandatory ? "after:text-red-600 after:content-['*']" : ''
          }`}
        data-testid={`${label}-dropDown`}
      >
        {label}
      </label>

      <div className={customStyle || "ml-3 sm:w-48 md:w-64 lg:w-80 xl:w-96 max-w-max"}>
        <select
          ref={DDRef}
          className="form-select appearance-display m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:bg-white focus:text-gray-700 focus:outline-none"
          onChange={(e) => onChange(e.target.value)}
          data-testid={`${label}-onChange`}
        >
          <option key="selected" value="">
            -- Select --
          </option>
          {List?.map((item: IListItem) => (
            <option
              key={item.id}
              value={item.value}
              data-testid={`${label}-option`}
            >
              {item.value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
