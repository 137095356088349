/* eslint-disable */
import React from 'react';
import Tab from 'components/Tab';
import { useParams } from 'react-router-dom';
import UpdateEventItems from './UpdateEventItems';
import UploadToSpa from './UploadToSpa';
import UploadEvents from './UploadEvents';
import CostTarget from './CostTarget';
import userRoleCheck, { findRole } from 'utils/role';
import roleCapability from 'constants/roleCapability';
import EventListForDist from './EventList/district/EventListForDist';
import EventList from './EventList/EventList';
import EventListForStore from './EventList/Store/EventListForStore';
import useEventItem from 'hooks/useEventItem';
import useEventList from 'hooks/useEventList';

const { userRoleType } = findRole();
const { UserRoleMaintenance } = userRoleCheck(roleCapability);
const orderTabsData = [
  {
    index: 0,
    label: 'Upload Events',
    route: '/events/upload-events/0',
    content: () => <UploadEvents />,
    visible: UserRoleMaintenance('Events', 'EventsUpload'),

  },
  {
    index: 1,
    label: 'Events List',
    route: '/events/event-list/1',
    content: () =>
      userRoleType === 'dist' ? (
        <EventListForDist />
      ) : userRoleType === 'store' ? (
        <EventListForStore />
      ) : (
        <EventList />
      ),
    visible: UserRoleMaintenance('Events', 'EventList'),
  },
  {
    index: 2,
    label: 'Update Event Items',
    route: '/events/update-event-items/2',
    content: () => <UpdateEventItems />,
    visible: UserRoleMaintenance('Events', 'UpdateEventItems'),

  },
  {
    index: 3,
    label: 'Upload To SPA',
    route: '/events/upload-to-spa/3',
    content: () => <UploadToSpa />,
    visible: UserRoleMaintenance('Events', 'UploadToSpa'),

  },
  {
    index: 4,
    label: 'Cost Target',
    route: '/events/cost-target/4',
    content: () => <CostTarget />,
    visible: UserRoleMaintenance('Events', 'CostTarget'),

  },
];

export default function Events() {
  const { tabIndex = 0 } = useParams();
  const { clearSavedEventProgram } = useEventList();
  return (
    <div>
      <Tab data-testid="events-menu" data={orderTabsData} activeIndex={Number(tabIndex)} onTabClick={() => {
        clearSavedEventProgram();
      }} />
    </div>
  );
}
