import ConfirmModal from 'components/Modal/ConfirmModal';
import { time } from 'console';
import React, { useEffect, useState } from 'react';

export default function EditableCell({
  value: initialValue,
  row: {
    index,
    original: { itemLockTypeFlag, lockFlag },
  },
  column: { id, validation },
  updateData
}: any) {
  const [value, setValue] = useState(initialValue);
  const [isError, setIsError] = useState(false);
  const [modal, setModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    // if (
    //   lockFlag === 'Y' ||
    //   validation?.disable ||
    //   (validation?.customLockValidation &&
    //     (itemLockTypeFlag === 'SLK' || itemLockTypeFlag === 'SDMLK'))
    // ) {
    //   setIsDisabled(true);
    // }
  }, []);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const onChangeHandler = (ev: any) => {
    setValue(ev.target.value);
    // if (!(/^\d*$/.test(ev.target.value)) && validation?.type === 'number') {
    //   setValue('');

    // } else {
    //   setValue(ev.target.value);

    //   if (ev.target.value === '') { setIsError(true); }
    //   else { setIsError(false); }
    //   if (ev.target.value > validation.value) setModal(true);
    // }


  };
  const onHandleKeydown = (e: any) => {
    if (validation.value) {
      if (validation?.type === 'number' && (e.key === '.' || e.key === '-' || e.key === 'e' || e.key === 'E')) {
        e.preventDefault();
      }
    }
  };

  const onBlurHandler = (ev: any) => {
    // const inputValue = ev.target.value;
    // if (inputValue !== '' && validation?.pattern) {
    //   setIsError(!validation?.pattern.test(inputValue));
    // } else if (
    //   (validation?.required && inputValue === '') ||
    //   inputValue === ' '
    // ) {
    //   setIsError(true);
    // } else {
    //   setIsError(false);
    // }

    // if (validation.custom) {
    //   const hasError = validation.custom(inputValue);
    //   setIsError(!hasError);
    // }

    // if (
    //   validation?.customLockValidation &&
    //   itemLockTypeFlag !== null &&
    //   itemLockTypeFlag !== 'NLK'
    // ) {
    //   if (itemLockTypeFlag === 'SINC' || 'SDMINC') {
    //     setIsStoreIncreaseOnly(initialValue);
    //   }
    //   if (ev.target.value < initialValue) {
    //     setIsError(true);
    //   }
    // }

    if (!modal) {
      updateData(index, id, value);
    }

  };

  return (
    <>
      <textarea
        className={`${validation?.isLink ? "underline text-blue-700" : "text-gray-700"} w-full rounded border px-2 py-1  ${isError ? 'has-error border-red-600' : 'border-[#B2B4BB]'
          }`}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        // Below properties are based on TYPE InputValidationType
        maxLength={validation?.maxLength}
        minLength={validation?.minLength}
        required={validation?.required}
        onKeyDown={onHandleKeydown}
        title={isError ? validation?.errorMessage : value}
        disabled={isDisabled}
        data-testid='editable-cell'
      />

      {modal ? (
        <ConfirmModal
          title="Confirm"
          onClose={() => {
            if (initialValue === null || initialValue === undefined) {
              setValue("");
            }
            else {
              setValue(initialValue)
            }
            setModal(false);
          }}
          onSuccess={() => {
            setModal(false);
            updateData(index, id, value);
          }}
          isModalOpen={modal}
          message={validation.message}
        />
      ) : null}
    </>
  );
}
