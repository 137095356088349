import { useCallback, useEffect } from 'react';
import { eventListSearchAction } from 'redux/actions/eventListAction/eventListAction';
import { EVENT_LIST_CLEAR_EVENT_PROGRAME_SAVE, EVENT_LIST_EVENT_PROGRAME_SAVE } from 'redux/actions/types';
import { useAppThunkDispatch } from 'redux/store';
import { useLocation } from 'react-router-dom';

type UseEventListProps = {
  event?: string;
  program?: string;
  showClosed?: string;
} | null;

interface IEventProgrameSave {
  event: any | undefined;
  program: any | undefined;
  programID: any | undefined;
  showClosed: boolean
}

const useEventList = () => {
  const dispatch = useAppThunkDispatch();
  const { pathname } = useLocation();

  const fetchEventList = useCallback((data: UseEventListProps) => {
    dispatch(
      eventListSearchAction({
        eventName: data?.event ?? '',
        programSK: data?.program ?? '',
        showClosedFlag: data?.showClosed ?? 'false',
      }),
    );
  }, []);

  const saveEventPrograme = useCallback(
    (value: IEventProgrameSave) => {
      dispatch({
        type: EVENT_LIST_EVENT_PROGRAME_SAVE,
        payload: value,
      });
    },
    [],
  );
  const clearSavedEventProgram = useCallback(() => {
    dispatch({
      type: EVENT_LIST_CLEAR_EVENT_PROGRAME_SAVE,
    });
  }, []);

  useEffect(() => {
    if (pathname !== "/events/event-list/1") {
      fetchEventList(null);
    }
  }, []);

  return { fetchEventList, saveEventPrograme, clearSavedEventProgram };
};

export default useEventList;
