import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type TabItem = {
  index: number;
  label: string;
  route: string;
  content: () => React.ReactNode;
  visible: boolean;
};

type TabProps = {
  data: TabItem[];
  activeIndex?: number;
  onTabClick?: () => void;
};
export const tabHeight = 40;

export default function Tab({ data, activeIndex = 0, onTabClick }: TabProps) {
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState(activeIndex);

  const tabClickHandler = useCallback(
    (index: number) => {
      setActiveTabIndex(index);
      navigate(data[index].route);
    },

    [navigate, setActiveTabIndex, data],
  );

  useEffect(() => {
    setActiveTabIndex(activeIndex);
  }, [activeIndex, setActiveTabIndex]);
  return (
    <>
      <div className="flex border-b border-t-2 bg-white px-2">
        <div className={`h-[${tabHeight}]px`}>
          {data.map(
            ({ index, label, visible }) =>
              visible && (
                <button
                  type="button"
                  key={index}
                  className={`after:content['*'] relative mx-3 border-b-4 py-2 font-[#2B303C] text-sm font-semibold transition-colors duration-300 after:absolute after:top-[25%] after:right-[-14px] after:h-[50%] after:w-[1px] after:bg-gray-200 ${
                    index === activeTabIndex ?
                      'border-[#028FFF]' :
                      'border-transparent hover:border-gray-200'
                  }`}
                  onClick={() => {
                    if (onTabClick) onTabClick();
                    tabClickHandler(index);
                  }}
                  data-testid={`${label}`}
                >
                  {label}
                </button>
              ),
          )}
        </div>
      </div>
      {data[activeTabIndex].content()}
    </>
  );
}
