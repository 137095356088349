/* eslint-disable react/jsx-props-no-spreading */
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import DropDown from 'components/Dropdown/Dropdown';
import StickyRTable from 'components/StickyRTable';
import useEventItem from 'hooks/useEventItem';
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { AppState } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { imageBaseUrl } from 'utils/imageBaseUrl';
import DisplayModal from 'components/Modal/DisplayModal';
import useEventItemDetails from 'hooks/useEventItemDetails';
import { regex } from 'utils/validations';
import NoData from 'components/NoData';
import applyLocaleString from 'utils/applyLocaleString';
import useEventListForView from 'hooks/useEventListForView';
import useEventItemForView from 'hooks/useEventItemForView';

function ViewDistrictListEventItems({
  planogramList,
  event,
  eventItemList,
}: any) {
  const {
    eventId = 0,
    eventName = '',
    close,
    divId = '',
    dist = '',
  } = useParams();
  const [selectedSpaceValue, setSelectedSpaceValue] = useState('');
  const [isDisplayModalOpen, setIsDisplayModalOpen] = useState(false);
  const [selectedUPC, setUPC] = useState('');
  const [tableData, setTableData] = useState([]);

  const { saveEventItemDetails } = useEventItemDetails();

  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const { fetchEventItemList } = useEventItem();
  const fetchEventItemListForView = useEventItemForView();

  useEffect(() => {
    if (event?.eventId) {
      fetchEventItemListForView({
        eventId: event?.eventId,
        programSK: '',
        pogSk: '',
        roleForView: 'dist',
        roleValueForView: `${divId}.${dist}`,
      });
    } else {
      fetchEventItemListForView({
        eventId: eventId.toString(),
        programSK: '',
        pogSk: '',
        roleForView: 'dist',
        roleValueForView: `${divId}.${dist}`,
      });
    }
  }, [eventId, event]);

  const handleTotalExtendedCost = (info: any) =>
    React.useMemo(
      () =>
        info.rows
          .reduce(
            (sum: any, row: any) =>
              row.original.ibc * row.original.suggestedDistQty + sum,
            0,
          )
          .toFixed(2),
      [info],
    );

  const handleTotalRetail = (info: any) =>
    React.useMemo(
      () =>
        info.rows
          .reduce((sum: any, row: any) => row.original.totalCost + sum, 0)
          .toFixed(2),
      [info],
    );

  const columns = React.useMemo(
    () => [
      {
        id: 'expander',
        width: 60,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span
            {...getToggleAllRowsExpandedProps({
              style: {
                fontSize: '25px',
              },
            })}
          >
            {isAllRowsExpanded ? '-' : '+'}
          </span>
        ),
        sticky: 'left',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                  fontSize: '25px',
                },
              })}
            >
              {row.isExpanded ? '-' : '+'}
            </span>
          ) : null,

        Footer: <span className="sticky -left-0">Total</span>,
      },
      {
        Header: 'Item Number',
        accessor: 'itemNumber',
        sticky: 'left',
        width: 100,
        Cell: (row: any) =>
          row?.cell?.row?.original.itemType !== 'C' ? (
            <Link
              className="text-sky-500"
              to={`/view/eventItemStoreDetailsView/${row?.cell?.row?.original.lockFlag}/${row?.cell?.row?.original.eventId}/${row?.cell?.row?.original.itemNumber}/${close}/${row?.cell?.row?.original.itemType}/
              ${row?.cell?.row?.original.cf}/   
              ${row?.cell?.row?.original.pack}/${row?.cell?.row?.original.ibc} /${dist}/ ${divId} `}
              onClick={() => {
                saveEventItemDetails({
                  eventRow: row?.cell?.row?.original,
                });
              }}
              data-testid="item-Number"
            >
              {row.value}
            </Link>
          ) : (
            <span> {row.value}</span>
          ),
      },
      {
        Header: 'Item Type',
        accessor: 'itemType',
        sticky: 'left',
        width: 90,
        height: 100,
      },
      {
        Header: 'Image',
        accessor: 'col4',
        sticky: 'left',
        width: 80,

        Cell: (row: any) => (
          <button
            type="button"
            className="flex h-10 w-16 flex-row  justify-center"
            onClick={() => {
              setIsDisplayModalOpen(!isDisplayModalOpen);
              setUPC(row?.cell?.row?.original?.upc.substring(2, 13));
            }}
            data-testid="item-Image"
          >
            <img
              className="h-10  w-16 "
              src={`${imageBaseUrl}/${row?.cell?.row?.original?.upc.substring(
                2,
                13,
              )}.gif`}
              alt="item"
            />
          </button>
        ),
      },

      {
        Header: 'Item Description',
        accessor: 'itemDesc',
        sticky: 'left',
        width: '300',
      },
      {
        Header: 'UPC',
        accessor: 'upc',
      },
      {
        Header: 'Pack',
        accessor: 'pack',
        width: 60,
      },
      {
        Header: 'Size',
        accessor: 'size',
        width: 60,
      },
      {
        Header: 'CF',
        accessor: 'cf',
        width: 60,
      },
      {
        Header: 'Each Cost',
        accessor: 'eachCost',
      },
      {
        Header: 'Suggested Retail',
        accessor: 'suggestedRetailwith$',
      },
      {
        Header: 'Promo Pricing',
        accessor: 'promoPrice',
      },
      {
        Header: 'Model UPC',
        accessor: 'modelUpc',
      },
      {
        Header: 'Sales History Taken From Date',
        accessor: 'historyFromDate',
      },
      {
        Header: 'Sales History Taken Until Date',
        accessor: 'historyTillDate',
      },
      {
        Header: 'Planogram Quantity',
        accessor: 'planogramId',
      },
      {
        Header: 'Extended Cost',
        accessor: 'extendedCostWith$',
        Footer: (info: any) => {
          const total = handleTotalExtendedCost(info);
          return <> ${applyLocaleString(total)}</>;
        },
      },
      {
        Header: 'Total Store Quantity',
        accessor: 'totalStrQtyModify',
      },
      {
        Header: 'Total District Quantity',
        accessor: 'suggestedDistQtyModify',
      },
      {
        Header: 'Total Eaches',
        accessor: 'TotalEachesWith$',
      },
      {
        Header: 'Total Retail Dollars',
        accessor: 'totalRetailWith$',
        Footer: (info: any) => {
          const total = handleTotalRetail(info);
          return <span>${applyLocaleString(total)}</span>;
        },
      },

      {
        Header: 'Ad Location',
        accessor: 'adLocation',
        Cell: (row: any) => (
          <div
            className="w-[100%] truncate"
            title={row?.cell?.row?.original?.adLocation}
          >
            {row?.cell?.row?.original?.adLocation}
          </div>
        ),
      },
      {
        Header: 'Display Location',
        accessor: 'displayLoc',
      },
      {
        Header: 'NSM Item Comment',
        accessor: 'itemAdminComment',
      },
      {
        Header: 'Division Item Comment',
        accessor: 'itemDivComment',
      },
      {
        Header: 'District Item Comment',
        accessor: 'itemDistComment',
      },
    ],
    [],
  );

  useEffect(() => {
    if (eventItemList && eventItemList.length > 0) {
      const result = eventItemList.map((item: any) => ({
        ...item,
        totalRetailWith$:
          item.totalCost === null || 0
            ? '$0.00'
            : `$${applyLocaleString(item.totalCost)}`,
        TotalEachesWith$:
          item?.pack === null ||
            parseFloat(item?.suggestedDistQty) === 0 ||
            null
            ? '0'
            : `${Number(item.pack) * Number(item.suggestedDistQty)}`,
        extendedCostWith$:
          parseFloat(item.suggestedDistQty) === 0 ||
            null ||
            parseFloat(item.ibc) === 0 ||
            null
            ? '$0.00'
            : `$${applyLocaleString(
              (Number(item.ibc) * Number(item.suggestedDistQty)).toFixed(2),
            )}`,
        suggestedRetailwith$:
          parseFloat(item.suggestedRetail) === 0 || null
            ? '$0.00'
            : `$${applyLocaleString(item.suggestedRetail)}`,
        totalStrQtyModify:
          parseFloat(item.totalStrQty) === 0 || null
            ? '0'
            : Number(item.totalStrQty),
        suggestedDistQtyModify:
          parseFloat(item.suggestedDistQty) === 0 || null
            ? '0'
            : Number(item.suggestedDistQty),
      }));

      setTableData(result);
    }
  }, [eventItemList]);

  return (
    <div>
      <div ref={ref} className="overflow-y-hidden pt-1">
        <Banner
          title={`${event?.eventName ? event?.eventName : eventName
            }, District :${dist}`}
        >
          {' '}
        </Banner>

        <section className="pl-4 pr-4 pt-4">
          <Accordion heading={event?.eventName ? event?.eventName : eventName}>
            <div className="flex flex-col items-start border-t-2 p-5">
              <div className="flex items-center">
                <DropDown
                  List={planogramList}
                  label="Space Available: "
                  onChange={setSelectedSpaceValue}
                />
              </div>
              <div className="flex items-center pl-2">
                {selectedSpaceValue ? (
                  <a
                    className="text-sky-500"
                    href={`${imageBaseUrl}/${event?.eventName
                        ? event?.eventName.toLowerCase()
                        : eventName.toLowerCase()
                      }${regex.isFirstTwoNum.test(selectedSpaceValue)
                        ? selectedSpaceValue.trimEnd().toLowerCase()
                        : selectedSpaceValue
                          .trimEnd()
                          .padStart(selectedSpaceValue.trimEnd().length + 1, '0')
                          .toLowerCase()
                      }.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event?.eventName ? event?.eventName : eventName}-
                    {selectedSpaceValue}
                  </a>
                ) : null}
              </div>
            </div>
          </Accordion>
        </section>
      </div>
      <div className="over  pl-4 pr-4" role="table">
        {tableData && tableData.length > 0 ? (
          <StickyRTable
            columns={columns}
            isfilter
            data={tableData}
            isTotal
            height={height}
            hiddenColumns={[]}
          />
        ) : (
          <NoData />
        )}
      </div>
      {/* WIP */}
      <DisplayModal
        message={
          <img
            src={`${imageBaseUrl}/${selectedUPC}.gif`}
            className="h-72 w-72"
            alt="ItemImage"
          />
        }
        isModalOpen={isDisplayModalOpen}
        onClose={() => setIsDisplayModalOpen(!isDisplayModalOpen)}
      />
    </div>
  );
}
const mapStateToProps = ({ eventList }: AppState) => ({
  event: eventList.eventItemDetails?.eventRow,
  planogramList: eventList.planogramList,
  eventItemList: eventList.eventItemList,
  eventItemNotModifiedList: eventList.eventItemNotModifiedList,
});

export default connect(mapStateToProps, null)(ViewDistrictListEventItems);
