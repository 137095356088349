import React, { ReactNode } from 'react';

type ButtonGroupType = {
  styles?: string;
  children: ReactNode;
};

export default function Container({ styles, children }: ButtonGroupType) {
  return (
    <div className={`flex items-center gap-3 px-4 py-2 ${styles ?? ''}`}>
      {children}
    </div>
  );
}
