import { connect } from 'react-redux';
import eventUploadFileAction from 'redux/actions/uploadEventsAction';

import Fileupload from './Fileupload';

const mapDispatchToProps = () => ({
  eventUploadFileAction,
});

export default connect(null, mapDispatchToProps)(Fileupload);
