import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import UploadEvents from './UploadEvents';

const mapStateToProps = ({ eventUpload }: AppState) => ({
  itemEventMap: eventUpload.itemEventMap,
  uploadedEventList: eventUpload.uploadedEventList,
  hasError: eventUpload.hasError,
  errorFile: eventUpload.errorFile,
  eventUploadResObj: eventUpload.eventUploadResObj,
});

export default connect(mapStateToProps, null)(UploadEvents);
