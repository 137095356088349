/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

interface IButton {
  type?: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  [x: string]: any;
}

export default function Button({
  type,
  text,
  onClick,
  disabled = false,
  ...props
}: IButton) {
  const disabledClassContained = disabled
    ? 'cursor-not-allowed opacity-50'
    : 'hover:bg-blue-600';
  const disabledClassOutline = disabled
    ? 'cursor-not-allowed opacity-50'
    : 'hover:bg-[#deeefc]';

  return type === 'contained' ? (
    <button
      type="button"
      onClick={onClick}
      className={`font-sm flex h-9 items-center rounded-md bg-primary px-6 text-base text-white transition-all  focus:outline-none focus:ring-4 focus:ring-blue-300 ${disabledClassContained}`}
      {...props}
      data-testid={`${text}-button`}
      disabled={disabled}

    >
      {text}
    </button>
  ) : (
    <button
      type="button"
      onClick={onClick}
      className={`font-sm h-9 rounded-md border border-primary bg-white px-6 text-base text-primary transition-all  focus:outline-none focus:ring-4 focus:ring-gray-200 ${disabledClassOutline}`}
      {...props}
      data-testid={`${text}-button`}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
