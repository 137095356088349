import axios from 'axios';
import { authProvider } from './authProvider';
import store from '../redux/store';
import { API_IS_PENDING, API_NOT_PENDING } from '../redux/actions/types';
import { findRole } from './role';

export const instance = axios.create({});
export const axiosInstanceWithOutLoader = axios.create({});
export const axiosInstanceWithCustomRole = axios.create({});
const { CancelToken } = axios;
const source = CancelToken.source();

let isTestEnv = false;

export const setTestEnv = (value: boolean) => {
  isTestEnv = value;
};
const { userRoleValue, userRoleType } = findRole();
// Add a request interceptor
instance.interceptors.request.use(async (config: any): Promise<any> => {
  const updatedConfig = config;
  try {
    if (!isTestEnv) {
      const auth = authProvider();
      const { accessToken } = auth;
      store.dispatch({ type: API_IS_PENDING });

      if (accessToken) {
        updatedConfig.headers['Content-Type'] = 'application/json';
        updatedConfig.headers.roleValue = userRoleValue;
        updatedConfig.headers['Access-Control-Allow-Credentials'] = true;
        updatedConfig.headers.Authorization = auth.apiToken;
        updatedConfig.headers['Ocp-Apim-Subscription-Key'] =
          store?.getState()?.common?.env?.data?.apimSubscriptionKey;
        updatedConfig.headers.role = userRoleType;
        return updatedConfig;
      }
      auth.clearSession();
      source.cancel('Access Token not available');
    }
    return updatedConfig;
  } catch (error) {
    // Handle Error
    return updatedConfig;
  }
});

instance.interceptors.response.use(
  (response: any) => {
    store.dispatch({ type: API_NOT_PENDING });

    return response;
  },
  (error: any) => {
    store.dispatch({ type: API_NOT_PENDING });

    if (!error.response) {
      if (error.message.match('cancelToken')) {
        throw new Error(JSON.stringify({ message: 'Session Expired' }));
      }
      throw new Error(JSON.stringify({ message: error.message }));
    } else {
      throw new Error(JSON.stringify(error.response.data));
    }
  },
);
// Add a request interceptor without loader
axiosInstanceWithOutLoader.interceptors.request.use(
  async (config: any): Promise<any> => {
    const updatedConfig = config;

    try {
      if (!isTestEnv) {
        const auth = authProvider();
        const { accessToken } = auth;

        if (accessToken) {
          updatedConfig.headers['Content-Type'] = 'application/json';
          updatedConfig.headers.userId = auth.userId;
          updatedConfig.headers['Access-Control-Allow-Credentials'] = true;
          updatedConfig.headers.Authorization = auth.apiToken;
          updatedConfig.headers['Ocp-Apim-Subscription-Key'] =
            store?.getState()?.common?.env?.data?.apimSubscriptionKey;
            updatedConfig.headers.roleValue = userRoleValue;
            updatedConfig.headers.role = userRoleType;
    

          return updatedConfig;
        }
        auth.clearSession();
        source.cancel('Access Token not available');
      }
      return updatedConfig;
    } catch (error) {
      // Handle Error
      return updatedConfig;
    }
  },
);

axiosInstanceWithOutLoader.interceptors.response.use(
  (response: any) => 
 response,
  
  (error: any) => {

    if (!error.response) {
      if (error.message.match('cancelToken')) {
        throw new Error(JSON.stringify({ message: 'Session Expired' }));
      }
      throw new Error(JSON.stringify({ message: error.message }));
    } else {
      throw new Error(JSON.stringify(error.response.data));
    }
  },
);

// Add a request interceptor with custom role value
axiosInstanceWithCustomRole.interceptors.request.use(async (config: any): Promise<any> => {
  const updatedConfig = config;
  try {
    if (!isTestEnv) {
      const auth = authProvider();
      const { accessToken } = auth;
      store.dispatch({ type: API_IS_PENDING });

      if (accessToken) {
        updatedConfig.headers['Content-Type'] = 'application/json';
        updatedConfig.headers['Access-Control-Allow-Credentials'] = true;
        updatedConfig.headers.Authorization = auth.apiToken;
        updatedConfig.headers['Ocp-Apim-Subscription-Key'] =
          store?.getState()?.common?.env?.data?.apimSubscriptionKey;
        return updatedConfig;
      }
      auth.clearSession();
      source.cancel('Access Token not available');
    }
    return updatedConfig;
  } catch (error) {
    // Handle Error
    return updatedConfig;
  }
});
axiosInstanceWithCustomRole.interceptors.response.use(
  (response: any) => {
    store.dispatch({ type: API_NOT_PENDING });

    return response;
  },
  (error: any) => {
    store.dispatch({ type: API_NOT_PENDING });

    if (!error.response) {
      if (error.message.match('cancelToken')) {
        throw new Error(JSON.stringify({ message: 'Session Expired' }));
      }
      throw new Error(JSON.stringify({ message: error.message }));
    } else {
      throw new Error(JSON.stringify(error.response.data));
    }
  },
);

export default instance;
