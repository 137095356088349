/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-else-return */
import Button from 'components/Button';
import RTable from 'components/RTable';
import React, { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import useUpdateEvent from 'hooks/useUpdateEvent';
import { toast } from 'react-toastify';
import EditableCell from 'pages/Events/UpdateEventItems/EditableCell';
import { IDivList } from 'redux/actions/eventListAction/eventListAction';
import { DateFormatmmddyyyy, DateFormatyyyymmdd } from 'utils/dateFormatter';
import NoData from 'components/NoData';

export interface ITypeEventItem {
  adminClDate: string;
  adminOpDate: string;
  corpId: string;
  divClDate: string;
  divName: string;
  divOpDate: string;
  divsion: string;
  salesHistoryEndDate: string;
  salesHistoryStartDate: string;
}
interface IFileUploadModal {
  title: string;
  isModalOpen: boolean;
  updateEventDetails: Array<any>;
  event: any;
  onClose: () => void;
}

export default function UpdateEvent({
  title,
  isModalOpen,
  updateEventDetails,
  event,
  onClose,
}: IFileUploadModal) {
  const { updateSalesHistory, updateEventDates, updateEvent } =
    useUpdateEvent();

  const { ref, height } = useResizeObserver<HTMLDivElement>();

  const [adminOpenDate, setAdminOpenDate] = useState('');
  const [adminCloseDate, setAdminCloseDate] = useState('');
  const [divOpenDate, setDivOpenDate] = useState('');
  const [divCloseDate, setDivCloseDate] = useState('');
  const [salesHistoryOpenDate, setSalesHistoryOpenDate] = useState('');
  const [salesHistoryCloseDate, setSalesHistoryCloseDate] = useState('');
  const [uploadData, setUploadData] = useState<IDivList[] | null | undefined>(
    updateEventDetails,
  ); // State to handle editable data in Table
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    setDivOpenDate('');
    setDivCloseDate('');
    setUploadData(null);
  }, [event]);
  useEffect(() => {
    if (divCloseDate !== null && divOpenDate !== null && uploadData) {
      setUploadData(null);
      const modifiedArray = uploadData.map((item: any) => ({
        ...item,
        divClDate: divCloseDate,
        divOpDate: divOpenDate,
      }));
      setUploadData(modifiedArray);
    }
  }, [divOpenDate, divCloseDate]);

  useEffect(() => {
    if (updateEventDetails) {
      setUploadData(updateEventDetails);
      const update = updateEventDetails[0];
      const {
        adminOpDate,
        adminClDate,
        salesHistoryStartDate,
        salesHistoryEndDate,
      } = update;
      const currentDate = new Date().toISOString().split('T')[0];
      setAdminOpenDate(
        adminOpDate ? DateFormatyyyymmdd(adminOpDate) : currentDate,
      );
      setAdminCloseDate(DateFormatyyyymmdd(adminClDate));
      setSalesHistoryOpenDate(DateFormatyyyymmdd(salesHistoryStartDate));
      setSalesHistoryCloseDate(DateFormatyyyymmdd(salesHistoryEndDate));
    }
  }, [updateEventDetails]);
  // Disable table refresh on input change
  useEffect(() => {
    setSkipPageReset(false);
  }, [uploadData]);

  const checkSalesHistoryValid = () => {
    const shOpenDate = new Date(salesHistoryOpenDate);
    const shCloseDate = new Date(salesHistoryCloseDate);
    const oneDay = 24 * 60 * 60 * 1000;
    const Days = Math.ceil(
      Math.abs(shOpenDate.getTime() - shCloseDate.getTime()) / oneDay,
    );
    if (
      salesHistoryOpenDate > salesHistoryCloseDate &&
      salesHistoryCloseDate !== '' &&
      salesHistoryOpenDate !== ''
    ) {
      toast.warning(
        'Sales history Open Date should be less than Sales history Close Date',
      );
      return true;
    } else if (Days > 365) {
      toast.warning(
        'Sales history open date and close date duration should not be more than a year.',
      );
      return true;
    }
    return false;
  };
  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setUploadData((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };
  const onSaveHandler = async () => {
    let isValid = true;
    if (adminCloseDate === '') {
      toast.warning('Admin Close Date should not be empty');
      isValid = false;
      throw new Error('error');
    }
    uploadData?.forEach((item) => {
      if (item?.divClDate === '' || item?.divOpDate === '') {
        toast.warning(
          `${item?.divName} - Division Close Date and Division Open Date should not be empty`,
        );
        isValid = false;
        throw new Error('error');
      } else if (item?.divClDate > adminCloseDate) {
        toast.warning(
          `${item?.divName} - Division Close Date should be in between Admin Open Date and Admin Close Date`,
        );
        isValid = false;
        throw new Error('error');
      } else if (item?.divClDate < item?.divOpDate) {
        toast.warning(
          `${item?.divName} - Division Close Date should be greater than Division Open Date`,
        );
        isValid = false;
        throw new Error('error');
      }
    });

    if (isValid) {
      const responseArray = uploadData?.map((item: any) => ({
        ...item,
        divClDate: DateFormatmmddyyyy(item.divClDate),
        divOpDate: DateFormatmmddyyyy(item.divOpDate),
      }));
      const res = await updateEventDates({
        adminCloseDt: DateFormatmmddyyyy(adminCloseDate) as any,
        adminOpenDt: DateFormatmmddyyyy(adminOpenDate) as any,
        corp: event.corpId,
        distCloseDt: '',
        distOpenDt: '',
        divCloseDtMaster: '',
        divList: responseArray,
        divOpenDtMaster: '',
        eventId: event.eventId,
        storeCloseDt: '',
        storeOpenDt: '',
      });
      if (res?.status === 200) {
        updateEvent({
          eventNumber: event.eventId,
          programNo: event.programNumber,
          programYr: event.programYear,
        });
      } else {
        toast.error('Event items save failed..');
      }
    }
  };
  const column1 = [
    {
      Header: 'Division',
      accessor: 'divsion',
    },
    {
      Header: 'Division Name',
      accessor: 'divName',
      width: '300',
    },
    {
      Header: 'Open Date',
      accessor: 'divOpDate',
      Cell: EditableCell,
      width: 70,
      validation: {
        type: 'date',
        min: adminOpenDate,
        max: adminCloseDate,
      },
    },
    {
      Header: 'Close Date',
      accessor: 'divClDate',
      Cell: EditableCell,
      width: 70,
      validation: {
        type: 'date',
      },
    },
  ];

  return (
    <div
      id="popup-modal"
      className={`${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="max-h-[98%] min-h-[98%] min-w-[30%] overflow-auto rounded-lg border bg-white shadow-2xl">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" type="button" onClick={onClose} data-testid='close-updateEventmodal'>
            X
          </button>
        </div>
        <div ref={ref} className="pt-[22px] ">
          <div className="flex items-start pl-3 pr-8">
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="admin-open-date">
                Admin Open Date:
              </label>
              <input
                className=" h-full border-2"
                type="date"
                id="admin-open-date"
                name="Admin open date"
                value={adminOpenDate}
                onChange={(e) => setAdminOpenDate(e.target.value)}
                disabled
                data-testid='admin-open-date-input'
              />
            </div>
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="admin-close-date">
                Admin Close Date:
              </label>
              <input
                className=" h-full border-2"
                type="date"
                id="admin-close-date"
                name="Admin Close date"
                min={adminOpenDate}
                value={adminCloseDate}
                onChange={(e) => setAdminCloseDate(e.target.value)}
                data-testid='admin-close-date-input'

              />
            </div>
          </div>
          <div className="flex items-center pt-5 pl-3">
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="div-open-date">
                Div Open Date:
              </label>
              <input
                className=" h-full border-2"
                type="date"
                max={adminCloseDate}
                min={adminOpenDate}
                id="div-open-date"
                name="div-open-date"
                value={divOpenDate}
                onChange={(e) => setDivOpenDate(e.target.value)}
                data-testid='div-open-date-input'

              />
            </div>
            <div className="flex h-9 items-center pl-10">
              <label className="pr-4" htmlFor="div-close-date">
                Div Close Date:
              </label>
              <input
                className=" h-full border-2"
                type="date"
                max={adminCloseDate}
                min={divOpenDate}
                id="div-close-date"
                name="div-close-date"
                value={divCloseDate}
                onChange={(e) => setDivCloseDate(e.target.value)}
                data-testid='div-close-date-input'

              />
            </div>
          </div>
          <div className="flex items-center pt-5 pl-3">
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="hist-close-date">
                Sales History Open Date:
              </label>
              <input
                className=" h-full border-2"
                type="date"
                id="hist-close-date"
                name="hist-close-date"
                value={salesHistoryOpenDate}
                onChange={(e) => setSalesHistoryOpenDate(e.target.value)}
                data-testid='hist-open-date-input'

              />
            </div>
            <div className="flex h-9 items-center pl-5">
              <label className="pr-4" htmlFor="hist-close-date">
                Sales History Close Date:
              </label>
              <input
                className=" h-full border-2"
                type="date"
                id="hist-close-date"
                name="hist-close-date"
                min={salesHistoryOpenDate}
                value={salesHistoryCloseDate}
                onChange={(e) => setSalesHistoryCloseDate(e.target.value)}
                data-testid='hist-close-date-input'

              />
            </div>
            <div className="h-9 items-center pl-4">
              <Button
                type="contained"
                text="Save"
                onClick={() => onSaveHandler()}
              />
            </div>
            <div className="h-9 items-center pl-3 pr-4 ">
              <Button
                type="contained"
                text="Get Sales History"
                onClick={() => {
                  if (checkSalesHistoryValid() === false) {
                    updateSalesHistory({
                      eventId: event.eventId,
                      salesHistoryEndDate: DateFormatmmddyyyy(
                        salesHistoryCloseDate,
                      ) as any,
                      salesHistoryStartDate: DateFormatmmddyyyy(
                        salesHistoryOpenDate,
                      ) as any,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="pl-5 pr-4 pt-4">
          {uploadData ? (
            <RTable
              columns={column1}
              isfilter
              updateData={updateTableData}
              skipPageReset={skipPageReset}
              data={uploadData as any}
              isTotal
              height={height}
              hiddenColumns={[]}
              data-testid='updateEvent-table'
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}
