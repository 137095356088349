import axios from 'utils/interceptor';
import { AnyAction } from "redux";
import { apiBaseURL } from 'utils/apiBaseUrl';
import { GET_FAQ } from './types';
import { userId } from 'layouts/Header/Header';
import { ThunkAppDispatch, useAppThunkDispatch } from 'redux/store';
import { toast } from 'react-toastify';
import { Dispatch } from 'react';


export const getFaq = (pageName: string) => (dispatch: ThunkAppDispatch) => {
  axios
    .get(`${apiBaseURL}/ssa/getFaq?userName=${userId}&page=${pageName}`, {
    })
    .then((response) => {
      console.log("response", response)
      const responseData = response?.data
      let tempData = [];

      if (responseData.length > 0) {
        tempData = responseData.map((data: any, index: number) => ({
          ...data, id: index + 1
        }))
      }
      dispatch({ type: GET_FAQ, payload: tempData });
    })
    .catch((error) => {
      const errorMsg = error.message;
      toast.error(`getFaqAdminEdit API call failed`, errorMsg);
    });
};

/**
 * Saves or updates a FAQ entry in the admin panel.
 * 
 * @param value - The data to be saved or updated.
 * @returns A Promise that resolves to the response from the API call.
 */
export const saveFaqAdmin =
  (value: any) => async (dispatch: ThunkAppDispatch) => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/ssa/saveFaq`, value,
        {
          headers: {
            userName: userId
          }
        },
      );
      if (response.status === 201) {
        toast.success('Save/Update successfully');
        dispatch(getFaq("all"));
      }
      return response;
    } catch (error) {
      toast.error('Failed saveFaqAdmin');
      return new Error(`save/update API call failed`);
    }
  };

export const deleteFaqAdmin =
  (value: any) => async (dispatch: ThunkAppDispatch) => {
    const { faqEventId } = value
      ; try {
        const response = await axios.delete(
          `${apiBaseURL}/ssa/deleteFaq`, {
          headers: {
            userName: userId
          },
          data: {
            faqEventId
          }
        }
        );
        if (response.status === 200) {
          toast.success('Deleted successfully');
          dispatch(getFaq("all"));
        }
        return response;
      } catch (error) {
        toast.error('Faied deleteFaqAdmin');
        return new Error(`Delete API call failed`);
      }
  };
