import React, { ReactNode } from 'react';

type ButtonGroupType = {
  styles?: string;
  children: ReactNode;
};

export default function ButtonGroup({ styles, children }: ButtonGroupType) {
  return (
    <div className={`flex items-center gap-3 ${styles ?? ''}`} data-testid='butn-grp'>{children}</div>
  );
}
