/* eslint-disable react/jsx-props-no-spreading */

import Banner from 'components/Banner';
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import useEventItem from 'hooks/useEventItem';
import useEventItemDetails from 'hooks/useEventItemDetails';
import useEventItemDetailsForView from 'hooks/useEventItemDetailsForView';
import useEventItemForView from 'hooks/useEventItemForView';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import applyLocaleString from 'utils/applyLocaleString';

function ViewDistStoreDetaiItems({
  eventItemListDetails,
  event,
  allStoreItem,
}: any) {
  const { eventId = 0, itemNumber = 0, distId, divId } = useParams();

  const { ref, height } = useResizeObserver<HTMLDivElement>();

  const { fetchEventItemListDetails } = useEventItemDetails();
  const fetchEventItemListDetailsForView = useEventItemDetailsForView();

  const [eventStoreItem, setEventStoreItem] = React.useState([]);

  const [selectedRow, setSelectedRow] = React.useState<any>([]);

  const { fetchEventItemList } = useEventItem();
  const fetchEventItemListForView = useEventItemForView();

  useEffect(() => {
    // fetchEventItemList({
    //   eventId: eventId.toString(),
    //   programSK: '',
    //   pogSk: '',
    // });
    fetchEventItemListForView({
      eventId: eventId.toString(),
      programSK: '',
      pogSk: '',
      roleForView: 'dist',
      roleValueForView:`${divId}.${distId}`,
    });
  }, [eventId]);

  useEffect(() => {
    const storeData = allStoreItem.find(
      (item: any) => item.itemNumber === itemNumber,
    );
    const thisRow: any = [];
    const storeTableFunction = (item: any) => {
      if (item?.masterItemId === itemNumber) {
        if (Array.isArray(item.subRows)) {
          storeTableFunction(item.subRows);
          thisRow.push(item, ...item.subRows);
        }
      }
    };
    storeTableFunction(storeData);
    const storeTableData = thisRow.map((item: any) => ({
      ...item,
      eachCostwith$:
        parseFloat(item?.eachCost) === 0
          ? '$0.00'
          : `$${applyLocaleString(item?.eachCost)}`,
    }));

    setSelectedRow(storeTableData);
  }, [allStoreItem, itemNumber]);

  useEffect(() => {
    if (event?.eventId) {
      // fetchEventItemListDetails({
      //   eventId: event?.eventId,
      //   itemNumber: event?.itemNumber,
      // });
      fetchEventItemListDetailsForView({
        eventId: event?.eventId,
        itemNumber: event?.itemNumber,
        roleForView: 'dist',
        roleValueForView: `${divId}.${distId}`,
      });
    } else {
      // fetchEventItemListDetails({
      //   eventId: eventId.toString(),
      //   itemNumber: itemNumber.toString(),
      // });
      fetchEventItemListDetailsForView({
        eventId: eventId.toString(),
        itemNumber: itemNumber.toString(),
        roleForView: 'dist',
        roleValueForView:`${divId}.${distId}`,
      });
    }
  }, [eventId, itemNumber]);
  useEffect(() => {
    if (eventItemListDetails) {
      setEventStoreItem(eventItemListDetails);
    }
  }, [eventItemListDetails]);

  const columns1 = [
    {
      Header: 'Item Number',
      accessor: 'itemNumber',
      width: 30,
    },
    {
      Header: 'Item Description',
      accessor: 'itemDesc',
      width: 40,
    },

    {
      Header: 'Pack',
      accessor: 'pack',
    },
    {
      Header: 'Size',
      accessor: 'size',
    },
    {
      Header: 'Each Cost',
      accessor: 'eachCostwith$',
    },
  ];
  const columns2 = [
    {
      Header: 'Store Number',
      accessor: 'storeId',
    },
    {
      Header: 'Suggested National Ad Quantity',
      accessor: 'suggestedAdminQty',
    },
    {
      Header: 'Division Suggested Quantity',
      accessor: 'suggestedDivQty',
    },
    {
      Header: 'District Suggested Quantity',
      accessor: 'suggestedDisQty',
    },
    {
      Header: 'Store Adjusted Quantity',
      accessor: 'adjustedStoreQty',
    },
    {
      Header: 'District Adjusted Quantity',
      accessor: 'adjustedDisQty',
    },
    {
      Header: 'Sales History Quantity',
      accessor: 'salesHistoryQty',
    },
  ];

  return (
    <div>
      <div ref={ref} className="pt-1">
        <Banner title="Event Store Items"> </Banner>
        <div className="pr-4 pl-4 pt-4" role="tabpanel">
          {selectedRow.length > 0 ? (
            <RTable
              columns={columns1}
              isfilter={false}
              data={selectedRow}
              isTotal={false}
              hiddenColumns={[]}
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>

      <div className="pt-4 pl-4 pr-4" role="table">
        {eventStoreItem && eventStoreItem.length > 0 ? (
          <RTable
            columns={columns2}
            isfilter
            data={eventStoreItem}
            isTotal
            height={height}
            hiddenColumns={[]}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ eventList }: AppState) => ({
  eventItemListDetails: eventList.eventItemListDetails,
  event: eventList.eventItemDetails?.eventRow,
  allStoreItem: eventList.eventItemList,
});

export default connect(mapStateToProps, null)(ViewDistStoreDetaiItems);
