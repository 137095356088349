type ValidationTypes =
  | 'isNumber'
  | 'isDecimal'
  | 'isNumbericDecimal'
  | 'isAlphaNumeric'
  | 'isLocation'
  | 'isEmail'
  | 'isUPC'
  | 'isType'
  | 'isFirstTwoNum'
  | 'isNumberWithDecimal'
  | 'isNumberWithZeroDecimal';
type ValidationRegexType = Record<ValidationTypes, RegExp>;

// Only add attributes whict are part of input element. Refer below link
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
export type InputValidationType = {
  max?: string;
  min?: string;
  maxLength?: string;
  minLength?: string;
  pattern?: RegExp;
  required?: boolean;
  type?: 'text' | 'number' | 'date' | 'tel' | 'email' | 'date';
  errorMessage?: string;
  disable?: boolean;
  custom?: (value: any) => boolean;
  value?: any;
};

export const regex: ValidationRegexType = {
  isNumber: /^\d+$/,
  isUPC: /^(\d{14})*$/,
  isDecimal: /^\d*\.\d+$/,
  // isNumbericDecimal: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
  isNumbericDecimal: /^(-|\+)?(([1-9][0-9]*)|(0))(?:\.[0-9]+)?$/,
  isAlphaNumeric: /^[a-zA-Z0-9 ]*$/,
  isLocation: /^[a-zA-Z0-9_@ ]*$/,
  isEmail: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
  isType: /^[A-Za-z{1}]+$/,
  isFirstTwoNum: /^\d{2}/,
  // isNumberWithDecimal: /^([0-9]+\.?[0-9]*|\.[0-9]+)$/,
  isNumberWithDecimal: /^(\+)?(([1-9][0-9]*)|(0))(?:\.[0-9]+)?$/,
  isNumberWithZeroDecimal: /^[1-9]\d*(\.[0]+)?$/,
};
