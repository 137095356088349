export const wscolsItemOrder = [
  { wch: 12 },
  { wch: 18 },
  { wch: 40 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
export const wscolsStoreOrder = [
  { wch: 30 },
  { wch: 12 },
  { wch: 12 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
export const wscolsDistrictOrder = [
  { wch: 12 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
export const wscolsDivisionOrder = [
  { wch: 30 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
