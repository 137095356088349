export const pageListObject: any = {
    "home": "Home",
    "upload-events": "Upload Events",
    "cost-target": "Cost Target",
    "update-group": "Update Store Group",
    "eventItemStoreView": "Event Item Store View As",
    "viewAsDiv": "View As",
    "storeGroup": "Store Group",
    "event-stores-items-admin": "Event Stores Items Admin",
    "event-stores-items-division": "Event Stores Items Division",
    "eventItemDetailDist": "Event Stores Items District",
    "event-items-admin": "Event Items Admin",
    "event-items-division": "Event Items Division",
    "eventItems-dist": "Event Items Dist",
    "event-items-store": "Event Items Store",
    "event-list": "Event List",
    "update-event-items": "Update Event Items",
    "upload-to-spa": "Upload To SPA",
    "division-order-totals": "Division Order Totals",
    "district-order-totals": "District Order Totals",
    "store-order-totals": "Store Order Totals",
    "item-order-totals": "Item Order Totals",
}

export const faqExcludedPageListObject: any = {
    "frequently-asked-questions-view": "Frequently Asked Questions and Answers",
    "frequently-asked-questions": "Frequently Asked Questions"
}
