import Banner from 'components/Banner';
import Button from 'components/Button';
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import roleCapability from 'constants/roleCapability';
import React, { LinkHTMLAttributes, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import userRoleCheck from 'utils/role';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppState, useAppThunkDispatch } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getStoreGroupQuantity,
  saveStoreGroupQuantity,
} from 'redux/actions/storeGroupAction';
import { IStoreGroup, IStoreGroupQuantity } from './interface';
import EditableCell from 'pages/Events/EventList/Admin/EditableCell';
import { regex } from 'utils/validations';
import useEventItem from 'hooks/useEventItem';
import { toast } from 'react-toastify';
import applyLocaleString from 'utils/applyLocaleString';
import Select, { OnChangeValue } from 'react-select';
import { ConsoleConstructorOptions } from 'console';

interface IFStoreGroup {
  store: string;
  value: string;
}

function StoreGroupQuantity() {
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const { UserRoleMaintenance } = userRoleCheck(roleCapability);
  const navigate = useNavigate();
  const { storeGroupQuantityList } = useSelector(
    (state: AppState) => state.storeGroup,
  );
  const dispatch = useAppThunkDispatch();

  const { fetchEventItemList } = useEventItem();
  const { itemNumber = '', eventId = '', close } = useParams();
  const { eventItemList } = useSelector((state: AppState) => state.eventList);
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const [storeGroupQuantity, setStoreGroupQuantity] = useState<any>([]);
  const [storeGroup, setStoreGroup] = useState<IFStoreGroup[] | []>();
  const [selectedStoreGroupNames, setSelectedStoreGroupNames] = useState<any>();


  const columns1 = [
    {
      Header: 'Item Number',
      accessor: 'itemNumber',
      width: 30,
    },
    {
      Header: 'Item Description',
      accessor: 'itemDesc',
      width: 40,
    },

    {
      Header: 'Pack',
      accessor: 'pack',
    },
    {
      Header: 'Size',
      accessor: 'size',
    },
    {
      Header: 'Each Cost',
      accessor: 'eachCostwith$',
    },
  ];

  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    // setSkipPageReset(true);
    setStoreGroupQuantity((old: any) =>
      old?.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const handleGoClick = () => {
    if (selectedStoreGroupNames === undefined || selectedStoreGroupNames?.length < 1) {
      toast.warning('Please select atleast one store group');

      setStoreGroupQuantity(selectedStoreGroupNames);
    }
    else {
      const arrTemp = selectedStoreGroupNames.map((item: any, index: number) => ({
        ...item, id: index + 1
      }))
      setStoreGroupQuantity(arrTemp);
    }
  };
  const handleSave = () => {
    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    const filteredStoreGroupQuantity = storeGroupQuantity
      .filter(
        (item: any) =>
          item.adjustedDivQty !== '' || item.suggestedDivQty !== '',
      )
      .map((item: any) => ({
        ...item, eventId,
        seasItemId: selectedItem[0]?.itemNumber,
        adjustedDivQty: item.adjustedDivQty === '' ? null : item.adjustedDivQty,
        suggestedDivQty:
          item.suggestedDivQty === '' ? null : item.suggestedDivQty,
      }));

    if (filteredStoreGroupQuantity.length > 0) {
      dispatch(saveStoreGroupQuantity({ storeGroupQuantity: filteredStoreGroupQuantity, eventId, itemNumber }));
    } else {
      toast.error('Please enter quantity');
    }
  };

  function RadioButton({ selectedValue, rawValue }: any) {
    const [value, setValue] = useState(selectedValue ?? false);

    const handleChange = async (ev: any) => {
      setValue(ev.target.checked);
      const tempArr: any = [...storeGroupQuantity];
      tempArr[rawValue.id - 1] = { ...tempArr[rawValue.id - 1], storeGroupLockedFlag: ev.target.checked ? "Y" : "N" }
      setStoreGroupQuantity(tempArr);
    };

    return (
      <input
        disabled={!(rawValue?.storeGroupLockedType === 'SGPLK')}
        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
        type="checkbox" onChange={handleChange} name="allSelect" checked={value} />
    )
  }

  const groupColumns = [
    {
      Header: 'Group',
      accessor: 'storeGroupName',
      width: 100,
    },
    {
      Header: 'Stores',
      accessor: 'storeNumber',
      Cell: (row: any) =>
        row.cell.row.original.storeNumber.length < 15 ? (
          <span>{row.cell.row.original.storeNumber.join(', ')}</span>
        ) : (
          <span title={row.cell.row.original.storeNumber.join(', ')}>
            {row.cell.row.original.storeNumber
              .slice(0, 15)
              .join(',')
              .concat('...')}
          </span>
        ),
    },
    {
      Header: 'Store Group Lock',
      accessor: 'lock',
      width: 30,
      Cell: (row: any) => {
        const hasLock = row?.cell?.row?.original?.storeGroupLockedFlag === 'Y';
        // eslint-disable-next-line no-nested-ternary
        return (

          <RadioButton
            selectedValue={row?.cell?.row?.original?.storeGroupLockedFlag === "Y"}
            rawValue={row?.cell?.row?.original}
          />
        );
      },
    },
    {
      Header: 'Suggested Division Quantity',
      accessor: 'suggestedDivQty',
      width: 60,
      validation: {
        min: 0,
        type: 'number',
        pattern: regex.isNumber,
        disable: close === 'true' || selectedItem[0]?.lockFlag === 'Y',
        errorMessage: 'Suggested Division Quantity should be a positive number',
        minLength: 0,
        maxLength: 10,
      },
      Cell: EditableCell,
    },
    {
      Header: 'Adjusted Division Quantity',
      accessor: 'adjustedDivQty',
      width: 60,
      validation: {
        min: 0,
        type: 'number',
        pattern: regex.isNumber,
        disable: close === 'true' || selectedItem[0]?.lockFlag === 'Y',
        errorMessage: 'Adjusted Division Quantity should be a positive number',
        minLength: 0,
        maxLength: 10,
      },
      Cell: EditableCell,
    },
    {
      Header: 'Updated User',
      accessor: 'lastUpdatedUser',
      width: 60,
    },
    {
      Header: 'Updated Date',
      accessor: 'lastUpdatedTS',
      width: 60,
      Cell: (row: any) => {
        if (row.cell.row.original.lastUpdatedTS !== undefined) {
          let updateDate = row.cell.row.original.lastUpdatedTS.split('T');
          updateDate = updateDate[0].split('-');
          return updateDate.length > 0
            ? `${updateDate[1]}/${updateDate[2]}/${updateDate[0]}`
            : '';
        }
        return '';
      },
    },
  ];

  useEffect(() => {
    dispatch(getStoreGroupQuantity({ eventId, itemNumber }));
  }, []);

  useEffect(() => {
    fetchEventItemList({
      eventId: eventId.toString(),
      programSK: '',
      pogSk: '',
    });
  }, [eventId]);

  useEffect(() => {
    let filtertedList = eventItemList.filter(
      (item: any) => item.itemNumber === itemNumber,
    );

    const subRows = filtertedList.reduce(
      (acc: any, curr: any) => curr.subRows,
      [],
    );

    filtertedList = [...filtertedList, ...subRows];

    filtertedList = filtertedList.map((item: any) => ({
      ...item,
      eachCostwith$:
        parseFloat(item?.eachCost) === 0
          ? '$0.00'
          : `$${applyLocaleString(item?.eachCost)}`,
    }));
    setSelectedItem(filtertedList);
  }, [eventItemList, itemNumber]);

  useEffect(() => {
    if (storeGroupQuantityList?.length > 0) {
      const filterdModifiedStoreGroups1 = storeGroupQuantityList.filter((item: IStoreGroupQuantity) => item?.storeNumber?.length > 0)
      setStoreGroup(filterdModifiedStoreGroups1);
      if (selectedStoreGroupNames) {
        setSelectedStoreGroupNames(selectedStoreGroupNames);
        const modifiedSelectedStoreGroupNames = [];
        for (let index = 0; index < selectedStoreGroupNames?.length; index += 1) {
          for (let index1 = 0; index1 < storeGroupQuantityList.length; index1 += 1) {
            if (storeGroupQuantityList[index1].storeGroupId === selectedStoreGroupNames[index].storeGroupId) {
              modifiedSelectedStoreGroupNames.push(storeGroupQuantityList[index1])
            }
          }
        }
        let arrTemp = [];
        if (modifiedSelectedStoreGroupNames.length > 0) {
          arrTemp = modifiedSelectedStoreGroupNames.map((item: any, index: number) => ({
            ...item, id: index + 1
          }))
        }
        setStoreGroupQuantity(arrTemp);
        setSelectedStoreGroupNames(arrTemp);
        const modifiedStoreGroups = storeGroupQuantityList.map(
          (item: IStoreGroupQuantity) => ({
            ...item,
            isDisabled: false
          }),
        );
        const filterdModifiedStoreGroups = modifiedStoreGroups.filter((item: IStoreGroupQuantity) => item?.storeNumber?.length > 0)
        const copyStoreGroupMain: any = [...filterdModifiedStoreGroups];
        for (let index = 0; index < selectedStoreGroupNames.length; index += 1) {
          const element: any = selectedStoreGroupNames[index];
          if (element?.storeNumber) {
            const tempStores = element?.storeNumber
            for (let index2 = 0; index2 < tempStores?.length; index2 += 1) {
              const element2 = tempStores[index2];
              for (let index3 = 0; index3 < copyStoreGroupMain.length; index3 += 1) {
                const element3 = copyStoreGroupMain[index3];
                const tempStores1 = element3?.storeNumber
                for (let index4 = 0; index4 < tempStores1?.length; index4 += 1) {
                  const element4 = tempStores1[index4];
                  if (element4 === element2 && element3?.value !== element?.value) {
                    copyStoreGroupMain[index3].isDisabled = true;
                  }
                  // else if (element4 === element2 && element3?.value === element?.value) {
                  //   copyStoreGroupMain[index3].isDisabled = false;
                  // }
                }
              }
            }
            // console.log(copyStoreGroupMain)
            setStoreGroup(copyStoreGroupMain)
          }
        }
      }

      // const modifiedStoreGroups = storeGroupQuantityList.map(
      //   (item: IStoreGroupQuantity) => ({
      //     ...item,
      //     divId: null,
      //     eventId,
      //     seasItemId: itemNumber,
      //     suggestedDivQty: item.suggestedDivQty ? item.suggestedDivQty : '',
      //     adjustedDivQty: item.adjustedDivQty ? item.adjustedDivQty : '',
      //   }),
      // );
      // setStoreGroupQuantity(modifiedStoreGroups);
    }
  }, [storeGroupQuantityList]);
  useEffect(() => {
    if (selectedStoreGroupNames?.length < 1) {
      const modifiedStoreGroups = storeGroupQuantityList.map(
        (item: IStoreGroupQuantity) => ({
          ...item,
          isDisabled: false
        }),
      );
      const filterdModifiedStoreGroups = modifiedStoreGroups.filter((item: IStoreGroupQuantity) => item?.storeNumber?.length > 0)
      setStoreGroup(filterdModifiedStoreGroups);
    }
  }, [selectedStoreGroupNames]);

  const onChange = (selectedOptions: OnChangeValue<ConsoleConstructorOptions, true>) => {
    setSelectedStoreGroupNames(selectedOptions);
    if (selectedOptions.length > 0) {
      // const temp = selectedOptions[selectedOptions.length - 1]
      const storeList: any = []
      const modifiedStoreGroups = storeGroupQuantityList.map(
        (item: IStoreGroupQuantity) => ({
          ...item,
          isDisabled: false
        }),
      );
      const filterdModifiedStoreGroups = modifiedStoreGroups.filter((item: IStoreGroupQuantity) => item?.storeNumber?.length > 0)
      const copyStoreGroupMain: any = [...filterdModifiedStoreGroups];
      for (let index = 0; index < selectedOptions.length; index += 1) {
        const element: any = selectedOptions[index];
        if (element?.storeNumber) {
          const tempStores = element?.storeNumber
          for (let index2 = 0; index2 < tempStores?.length; index2 += 1) {
            const element2 = tempStores[index2];
            for (let index3 = 0; index3 < copyStoreGroupMain.length; index3 += 1) {
              const element3 = copyStoreGroupMain[index3];
              const tempStores1 = element3?.storeNumber
              for (let index4 = 0; index4 < tempStores1?.length; index4 += 1) {
                const element4 = tempStores1[index4];
                if (element4 === element2 && element3?.value !== element?.value) {
                  copyStoreGroupMain[index3].isDisabled = true;
                }
                // else if (element4 === element2 && element3?.value === element?.value) {
                //   copyStoreGroupMain[index3].isDisabled = false;
                // }
              }
            }
          }
          // console.log(copyStoreGroupMain)
          setStoreGroup(copyStoreGroupMain)
          // if (tempStores.length > 0) {
          //   for (let index1 = 0; index1 < tempStores.length; index1 += 1) {
          //     const element1 = tempStores[index1];
          //     if (storeList.indexOf(element1) === -1) {
          //       storeList.push(element1);
          //     }

          //   }
          // }

          // storeList = element?.store.split(",")
        }

      }
      // console.log('storeList', storeList)
      // const copyStoreGroupMain = [...storeGroupMain];
      // for (let index2 = 0; index2 < copyStoreGroupMain?.length; index2 += 1) {
      //   const element2 = copyStoreGroupMain[index2];
      //   if (element2?.store) {
      //     const tempStores1 = element2?.store.split(",")
      //     for (let index3 = 0; index3 < tempStores1.length; index3 += 1) {
      //       let isBreakLoop2 = false;
      //       const element4 = tempStores1[index3];
      //       for (let index4 = 0; index4 < storeList?.length; index4 += 1) {
      //         const element5 = storeList[index4];
      //         if (element4 === element5) {
      //           delete copyStoreGroupMain[index2];
      //           isBreakLoop2 = true;
      //           break;
      //         }
      //       }
      //       if (isBreakLoop2) break;

      //     }
      //   }

      // }
    }


  }

  return (
    <div>
      <div id="section" ref={ref} className="pt-1">
        <Banner title="Store Groups">
          <Button
            type="contained"
            text="Save"
            disabled={close === 'true' || selectedItem[0]?.lockFlag === 'Y'}
            onClick={() => {
              handleSave();
            }}
            data-testid="save-button"
          />
        </Banner>

        <div className="pr-4 pl-4 pt-4">
          {selectedItem.length > 0 ? (
            <RTable
              columns={columns1}
              isfilter={false}
              data={selectedItem}
              isTotal={false}
              hiddenColumns={[]}
              data-testid="event-Store-item-table1"
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>
      <div className="pb-4 pl-4 pr-4 pt-4">
        <div className='items start mt-2 mb-2 flex w-[100%]'>
          <Select
            className="w-[500px] z-20"
            closeMenuOnSelect={false}
            defaultValue={selectedStoreGroupNames}
            onChange={onChange}
            options={storeGroup ?? []}
            isSearchable
            value={selectedStoreGroupNames}
            getOptionLabel={(option) => `${option?.value}`}
            name='id'
            isMulti
            placeholder='Select Store Groups'
          />
          <div className='pl-[0.5%]'>
            <Button
              type="contained"
              text="Go"
              onClick={() => {
                handleGoClick();
              }}
              data-testid="save-button"
            />
          </div>

        </div>

        {storeGroupQuantity && storeGroupQuantity.length > 0 ? (
          <RTable
            isfilter
            columns={groupColumns}
            data={storeGroupQuantity}
            updateData={updateTableData}
            isTotal
            height={height}
            hiddenColumns={[]}
            data-testid="view-table"
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

export default StoreGroupQuantity;