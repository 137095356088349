import { useCallback } from 'react';
import { divisionOrderTotalsSearchAction } from 'redux/actions/divisionOrderTotalsProgrameAction';

import { useAppThunkDispatch } from 'redux/store';

type UseOrderListProps = {
  distNumber?: string;
  divNumber?: string;
  itemNumber?: string;
  prgmName?: string;
  prgmYr?: string;
  strNumber?: string;
  totalType?: string;
  eventId?: any;
} | null;

const useOrderList = () => {
  const dispatch = useAppThunkDispatch();

  const fetchOrderList = useCallback((data: UseOrderListProps) => {
    dispatch(
      divisionOrderTotalsSearchAction({
        distNumber: data?.distNumber ?? '',
        divNumber: data?.divNumber ?? '',
        itemNumber: data?.itemNumber ?? '',
        prgmName: data?.prgmName ?? '',
        prgmYr: data?.prgmYr ?? '',
        strNumber: data?.strNumber ?? '',
        totalType: data?.totalType ?? '',
        eventId: data?.eventId ?? 'all',
      }),
    );
  }, []);

  return fetchOrderList;
};

export default useOrderList;
