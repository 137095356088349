import { useCallback } from 'react';
import { storeCommentAction } from 'redux/actions/eventListAction/eventListAction';

import { useAppThunkDispatch } from 'redux/store';

type UseStoreCommentProps = {
  eventId: string;
  storeNumber: string;
  programSK: string;
} | null;

const useStoreComment = () => {
  const dispatch = useAppThunkDispatch();

  const storeComment = useCallback((data: UseStoreCommentProps) => {
    dispatch(
      storeCommentAction({
        eventId: data?.eventId ?? '',
        programSK: data?.programSK ?? '',
        storeNumber: data?.storeNumber ?? '',
      }),
    );
  }, []);

  return storeComment;
};

export default useStoreComment;
