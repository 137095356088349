import applyLocaleString from './applyLocaleString';

const divisionSugWithMinus = (value: any) => {
  let divMinusWith$ = `$${applyLocaleString(value)}`;
  if (parseFloat(value) === 0) {
    divMinusWith$ = '$0.00';
  } else if (parseFloat(value) < 0) {
    divMinusWith$ = `-$${Math.abs(value).toFixed(2)}`;
  }

  return divMinusWith$;
};
export default divisionSugWithMinus;
