import { AbsLogoIcon, deleteIcon, editIcon } from 'assets';
import Banner from 'components/Banner';
import Button from 'components/Button';
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import React, { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import EditableCell from './EditableCell';
import { RoleTypeEnum } from './roleTypeEnum';
import { PageNameEnum } from './pageNameEnum';
import FAQShow from './FAQShow';
import { useSelector } from 'react-redux';
import { AppState, useAppThunkDispatch } from 'redux/store';
import { deleteFaqAdmin, getFaq, saveFaqAdmin } from 'redux/actions/faqAction';
import { toast } from 'react-toastify';
import { exit } from 'process';


export default function FaqAdminEdit() {
  const { faqList } = useSelector(
    (state: AppState) => state.faq,
  );


  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [arrFAQModified, setArrFAQModified] = useState([]);
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    dispatch(getFaq("all"));
  }, []);

  useEffect(() => {
    setArrFAQModified(faqList);
    setSkipPageReset(false);
  }, [faqList]);

  // useEffect(() => {
  //   setSkipPageReset(false);
  // }, [arrFAQModified]);

  const handleAddNewItem = () => {
    const obj = [{ faqEventId: "", id: 1, "question": "", "linkingURL": "", "text": "", "role": "All", "page": "All" }]

    const tempArrFAQModified = arrFAQModified.map((data: any) => ({
      ...data, id: data.id + 1
    }))
    const tempArr: any = obj.concat(tempArrFAQModified);
    setArrFAQModified(tempArr);
  };

  const handleSaveClick = () => {
    let isError = false;

    arrFAQModified.forEach((item: any, index: number) => {
      if (item?.question === "" || item?.text === "") {
        isError = true;
      }
    });

    if (isError) {
      toast.warning("Question and Text can not be blank.")
      return;
    }

    arrFAQModified.forEach((item: any, index: number) => {
      if (item?.linkingURL !== "" && !(item?.linkingURL.startsWith("https://aci.read.inkling.com/"))) {
        isError = true;

      }
    });

    if (isError) {
      toast.warning("Linking URL should start with https://aci.read.inkling.com/")
      return;
    }

    if (!isError) {
      dispatch(saveFaqAdmin(arrFAQModified));
    }
  }
  const handleDelete = (value: any) => {
    dispatch(deleteFaqAdmin({ "faqEventId": value }))
  };
  const handleDeleteWithOutAPI = (value: any) => {
    let arrTemp: any = [];
    if (arrFAQModified?.length > 1) {
      let count = 1;
      for (let index = 0; index < arrFAQModified.length; index += 1) {
        const element: any = arrFAQModified[index];
        if (element.id !== value) {
          element.id = count;
          count += 1;
          arrTemp.push(element);
        }

      }
    }
    else {
      arrTemp = [];
    }
    setArrFAQModified(arrTemp);
    toast.success("Deleted successfully");
  };

  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setArrFAQModified((old: any) =>
      old?.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };
  function PageNameDropDown({ selectedVal, itemRow }: any) {
    const DDRef = React.useRef<any>(null);

    const [value, setValue] = useState(
      selectedVal ?? Object.keys(PageNameEnum)[0],
    );

    const handleChange = async (ev: any) => {
      setValue(ev.target.value);
      const tempArr: any = [...arrFAQModified];
      tempArr[itemRow.id - 1] = { ...tempArr[itemRow.id - 1], page: ev.target.value }
      setArrFAQModified(tempArr);
      const updatedRow = {
        ...itemRow,
        role: ev.target.value,
      };
    };

    return (
      <select
        className="form-select appearance-display m-0 block w-full h-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-gray-700 transition focus:outline-none"
        value={value}
        onChange={handleChange}
        ref={DDRef}
      >
        {Object.entries(PageNameEnum).map((item) => (
          <option value={item[0]} key={item[0]} >
            {item[1]}
          </option>
        ))}
      </select>
    );
  }
  function RoleDropDown({ selectedVal, itemRow }: any) {
    const DDRef = React.useRef<any>(null);

    const [value, setValue] = useState(
      selectedVal ?? Object.keys(RoleTypeEnum)[0],
    );

    const handleChange = async (ev: any) => {
      setValue(ev.target.value);
      const tempArr: any = [...arrFAQModified];
      tempArr[itemRow.id - 1] = { ...tempArr[itemRow.id - 1], role: ev.target.value }
      setArrFAQModified(tempArr);
      const updatedRow = {
        ...itemRow,
        role: ev.target.value,
      };
    };

    return (
      <select
        className="form-select appearance-display m-0 block w-full h-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-gray-700 transition focus:outline-none"
        value={value}
        onChange={handleChange}
        ref={DDRef}
      >
        {Object.entries(RoleTypeEnum).map((item) => (
          <option value={item[0]} key={item[0]} >
            {item[1]}
          </option>
        ))}
      </select>
    );
  }
  const columns1 = [
    {
      Header: '',
      accessor: 'col2',
      width: 5,
      Cell: (row: any) => {
        const disabled =
          row?.cell?.row?.original.faqEventId !== "";
        const isDisabledClass = !disabled ? 'opacity-25' : 'opacity-100';
        return (
          <div className={` flex items-center justify-center max-w-[25px]`}>
            <button
              type="button"
              data-testid='delete-button'
              onClick={() => {
                if (row?.cell?.row?.original.faqEventId !== "") {
                  handleDelete(row?.cell?.row?.original.faqEventId)
                }
                else handleDeleteWithOutAPI(row?.cell?.row?.original.id)

              }
              }
            >
              <img src={deleteIcon} alt="delete" title="delete" />
            </button>
          </div>
        );
      },
    },
    {
      Header: 'Question',
      accessor: 'question',
      width: 250,
      Cell: EditableCell,
      validation: {
        maxLength: '250',
        errorMessage: 'Accept only alphabets',
      },
    },
    {
      Header: 'Linking URL',
      accessor: 'linkingURL',
      Cell: EditableCell,
      validation: {
        maxLength: '250',
        errorMessage: 'Accept only alphabets',
        isLink: true
      },
    },

    {
      Header: 'Text',
      accessor: 'text',
      width: 350,
      Cell: EditableCell,
      validation: {
        maxLength: '250',
        errorMessage: 'Accept only alphabets',
      },
    },
    {
      Header: 'Role',
      accessor: 'role',
      width: 80,
      Cell: (row: any) => {
        const hasAdminLock = row?.cell?.row?.original.lockFlag !== 'N';
        // const isClosedEvent = close === 'true';
        // const lockType: TypeLockTypeObj =
        //   row?.cell?.row?.original.itemLockTypeFlag ?? 'NLK';
        // eslint-disable-next-line no-nested-ternary
        return (

          <RoleDropDown
            selectedVal={row?.cell?.row?.original.role}
            itemRow={row?.cell?.row?.original}
          />
        );
      },
    },
    {
      Header: 'Page',
      accessor: 'page',
      width: 120,
      Cell: (row: any) => {
        const hasAdminLock = row?.cell?.row?.original.lockFlag !== 'N';
        return (
          <PageNameDropDown
            selectedVal={row?.cell?.row?.original.page}
            itemRow={row?.cell?.row?.original}
          />
        );
      },
    },
  ];

  return (
    <div>
      <div id="section" ref={ref} className="pt-1">
        <Banner title="Frequently Asked Questions">
          <Button
            type="normal"
            text="Add New Item"
            onClick={handleAddNewItem}
          />
          <Button
            type="contained"
            text="Save"
            data-testid="save-button"
            onClick={handleSaveClick}
          />
        </Banner>
      </div>
      <div className="pr-4 pl-4 pt-4">
        {arrFAQModified.length > 0 ? (
          <RTable
            columns={columns1}
            isfilter
            data={arrFAQModified}
            isTotal={false}
            hiddenColumns={[]}
            updateData={updateTableData}
            skipPageReset={skipPageReset}
            data-testid="event-Store-item-table1"
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
