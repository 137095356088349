import { AnyAction, Dispatch } from 'redux';
import { apiBaseURL } from 'utils/apiBaseUrl';
import axios from 'utils/interceptor';
import {
  DIVISION_ORDER_TOTALS_PROGRAME_YEAR,
  DIVISION_ORDER_TOTALS_SEARCH,
  GET_EVENT_NAME,
} from './types';

export const divisionOrderTotalsProgrameAction =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get(`${apiBaseURL}/ssa/getProgramYearInfo`);
      dispatch({
        type: DIVISION_ORDER_TOTALS_PROGRAME_YEAR,
        payload: response?.data,
      });
      return response;
    } catch (error: any) {
      return new Error(`DivisionOrderTotalsPrograme API call failed`);
    }
  };

export const divisionOrderTotalsSearchAction =
  (value: any) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getTotalOnFilter?prgmName=${value.prgmName}&divNumber=${value.divNumber}&distNumber=${value.distNumber}&strNumber=${value.strNumber}&prgmYr=${value.prgmYr}&totalType=${value.totalType}&itemNumber=${value.itemNumber}&eventId=${value.eventId}`,
      );
      dispatch({ type: DIVISION_ORDER_TOTALS_SEARCH, payload: response?.data });
      return response;
    } catch (error) {
      return new Error(`DivisionOrderTotalsSearch API call failed`);
    }
  };
export const getEventNameAction =
  (value: any) => async (dispatch: Dispatch<AnyAction>) => {
    const { programYear, programName } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getEventNamesByProgramNameAndYear`,
        {
          headers: {
            pgmName: programName,
            year: programYear,
          },
        },
      );
      dispatch({ type: GET_EVENT_NAME, payload: response?.data });
      return response;
    } catch (error) {
      return new Error(`getEventNamesByProgramNameAndYear API call failed`);
    }
  };
