/* eslint-disable jsx-a11y/alt-text */
import './style.css';

import React from 'react';

export default function IconComponent({ source, styles }: any) {
  return (
    <div className={styles}>
      <img src={source} className="icon" />
    </div>
  );
}
