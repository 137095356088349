import React from 'react';

interface IInputFieldProps {
  value?: string | number;
  id?: string;
  label?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  styleClass?: string;
  isMandatory?: boolean;
}
function InputField(props: IInputFieldProps) {
  const {
    value,
    id,
    handleChange,
    label,
    disabled,
    name,
    styleClass,
    isMandatory,
  } = props;
  return (
    <div className="flex items-center gap-3">
      <label className="pb-3 text-center text-sm font-semibold text-black">
        {label} {isMandatory ? <span className="text-red-600">*</span> : null}
      </label>
      <div className={styleClass}>
        <input
          className={`
             appearance-display
             mb-2
             block
             w-full
             rounded
             border
             border-solid
             border-[#B2B4BB]
             ${
               disabled ? 'bg-gray-100' : 'bg-white'
             } bg-clip-padding bg-no-repeat
             px-3 py-1 
             text-gray-700
             transition
             ease-in-out
             focus:bg-white focus:text-gray-700  focus:outline-none`}
          id={id}
          value={value}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          data-testid={`${label}-input`}
        />
      </div>
    </div>
  );
}
export default InputField;
