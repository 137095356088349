export type TypePageName = 'All' | 'Event List' | 'Update Event Items' | 'Upload to SPA' | 'Cost Target';

export enum PageNameEnum {
  "All" = "All",
  "home" = "Home",
  "Upload Events" = "Upload Events",
  "Cost Target" = "Cost Target",
  "Update Store Group" = "Update Store Group",
  "Event Item Store View As" = "Event Item Store View As",
  "View As" = "View As",
  "Store Group" = "Store Group",
  "Event Stores Items Admin" = "Event Stores Items Admin",
  "Event Stores Items Division" = "Event Stores Items Division",
  "Event Stores Items District" = "Event Stores Items District",
  "Event Items Admin" = "Event Items Admin",
  "Event Items Division" = "Event Items Division",
  "Event Items Dist" = "Event Items Dist",
  "Event Items Store" = "Event Items Store",
  "Event List" = "Event List",
  "Update Event Items" = "Update Event Items",
  "Upload To SPA" = "Upload To SPA",
  "Division Order Totals" = "Division Order Totals",
  "District Order Totals" = "District Order Totals",
  "Store Order Totals" = "Store Order Totals",
  "Item Order Totals" = "Item Order Totals",

}
