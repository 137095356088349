/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { lock } from 'assets';
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import DropDown from 'components/Dropdown/Dropdown';
import useEventItem from 'hooks/useEventItem';
import React, { useState, useEffect, useCallback } from 'react';
import exportToXLSX from 'utils/exportToXLSX';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState, useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { imageBaseUrl } from 'utils/imageBaseUrl';
import { toast } from 'react-toastify';
import DisplayModal from 'components/Modal/DisplayModal';
import EditableCell from 'pages/Events/UpdateEventItems/EditableCell';
import wscolsEventItem from 'pages/Events/constantsEvent';
import {
  TypeLockTypeObj,
  LockTypeEnum,
} from 'pages/Events/EventList/lockTypeEnum';
import StickyRTable from 'components/StickyRTable';
import { groupWithColorForM, groupWithColorForO } from '../colorGrouping';
import { regex } from 'utils/validations';
import { EVENT_ITEM_LIST_CLEAR } from 'redux/actions/types';
import UILoader from 'components/UILoader';
import NoData from 'components/NoData';
import applyLocaleString from 'utils/applyLocaleString';
import { LazyLoadImage } from "react-lazy-load-image-component";
import VirtualizedRTable from 'components/StickyRTable/VirtualizedRTable';

interface TypeEventItemList {
  ibc: any;
  adLocation: string;
  adjStoreQty: string;
  childData: [null];
  displayLoc: string;
  divLockFlag: string;
  eventId: string;
  itemAdminComment: string;
  itemComment: string;
  itemDistComment: string;
  itemDivComment: string;
  itemNumber: string;
  itemType: string;
  lockFlag: string;
  modelUpc: string;
  pgmStrSk: string;
  promoPrice: string;
  suggestedAdminQty: string;
  adjStoreQtyModified?: string;
}
function EventItemsForStore({
  eventItemList,
  planogramList,
  event,
  eventItemNotModifiedList,
}: any) {
  const dispatch = useAppThunkDispatch();
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const { fetchEventItemList, saveEvent } = useEventItem();
  const {
    eventId = '',
    eventName = '',
    close,
    programSK = '',
    strCostTarget = 0,
  } = useParams();
  const [selectedSpaceValue, setSelectedSpaceValue] = useState('');
  const [isDisplayModalOpen, setIsDisplayModalOpen] = useState(false);
  const [selectedUPC, setUPC] = useState('');
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [eventListData, setEventListData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isUnsavedValues, setIsUnsavedValues] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => () => {
    // When component unmounts clearing the eventItemList from store,
    // clear the items in store to fix glitch in table showing old values
    if (eventItemList.length !== 0) {
      dispatch({ type: EVENT_ITEM_LIST_CLEAR });
    }
  });

  useEffect(() => {
    if (eventItemList && eventItemList.length > 0) {
      const result = eventItemList.map((item: any) => ({
        ...item,
        eachCostwith$:
          parseFloat(item?.eachUnitCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.eachUnitCost)}`,
        suggestedRetailWith$:
          parseFloat(item?.suggestedRetail) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.suggestedRetail)}`,
        totalRetailWith$:
          parseFloat(item?.totalCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.totalCost)}`,
        extendedCostWith$:
          parseFloat(item.extendCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(parseFloat(item.extendCost))}`,
        adjStoreQtyModified:
          item.itemLockTypeFlag === 'SLK' || item.itemLockTypeFlag === 'SDMLK'
            ? item.suggestedDivQty
            : item.adjStoreQtyOld === null
              ? parseFloat(item.suggestedDivQty)
              : parseFloat(item.adjStoreQty),
      }));
      const colorGroupedResult = groupWithColorForM(result);
      const colorGroupResultWithOAndM = groupWithColorForO(colorGroupedResult);
      const updatedEventItemData = colorGroupResultWithOAndM.map(
        (item: any) => {
          if (item.subRows.length > 0) {
            return {
              ...item,
              subRows: item.subRows.map((subItem: any) => ({
                ...subItem,
                eachCostwith$:
                  parseFloat(subItem?.eachUnitCost) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(subItem?.eachUnitCost)}`,
                suggestedRetailWith$:
                  parseFloat(subItem?.suggestedRetail) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(subItem?.suggestedRetail)}`,
                totalRetailWith$:
                  parseFloat(item?.totalCost) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(item?.totalCost)}`,
                extendedCostWith$:
                  parseFloat(item.extendCost) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(parseFloat(item.extendCost))}`,
                adjStoreQtyModified:
                  item.itemLockTypeFlag === 'SLK' ||
                    item.itemLockTypeFlag === 'SDMLK'
                    ? item.suggestedDivQty
                    : item.adjStoreQtyOld === null
                      ? parseFloat(item.suggestedDivQty)
                      : parseFloat(item.adjStoreQty),
              })),
            };
          }
          return item;
        },
      );

      setEventListData(updatedEventItemData);
      if (close !== 'true' && isFirstLoad) {
        setIsFirstLoad(false);
        setIsUnsavedValues(true);
      }
      setLoading(false);
    }

  }, [eventItemList, eventListData]);

  useEffect(() => {
    if (event?.eventId) {
      fetchEventItemList({
        eventId: event?.eventId,
        programSK: event?.seasProgramSk,
        pogSk: '',
      });
    } else {
      fetchEventItemList({
        eventId,
        programSK,
        pogSk: '',
      });
    }
  }, [event, eventId]);

  const calculateStoreyQuantity = (
    historyQtys: string, // Sales History Quantity
    suggestedQtys: string | null, // 'Suggested Quantity(Packs)'
    planoQtys: string | null, // 'Planogram Quantity'
    storeQtys: string | null, // 'Store Quantity(Packs)' editable field
    pack: string,
    itemType: string,
    userName: string,
  ) => {
    const regexPattern = /^[sS]\d{4}[cC]\d{2}$/;
    const storeUser = userName.match(regexPattern);
    if (storeUser === null) {
      const historyQtyPacks = Number(historyQtys) / Number(pack);
      if (
        storeQtys === null ||
        storeQtys === undefined ||
        Number(storeQtys) === 0 ||
        suggestedQtys === null ||
        suggestedQtys === undefined
      ) {
        if (
          historyQtyPacks > Number(suggestedQtys) &&
          Number(historyQtys) >= 0.75 * Number(pack)
        ) {
          if (historyQtyPacks % 1 >= 0.75) {
            // eslint-disable-next-line
            storeQtys = Math.trunc(Math.ceil(historyQtyPacks)).toString();
          } else {
            // eslint-disable-next-line
            storeQtys = Math.trunc(Math.floor(historyQtyPacks)).toString();
          }
        } else if (Number(suggestedQtys) > historyQtyPacks) {
          // eslint-disable-next-line
          storeQtys = suggestedQtys;
        } else if (
          Number(suggestedQtys) === 0 &&
          historyQtyPacks === 0 &&
          planoQtys !== null
        ) {
          // eslint-disable-next-line
          storeQtys = planoQtys;
        } else if (Number(suggestedQtys) === historyQtyPacks) {
          // eslint-disable-next-line
          storeQtys = suggestedQtys;
        }
      }

      if (
        (storeQtys === null ||
          storeQtys === undefined ||
          Number(storeQtys) === 0) &&
        planoQtys !== null
      ) {
        // eslint-disable-next-line
        storeQtys = planoQtys;
      }
    }

    if (itemType === 'C') {
      // eslint-disable-next-line
      storeQtys = '0';
    }
    return storeQtys;
  };
  const changeEventItemList = () => {
    const finalList: any = [];
    let updatedStoreQty: string | null = '';
    const tempEventItemList: any = [...eventListData];
    const selectedSpaceObj = planogramList?.find(
      (item: any) => selectedSpaceValue === item.value,
    );

    if (selectedSpaceObj != null) {
      tempEventItemList.forEach((valueP: any) => {
        const value = valueP;
        if (value.modifiedByPlanogram) {
          // eslint-disable-next-line

          value.adjStoreQty = value.adjStoreQtyBeforePlanogram;
          // eslint-disable-next-line

          value.modifiedByPlanogram = true;
        }

        const oldAdjStoreQty = value.adjStoreQty;
        let existFlag = false;
        // eslint-disable-next-line

        value.planogramId = null;

        value.planoList.forEach((item: any) => {
          if (selectedSpaceObj === null) {
            existFlag = false;
          } else if (selectedSpaceObj.id === item.id) {
            existFlag = true;
          }
        });

        if (existFlag) {
          // eslint-disable-next-line

          value.planogramId = '1';
          if (
            Number(value.adjStoreQty) === 0 &&
            value.itemType !== 'C' &&
            value.strSugQtyFlag !== '1'
          ) {
            // eslint-disable-next-line
            value.adjStoreQty = '1';
          }
        } else {
          // eslint-disable-next-line
          value.planogramId = null;
        }
        updatedStoreQty = calculateStoreyQuantity(
          value.historyQty, // Sales History Quantity
          value.sugStoreQty, // 'Suggested Quantity(Packs)'
          value.planogramId, // 'Planogram Quantity'
          value.adjStoreQtyOld === null
            ? value.suggestedDivQty
            : value.adjStoreQty, // 'Store Quantity(Packs)' editable field
          value.pack,
          value.itemType,
          value.lastUpdateUserId,
        );
        value.adjStoreQty = updatedStoreQty;

        if (oldAdjStoreQty !== updatedStoreQty) {
          // eslint-disable-next-line
          value.modified = true;
          // eslint-disable-next-line
          value.modifiedByPlanogram = true;
          // eslint-disable-next-line
          value.adjStoreQtyBeforePlanogram = oldAdjStoreQty;
        } else {
          value.modifiedByPlanogram = false;
          if (value.adjStoreQty !== value.dbStoreQty) {
            value.modified = true;
          } else {
            value.modified = false;
          }
        }

        if (
          value.itemLockTypeFlag === 'SLK' ||
          value.itemLockTypeFlag === 'SDMLK'
        ) {
          value.adjStoreQty = value.suggestedDivQty;
        }

        value.adjStoreQty = Math.floor(value.adjStoreQty); // to truncate any decimal value for store quantity

        finalList.push(value);
      });
    }

    const newArray = finalList.map((item: any) => ({
      ...item,
      adjStoreQtyModified: item.adjStoreQty,
    }));
    setEventListData(newArray);
  };
  useEffect(() => {
    if (selectedSpaceValue !== '') {
      changeEventItemList();
    }
  }, [selectedSpaceValue]);
  // To calculate Column Total for TotalRetail
  const handleTotalRetail = (info: any) => {
    let value = 0;
    // MainRow Total
    const mainRowTotal = React.useMemo(
      () =>
        info.rows
          .reduce((sum: any, row: any) => row.original.totalCost + sum, 0)
          .toFixed(2),
      [info],
    );

    // SubRow Total
    const arrSubRowTotal = info.rows.map((item: any) => {
      value = item.subRows
        .reduce((sum: any, subItem: any) => subItem.original.totalCost + sum, 0)
        .toFixed(2);
      return Number(value);
    });
    const subRowTotal = arrSubRowTotal.reduce(
      (sum: any, subRowItem: any) => parseFloat(subRowItem) + sum,
      0,
    );

    return Number(mainRowTotal) + subRowTotal;
  };

  const handleTotalExtendedCost = (info: any) => {
    let value = 0;
    // MainRow Total
    const mainRowTotal = React.useMemo(
      () =>
        info.rows
          .reduce((sum: any, row: any) => row.original.extendCost + sum, 0)
          .toFixed(2),
      [info],
    );

    // SubRow Total
    const arrSubRowTotal = info.rows.map((item: any) => {
      value = item.subRows
        .reduce(
          (sum: any, subItem: any) => subItem.original.extendCost + sum,
          0,
        )
        .toFixed(2);

      return Number(value);
    });

    const subRowTotal = arrSubRowTotal.reduce(
      (sum: any, subRowItem: any) => parseFloat(subRowItem) + sum,
      0,
    );

    return Number(mainRowTotal) + subRowTotal;
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'expander',
        width: 60,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span
            {...getToggleAllRowsExpandedProps({
              style: {
                fontSize: '25px',
              },
            })}
          >
            {isAllRowsExpanded ? '-' : '+'}
          </span>
        ),
        sticky: 'left',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                  fontSize: '25px',
                },
              })}
            >
              {row.isExpanded ? '-' : '+'}
            </span>
          ) : null,
        Footer: <span className="sticky -left-0">Total</span>,
      },
      {
        Header: 'Item Number',
        accessor: 'itemNumber',
        sticky: 'left',
        width: 100,
      },
      {
        Header: 'Item Type',
        accessor: 'itemType',
        sticky: 'left',
        width: 90,
        height: 100,
      },
      {
        Header: 'Image',
        accessor: 'col4',
        sticky: 'left',
        width: 80,

        Cell: (row: any) => (
          <button
            type="button"
            className="flex h-10 w-16 flex-row  justify-center"
            onClick={() => {
              setIsDisplayModalOpen(!isDisplayModalOpen);
              setUPC(row?.cell?.row?.original?.upc.substring(2, 13));
            }}
            data-testid="item-image"
          >
            <LazyLoadImage
              className="h-10 object-contain w-16 "
              src={`${imageBaseUrl}/${row?.cell?.row?.original?.upc.substring(
                2,
                13,
              )}.gif`}
              alt="item"
            />
          </button>
        ),
      },
      {
        Header: 'Item Description',
        accessor: 'itemDesc',
        sticky: 'left',
        width: '300',
        Cell: (row: any) => (
          <span className={`${row?.cell?.row?.original.groupColor}`}>
            {row?.cell?.row?.original.itemDesc}
          </span>
        ),
      },
      {
        Header: 'Lock',
        accessor: 'itemLockTypeFlag',
        sticky: 'left',
        width: 230,
        Cell: (row: any) => {
          const hasAdminLock = row?.cell?.row?.original.lockFlag !== 'N';
          const lockType: TypeLockTypeObj =
            row?.cell?.row?.original.itemLockTypeFlag ?? 'NLK';

          return hasAdminLock ? (
            <img src={lock} alt="lock" className="mt-2 h-5 w-5" />
          ) : (
            <span>{LockTypeEnum[lockType]}</span>
          );
        },
      },
      {
        Header: 'UPC',
        accessor: 'upc',
      },
      {
        Header: 'Pack',
        accessor: 'pack',
        width: 60,
      },
      {
        Header: 'Size',
        accessor: 'size',
        width: 60,
      },
      {
        Header: 'Each Cost',
        accessor: 'eachCostwith$',
      },
      {
        Header: 'Suggested Retail',
        accessor: 'suggestedRetailWith$',
      },
      {
        Header: 'Promo Price',
        accessor: 'promoPrice',
      },
      {
        Header: 'Planogram Quantity',
        accessor: 'planogramId',
      },
      {
        Header: 'Sales History Quantity',
        accessor: 'historyQty',
      },
      {
        Header: 'Suggested Quantity(Packs)',
        accessor: 'sugStoreQty',
      },
      // editable field
      {
        Header: 'Store Quantity(Packs)',
        accessor: 'adjStoreQtyModified',
        Cell: (row: any) =>
          row?.cell?.row?.original?.itemType === 'C' ? (
            row?.cell?.row?.original?.adjStoreQtyModified
          ) : (
            <EditableCell {...row} />
          ),
        validation: {
          value: 5,
          type: 'number',
          disable: close === 'true',
          min: 0,
          message: 'Store Quantity(Packs) is more than 5, Are you sure?',
          customLockValidation: true,
          errorMessage: 'Store Quantity(Packs) cannot be decreased',
        },
      },
      {
        Header: 'Store Quantity(Eaches)',
        accessor: '',
        Cell: (row: any) => (
          <div className="w-2">
            {(row?.cell?.row?.original?.adjStoreQtyOld === null
              ? parseFloat(row?.cell?.row?.original?.suggestedDivQty)
              : parseFloat(row?.cell?.row?.original?.adjStoreQty)) *
              parseFloat(row?.cell?.row?.original?.pack)}
          </div>
        ),
      },
      {
        Header: 'Total Retail Dollars',
        accessor: 'totalRetailWith$',
        Footer: (info: any) => {
          const total = handleTotalRetail(info);
          return <span>${applyLocaleString(total)}</span>;
        },
      },
      {
        Header: 'Extended Cost',
        accessor: 'extendedCostWith$',
        Footer: (info: any) => {
          const total = handleTotalExtendedCost(info);
          return <span>${applyLocaleString(total)}</span>;
        },
      },
      {
        Header: 'Ad Location',
        accessor: 'adLocation',
      },
      {
        Header: 'Display Location',
        accessor: 'displayLoc',
      },
      {
        Header: 'NSM Comment',
        accessor: 'itemAdminComment',
      },
      {
        Header: 'Division Comment',
        accessor: 'itemDivComment',
      },
      {
        Header: 'District Comment',
        accessor: 'itemDistComment',
      },
    ],
    [],
  );

  const handleSave = async () => {
    // TODO: Pass only updated data to API
    // All records are now passed in API

    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    const newData = eventListData?.map((item: any) => ({
      ...item,
      adjStoreQty:
        item.adjStoreQtyModified !== '' ? item.adjStoreQtyModified : null,
    }));
    try {
      const res = await saveEvent(newData);

      // If server returns 500
      if (res.status === 500) {
        throw new Error('Failed to save the uploaded data');
      }

      if (res.data.status === 'success') {
        setEventListData([]);
        setIsUnsavedValues(false);
        toast.success('Detail saved successfully');
        fetchEventItemList({
          eventId,
          programSK,
          pogSk: '',
        });
      } else toast.error('Item save failed');
    } catch (error: any) {
      toast.error(error?.message ?? 'Failed to save');
    }
  };
  const storeQtyAutoSave = async (arrInputParam: Array<any>) => {
    // TODO: Pass only updated data to API
    // All records are now passed in API
    if (arrInputParam.length < 1) {
      return;
    }

    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    try {
      const res = await saveEvent(arrInputParam);
      // If server returns 500
      if (res.status === 500) {
        throw new Error('Failed to save the uploaded data');
      }

      if (res.data.status === 'success') {
        // toast.success('Detail saved successfully');
        // fetchEventItemList({
        //   eventId,
        //   programSK,
        //   pogSk: '',
        // });
      } else toast.error('Item save failed');
    } catch (error: any) {
      toast.error(error?.message ?? 'Failed to save');
    }
  };
  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    // modifying the table data based on the input qty
    if (eventListData[rowIndex]?.[columnId] !== value) {
      const tempArry = eventListData.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
            adjStoreQty: value,
            extendedCostWith$: Number(row.ibc) * value === 0 ? '$0.00' : `$${Number((Number(row.ibc) * value).toFixed(2))}`,
            extendCost: Number((Number(row.ibc) * value).toFixed(2)),
            totalRetailWith$: Number(row.suggestedRetail) * Number(row.pack) * value === 0 ? '$0.00' : `$${Number((Number(row.suggestedRetail) * Number(row.pack) * value).toFixed(2))}`,
            totalCost: Number((Number(row.suggestedRetail) * Number(row.pack) * value).toFixed(2)),
          }
        }
        return row;
      })
      setEventListData(tempArry);
      storeQtyAutoSave([tempArry[rowIndex]])
    }
  };

  const getExportArray = (exportData: Array<any>) => {
    try {
      return exportData.map((item) => ({
        'Item Type': item?.itemType,
        Image: `${imageBaseUrl}/${item?.upc.substring(2, 13)}.gif`,
        UPC: item?.upc,
        'Item Number': item?.itemNumber,
        'Item Description': item?.itemDesc,
        Pack: item?.pack,
        Size: item?.size,
        CF: item?.cf, // not in the table?/
        'Each Cost': `$${applyLocaleString(item?.eachUnitCost)}`,
        'Suggested Retail': `$${applyLocaleString(item?.suggestedRetail)}`,
        'Promo Pricing': item?.promoPrice,
        'Sales History Quantity': item?.historyQty,
        'Planogram Quantity': item?.planogramId,
        'Suggested Quantity(Packs)': item?.sugStoreQty,
        'Store Quantity(Packs)':
          item?.adjStoreQtyOld === null
            ? item?.suggestedDivQty
            : item?.adjStoreQty,
        'Store Quantity(Eaches)':
          (item?.adjStoreQtyOld === null
            ? parseFloat(item?.suggestedDivQty)
            : parseFloat(item?.adjStoreQty)) * parseFloat(item?.pack),
        'Total Retail Dollar': `$${applyLocaleString(item?.totalCost)}`,
        'Extended Cost': `$${applyLocaleString(item?.extendCost)}`,
        'Ad Location': item?.adLocation,
        'Display Location': item?.displayLoc,
        'NSM Comment': item?.itemAdminComment,
        'Division Comment': item?.itemDivComment,
        'District Comment': item?.itemDistComment,
      }));
    } catch (error) {
      toast.error('Error in export');
      return [];
    }
  };
  const handleExportClick = () => {
    if (eventListData && eventListData.length > 0) {
      exportToXLSX({
        fileName: 'Event_item_Store',
        data: getExportArray(eventListData),
        column: wscolsEventItem,
      });
    } else {
      toast.warning('No Data');
    }
  };

  return (
    <div>
      <DisplayModal
        message={
          <img
            src={`${imageBaseUrl}/${selectedUPC}.gif`}
            className="object-scale-down h-80 w-92"
            alt="ItemImage"
          />
        }
        isModalOpen={isDisplayModalOpen}
        onClose={() => setIsDisplayModalOpen(!isDisplayModalOpen)}
      />
      <div ref={ref} className="overflow-y-hidden pt-1">

        <Banner title={event?.eventName ? event?.eventName : eventName}>
          <p className={`${isUnsavedValues ? "visible" : "invisible"} animate-pulse bg-gradient-to-r from-orange-500 via-green-500 to-violet-500 bg-clip-text text-transparent text-lg font-semibold text-red-500`}>**You have unsaved preloaded values</p>
          <Button
            type="contained"
            text="Save"
            disabled={
              !eventListData || eventListData?.length < 1 || close === 'true'
            }
            onClick={() => handleSave()}
            data-testid="save-button"
          />
          <Button
            text="Item Level Export"
            onClick={handleExportClick}
            disabled={!eventListData || eventListData.length < 1}
            data-testid="item-export"
          />
        </Banner>

        <section className="pl-4 pr-4 pt-4">
          <Accordion heading={event?.eventName ? event?.eventName : eventName}>
            <div className="flex flex-row border-t-2">
              <div className="flex flex-col items-start  p-5">
                <div className="flex items-center">
                  <DropDown
                    List={planogramList}
                    label="Space Available: "
                    onChange={setSelectedSpaceValue}
                    data-testid="space-available"
                  />
                </div>
                <div className="flex items-center pl-2">
                  {selectedSpaceValue ? (
                    <a
                      className="text-sky-500"
                      href={`${imageBaseUrl}/${event?.eventName
                        ? event?.eventName.toLowerCase()
                        : eventName.toLowerCase()
                        }${regex.isFirstTwoNum.test(selectedSpaceValue)
                          ? selectedSpaceValue.trimEnd().toLowerCase()
                          : selectedSpaceValue
                            .trimEnd()
                            .padStart(selectedSpaceValue.trimEnd().length + 1, '0')
                            .toLowerCase()
                        }.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event?.eventName ? event?.eventName : eventName}-
                      {selectedSpaceValue}
                    </a>
                  ) : null}
                </div>
              </div>
              <span className="flex flex-col items-start p-5">
                {' '}
                <span className=" pt-2">
                  Store Cost Target:-{' '}
                  {`$${applyLocaleString(
                    event?.storeCostTarget.toFixed(2) ??
                    Number(strCostTarget).toFixed(2),
                  )}`}
                </span>
              </span>
            </div>
          </Accordion>
        </section>
      </div>
      <div className="over pl-4 pr-4 ">
        {eventListData && eventListData.length > 0 ? (
          <VirtualizedRTable
            columns={columns}
            isfilter
            data={eventListData}
            skipPageReset={skipPageReset}
            updateData={updateTableData}
            isTotal
            height={height}
            hiddenColumns={[]}
            data-testid="event-item-table"
          />
        ) : loading ? (
          <UILoader info="Please wait while loading..." />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
const mapStateToProps = ({ eventList }: AppState) => ({
  eventItemList: eventList.eventItemList,
  planogramList: eventList.planogramList,
  event: eventList.eventItemDetails?.eventRow,
  eventItemNotModifiedList: eventList.eventItemNotModifiedList,
});

export default connect(mapStateToProps, null)(EventItemsForStore);
