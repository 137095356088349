import React, { useEffect, useState } from 'react';
import Banner from "components/Banner";
import Button from "components/Button";
import { getFaq } from 'redux/actions/faqAction';
import { AppState, useAppThunkDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import FAQView from './FAQView';
import NoData from 'components/NoData';
import useResizeObserver from 'use-resize-observer';

export default function FAQViewAll() {
    const height = window.innerHeight;
    const dispatch = useAppThunkDispatch();
    const [pageData, setPageData] = useState([]);
    const [heightView, setHeightView] = useState(400);
    const { ref } = useResizeObserver<HTMLDivElement>();

    const { faqList } = useSelector(
        (state: AppState) => state.faq,
    );

    const clearAll = () => {
        setPageData([]);
    }

    useEffect(() => {
        console.log("height", height);

        if (faqList?.length > 0) {
            setPageData(faqList);
        }
    }, [faqList])

    useEffect(() => {
        clearAll();
        dispatch(getFaq("All"))
    }, [])



    return (
        <div id="section" ref={ref} className="pt-1">
            <Banner title="Frequently Asked Questions and Answers" />

            <div className="overflow-y-scroll h-[50%] w-[100%] p-5">
                {pageData?.length > 0 ? (
                    pageData?.map((item: any) =>
                        < FAQView
                            key={item?.id}
                            text={item?.text}
                            heading={item?.question}
                            url={item?.linkingURL} />
                    )) : (<NoData />)}
            </div>
        </div>
    )
}