import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { API_IS_PENDING, API_NOT_PENDING } from 'redux/actions/types';
import { AppState, useAppThunkDispatch } from 'redux/store';

const useLoader = () => {
  const dispatch = useAppThunkDispatch();
  const isLoading = useSelector(
    (state: AppState) => state.appGlobal.isAPIPending,
  );

  const setIsLoading = useCallback((show: boolean) => {
    if (show) {
      dispatch({ type: API_IS_PENDING });
    } else {
      dispatch({ type: API_NOT_PENDING });
    }
  }, []);

  return { isLoading, setIsLoading };
};

export default useLoader;
