import ConfirmModal from 'components/Modal/ConfirmModal';
import React, { useEffect, useState } from 'react';

export default function EditableCellForDist({
  value: initialValue,
  row: {
    index,
    original: { itemLockTypeFlag, lockFlag, storeGroupLockFlag },
  },
  column: { id, validation },
  updateData,
}: any) {
  const [value, setValue] = useState(initialValue);
  const [isError, setIsError] = useState(false);
  const [modal, setModal] = useState(false);
  const [isStoreIncreaseOnly, setIsStoreIncreaseOnly] = useState(initialValue);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (
      lockFlag === 'Y' ||
      validation?.disable ||
      (validation.customLockValidation && itemLockTypeFlag === 'SDMLK') ||
      (validation.customLockValidation && storeGroupLockFlag === 'Y')
    ) {
      setIsDisabled(true);
    }
  }, []);

  useEffect(() => {
    setValue(initialValue);
    setIsStoreIncreaseOnly(initialValue);
  }, [initialValue]);
  const onChangeHandler = (ev: any) => {

    if (!(/^\d*$/.test(ev.target.value)) && validation?.type === 'number') {
      setValue('');

    } else {
      setValue(ev.target.value);

      if (ev.target.value === '') setIsError(true);
      if (ev.target.value > validation.value) setModal(true);
    }
  };
  const onHandleKeydown = (e: any) => {
    if (validation.value) {
      if (validation?.type === 'number' && (e.key === '.' || e.key === '-' || e.key === 'e' || e.key === 'E')) {
        e.preventDefault();
      }
    }
  };

  const onBlurHandler = (ev: any) => {
    const inputValue = ev.target.value;
    if (inputValue !== '' && validation?.pattern) {
      setIsError(!validation?.pattern.test(inputValue));
    } else if (
      (validation?.required && inputValue === '') ||
      inputValue === ' '
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    if (validation.custom) {
      const hasError = validation.custom(inputValue);
      setIsError(!hasError);
    }

    if (
      validation.customLockValidation &&
      itemLockTypeFlag !== null &&
      itemLockTypeFlag !== 'NLK' &&
      itemLockTypeFlag !== 'SINC' &&
      itemLockTypeFlag !== 'SLK'
    ) {
      if (itemLockTypeFlag === 'SDMINC') {
        setIsStoreIncreaseOnly(initialValue);
      }

      if (Number(ev.target.value) < Number(initialValue)) {
        setIsError(true);
      }
    }

    updateData(index, id, value);
  };

  return (
    <>
      <input
        className={`w-full rounded border  px-2 py-1 text-gray-700 ${isError ? 'has-error border-red-600' : 'border-[#B2B4BB]'
          }`}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        // Below properties are based on TYPE InputValidationType
        max={validation?.max}
        min={
          validation.customLockValidation &&
            itemLockTypeFlag !== null &&
            itemLockTypeFlag !== 'NLK' &&
            itemLockTypeFlag !== 'SINC' &&
            itemLockTypeFlag !== 'SLK'
            ? isStoreIncreaseOnly
            : validation?.min
        }
        maxLength={validation?.maxLength}
        minLength={validation?.minLength}
        required={validation?.required}
        type={validation?.type}
        onKeyDown={onHandleKeydown}
        title={isError ? validation?.errorMessage : value}
        disabled={isDisabled}
        data-testid="editable-cell"
      />

      {modal ? (
        <ConfirmModal
          title="Confirm"
          onClose={() => {
            if (initialValue === null || initialValue === undefined) {
              setValue("");
            }
            else {
              setValue(initialValue)
            }
            setModal(false);
          }}
          onSuccess={() => {
            setModal(false);
          }}
          isModalOpen={modal}
          message={validation.message}
        />
      ) : null}
    </>
  );
}
