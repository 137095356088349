const wscolsEventItem = [
  { wch: 12 },
  { wch: 20 },
  { wch: 16 },
  { wch: 12 },
  { wch: 50 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
  { wch: 12 },
];
export default wscolsEventItem;
