/* eslint-disable react/jsx-props-no-spreading */

import Banner from 'components/Banner';
import Button from 'components/Button';
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import useEventItem from 'hooks/useEventItem';
import useEventItemDetails from 'hooks/useEventItemDetails';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppState } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import applyLocaleString from 'utils/applyLocaleString';
import EditableCell from '../../UpdateEventItems/EditableCell';

interface TypeEventStoreList {
  adjustedAdminQty: string;
  adjustedDisQty: string;
  adjustedDivQty: string;
  adjustedStoreQty: string;
  cst_tgt_amt: string;
  distId: string;
  divId: string;
  errorMsg: string;
  eventId: string;
  itemDesc: string;
  masterItemId: string;
  pgmStrSk: string;
  pgm_str_event_sk: string;
  salesHistoryQty: string;
  seasItemId: string;
  seasPgmSk: string;
  storeId: string;
  suggestedAdminQty: string;
  suggestedDisQty: string;
  suggestedDivQty: string;
  suggestedStoreQty: string;
  totalCase: string;
  totalExtendedCost: string;
}
function EventsStoresItemsForDiv({
  eventItemListDetails,
  event,
  allStoreItem,
}: any) {
  const { itemNumber = 0 } = useParams();
  const { eventId = 0, close } = useParams();

  const { ref, height } = useResizeObserver<HTMLDivElement>();

  const { fetchEventItemListDetails, saveEventStore } = useEventItemDetails();
  const [eventStoreItem, setEventStoreItem] = React.useState([]);

  const [selectedRow, setSelectedRow] = React.useState<any>([]);

  const [saveStoreData, setsaveStoreData] = React.useState<
    TypeEventStoreList[] | null | undefined
  >(eventItemListDetails);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const { fetchEventItemList } = useEventItem();

  useEffect(() => {
    fetchEventItemList({
      eventId: eventId.toString(),
      programSK: '',
      pogSk: '',
    });
  }, [eventId]);
  useEffect(() => {
    const storeData = allStoreItem.find(
      (item: any) => item.itemNumber === itemNumber,
    );
    const thisRow: any = [];
    const storeTableFunction = (item: any) => {
      if (item?.masterItemId === itemNumber) {
        if (Array.isArray(item.subRows)) {
          storeTableFunction(item.subRows);
          thisRow.push(item, ...item.subRows);
        }
      }
    };
    storeTableFunction(storeData);
    const storeTableData = thisRow.map((item: any) => ({
      ...item,
      eachCostwith$:
        parseFloat(item?.eachCost) === 0
          ? '$0.00'
          : `$${applyLocaleString(item?.eachCost)}`,
    }));

    setSelectedRow(storeTableData);
  }, [allStoreItem, itemNumber]);

  useEffect(() => {
    setsaveStoreData(null);
    if (event?.eventId) {
      fetchEventItemListDetails({
        eventId: event?.eventId,
        itemNumber: event?.itemNumber,
      });
    } else {
      fetchEventItemListDetails({
        eventId: eventId.toString(),
        itemNumber: itemNumber.toString(),
      });
    }
  }, [event, eventId, itemNumber]);
  useEffect(() => {
    if (eventItemListDetails) {
      setEventStoreItem(eventItemListDetails);
      setsaveStoreData(eventItemListDetails);
    }
  }, [eventItemListDetails]);
  useEffect(() => {
    setSkipPageReset(false);
  }, [saveStoreData]);
  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setsaveStoreData((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };
  const checkIfDataIsModified = useCallback(() => {
    const newExtractingArray = [...eventStoreItem];

    const existingDataStrigified = newExtractingArray.sort(
      (a: any, b: any) => a.itemNumber - b.itemNumber,
    );
    const newArray = [...(saveStoreData ?? [])];

    const uploadedDataStrigified =
      newArray &&
      newArray.sort((a: any, b: any) => a.itemNumber - b.itemNumber);

    const isSame =
      JSON.stringify(existingDataStrigified) ===
      JSON.stringify(uploadedDataStrigified);

    return !isSame;
  }, [eventStoreItem, saveStoreData]);
  const handleSave = async () => {
    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    const dataIsModified = checkIfDataIsModified();
    if (!dataIsModified) {
      toast.info('There are no records to update');
      return;
    }

    const res = await saveEventStore(saveStoreData);
    // If server returns 500
    if (res.status === 500) {
      throw new Error('Failed to save the uploaded data');
    }
    if (res.data.status === 'success') {
      fetchEventItemListDetails({
        eventId: event?.eventId,
        itemNumber: event?.itemNumber,
      });
    } else {
      toast.error('Items save failed..');
    }
  };

  const columns1 = [
    {
      Header: 'Item Number',
      accessor: 'itemNumber',
      width: 30,
    },
    {
      Header: 'Item Description',
      accessor: 'itemDesc',
      width: 40,
    },

    {
      Header: 'Pack',
      accessor: 'pack',
    },
    {
      Header: 'Size',
      accessor: 'size',
    },
    {
      Header: 'Each Cost',
      accessor: 'eachCostwith$',
    },
  ];
  const columns2 = [
    {
      Header: 'Store Number',
      accessor: 'storeId',
    },
    {
      Header: 'Suggested Admin Quantity',
      accessor: 'suggestedAdminQty',
    },
    {
      Header: 'Suggested Division Quantity',
      accessor: 'suggestedDivQty',
      validation: {
        value: 5,
        type: 'number',
        disable: close === 'true' || selectedRow[0]?.lockFlag === 'Y',
        min: 0,

        message: 'Suggested Division Quantity is more than 5, Are you sure?',
      },
      Cell: EditableCell,
    },
    {
      Header: 'Adjusted Store Quantity',
      accessor: 'adjustedStoreQty',
    },
    {
      Header: 'Adjusted District Quantity',
      accessor: 'adjustedDisQty',
    },
    {
      Header: 'Adjusted Division Quantity',
      accessor: 'adjustedDivQty',
      validation: {
        value: 5,
        type: 'number',
        disable: close === 'true' || selectedRow[0]?.lockFlag === 'Y',
        min: 0,

        message: 'Adjusted Division Quantity is more than 5, Are you sure?',
      },
      Cell: EditableCell,
    },
    {
      Header: 'Sales History Quantity',
      accessor: 'salesHistoryQty',
    },
  ];

  return (
    <div>
      <div ref={ref} className="pt-1">
        <Banner title="Event Store Items">
          <Button
            type="contained"
            text="Save"
            disabled={close === 'true'}
            onClick={() => {
              handleSave();
            }}
            data-testid="save-button"
          />
        </Banner>
        <div className="pr-4 pl-4 pt-4">
          {selectedRow.length > 0 ? (
            <RTable
              columns={columns1}
              isfilter={false}
              data={selectedRow}
              isTotal={false}
              hiddenColumns={[]}
              data-testid="event-Store-item-table1"
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>

      <div className="pt-4 pl-4 pr-4">
        {selectedRow.length > 0 && eventStoreItem && eventStoreItem.length > 0 ? (
          <RTable
            columns={columns2}
            isfilter
            data={eventStoreItem}
            isTotal
            updateData={updateTableData}
            skipPageReset={skipPageReset}
            height={height}
            hiddenColumns={[]}
            data-testid="event-Store-item-table2"
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ eventList }: AppState) => ({
  eventItemListDetails: eventList.eventItemListDetails,
  event: eventList.eventItemDetails?.eventRow,
  allStoreItem: eventList.eventItemList,
});

export default connect(mapStateToProps, null)(EventsStoresItemsForDiv);
