import { useCallback } from 'react';
import {
  IUpdateSalesHistory,
  IUpdateSaveComments,
  updateEventAction,
  updateEventSaveAction,
  updateSalesHistoryAction,
} from 'redux/actions/eventListAction/eventListAction';
import { AxiosResponse } from 'axios';

import { useAppThunkDispatch } from 'redux/store';

type UseUpdateEventProps = {
  eventNumber: string;
  programNo?: string;
  programYr?: string;
} | null;

const useUpdateEvent = () => {
  const dispatch = useAppThunkDispatch();

  const updateEvent = useCallback((data: UseUpdateEventProps) => {
    dispatch(
      updateEventAction({
        eventNumber: data?.eventNumber ?? '',
        programNo: data?.programNo ?? '',
        programYr: data?.programYr ?? '',
      }),
    );
  }, []);

  const updateEventDates = useCallback(async (data: IUpdateSaveComments) => {
    const res = (await dispatch(updateEventSaveAction(data))) as AxiosResponse;
    return res;
  }, []);

  const updateSalesHistory = useCallback(async (data: IUpdateSalesHistory) => {
    (await dispatch(updateSalesHistoryAction(data))) as AxiosResponse;
  }, []);

  return { updateEvent, updateSalesHistory, updateEventDates };
};

export default useUpdateEvent;
