import { Path } from 'assets';
import React, { ReactNode, useEffect, useState } from 'react';

interface IProps {
    text?: string;
    heading?: string;
    url?: string;
}
export default function FAQView({ heading, text = "", url = "" }: IProps) {
    const [open, setOpen] = useState(false);

    const stringTrim = (valueStr: string, trimLength: number) => {
        let result = valueStr?.substring(0, trimLength).toString();
        result += "......";
        return result
    }

    return (
        <div
            className="accordion border-solid border-black mb-4 flex w-full flex-col shadow-xl drop-shadow-xl rounded-2xl bg-white"
        >
            <div id="accordionInner" className="min-w-[1200px] accordion-item flex justify-between ">
                <h1
                    className="w-[1300px] text-green-600 text-lg font-nunito py-3 px-4 font-semibold text-black pr-5"
                    data-testid={`${heading ?? 'noLabel'}-accordion`}
                >
                    {heading}
                </h1>
                <button type="button" onClick={() => setOpen(!open)} data-testid='arrow-btn'>
                    <img
                        alt="arrow"
                        className={`cursor-pointer
         py-3 px-4
         ${open && 'rotate-180'}`}
                        src={Path}
                    />
                </button>
            </div>
            <div className={`${open ? 'block ' : 'hidden'} border-t`}>
                <p className=" text-wrap pl-6 pr-5 max-w-[1200px] text-gray-600 text-lg">
                    {text}
                </p>
                {url !== "" ?
                    <div className="p-2 w-[1200px] ">
                        <p className='text-gray-600 text-lg pl-4'>Click the link for more details:</p>
                        <a className=" pl-4 pr-5 -pb-[6] inline-block underline text-lg text-blue-500" href={url} title={url} rel="noreferrer" target="_blank">{url?.length > 130 ? stringTrim(url, 130) : url}</a>
                    </div> : ""}
            </div>
        </div>
    );
}
