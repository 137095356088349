/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { lock } from 'assets';
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button, { ButtonGroup } from 'components/Button';
import DropDown from 'components/Dropdown/Dropdown';
import StickyRTable from 'components/StickyRTable';
import useEventItem from 'hooks/useEventItem';
import React, { useState, useEffect, useCallback } from 'react';
import Fileupload from 'components/Modal/Fileupload';

import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { AppState, useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { imageBaseUrl } from 'utils/imageBaseUrl';
import { toast } from 'react-toastify';
import DisplayModal from 'components/Modal/DisplayModal';
import useEventItemDetails from 'hooks/useEventItemDetails';
import userRoleCheck, { findRole } from 'utils/role';
import roleCapability from 'constants/roleCapability';
import {
  exportEventItem,
  exportStoreItem,
} from 'redux/actions/eventListAction/eventListActionForDiv';
import roleColCapability from 'constants/roleColumnCapability';
import { LockTypeEnum, TypeLockTypeObj } from '../lockTypeEnum';
import EditableCellForDist from './EditableCellForDist';
import { groupWithColorForM, groupWithColorForO } from '../colorGrouping';
import { regex } from 'utils/validations';
import { EVENT_ITEM_LIST_CLEAR } from 'redux/actions/types';
import UILoader from 'components/UILoader';
import NoData from 'components/NoData';
import applyLocaleString from 'utils/applyLocaleString';
import { apiBaseURL } from 'utils/apiBaseUrl';
import { axiosInstanceWithOutLoader } from 'utils/interceptor';
import StoreLevelQTYUpdate from './StoreLevelQTYUpdate';

interface TypeEventItemList {
  adLocation: string;
  adjStoreQty: string;
  childData: [null];
  displayLoc: string;
  divLockFlag: string;
  eventId: string;
  itemAdminComment: string;
  itemComment: string;
  itemDistComment: string;
  itemDivComment: string;
  itemNumber: string;
  itemType: string;
  lockFlag: string;
  modelUpc: string;
  pgmStrSk: string;
  promoPrice: string;
  suggestedAdminQty: string;
}
function EventItemsForDist({
  eventItemList,
  planogramList,
  event,
  eventItemNotModifiedList,
}: any) {
  const { UserRoleMaintenance } = userRoleCheck(roleCapability);
  const dispatch = useAppThunkDispatch();
  const { userRoleValue } = findRole();

  const { eventId = 0, eventName = '', close, programSK = '' } = useParams();
  const [selectedSpaceValue, setSelectedSpaceValue] = useState('');
  const [isModalOpen, setIsOpen] = useState(false);
  const [isModalOpenStoreQtyUpdate, setisModalOpenStoreQtyUpdate] = useState(false);
  const [isDisplayModalOpen, setIsDisplayModalOpen] = useState(false);
  const [selectedUPC, setUPC] = useState('');
  const [lockFlagValue, setLockFlagValue] = useState(false);

  const { saveEventItemDetails } = useEventItemDetails();
  const [uploadData, setUploadData] = useState<
    TypeEventItemList[] | null | undefined
  >(eventItemList);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [eventListData, setEventListData] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { UserRoleColMaintenance } = userRoleCheck(roleColCapability);

  useEffect(() => () => {
    // When component unmounts clearing the eventItemList from store,
    // clear the items in store to fix glitch in table showing old values
    if (eventItemList.length !== 0) {
      dispatch({ type: EVENT_ITEM_LIST_CLEAR });
    }
  });


  useEffect(() => {

    if (eventItemList && eventItemList.length > 0) {
      const result = eventItemList.map((item: any) => ({
        ...item,
        eachCostwith$:
          parseFloat(item?.eachCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.eachCost)}`,
        suggestedRetailWith$:
          parseFloat(item?.suggestedRetail) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.suggestedRetail)}`,
        totalRetailWith$:
          item?.totalCost === null
            ? '$0.00'
            : `$${applyLocaleString(Number(item.totalCost).toFixed(2))}`,
        extendedCostWith$:
          parseFloat(item.suggestedDistQty) === 0 ||
            null ||
            parseFloat(item.ibc) === 0 ||
            null
            ? '$0.00'
            : `$${applyLocaleString(
              (Number(item.ibc) * Number(item.suggestedDistQty)).toFixed(2),
            )}`,
        totalEaches:
          parseFloat(item.suggestedDistQty) === 0 ||
            null ||
            parseFloat(item.pack) === 0 ||
            null
            ? '0'
            : `${Number(item.pack) * Number(item.suggestedDistQty)}`,
      }));
      const colorGroupedResult = groupWithColorForM(result);
      const colorGroupResultWithOAndM = groupWithColorForO(colorGroupedResult);
      const updatedEventItemData = colorGroupResultWithOAndM.map(
        (item: any) => {
          if (item.subRows.length > 0) {
            return {
              ...item,
              subRows: item.subRows.map((subItem: any) => ({
                ...subItem,
                eachCostwith$:
                  parseFloat(subItem?.eachCost) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(subItem?.eachCost)}`,
                suggestedRetailWith$:
                  parseFloat(subItem?.suggestedRetail) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(subItem?.suggestedRetail)}`,
                totalRetailWith$:
                  item?.totalCost === null
                    ? '$0.00'
                    : `$${applyLocaleString(item.totalCost.toFixed(2))}`,
                extendedCostWith$:
                  parseFloat(subItem.suggestedDistQty) === 0 ||
                    null ||
                    parseFloat(subItem.ibc) === 0 ||
                    null
                    ? '$0.00'
                    : `$${applyLocaleString(
                      (
                        Number(subItem.ibc) * Number(subItem.suggestedDistQty)
                      ).toFixed(2),
                    )}`,
                totalEaches:
                  parseFloat(item.suggestedDistQty) === 0 ||
                    null ||
                    parseFloat(item.pack) === 0 ||
                    null
                    ? '0'
                    : `${Number(item.pack) * Number(item.suggestedDistQty)}`,
              })),
            };
          }
          return item;
        },
      );

      setEventListData(updatedEventItemData);
      setLoading(false);
    }
    if (eventListData) {
      setUploadData(eventListData);
    }
  }, [eventItemList, eventListData]);


  const fetchStoreNumber = async (eventid: string) => {
    try {
      const response = await axiosInstanceWithOutLoader.get(
        `${apiBaseURL}/ssa/getEventDivDistStoreList?eventId=${eventId}`,
      );
      let result = []

      if (response.status === 200) {
        result = response?.data?.map((obj: any) => {
          console.log('');
          return { value: obj, id: obj };
        })
      }
      setStoreList(result)
      return response;
    } catch {
      return new Error(`getEventDivDistStoreList API call failed`);
    }
  }

  useEffect(() => () => {
    fetchStoreNumber(eventId.toString())
  }, []);
  // To calculate Column Total
  const handleTotalRetail = (info: any) => {
    let value = 0;
    // MainRow Total
    const mainRowTotal = React.useMemo(
      () =>
        info.rows
          .reduce((sum: any, row: any) => row.original.totalCost + sum, 0)
          .toFixed(2),
      [info],
    );

    // SubRow Total
    const arrSubRowTotal = info.rows.map((item: any) => {
      value = item.subRows
        .reduce((sum: any, subItem: any) => subItem.original.totalCost + sum, 0)
        .toFixed(2);
      return Number(value);
    });
    const subRowTotal = arrSubRowTotal.reduce(
      (sum: any, subRowItem: any) => parseFloat(subRowItem) + sum,
      0,
    );

    const Total = Number(mainRowTotal) + subRowTotal;
    return Total.toFixed(2);
  };

  const handleTotalExtendedCost = (info: any) => {
    let value = 0;
    // MainRow Total
    const mainRowTotal = React.useMemo(
      () =>
        info.rows
          .reduce(
            (sum: any, row: any) =>
              row.original.ibc * row.original.suggestedDistQty + sum,
            0,
          )
          .toFixed(2),
      [info],
    );

    // SubRow Total
    const arrSubRowTotal = info.rows.map((item: any) => {
      value = item.subRows
        .reduce(
          (sum: any, subItem: any) =>
            subItem.original.ibc * subItem.original.suggestedDistQty + sum,
          0,
        )
        .toFixed(2);

      return Number(value);
    });

    const subRowTotal = arrSubRowTotal.reduce(
      (sum: any, subRowItem: any) => parseFloat(subRowItem) + sum,
      0,
    );

    const Total = Number(mainRowTotal) + subRowTotal;
    return Total.toFixed(2);
  };
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const { fetchEventItemList, saveEvent } = useEventItem();
  useEffect(() => {
    setUploadData(null);
    if (event?.eventId) {
      fetchEventItemList({
        eventId: event?.eventId,
        programSK: '',
        pogSk: '',
      });
    } else {
      fetchEventItemList({
        eventId: eventId.toString(),
        programSK: '',
        pogSk: '',
      });
    }
  }, [event, eventId]);

  const columns = React.useMemo(
    () => [
      {
        id: 'expander',
        width: 60,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span
            {...getToggleAllRowsExpandedProps({
              style: {
                fontSize: '25px',
              },
            })}
          >
            {isAllRowsExpanded ? '-' : '+'}
          </span>
        ),
        sticky: 'left',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                  fontSize: '25px',
                },
              })}
            >
              {row.isExpanded ? '-' : '+'}
            </span>
          ) : null,
        Footer: <span className="sticky -left-0">Total</span>,
      },
      {
        Header: 'Item Number',
        accessor: 'itemNumber',
        sticky: 'left',
        width: 100,
        Cell: (row: any) =>
          row?.cell?.row?.original.itemType !== 'C' ? (
            <Link
              data-testid="link"
              className="text-sky-500"
              to={`/events/event-list/1/eventItemDetailDist/${row?.cell?.row?.original.lockFlag}/${row?.cell?.row?.original.eventId}/${row?.cell?.row?.original.itemNumber}/${close}/${row?.cell?.row?.original.itemType}/${row?.cell?.row?.original.pack}/${row?.cell?.row?.original.cf}/${row?.cell?.row?.original.eachCost}`}
              onClick={() => {
                saveEventItemDetails({
                  eventRow: row?.cell?.row?.original,
                });
              }}
            >
              {row.value}
            </Link>
          ) : (
            <span> {row.value}</span>
          ),
        Footer: <span> </span>,
      },
      {
        Header: 'Item Type',
        accessor: 'itemType',
        sticky: 'left',
        width: 90,
        height: 100,
      },
      {
        Header: 'Image',
        accessor: 'col4',
        sticky: 'left',
        width: 80,

        Cell: (row: any) => (
          <button
            type="button"
            className="flex h-10 w-16 flex-row  justify-center"
            onClick={() => {
              setIsDisplayModalOpen(!isDisplayModalOpen);
              setUPC(row?.cell?.row?.original?.upc.substring(2, 13));
            }}
            data-testid='item-image'

          >
            <img
              className="h-10 object-contain w-16 "
              src={`${imageBaseUrl}/${row?.cell?.row?.original?.upc.substring(
                2,
                13,
              )}.gif`}
              alt="item"
            />
          </button>
        ),
      },
      {
        Header: 'Item Description',
        accessor: 'itemDesc',
        sticky: 'left',
        width: '300',
        Cell: (row: any) => (
          <span className={`${row?.cell?.row?.original.groupColor}`}>
            {row?.cell?.row?.original.itemDesc}
          </span>
        ),
      },
      {
        Header: 'Lock',
        accessor: 'itemLockTypeFlag',
        sticky: 'left',
        width: 230,
        Cell: (row: any) => {
          const hasAdminLock = row?.cell?.row?.original.lockFlag !== 'N';
          const lockType: TypeLockTypeObj =
            row?.cell?.row?.original.itemLockTypeFlag ?? 'NLK';

          return hasAdminLock ? (
            <img src={lock} alt="lock" className="mt-2 h-5 w-5" />
          ) : (
            <span>{LockTypeEnum[lockType]}</span>
          );
        },
      },
      {
        Header: 'UPC',
        accessor: 'upc',
      },
      {
        Header: 'Pack',
        accessor: 'pack',
        width: 60,
      },
      {
        Header: 'Size',
        accessor: 'size',
        width: 60,
      },
      {
        Header: 'CF',
        accessor: 'cf',
        width: 60,
      },
      {
        Header: 'Each Cost',
        accessor: 'eachCostwith$',
      },
      {
        Header: 'Suggested Retail',
        accessor: 'suggestedRetailWith$',
      },
      {
        Header: 'District Quantity',
        accessor: 'adjDisQty',
        Cell: EditableCellForDist,
        validation: {
          value: 5,
          type: 'number',
          disable: close === 'true',
          min: 0,
          message: 'District Quantity is more than 5, Are you sure?',
          customLockValidation: true,
          errorMessage: 'District Quantity cannot be decreased',

        },
      },
      {
        Header: 'Promo Pricing',
        accessor: 'promoPrice',
      },
      {
        Header: 'Model UPC',
        accessor: 'modelUpc',
        width: '400',
        Cell: (row: any) => (
          <span className="break-words">
            {row?.cell?.row?.original.modelUpc}
          </span>
        ),
      },
      {
        Header: 'Sales History Taken From Date',
        accessor: 'historyFromDate',
      },
      {
        Header: 'Sales History Taken Until Date',
        accessor: 'historyTillDate',
      },
      {
        Header: 'Aggregate Sales History Quantity',
        accessor: 'aggrSalesHistQty',
      },
      {
        Header: 'Planogram Quantity',
        accessor: 'planogramId',
      },
      {
        Header: 'Extended Cost',
        accessor: 'extendedCostWith$',
        Footer: (info: any) => {
          const total = handleTotalExtendedCost(info);
          return <span>${applyLocaleString(total)}</span>;
        },
      },
      {
        Header: 'Total Store Quantity',
        accessor: 'totalStrQty',
      },
      {
        Header: 'Total District Quantity',
        accessor: 'suggestedDistQty',
      },
      {
        Header: 'Total Eaches',
        accessor: 'totalEaches',
        Cell: (row: any) => (
          <span>
            {row.cell.row.original.suggestedDistQty *
              row.cell.row.original.pack}
          </span>
        ),
      },
      {
        Header: 'Total Retail Dollars',
        accessor: 'totalRetailWith$',
        Footer: (info: any) => {
          const total = handleTotalRetail(info);
          return <span>${applyLocaleString(total)}</span>;
        },
      },
      {
        Header: 'Ad Location',
        accessor: 'adLocation',
      },
      {
        Header: 'Display Location',
        accessor: 'displayLoc',
      },
      {
        Header: 'NSM Comment',
        accessor: 'itemAdminComment',
      },
      {
        Header: 'Division Comment',
        accessor: 'itemDivComment',
      },
      {
        // editable field
        Header: 'District Comment',
        accessor: 'itemDistComment',
        Cell: EditableCellForDist,
        validation: {
          maxLength: 3500,
          disable: lockFlagValue === true || close === 'true',
        },
      },
      {
        Header: 'Lock',
        accessor: 'lockFlag',

        Cell: (row: any) =>
          row.value === 'Y' ? setLockFlagValue(true) : setLockFlagValue(false),
      },
    ],
    [],
  );

  const handleClose = () => {
    setIsOpen(!isModalOpen);
  };
  const handleCloseStoreQtyUpdate = () => {
    setisModalOpenStoreQtyUpdate(!isModalOpenStoreQtyUpdate)
  };

  const handleItemExportClick = () => {
    if (eventListData && eventListData.length > 0) {
      dispatch(
        exportEventItem({
          eventId: eventId.toString(),
          itemDescription: '',
          itemNumber: '',
          pogSk: '',
          programSK: '',
          upc: '',
        }),
      );
    } else {
      toast.warning('No Data');
    }
  };

  const handleStoreLevelExportClick = () => {
    if (eventListData && eventListData.length > 0) {
      dispatch(
        exportStoreItem({
          divId: userRoleValue,
          progSk: programSK.toString(),
          eventId: eventId.toString(),
          itemDescription: '',
          itemNumber: '',
          upc: '',
        }),
      );
    } else {
      toast.warning('No Data');
    }
  };

  const checkIfDataIsModified = useCallback(() => {
    const newExtractingArray = [...eventListData];
    const existingDataStrigified = newExtractingArray.sort(
      (a: any, b: any) => a.itemNumber - b.itemNumber,
    );
    const newArray = [...(uploadData ?? [])];

    const uploadedDataStrigified =
      newArray &&
      newArray.sort((a: any, b: any) => a.itemNumber - b.itemNumber);

    const isSame =
      JSON.stringify(existingDataStrigified) ===
      JSON.stringify(uploadedDataStrigified);

    return !isSame;
  }, [eventItemList, uploadData, eventListData]);

  const handleSave = async () => {
    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    const dataIsModified = checkIfDataIsModified();
    if (!dataIsModified) {
      toast.info('There are no records to update');
      return;
    }

    const newData = uploadData?.map((item) => ({
      ...item,
    }));

    try {
      const res = await saveEvent(newData);

      // If server returns 500
      if (res.status === 500) {
        throw new Error('Failed to save the uploaded data');
      }

      if (res.data.status === 'success') {
        toast.success('Detail saved successfully');
        fetchEventItemList({
          eventId: eventId.toString(),
          programSK: '',
          pogSk: '',
        });
      } else {
        const mappedEventList = eventItemNotModifiedList
          .map((item: any) => {
            const errorItem = res.data.itemdDetailsList.find(
              (ErrItem: any) => ErrItem.itemNumber === item.itemNumber,
            );
            return {
              itemNumber: item.itemNumber,
              itemDesc: item.itemDesc,
              upc: item.upc,
              errorMessage: errorItem?.errorMessage ?? null,
            };
          })
          .filter((item: any) => item.errorMessage !== null);

        throw new Error('Failed to save the uploaded data');
      }
    } catch (error: any) {
      toast.error(
        error?.message ?? 'Failed to upload/read data from excel file',
      );
    }
  };
  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setUploadData((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  return (
    <div>
      <div ref={ref} className="overflow-y-hidden pt-1">
        <Banner title={event?.eventName ? event?.eventName : eventName}>
          <Button
            type="contained"
            text="Save"
            disabled={
              !eventListData ||
              eventListData?.length < 1 ||
              lockFlagValue === true ||
              close === 'true'
            }
            onClick={() => handleSave()}
            data-testid="save-button"
          />
          {UserRoleMaintenance('Events', 'StoreLevelImport/Export') ? (
            <Button
              text="Store Level Export"
              onClick={handleStoreLevelExportClick}
              disabled={!eventListData || eventListData.length < 1}
              data-testid="store-export"
            />
          ) : null}

          <Button
            text="Screen Export"
            onClick={handleItemExportClick}
            disabled={!eventListData || eventListData.length < 1}
            data-testid="screen-export"
          />
        </Banner>

        <section className="pl-4 pr-4 pt-4">
          <Accordion heading={event?.eventName ? event?.eventName : eventName}>
            <div className="flex flex-col items-start border-t-2 p-5">
              <div className="flex items-center">
                <DropDown
                  List={planogramList}
                  label="Space Available: "
                  onChange={setSelectedSpaceValue}
                  data-testid="space-available"
                />
                <div className=" flex pl-2">
                  <Button
                    type="contained"
                    text="Store Level Qty Update"
                    onClick={handleCloseStoreQtyUpdate}
                  />
                </div>

                <ButtonGroup styles="pl-4">
                  {UserRoleMaintenance('Events', 'StoreLevelImport/Export') ? (
                    <Button
                      type="contained"
                      text="Store Upload"
                      onClick={() => {
                        setIsOpen(!isModalOpen);
                      }}
                      data-testid="store-upload"
                    />
                  ) : null}

                  <Fileupload
                    title="Store Level File Upload"
                    onClose={handleClose}
                    isModalOpen={isModalOpen}
                    data-testid="store-file-upload"
                  />
                  <StoreLevelQTYUpdate
                    title="Store Level Qty Update"
                    storeListParent={storeList}
                    close={close}
                    eventId={event?.eventId ? event?.eventId : eventId.toString()}
                    onClose={handleCloseStoreQtyUpdate}
                    isModalOpen={isModalOpenStoreQtyUpdate}
                    data-testid="Store Level Qty Update"
                  />
                </ButtonGroup>
              </div>
              <div className="flex items-center pl-2">
                {selectedSpaceValue ? (
                  <a
                    className="text-sky-500"
                    href={`${imageBaseUrl}/${event?.eventName
                      ? event?.eventName.toLowerCase()
                      : eventName.toLowerCase()
                      }${regex.isFirstTwoNum.test(selectedSpaceValue)
                        ? selectedSpaceValue.trimEnd().toLowerCase()
                        : selectedSpaceValue
                          .trimEnd()
                          .padStart(selectedSpaceValue.trimEnd().length + 1, '0')
                          .toLowerCase()
                      }.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event?.eventName ? event?.eventName : eventName}-
                    {selectedSpaceValue}
                  </a>
                ) : null}
              </div>
            </div>
          </Accordion>
        </section>
      </div>
      <div className="over pl-4 pr-4">
        {eventListData && eventListData.length > 0 ? (
          <StickyRTable
            columns={columns}
            isfilter
            data={eventListData}
            skipPageReset={skipPageReset}
            updateData={updateTableData}
            isTotal
            height={height}
            hiddenColumns={UserRoleColMaintenance('Events', 'EventItem')}
            data-testid="event-item-table"
          />
        ) : loading ? (
          <UILoader info="Please wait while loading..." />
        ) : (
          <NoData />
        )}
      </div>
      {/* WIP */}
      <DisplayModal
        message={
          <img
            src={`${imageBaseUrl}/${selectedUPC}.gif`}
            className="object-scale-down h-80 w-92"
            alt="ItemImage"
          />
        }
        isModalOpen={isDisplayModalOpen}
        onClose={() => setIsDisplayModalOpen(!isDisplayModalOpen)}
      />
    </div>
  );
}
const mapStateToProps = ({ eventList }: AppState) => ({
  eventItemList: eventList.eventItemList,
  planogramList: eventList.planogramList,
  event: eventList.eventItemDetails?.eventRow,
  eventItemNotModifiedList: eventList.eventItemNotModifiedList,
});

export default connect(mapStateToProps, null)(EventItemsForDist);
