import { AnyAction } from 'redux';
import { API_IS_PENDING, API_NOT_PENDING } from 'redux/actions/types';
import { AppState } from 'redux/store';

type appGlobalReducerStateInit = {
  user: string;
  isAPIPending: boolean;
};

const initialState: appGlobalReducerStateInit = {
  user: '',
  isAPIPending: false,
};

const appGlobalReducer = (state = initialState, action: AnyAction): AppState => {
  switch (action.type) {
    case API_IS_PENDING:
      return {
        ...state,
        isAPIPending: true,
      };

    case API_NOT_PENDING:
      return {
        ...state,
        isAPIPending: false,
      };

    default:
      return state;
  }
};
export default appGlobalReducer;
