import { userId } from 'layouts/Header/Header';
import { toast } from 'react-toastify';

import { apiBaseURL } from 'utils/apiBaseUrl';

import axios from 'utils/interceptor';

interface IUploadToSpaActionProps {
  program: string;
}

const uploadToSpaAction = (value: IUploadToSpaActionProps) => async () => {
  const { program } = value;

  try {
    const response = await axios.post(
      `${apiBaseURL}/ssa/exportToSpa?program=${program}`,
      value,
      {
        headers: {
          userName: userId,
        },
      },
    );

    if (response.data.status === 'success') {
      toast.success(
        'Upload To SPA job initiated successfully. You will receive email notification for status on completion of job.',
      );
    } else {
      toast.error(response.data.status);
    }
    return response;
  } catch {
    return new Error(`getEventList API call failed`);
  }
};
export default uploadToSpaAction;
