import { apiBaseURL } from 'utils/apiBaseUrl';
import { findRole } from 'utils/role';
import { axiosInstanceWithOutLoader } from 'utils/interceptor';
import { toast } from 'react-toastify';
import { userId } from 'layouts/Header/Header';

const uploadStoreItemsDistrict = (eventId: string, file: File) => async () => {
  try {
    const { userRoleValue } = findRole();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('eventId', eventId);
    formData.append('userName', userId);
    formData.append('roleValue', userRoleValue);
    formData.append('role', 'dist');

    const res = await axiosInstanceWithOutLoader.post(
      `${apiBaseURL}/ssa/saveImportQuantity`,
      formData,

      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if(res?.data?.status === "fail"){
      toast.error(res?.data?.itemdDetailsList?.[0]?.errorMessage );
      return new Error(`Store level upload failed`);
    }
    
    if (res.status !== 200) {
      toast.error('Store level upload failed');
      return new Error(`Store level upload failed`);
    }
    if (res.data.error === 'null') {
      toast.warn(res.data.status);
    } else if (res.data.error === 'true') {
      toast.error(res.data.status);
    } else if (res.data.error === 'false') {
      toast.success(res.data.status);
    }

    return res;
  } catch {
    return new Error(`Store level upload API failed`);
  }
};
export default uploadStoreItemsDistrict;
