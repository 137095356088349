import { useCallback } from 'react';
import {eventListSearchActionForView } from 'redux/actions/eventListAction/eventListAction';
import { useAppThunkDispatch } from 'redux/store';

type UseEventListProps = {
  event?: string;
  program?: string;
  showClosed?: string;
  roleForView?: string;
  roleValueForView?: string;
} | null;

const useEventListForView = () => {
  const dispatch = useAppThunkDispatch();

  const fetchEventListForView = useCallback((data: UseEventListProps) => {
    dispatch(
      eventListSearchActionForView({
        eventName: data?.event ?? '',
        programSK: data?.program ?? '',
        showClosedFlag: data?.showClosed ?? 'false',
        roleForView: data?.roleForView ?? '',
        roleValueForView: data?.roleValueForView ?? '',
      }),
    );
  }, []);

  return fetchEventListForView;
};

export default useEventListForView;
