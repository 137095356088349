import Button, { ButtonGroup } from 'components/Button';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { EventStoreType } from 'redux/reducers/costTargetReducer';
import { regex } from 'utils/validations';
import * as XLSX from 'xlsx';

interface IImportCostTarget {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  data: EventStoreType[];
  setData: Dispatch<SetStateAction<EventStoreType[] | undefined>>;
}

type ExcelImportItemType = {
  'Cost target': string;
  District: string;
  'Event Name': string;
  Store: string;
  value: string;
};

function ImportCostTarget({
  title,
  isModalOpen,
  onClose,
  data,
  setData,
}: IImportCostTarget) {
  const inputRef = useRef<HTMLInputElement>(null);

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const onCloseHandler = () => {
    clearInput();
    onClose();
  };

  const handleUploadFile = async (e: any) => {
    try {
      e.preventDefault();

      if (e.target.files) {
        const file = e.target.files[0];
        const bufferData = await file.arrayBuffer();
        /* data is an ArrayBuffer */
        const workbook = XLSX.read(bufferData);
        const columnHeader: any = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 1 },
        )[0];
        const columnData: ExcelImportItemType[] = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
        );

        // Checks if the excel column headers match the below names
        if (
          columnHeader[0] !== 'Event Name' ||
          columnHeader[1] !== 'District' ||
          columnHeader[2] !== 'Store' ||
          columnHeader[3] !== 'Cost target' ||
          columnHeader[4] !== 'value'
        ) {
          throw new Error(
            'Invalid excel file format.  Please review column sequence.',
          );
        }

        // Check if Item Number or UPC is not empty and is in valid format
        columnData.forEach((col: any) => {
          const isStoreEmpty = col.Store === undefined;
          if (isStoreEmpty) {
            throw new Error('Item number OR UPC cannot be empty');
          }

          if (
            col['Cost target'] !== '' &&
            !regex.isNumbericDecimal.test(col['Cost target'])
          ) {
            throw new Error('Cost target values should contain numbers only');
          }
        });

        // Checks if the existing data and excel data length match
        if (data.length !== columnData.length) {
          throw new Error('Items does not match with uploaded file.');
        }

        const modifiedData = data.map((item) => {
          const matchedItem = columnData.find(
            (excelItem) => excelItem.Store === item.storeId,
          );

          if (matchedItem) {
            return {
              ...item,
              cst_tgt_amt: matchedItem?.['Cost target'],
            };
          }

          return item;
        });

        setData(modifiedData);
        toast.success('File import completed successfully');
        onCloseHandler();
      } else {
        throw new Error('No files uploaded');
      }
    } catch (error: any) {
      toast.error(
        error?.message ?? 'Failed to upload/read data from excel file',
      );
    }
  };

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl ">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button
            className=""
            type="button"
            onClick={onCloseHandler}
            data-testid="close-import"
          >
            X
          </button>
        </div>
        <form onSubmit={handleUploadFile}>
          <div className="flex flex-row justify-center p-5 pt-[50px]">
            <div className="flex w-full flex-row content-center">
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                type="file"
                required
                accept=".xlsx"
                onChange={handleUploadFile}
                ref={inputRef}
                data-testid="browse-costTarget"
              />
            </div>
          </div>
          <ButtonGroup styles="justify-end pt-[25px] p-5">
            <Button text="Close" onClick={onCloseHandler} />
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
}

export default ImportCostTarget;
