/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint no-lonely-if: "off" */
import React, { useEffect, useRef, useState } from 'react';
import { AppState, useAppThunkDispatch } from 'redux/store';
import FAQView from './FAQView';
import { getFaq } from 'redux/actions/faqAction';
import { useSelector } from 'react-redux';
import { apiBaseURL } from 'utils/apiBaseUrl';
import { pageListObject } from 'constants/pageListObject';
import NoData from 'components/NoData';


interface IFAQShow {
    title: string;
    isModalOpen: boolean;
    onClose: () => void;
}

const data = [
    {
        id: 1,
        question: "How to Reset paswword for admin",
        text: "Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin passwordReset admin passwordReset admin password Reset admin password qwqwqwqwqwqwqw qwqwqwqwqwqwqw qwqwqwqwqwqwq",
        linkingURL: "https://www.google.com/",
        isClose: true,
    },
    {
        id: 2,
        isClose: true,
        question: "How to Reset paswword for admin How to Reset paswword for admin How to Reset paswword for admin How to Reset paswword for adminHow to Reset paswword for adminHow to Reset paswword for adminHow to Reset paswword for adminHow to Reset paswword for admin",
        text: "Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin passwordReset admin passwordReset admin password Reset admin password qwqwqwqwqwqwqw qwqwqwqwqwqwqwqw qwqwqwqwqwq",
        linkingURL: "https://www.google.com/ https://www.google.com/https://www.google.com/https://www.google.com/https://www.google.com/https://www.google.com/https://www.google.com/https://www.google.com/https://www.google"

    },
    {
        id: 3,
        isClose: true,
        heading: "How to Reset paswword for admin",
        text: "Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin passwordReset admin passwordReset admin password Reset admin password qwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwq",
        linkingURL: "https://www.google.com/https://www.google.com/https://www.google.com/https://wwwgoogle.com/https://www.google.com/https://www.google.com/https://ww"
    },
    {
        id: 4,
        isClose: true,
        heading: "How to Reset paswword for admin",
        text: "Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin passwordReset admin passwordReset admin password Reset admin password qwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwq",
        url: "How to Reset paswword for admin How to Reset paswword for admin How to Reset paswword for admin How to Reset paswword for adminHow to Reset paswword for adminHow to Reset paswword for adminHow to Reset paswword for adminHow to Reset paswword for admin"
    },
    {
        id: 5,
        isClose: true,
        heading: "How to Reset paswword for admin",
        text: "Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin password Reset admin passwordReset admin passwordReset admin password Reset admin password qwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwqwq",
    },
]


export default function FAQShow({
    title,
    isModalOpen,
    onClose,
}: IFAQShow) {
    const dispatch = useAppThunkDispatch();
    const [pageData, setPageData] = useState([]);
    const { protocol, hostname } = window.location;
    const origin = `${protocol}//${hostname}`;

    const { faqList } = useSelector(
        (state: AppState) => state.faq,
    );

    const clearInput = () => {
        setPageData([])
    };

    useEffect(() => {
        if (isModalOpen) {
            const currentUrl = window?.location?.href;
            const localUrl = `${origin}:3000/home`;
            const domainUrl = `${origin}/home`
            const localUrlBase = `${origin}:3000/`
            if (currentUrl === domainUrl || currentUrl === localUrl || currentUrl === localUrlBase) {
                dispatch(getFaq("Home"));
            }
            else {
                if (currentUrl) {
                    const keys = Object.keys(pageListObject);
                    for (let index = 0; index < keys.length; index += 1) {
                        // const element = array[index];
                        if (currentUrl.includes(keys[index])) {
                            const key = keys[index];
                            dispatch(getFaq(pageListObject[key]));
                            break;
                        }
                    }
                }
            }
        }
    }, [isModalOpen])

    useEffect(() => {
        if (isModalOpen) {
            setPageData(faqList)
        }
    }, [faqList])

    const onCloseHandler = () => {
        clearInput();
        onClose()
    };

    return (
        <div
            id="popup-modal"
            className={` ${!isModalOpen && 'hidden'
                } max-h-[100%] fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center  overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
        >
            <div className=" bg-slate-100 min-w-[50%] max-w-[90%] max-h-[100%] rounded-lg border bg-white shadow-xl drop-shadow-xl rounded-2xl overflow-y-auto">
                <div className="max-h-[50px] flex flex-row content-center justify-between border-b pl-5 pr-5 pt-2 pb-1">
                    <h1 className="font-nunito-Regular w-fit text-base font-bold text-black pt-2">
                        {title}
                    </h1>
                    <button className="font-bold text-xl bg-white rounded-md p-2 hover:text-black inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" type="button" title="Close" onClick={onCloseHandler} data-testid='cross-button'>
                        X
                    </button>
                </div>
                <div className='overflow-y-scroll h-[600px] w-[100%] p-5'>
                    {pageData?.length > 0 ? (
                        pageData?.map((item: any) =>
                            < FAQView
                                key={item?.id}
                                text={item?.text}
                                heading={item?.question}
                                url={item?.linkingURL} />
                        )) : (<NoData />)}
                </div>

            </div>
        </div>
    );
}
