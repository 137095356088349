/* eslint-disable react/jsx-props-no-spreading */
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import DropDown from 'components/Dropdown/Dropdown';
import useEventItem from 'hooks/useEventItem';
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import RTable from 'components/RTable';
import { imageBaseUrl } from 'utils/imageBaseUrl';
import DisplayModal from 'components/Modal/DisplayModal/DisplayModal';
import { regex } from 'utils/validations';
import NoData from 'components/NoData';
import applyLocaleString from 'utils/applyLocaleString';
import useEventItemForView from 'hooks/useEventItemForView';

function ViewStoreListEventItems({
  planogramList,
  eventItemNotModifiedList,
  event,
}: any) {
  const { eventName = '', divId = '', store = '', eventId = '',  seasProgramSk } = useParams();
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const [selectedSpaceValue, setSelectedSpaceValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const { fetchEventItemList } = useEventItem();
  const fetchEventItemListForView = useEventItemForView();

  const [isDisplayModalOpen, setIsDisplayModalOpen] = useState(false);
  const [selectedUPC, setUPC] = useState('');

  const handleTotalCost = (info: any) =>
    React.useMemo(
      () =>
        info.rows
          .reduce((sum: any, row: any) => row.original.totalCost + sum, 0)
          .toFixed(2),
      [info],
    );

  const handleTotalExtendedCost = (info: any) =>
    React.useMemo(
      () =>
        info.rows
          .reduce(
            (sum: any, row: any) =>
              row.original.ibc * row.original.adjStoreQty + sum,
            0,
          )
          .toFixed(2),
      [info],
    );

  const columns = [
    {
      Header: '',
      accessor: 'col1',
      Footer: <span>Total</span>,
    },
    {
      Header: 'Item Number',
      accessor: 'itemNumber',
    },
    {
      Header: 'Item Type',
      accessor: 'itemType',
    },
    {
      Header: 'Image',
      accessor: 'col4',
      sticky: 'left',
      width: 80,
      Cell: (row: any) => (
        <button
          type="button"
          className="flex h-10 w-16 flex-row  justify-center"
          onClick={() => {
            setIsDisplayModalOpen(!isDisplayModalOpen);
            setUPC(row?.cell?.row?.original?.upc.substring(2, 13));
          }}
          data-testid='item-Image'
        >
          <img
            className="h-10  w-16 "
            src={`${imageBaseUrl}/${row?.cell?.row?.original?.upc.substring(
              2,
              13,
            )}.gif`}
            alt="item"
          />
        </button>
      ),
    },
    {
      Header: 'Item Description',
      accessor: 'itemDesc',
    },
    {
      Header: 'UPC',
      accessor: 'upc',
    },
    {
      Header: 'Pack',
      accessor: 'pack',
    },
    {
      Header: 'Size',
      accessor: 'size',
    },
    {
      Header: 'Each Cost',
      accessor: 'eachUnitCost',
    },
    {
      Header: 'Suggested Retail',
      accessor: 'suggestedRetailWith$',
    },
    {
      Header: 'Promo Price',
      accessor: 'promoPrice',
    },
    {
      Header: 'Planogram Quantity',
      accessor: 'planogramId',
    },
    {
      Header: 'Sales History Quantity',
      accessor: 'historyQty',
    },
    {
      Header: 'Suggested Quantity (Packs)',
      accessor: 'sugStoreQty',
    },
    {
      Header: 'Store Quantity (Packs)',
      accessor: 'adjStoreQty',
    },
    {
      Header: 'Store Quantity (Eaches)',
      accessor: 'StoreEaches',
    },
    {
      Header: 'Total Retail Dollars',
      accessor: 'totalCostwith$',
      Footer: (info: any) => {
        const total = handleTotalCost(info);
        return <span>${applyLocaleString(total)}</span>;
      },
    },
    {
      Header: 'Extended Cost',
      accessor: 'extendCostwith$',
      Footer: (info: any) => {
        const total = handleTotalExtendedCost(info);
        return <span>${applyLocaleString(total)}</span>;
      },
    },
    {
      Header: 'Ad Location',
      accessor: 'adLocation',
      Cell: (row: any) => (
        <div
          className="w-24 truncate"
          title={row?.cell?.row?.original?.adLocation}
        >
          {row?.cell?.row?.original?.adLocation}
        </div>
      ),
    },
    {
      Header: 'Display Location',
      accessor: 'displayLoc',
    },
    {
      Header: 'NSM Item Comment',
      accessor: 'itemAdminComment',
    },
    {
      Header: 'Division Item Comment',
      accessor: 'itemDivComment',
    },
    {
      Header: 'District Item Comment',
      accessor: 'itemDistComment',
    },
  ];

  useEffect(() => {
    if (event?.eventId) {
      fetchEventItemListForView({
        eventId: event.eventId,
        programSK:  event.seasProgramSk,
        pogSk: '',
        roleForView: 'store',
        roleValueForView: store,
      });
      setTableData(eventItemNotModifiedList);
    } else {
      fetchEventItemListForView({
        eventId: eventId.toString(),
        programSK: seasProgramSk,
        pogSk: '',
        roleForView: 'store',
        roleValueForView: store,
      });
      setTableData(eventItemNotModifiedList);
    }
  }, [eventId]);
  useEffect(() => {
    if (eventItemNotModifiedList && eventItemNotModifiedList.length > 0) {
      const result = eventItemNotModifiedList.map((item: any) => ({
        ...item,
        extendCostwith$:
          item.adjStoreQty === 0 || null || item.ibc === 0 || null
            ? '$0.00'
            : `$${applyLocaleString(
                (Number(item.adjStoreQty) * Number(item.ibc)).toFixed(2),
              )}`,
        suggestedRetailWith$:
          parseFloat(item?.suggestedRetail) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.suggestedRetail)}`,
        totalCostwith$:
          item?.totalCost === null
            ? '$0.00'
            : `$${applyLocaleString(item?.totalCost)}`,

        StoreEaches:
          item.adjStoreQty === 0 || null || item.pack === 0 || null
            ? '0'
            : (Number(item.adjStoreQty) * Number(item.pack)).toFixed(2),
      }));
      setTableData(result);
    }
  }, [eventItemNotModifiedList]);

  return (
    <div>
      <div ref={ref} className="overflow-y-hidden pt-1">
        <Banner title={eventName.toString()}> </Banner>

        <section className="pl-4 pr-4 pt-4">
          <Accordion heading={`District: ${divId.toString()}, Store: ${store}`}>
            <div className="flex flex-col items-start border-t-2 p-5">
              <div className="flex items-center">
                <DropDown
                  List={planogramList}
                  label="Space Available: "
                  onChange={setSelectedSpaceValue}
                  data-testid="spaceAvailable"
                />
              </div>

              <div className="flex items-center pl-2">
                {selectedSpaceValue ? (
                  <a
                    className="text-sky-500"
                    href={`${imageBaseUrl}/${eventName.toLowerCase()}${
                      regex.isFirstTwoNum.test(selectedSpaceValue)
                        ? selectedSpaceValue.trimEnd().toLowerCase()
                        : selectedSpaceValue
                            .trimEnd()
                            .padStart(selectedSpaceValue.trimEnd().length + 1, '0')
                            .toLowerCase()
                    }.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="spaceAvailabletag"
                  >
                    {eventName}-{selectedSpaceValue}
                  </a>
                ) : null}
              </div>
            </div>
          </Accordion>
        </section>
      </div>
      <div className="pb-4 pl-4 pr-4" role="table">
        {tableData && tableData.length > 0 ? (
          <RTable
            columns={columns}
            isfilter
            data={tableData}
            isTotal
            height={height}
            hiddenColumns={[]}
            data-testid="storeListEventItemTable"
          />
        ) : (
          <NoData />
        )}
      </div>
      <DisplayModal
        message={
          <img
            src={`${imageBaseUrl}/${selectedUPC}.gif`}
            className="h-72 w-72"
            alt="ItemImage"
          />
        }
        isModalOpen={isDisplayModalOpen}
        onClose={() => setIsDisplayModalOpen(!isDisplayModalOpen)}
        data-testid='image-modal'
      />
    </div>
  );
}
const mapStateToProps = ({ eventList }: AppState) => ({
  planogramList: eventList.planogramList,
  eventItemNotModifiedList: eventList.eventItemNotModifiedList,
  event: eventList.eventItemDetails?.eventRow,
});

export default connect(mapStateToProps, null)(ViewStoreListEventItems);
