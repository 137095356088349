import { AbsLogoIcon } from 'assets';
import React from 'react';

export default function Home() {
  return (
    <section className="mt-40 flex flex-col items-center">
      <img src={AbsLogoIcon} alt="ABS Logo" width="240" />
      <h1 className="mt-3 text-4xl font-bold tracking-wide">STORE SURVEY</h1>
      <h6 className="text-lg text-primary">Assortment Product</h6>
      <p className="mt-6 w-1/2 text-center">
        Store Survey application only works on Microsoft EDGE browser, please
        open Microsoft Egde on your computer and copy/paste the link into the
        browser to access.
      </p>
    </section>
  );
}
