import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import {
  eventItemAction,
  eventItemSaveAction,
  updateEventItemLockAction,
  updateEventItemNewLockTypeAction,
} from 'redux/actions/eventListAction/eventListAction';
import { SAVE_EVENT_ITEM_DETAILS } from 'redux/actions/types';
import { useAppThunkDispatch } from 'redux/store';

type UseEventItemListProps = {
  eventId: string;
  programSK?: string;
  pogSk?: string;
} | null;
type IEventItem = {
  eventRow: Record<string, unknown>;
} | null;
const useEventItem = () => {
  const dispatch = useAppThunkDispatch();

  const fetchEventItemList = useCallback((data: UseEventItemListProps) => {
    dispatch(
      eventItemAction({
        eventId: data?.eventId ?? '',
        programSK: data?.programSK ?? '',
        pogSk: data?.pogSk ?? '',
      }),
    );
  }, []);

  const updateLock = useCallback(
    async (value: any) =>
      (await dispatch(updateEventItemLockAction(value))) as AxiosResponse,
    [],
  );

  const updateNewLockType = useCallback(
    async (data: any) =>
      (await dispatch(updateEventItemNewLockTypeAction(data))) as AxiosResponse,
    [],
  );

  const saveEvent = useCallback(
    async (value: any) =>
      (await dispatch(eventItemSaveAction(value))) as AxiosResponse,
    [],
  );

  const saveEventItem = useCallback((value: IEventItem) => {
    dispatch({
      type: SAVE_EVENT_ITEM_DETAILS,
      payload: value,
    });
  }, []);
  return {
    fetchEventItemList,
    saveEventItem,
    updateLock,
    updateNewLockType,
    saveEvent,
  };
};

export default useEventItem;
