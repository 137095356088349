import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import {
  eventItemDetailsAction,
  eventStoreSaveAction,
} from 'redux/actions/eventListAction/eventListAction';
import { STORE_EVENT_ITEM_DETAILS } from 'redux/actions/types';
import { useAppThunkDispatch } from 'redux/store';

type UseEventItemListDetailsProps = {
  eventId: string;
  itemNumber?: string;
} | null;
type IEventItem = {
  eventRow: any;
} | null;
const useEventItemDetails = () => {
  const dispatch = useAppThunkDispatch();

  const fetchEventItemListDetails = useCallback(
    (data: UseEventItemListDetailsProps) => {
      dispatch(
        eventItemDetailsAction({
          eventId: data?.eventId ?? '',
          itemNumber: data?.itemNumber ?? '',
        }),
      );
    },
    [],
  );
  const saveEventStore = useCallback(async (value: any) => {
    const res = (await dispatch(
      eventStoreSaveAction({ eventItemDetailList: value }),
    )) as AxiosResponse;
    return res;
  }, []);
  const saveEventItemDetails = useCallback((value: IEventItem) => {
    dispatch({
      type: STORE_EVENT_ITEM_DETAILS,
      payload: value,
    });
  }, []);
  return { fetchEventItemListDetails, saveEventItemDetails, saveEventStore };
};

export default useEventItemDetails;
