import {
  AbsLogoIcon,
  CloseIcon,
  OrderIcon,
  HamburgerIcon,
  LeaderboardIcon,
  EventIcon,
  ViewAsIcon,
  StoreGroupIcon,
  question,
} from 'assets';
import roleCapability from 'constants/roleCapability';
import React, { useContext } from 'react';
import userRoleCheck, { findRole } from 'utils/role';
import MenuItem from './MenuItem';
import SideMenuContext from './SideMenuContext';

export default function SideMenu() {
  const { open, toggleOpen } = useContext(SideMenuContext);
  const { UserRoleMaintenance } = userRoleCheck(roleCapability);
  const { userRoleType } = findRole();

  return (
    <nav
      className={`${open ? 'w-[250px]' : 'w-[55px]'
        } fixed z-50 flex h-screen flex-col items-center justify-between bg-sidemenu pb-2`}
    >
      <div className="w-full">
        {/* Logo/Hamburger Area */}
        <div className="flex h-[70px] flex-col justify-center border-b border-white/20">
          {open ? (
            <div className="flex items-center px-4">
              <img src={AbsLogoIcon} alt="ABS Logo" width="36" />
              <span
                className="ml-3 pt-1 text-xs uppercase tracking-[0.18rem] text-white"
                data-testid="app-name"
              >
                store survey
              </span>
              <button
                type="button"
                className="ml-auto p-1"
                onClick={() => toggleOpen(!open)}
                data-testid="close-btn"
              >
                <img src={CloseIcon} alt="Menu close icon" width="16" />
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="m-auto flex"
              onClick={() => toggleOpen(!open)}
              data-testid="hamburger-btn"
            >
              <img src={HamburgerIcon} alt="Hamburger menu" width="32" />
            </button>
          )}
        </div>

        {/* Menu Items */}

        <MenuItem url="/home" icon={LeaderboardIcon} title="Local Dashboard" />

        <MenuItem
          url={
            UserRoleMaintenance('Events', 'EventsUpload')
              ? '/events/upload-events/0'
              : '/events/event-list/1'
          }
          icon={EventIcon}
          title="Events"
        >
          {UserRoleMaintenance('Events', 'EventsUpload') ? (
            <MenuItem url="/events/upload-events/0" title="Upload Events" />
          ) : null}
          <MenuItem url="/events/event-list/1" title="Event List" />
          {UserRoleMaintenance('Events', 'UpdateEventItems') ? (
            <MenuItem
              url="/events/update-event-items/2"
              title="Update Event Items"
            />
          ) : null}
          {UserRoleMaintenance('Events', 'UploadToSpa') ? (
            <MenuItem url="/events/upload-to-spa/3" title="Upload to SPA" />
          ) : null}
          {UserRoleMaintenance('Events', 'CostTarget') ? (
            <MenuItem url="/events/cost-target/4" title="Cost Target" />
          ) : null}
        </MenuItem>
        {userRoleType !== 'store' ? (
          <MenuItem
            url={
              userRoleType === 'dist'
                ? '/orders/store-order-totals/2'
                : '/orders/division-order-totals/0'
            }
            icon={OrderIcon}
            title="Order Review"
          >
            {UserRoleMaintenance('OrderTotals', 'DivisionOrder') ? (
              <MenuItem
                url="/orders/division-order-totals/0"
                title="Division Order Totals"
              />
            ) : null}
            {UserRoleMaintenance('OrderTotals', 'DistrictOrder') ? (
              <MenuItem
                url="/orders/district-order-totals/1"
                title="District Order Totals"
              />
            ) : null}
            {UserRoleMaintenance('OrderTotals', 'StoreOrder') ? (
              <MenuItem
                url="/orders/store-order-totals/2"
                title="Store Order Totals"
              />
            ) : null}
            {UserRoleMaintenance('OrderTotals', 'ItemOrder') ? (
              <MenuItem
                url="/orders/item-order-totals/3"
                title="Item Order Totals"
              />
            ) : null}
          </MenuItem>
        ) : null}

        {UserRoleMaintenance('ViewAs', 'DistStore') ? (
          <MenuItem icon={ViewAsIcon} title="View As" url="/view/viewAsDiv">
            <MenuItem url="/view/viewAsDiv" title="District/Store" />
          </MenuItem>
        ) : null}

        {UserRoleMaintenance('StoreGroup', 'DivStoreGroup') ? (
          <MenuItem
            icon={StoreGroupIcon}
            title="Store Group"
            url="/store/storeGroup"
          >
            <MenuItem url="/store/storeGroup" title="Store Group" />
          </MenuItem>
        ) : null}
        {UserRoleMaintenance('FAQ', 'AdmFAQ') ? (
          <MenuItem url="/frequently-asked-questions" icon={question} title="FAQ" />
        ) : null}
        {UserRoleMaintenance('FAQView', 'FAQViewAll') ? (
          <MenuItem url="/frequently-asked-questions-view" icon={question} title="FAQ" />
        ) : null}
      </div>

      {open ? null : <img src={AbsLogoIcon} alt="ABS Logo" width="30" />}
    </nav>
  );
}
