import { AnyAction } from 'redux';
import {
  EVENT_LIST_SEARCH,
  EVENT_LIST_INITIALIZE,
  UPDATE_EVENT_LIST_ITEMS,
  UPDATE_EVENT,
  STORE_COMMENT,
  DISTRICT_COMMENT,
  DIVISION_COMMENT,
  NSM_COMMENT,
  DELETE_EVENT,
  EVENT_ITEM,
  SAVE_EVENT_ITEM_DETAILS,
  EVENT_ITEM_DETAILS,
  STORE_EVENT_ITEM_DETAILS,
  DISTRICT_LIST,
  STORE_LIST,
  UPDATE_EVENT_DIV,
  EVENT_ITEM_LIST_CLEAR,
  EVENT_LIST_EVENT_PROGRAME_SAVE,
  EVENT_LIST_CLEAR_EVENT_PROGRAME_SAVE,
} from 'redux/actions/types';
import { AppState } from 'redux/store';

interface IEventList {
  eventList: any;
  programList: any | null;
  eventListDD: any | null;
  eventItemList: any;
  planogramList: any;
  updateEvent: any | null;
  updateEventForDiv: any | null;
  storeComment: any | null;
  nsmComment: any | null;
  divComment: any | null;
  distComment: any | null;
  deleteEvent: any | null;
  eventItemDetails: any | null;
  eventItemListDetails: any | null;
  eventItemNotModifiedList: any | null;
  districtList: any;
  storeList: any;
  updateEventListDD: any | null;
  roleCommentMap: any | null;
}

export const eventListInit: IEventList = {
  eventList: [],
  programList: null,
  eventListDD: null,
  eventItemList: [],
  eventItemListDetails: [],
  eventItemNotModifiedList: [],
  planogramList: null,
  updateEvent: null,
  updateEventForDiv: null,
  storeComment: null,
  nsmComment: null,
  divComment: null,
  distComment: null,
  deleteEvent: {},
  eventItemDetails: null,
  districtList: [],
  storeList: [],
  updateEventListDD: [],
  roleCommentMap: null,
};
const EventListReducer = (
  state = eventListInit,
  action: AnyAction,
): AppState => {
  switch (action.type) {
    case EVENT_LIST_INITIALIZE:
      return {
        ...state,
      };
    case EVENT_LIST_SEARCH:
      return {
        ...state,
        eventList: [...action.payload.eventDataList],
        programList: [...action.payload.programNameList],
        eventListDD: action.payload.eventDataList.map((item: any) => ({
          id: item.eventId,
          value: `${item.eventId}-${item.eventName}`,
        })),
      };

    case EVENT_ITEM_LIST_CLEAR:
      return {
        ...state,
        eventItemList: [],
      };
    case EVENT_LIST_EVENT_PROGRAME_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case EVENT_LIST_CLEAR_EVENT_PROGRAME_SAVE:
      return {
        ...state,
        eventList: undefined,
        event: undefined,
        program: undefined,
        programID: undefined,
        showClosed: false
      };

    case EVENT_ITEM:
      return {
        ...state,
        eventItemList: [...action.payload.modifiedData],
        eventItemNotModifiedList: [...action.payload.eventItemList],

        planogramList: [...action.payload.planogramList],
      };

    case SAVE_EVENT_ITEM_DETAILS:
    case STORE_EVENT_ITEM_DETAILS:
      return {
        ...state,
        eventItemDetails: { ...action.payload },
      };
    case EVENT_ITEM_DETAILS:
      return {
        ...state,
        eventItemListDetails: [...action.payload],
      };

    case UPDATE_EVENT:
      return {
        ...state,
        updateEvent: [...action.payload],
      };
    case UPDATE_EVENT_DIV:
      return {
        ...state,
        updateEventForDiv: { ...action.payload },
      };
    case STORE_COMMENT:
      return {
        ...state,
        storeComment: [...action.payload],
      };
    case NSM_COMMENT:
      return {
        ...state,
        nsmComment: { ...action.payload },
        roleCommentMap: { ...action.payload.roleCommentMap },
      };
    case DIVISION_COMMENT:
      return {
        ...state,
        divComment: [...action.payload],
      };
    case DISTRICT_COMMENT:
      return {
        ...state,
        distComment: [...action.payload],
      };
    case DELETE_EVENT:
      return {
        ...state,
        deleteEvent: { ...action.payload },
      };
    case UPDATE_EVENT_LIST_ITEMS:
      return {
        ...state,
        eventList: [...action.payload],
        updateEventListDD: action.payload.map((item: any, index: any) => ({
          id: `${index}-item.eventId`,
          eventId: item.eventId,
          value: `${item.eventId}-${item.eventName}`,
        })),
      };
    case DISTRICT_LIST:
      return { ...state, districtList: [...action.payload.modifiedData] };
    case STORE_LIST:
      return { ...state, storeList: [...action.payload.modifiedData] };
    default:
      return { ...state };
  }
};
export default EventListReducer;
