/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import Button, { ButtonGroup } from 'components/Button';
import DropDown from 'components/Dropdown/Dropdown';
import RTable from 'components/RTable';
import useEventItemDetails from 'hooks/useEventItemDetails';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { apiBaseURL } from 'utils/apiBaseUrl';
import instance from 'utils/interceptor';
import { findRole } from 'utils/role';
import EditableCellForDistQty from './EditableCellForDistQty';
import NoData from './NoData';

interface IFileUploadModal {
  title: string;
  close: string | undefined;
  eventId: string;
  isModalOpen: boolean;
  storeListParent: any;
  onClose: () => void;
  eventUploadFileAction?: (file: File) => any;
}


export default function StoreLevelQTYUpdate({
  title,
  eventId,
  close,
  isModalOpen,
  storeListParent,
  onClose,
  eventUploadFileAction,
}: IFileUploadModal) {
  const dispatch = useAppThunkDispatch();
  const [selectedStoreValue, setSelectedStoreValue] = useState('');
  const [tableDataStoreItem, setTableDataStoreItem] = React.useState<any>([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const { ref } = useResizeObserver<HTMLDivElement>();
  const [originalTableDataStoreItem, setOriginalTableDataStoreItem] = useState([]);
  const { saveEventStore } = useEventItemDetails();

  const fetchStoreItems = async () => {
    try {
      const response = await instance.get(
        `${apiBaseURL}/ssa/getEventItemDetailByStoreNo?eventId=${eventId}&storeId=${selectedStoreValue}`,
      );
      let result = []

      if (response.status === 200) {
        result = response?.data
      }

      const modifiedResult = result?.map((item: any) => ({
        ...item,
        itemLockTypeFlagLabel: item.lockFlag === 'Y' ? "Lock" : item.itemLockTypeFlag === 'SDMLK' ? "Store and DM lock" : item.itemLockTypeFlag === 'SINC' ? 'Store Increase Only' : item.itemLockTypeFlag === 'SLK' ? 'Store lock only' : item.itemLockTypeFlag === 'SDMINC' ? 'Store and DM increase only' : 'No lock'
      }));
      setTableDataStoreItem(modifiedResult);
      setOriginalTableDataStoreItem(modifiedResult);
      return response;
    } catch {
      return new Error(`getEventDivDistStoreList API call failed`);
    }
  }

  const clearInput = () => {
    setSelectedStoreValue('');
    setOriginalTableDataStoreItem([]);
    setTableDataStoreItem([]);
  };

  const checkIfDataIsModified = () => {
    console.log(tableDataStoreItem);

    const newExtractingArray = [...tableDataStoreItem];

    const existingDataStrigified = newExtractingArray.sort(
      (a: any, b: any) => a.seasItemId - b.seasItemId,
    );
    const newArray = [...(originalTableDataStoreItem ?? [])];

    const uploadedDataStrigified =
      newArray &&
      newArray.sort((a: any, b: any) => a.seasItemId - b.seasItemId);

    const isSame =
      JSON.stringify(existingDataStrigified) ===
      JSON.stringify(uploadedDataStrigified);

    return !isSame;
  };

  const columns2 = [
    {
      Header: 'Item Number',
      accessor: 'seasItemId',
      width: 20,
    },
    {
      Header: 'Lock',
      accessor: 'itemLockTypeFlagLabel',
      width: 20,
    },

    {
      Header: 'Description',
      accessor: 'itemDesc',
      minWidth: 300,
    },
    {
      Header: 'District Suggested Quantity',
      accessor: 'suggestedDistQty',
      width: 50,
      validation: {
        value: 5,
        type: 'number',
        disable: close === 'true',
        min: 0,
        customLockValidation: true,
        message: 'District Suggested Quantity is more than 5, Are you sure?',
      },
      Cell: EditableCellForDistQty,
    },
    {
      Header: 'District Adjusted Quantity',
      accessor: 'adjDisQty',
      width: 50,
      validation: {
        value: 5,
        type: 'number',
        disable: close === 'true',
        min: 0,
        customLockValidation: true,
        message: 'District Adjusted Quantity is more than 5, Are you sure?',
      },
      Cell: EditableCellForDistQty,
    },
  ];

  const onCloseHandler = () => {
    clearInput();
    onClose();
  };

  const btnGoClick = () => {
    fetchStoreItems();
  }
  const btnSaveClick = async () => {
    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    const dataIsModified = checkIfDataIsModified();
    if (!dataIsModified) {
      toast.info('There are no records to update');
      return;
    }

    const newData = originalTableDataStoreItem?.map((item: any) => ({
      ...item,
      adjustedDisQty: item.adjDisQty,
      suggestedDisQty: item.suggestedDistQty,

    }));

    try {
      const res = await saveEventStore(newData);
      // If server returns 500
      if (res.status === 500) {
        throw new Error('Failed to save the uploaded data');
      }
      if (res.data.status === 'success') {
        fetchStoreItems()
      }
      else if (res.data.status !== 'success') {
        toast.error('Items save failed..');
      }
    } catch (error: any) {
      toast.error(
        error?.message ?? 'Failed to upload/read data from excel file',
      );
    }
  }

  const onChangeHandler = (ev: any) => {

    if (ev !== '') {
      setSelectedStoreValue(ev)
    }
    else {
      setSelectedStoreValue('')
    }
  };
  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setOriginalTableDataStoreItem((old: any) =>
      old?.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };



  return (
    <div
      id="popup-modal"
      className={` ${!isModalOpen && 'hidden'
        } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[50%] rounded-lg border bg-white shadow-2xl ">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="font-bold text-lg" type="button" onClick={onCloseHandler} data-testid='cross-button'>
            X
          </button>

        </div>
        <div className=" flex flex-row p-5 item-start align-middle border-b">
          <DropDown
            List={storeListParent?.length > 0 ? storeListParent : []}
            label="Store:"
            onChange={onChangeHandler}
            value={selectedStoreValue}
            data-testid="space-available"
          />
          <div className="flex pl-2 space-x-3">
            <Button
              type="contained"
              text="Go"
              disabled={selectedStoreValue === ""}
              onClick={btnGoClick}
            />
            <div className={`${close === 'true' ? 'invisible' : 'visible'}`}>
              <Button
                type="normal"
                text="Save"
                disabled={tableDataStoreItem?.length < 1}
                onClick={btnSaveClick}
              />
            </div>
          </div>
        </div>
        <div className="pt-4 pl-4 pr-4">
          {tableDataStoreItem?.length > 0 ? (
            <RTable
              columns={columns2}
              isfilter
              data={tableDataStoreItem}
              isTotal
              updateData={updateTableData}
              skipPageReset={skipPageReset}
              height={100}
              hiddenColumns={[]}
              data-testid="event-Store-item-table2"
            />
          ) : (
            <NoData />
          )}
        </div>

      </div>
    </div >
  );
}
