const roleName = [
  {
    roleName: 'foss.ssa.admin',
    value: 'FOSS Admin',
    priority: 1,
  },
  {
    // actual roleName:foss.div.#
    roleName: 'foss.div',
    value: 'FOSS Division',
    priority: 2,
  },
  {
    // actual roleName:foss.dstr.##.##
    roleName: 'foss.dstr',
    value: 'FOSS District',
    priority: 3,
  },
  {
    roleName: 'foss.ssa.stores',
    value: 'FOSS Store',
    priority: 4,
  },
];

export default roleName;
