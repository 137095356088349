/* eslint-disable */
//@ts-nocheck
import Filter from 'components/Filter';
import React, { useEffect } from 'react';
import {
  useBlockLayout,
  useGlobalFilter,
  useSortBy,
  useExpanded,
  usePagination,
  useTable,
} from 'react-table';
import { headerHeight } from 'layouts/Header/Header.tsx';
import { bannerHeight } from 'components/Banner/Banner';
import { useSticky } from 'react-table-sticky';
import DisplayModal from '../Modal/DisplayModal';
import { FixedSizeList } from 'react-window'
import scrollbarWidth from './scrollbarWidth'


interface IHeader {
  Header: string;
  accessor: string;
  Cell?: (props: any) => void;
}
interface IData {
  col1?: string;
  col2?: string;
  col3?: string;
  col4?: string;
  col5?: string;
  col6?: string;
  col7?: string;
  col8?: string;
  col9?: string;
  col10?: string;
  col11?: string;
  col12?: string;
  col13?: string;
  col14?: string;
  col15?: string;
  col16?: string;
  col17?: string;
  col18?: string;
  col19?: string;
  col20?: string;
  col21?: string;
  col22?: string;
  col23?: string;
  col24?: string;
}
interface ITableProps {
  columns: any;
  data: IData[];
  isTotal: boolean;
  isfilter: boolean;
  hiddenColumns?: any;
  height?: number;

  updateData?: (rowIndex: any, columnId: any, value: any) => void;
  skipPageReset?: boolean;
}

const VirtualizedRTableTest = (props: ITableProps) => {
  const {
    columns,
    data,
    isfilter,
    height,
    updateData,
    skipPageReset,
    hiddenColumns,
  } = props;

  const updatedHeaderHight = headerHeight;
  const updatedBannerHeight = bannerHeight;
  const [tableHeight, setTableHeight] = React.useState(height);
  const [isColumnListOpen, setIsColumnListOpen] = React.useState(false);

  React.useEffect(() => {
    setTableHeight(height + updatedBannerHeight + updatedHeaderHight + 95);
  }, [height]);

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    footerGroups,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    rows,
    state: { pageIndex, pageSize, globalFilter },
    allColumns,
    totalColumnsWidth,
    getToggleHideAllColumnsProps,
  } = useTable<object>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: data.length,
        hiddenColumns: hiddenColumns,
      },
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateData is not part of the API, but will be
      // automatically availaible on the instance
      updateData,
      useSticky,
    },
    useGlobalFilter,
    useBlockLayout,
    useSticky,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const columnSortText = (column) => {
    if (column?.isSorted) {
      return column?.isSortedDesc ? ' 🔽' : ' 🔼';
    }
    return '';
  };

  const calculateRowLength = () => {
    if (rows.length < pageSize) {
      return rows.length;
    } else if (rows.length > pageSize * (pageIndex + 1)) {
      return pageSize * (pageIndex + 1);
    } else {
      return rows.length;
    }
  };
  useEffect(() => {
    setPageSize(data.length);
  }, [data]);
  useEffect(() => {
    if (rows?.length > 0) {
      setPageSize(rows.length);
    }
  }, [rows]);

  const toggleColumnListDisplay = () => {
    setIsColumnListOpen(!isColumnListOpen);
  };

  const filteredColumns = allColumns.filter(
    (column) => !hiddenColumns.includes(column.id),
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      const { style: rowStyle, ...restRow } = row.getRowProps({ style });
      return (
        <div
          {...restRow}
          style={{ ...rowStyle, width: `auto` }}
          className="tr bg "
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td border bg-[white] p-2 text-center ">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  let columnList = (
    <div className="w-full pl-4">
      <div>
      </div>

      {filteredColumns.map((column) => (
        <div key={column.id}>
          <label>
            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
            {typeof column.Header === 'string' ? column.Header : column.id}
          </label>
        </div>
      ))}

      <br />
    </div>
  );

  return (
    <div className="rounded-md bg-white p-2 w-[100%] h-[700px]">
      <DisplayModal
        message={columnList}
        isModalOpen={isColumnListOpen}
        onClose={toggleColumnListDisplay}
      />

      {isfilter && (
        <Filter
          total={rows.length}
          filterValue={globalFilter}
          setFilter={setGlobalFilter}
          settingClick={toggleColumnListDisplay}
        />
      )}
      <div {...getTableProps()} className="table sticky" >
        <div
          style={{
            height: `calc(100vh - ${tableHeight}px)`,
            // overflow: 'auto',
            width: `calc(100vw - 140px)`,
          }}
        >


          <div style={{ position: `relative` }}>

            <FixedSizeList
              height={800}
              itemCount={rows.length}
              itemSize={65}
              width={'100%'}
              style={{ willChange: `auto` }}
              className="bg-[white] p-0 text-center overflow-auto"
              innerElementType={({ children, style, ...rest }: any) => (
                <>

                  <div className="sticky top-0 z-20">

                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <div
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="border bg-[#f3f5f5] py-4 px-3 overflow-auto"
                          >
                            <span
                              className="z-40 text-xs font-bold"
                              data-testid="table-header"
                            >
                              {column.render('Header')}
                            </span>
                            <span>{columnSortText(column)}</span>
                          </div>
                        ))}
                      </div>
                    ))}

                  </div>

                  <div {...getTableBodyProps()} style={{ position: `relative` }}>
                    {children}
                  </div>
                  <div className="sticky bottom-0 z-20 bg-[#f3f5f5] inline-block top-[98%]">
                    {footerGroups.map((group) => (
                      <div className="border" {...group.getFooterGroupProps()}>
                        {group.headers.map((column) => (
                          <div
                            className="sticky -left-1 border p-1 text-center "
                            {...column.getFooterProps()}
                          >
                            {column.render('Footer')}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </>

              )}
            >
              {RenderRow}
            </FixedSizeList>

          </div>
        </div>
      </div>

      <div className="mt-1">
        {' '}
        <div className="pagination bottom-0 flex  justify-between bg-white pt-4 pb-1 h-16">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default VirtualizedRTableTest;
