import { NoDataImage } from 'assets';
import React from 'react';

type NoDataProps = {
  title?: string;
  subTitle?: string | null;
};

function NoData({ title = 'No Data', subTitle = null }: NoDataProps) {
  return (
    <div className=" w-full h-full flex min-h-[600px] items-center justify-center rounded-md bg-white ">
      <div className=" w-full h-full">
        <img src={NoDataImage} alt="No data" width={70} className="m-auto" />
        <h2 className="text mt-6 text-center text-xl font-bold text-gray-900 opacity-60">
          {title}
        </h2>
        {subTitle ? (
          <p className="mt-2 text-center opacity-50">{subTitle}</p>
        ) : null}
      </div>
    </div>
  );
}

export default NoData;
