import { useCallback } from 'react';
import uploadToSpaAction from 'redux/actions/uploadToSpaAction';
import { useAppThunkDispatch } from 'redux/store';

type UploadToSpaProps = {
  program?: string;
} | null;

const useUploadToSpa = () => {
  const dispatch = useAppThunkDispatch();

  const fetchUploadSpaList = useCallback((data: UploadToSpaProps) => {
    dispatch(
      uploadToSpaAction({
        program: data?.program ?? '',
      }),
    );
  }, []);

  return fetchUploadSpaList;
};

export default useUploadToSpa;
