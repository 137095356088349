import { apiBaseURL } from 'utils/apiBaseUrl';
import axios, { axiosInstanceWithOutLoader } from 'utils/interceptor';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { userId } from 'layouts/Header/Header';
import { findRole } from 'utils/role';

interface IExportEventItem {
  eventId: string;
  itemDescription: string;
  itemNumber: string;
  pogSk: string;
  programSK: string;
  upc: string;
}
interface IExportStoreEventItem {
  divId: string;
  progSk: string;
  eventId: string;
  itemDescription: string;
  itemNumber: string;
  upc: string;
}
const { userRoleValue, userRoleType } = findRole();

export const exportEventItem = (value: IExportEventItem) => async () => {
  const { eventId, itemDescription, itemNumber, pogSk, programSK, upc } = value;
  try {
    const response = await axios.get(
      `${apiBaseURL}/ssa/exportEventItem?eventId=${eventId}&upc=${upc}&itemNumber=${itemNumber}&itemDescription=${itemDescription}&programSK=${programSK}&pogSk=${pogSk}`,
      {
        method: 'GET',
        responseType: 'arraybuffer',
      },
    );
    if (response.status === 200) {
      try {
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        });
        FileSaver.saveAs(
          blob,
          userRoleType === 'division'
            ? 'Event_item_Division.xlsx'
            : 'Event_item_District.xlsx',
        );
      } catch (error) {
        console.error(error);
      }
    }
    return response;
  } catch {
    return new Error(`getEventList API call failed`);
  }
};

export const exportStoreItem = (value: IExportStoreEventItem) => async () => {
  const { eventId, itemDescription, itemNumber, divId, progSk, upc } = value;

  try {
    const res = await axios.get(
      `${apiBaseURL}/ssa/eventStoreFileName?progSk=${progSk}&divId=${divId}`,
    );
    if (res.status === 200) {
      const response = await axios.get(
        `${apiBaseURL}/ssa/eventStoreItemExport?eventId=${eventId}&upc=${upc}&itemNumber=${itemNumber}&itemDescription=${itemDescription}`,
        {
          method: 'GET',
          responseType: 'arraybuffer',
        },
      );
      if (response.status === 200) {
        try {
          const blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel',
          });
          FileSaver.saveAs(blob, `${res.data.status}.xlsx`);
        } catch (error) {
          console.error(error);
        }
      }
    }
    return res;
  } catch {
    return new Error(`getEventList API call failed`);
  }
};

export const uploadStoreItemsDivision =
  (eventId: string, data: any[]) => async () => {
    try {
      const res = await axiosInstanceWithOutLoader.post(
        `${apiBaseURL}/ssa/saveImportQuantityJson`,
        {
          eventId,
          items: data,
          role: 'division',
          roleValue: userRoleValue,
          strategy: 'NoStoredProcedure',
          userName: userId,
        },
      );

      if(res?.data?.status === "fail"){
        toast.error(res?.data?.itemdDetailsList?.[0]?.errorMessage );
        return new Error(`Store level upload failed`);
      }
            
      if (res.status !== 200) {
        toast.error('Store level upload failed');
        return new Error(`Store level upload failed`);
      }
      toast.success('Store level upload completed successfully');

      return res;
    } catch {
      return new Error(`Store level upload API failed`);
    }
  };
