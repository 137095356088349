/* eslint-disable jsx-a11y/control-has-associated-label */
import Button, { ButtonGroup } from 'components/Button';
import useNsmComment from 'hooks/useNsmComment';
import React, { useEffect, useState } from 'react';
import { findRole } from 'utils/role';

interface IFileUploadModal {
  nsmTitle?: string;
  divisionTitle?: string;
  districtTitle?: string;
  storeTitle?: string;
  isModalOpen: boolean;
  nsmCmmentTxt?: any;
  divisionCommentTxt?: any;
  districtCommentTxt?: any;
  storeCommentTxt?: any;
  event: string;
  onClose: () => void;
  nsmDisable?: boolean;
  divisionDisable?: boolean;
  districtDisable?: boolean;
  storeDisable?: boolean;
}

export default function MultiComment({
  nsmTitle,
  divisionTitle,
  districtTitle,
  storeTitle,
  nsmDisable,
  divisionDisable,
  districtDisable,
  storeDisable,
  isModalOpen,
  nsmCmmentTxt,
  divisionCommentTxt,
  districtCommentTxt,
  storeCommentTxt,
  event,
  onClose,
}: IFileUploadModal) {
  const { userRoleType } = findRole();
  const { districtCommentSave, divisionCommentSave } = useNsmComment();
  const [commenNsm, setNsmComment] = useState('');
  const [commentDiv, setDivComment] = useState('');
  const [commentDist, setDistComment] = useState('');
  const [commentStore, setStoreComment] = useState('');

  useEffect(() => {
    setNsmComment(nsmCmmentTxt ?? '');
    setDivComment(divisionCommentTxt ?? '');
    setDistComment(districtCommentTxt ?? '');
    setStoreComment(storeCommentTxt ?? '');
  }, [
    nsmCmmentTxt,
    districtCommentTxt,
    divisionCommentTxt,
    storeCommentTxt,
    onClose,
  ]);
  const handleComment = () => {
    if (userRoleType === 'dist') {
      districtCommentSave({
        commentText: commentDist,
        eventId: event,
      });
    } else if (userRoleType === 'store') {
      districtCommentSave({
        commentText: commentStore,
        eventId: event,
      });
    } else {
      divisionCommentSave({
        commentText: commentDiv,
        eventId: event,
      });
    }
  };
  const handleClear = () => {
    if (userRoleType === 'dist') {
      setDistComment('');
    } else if (userRoleType === 'store') {
      setStoreComment('');
    } else {
      setDivComment('');
    }
  };
  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {nsmTitle}
          </h1>
          <button className="" type="button" onClick={onClose} data-testid='close-modal'>
            X
          </button>
        </div>
        <div className="flex flex-row justify-center p-5">
          <div className="flex h-full w-full flex-row content-center">
            <textarea
              className="
        form-control
        m-0
        block
        h-full
        w-full
        rounded
        border
        border-solid
        border-gray-300
        bg-white
        bg-clip-padding px-3
        py-1.5 text-base font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-blue-600
        focus:bg-white focus:text-gray-700 focus:outline-none disabled:bg-gray-100
      "
              id="exampleFormControlTextarea1"
              placeholder="Your Comments"
              value={commenNsm}
              onChange={(e) => setNsmComment(e.target.value)}
              disabled={nsmDisable}
              data-testid={`${nsmTitle}-input`}

            />
          </div>
        </div>

        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {divisionTitle}
          </h1>
        </div>
        <div className="flex flex-row justify-center p-5">
          <div className="flex h-full w-full flex-row content-center">
            <textarea
              className="
        form-control
        m-0
        block
        h-full
        w-full
        rounded
        border
        border-solid
        border-gray-300
        bg-white
        bg-clip-padding px-3
        py-1.5 text-base font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-blue-600
        focus:bg-white focus:text-gray-700 focus:outline-none disabled:bg-gray-100
      "
              id="exampleFormControlTextarea1"
              placeholder="Your Comments"
              value={commentDiv}
              onChange={(e) => setDivComment(e.target.value)}
              disabled={divisionDisable}
              data-testid={`${divisionTitle}-input`}

            />
          </div>
        </div>

        {userRoleType === 'dist' || userRoleType === 'store' ? (
          <>
            <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
              <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
                {districtTitle}
              </h1>
            </div>
            <div className="flex flex-row justify-center p-5">
              <div className="flex h-full w-full flex-row content-center">
                <textarea
                  className="
        form-control
        m-0
        block
        h-full
        w-full
        rounded
        border
        border-solid
        border-gray-300
        bg-white
        bg-clip-padding px-3
        py-1.5 text-base font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-blue-600
        focus:bg-white focus:text-gray-700 focus:outline-none disabled:bg-gray-100
      "
                  id="exampleFormControlTextarea1"
                  placeholder="Your Comments"
                  value={commentDist}
                  onChange={(e) => setDistComment(e.target.value)}
                  disabled={districtDisable}
                  data-testid={`${districtTitle}-input`}

                />
              </div>
            </div>
          </>
        ) : null}
        {userRoleType === 'store' ? (
          <>
            <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
              <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
                {storeTitle}
              </h1>
            </div>
            <div className="flex flex-row justify-center p-5">
              <div className="flex h-full w-full flex-row content-center">
                <textarea
                  className="
        form-control
        m-0
        block
        h-full
        w-full
        rounded
        border
        border-solid
        border-gray-300
        bg-white
        bg-clip-padding px-3
        py-1.5 text-base font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-blue-600
        focus:bg-white focus:text-gray-700 focus:outline-none disabled:bg-gray-100
      "
                  id="exampleFormControlTextarea1"
                  placeholder="Your Comments"
                  value={commentStore}
                  onChange={(e) => setStoreComment(e.target.value)}
                  disabled={storeDisable}
                  data-testid={`${storeTitle}-input`}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="-mr-2 flex flex-row items-center justify-end p-5  pt-[25px]">
          <ButtonGroup styles="ml-auto">
            <Button
              text="Clear"
              //   disabled={!UserRoleMaintenance('Events', 'NsmComment')}
              onClick={() => handleClear()}
            />
            <Button
              type="contained"
              text="Save"
              onClick={() => handleComment()}
            />
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}
