/* eslint-disable no-nested-ternary */
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import Conatiner from 'components/Container/Container';
import DropDown from 'components/Dropdown/Dropdown';
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import useDistrictList from 'hooks/useDistrictList';
import useEventItem from 'hooks/useEventItem';
import useEventList from 'hooks/useEventList';
import useEventListForView from 'hooks/useEventListForView';
import useStoreList from 'hooks/useStoreList';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { findRole } from 'utils/role';

function ViewAs({ storeArrayList, districtListResponse, eventItems }: any) {
  const [selecteddistrictValue, setSelecetDistrictValue] = useState('');
  const [selectedStoreValue, setSelecetStoreValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const fetchStoreList = useStoreList();
  const fetchEventList = useEventList();
  const fetchEventListForView = useEventListForView();
  const { userRoleType, userRoleValue } = findRole();

  const { saveEventItem } = useEventItem();
  useDistrictList();
  const handleSearch = (value: any) => {
    // fetchEventList(value);

    fetchEventListForView(value);
  };

  useEffect(() => {
    setSelecetStoreValue('');
    fetchStoreList({ distId: selecteddistrictValue });
  }, [selecteddistrictValue]);
  useEffect(() => {
    setTableData(eventItems);
  }, [eventItems]);

  const districtColumns = [
    {
      Header: 'Event Name',
      accessor: 'eventName',
      width: 300,
      Cell: (row: any) => (
        <Link
          data-testid="event"
          className=" text-sky-500"
          to={`/view/eventItemDistView/${row?.cell?.row?.original.eventId}/${row?.cell?.row?.original.seasProgramSk}/${row?.cell?.row?.original.eventName}/${row?.cell?.row?.original.status}/${isChecked}/${userRoleValue}/${selecteddistrictValue}`}
          onClick={() => saveEventItem({ eventRow: row?.cell?.row?.original })}
        >
          {row.value}{' '}
        </Link>
      ),
    },
    {
      Header: 'District Start Date',
      accessor: 'distStartDate',
    },
    {
      Header: 'District Cut Off Date',
      accessor: 'distEndDate',
    },
    {
      Header: 'Store Start Date',
      accessor: 'storeStartDate',
    },

    {
      Header: 'Store Cut Off Date',
      accessor: 'storeEndDate',
    },
    {
      Header: 'Event Status',
      accessor: 'status',
    },
  ];
  const storeColumns = [
    {
      Header: 'Event Name',
      accessor: 'eventName',
      width: 300,
      Cell: (row: any) => (
        <Link
          className=" text-sky-500"
          to={`/view/eventItemStoreView/${row?.cell?.row?.original.eventId}/${selectedStoreValue}/${row?.cell?.row?.original.seasProgramSk}/${row?.cell?.row?.original.eventName}/${row?.cell?.row?.original.status}/${selecteddistrictValue}`}
          onClick={() => saveEventItem({ eventRow: row?.cell?.row?.original })}
          data-testid="event-name"
        >
          {`${row?.row?.original?.eventName}`}
        </Link>
      ),
    },
    {
      Header: 'Store Start Date',
      accessor: 'storeStartDate',
    },

    {
      Header: 'Store Cut Off Date',
      accessor: 'storeEndDate',
    },
  ];

  return (
    <div>
      <div id="section" ref={ref} className="pt-1">
        <Banner
          title={
            selecteddistrictValue && selectedStoreValue
              ? 'Store List'
              : 'District List'
          }
        >
          {/* <Button text="Save" /> */}
        </Banner>
        <section className="pt-4 pl-4 pr-4">
          <Accordion heading="View As">
            <Conatiner styles="border-t">
              <div className="items start mt-2 mb-2 flex">
                <DropDown
                  List={districtListResponse}
                  isMandatory
                  label="Select District"
                  value={selecteddistrictValue}
                  onChange={setSelecetDistrictValue}
                  data-testid="Select-District"
                />
                <div className="items flex pl-4">
                  <DropDown
                    List={storeArrayList}
                    isMandatory
                    value={selectedStoreValue}
                    label="Select Store"
                    onChange={setSelecetStoreValue}
                    data-testid="Select-Store"
                  />
                </div>
                <div className="flex items-center pl-4">
                  {searchClicked && (
                    <CheckBox
                      id="checkbox"
                      label="Show closed events"
                      handleChange={() => {
                        setIsChecked(!isChecked);
                        handleSearch({
                          event: '',
                          program: '',
                          showClosed: !isChecked,
                          roleForView:
                          selecteddistrictValue && selectedStoreValue
                            ? 'store'
                            : selecteddistrictValue
                            ? 'dist'
                            : userRoleType,
                        roleValueForView:
                          selecteddistrictValue && selectedStoreValue
                            ? selectedStoreValue
                            : selecteddistrictValue
                            ? `${userRoleValue}.${ selecteddistrictValue}`
                            : userRoleValue,
                        });
                      }}
                      checked={isChecked}
                    />
                  )}
                </div>
                <div className="flex items-center pl-4">
                  <Button
                    type="contained"
                    text="View"
                    onClick={() => {
                      setSearchClicked(true);
                      handleSearch({
                        event: '',
                        program: '',
                        showClosed: isChecked,
                        roleForView:
                          selecteddistrictValue && selectedStoreValue
                            ? 'store'
                            : selecteddistrictValue
                            ? 'dist'
                            : userRoleType,
                        roleValueForView:
                          selecteddistrictValue && selectedStoreValue
                            ? selectedStoreValue
                            : selecteddistrictValue
                            ?`${userRoleValue}.${ selecteddistrictValue}`
                            : userRoleValue,
                      });
                    }}
                    disabled={selecteddistrictValue === ''}
                  />
                </div>
              </div>
            </Conatiner>
          </Accordion>
        </section>
      </div>
      <div className="pb-4 pl-4 pr-4">
        {tableData && tableData.length > 0 && searchClicked ? (
          <RTable
            isfilter
            columns={
              selecteddistrictValue && selectedStoreValue
                ? storeColumns
                : districtColumns
            }
            data={tableData}
            isTotal
            height={height}
            hiddenColumns={[]}
            data-testid="viewTable"
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
const mapStateToProps = ({ eventList }: AppState) => ({
  storeArrayList: eventList.storeList,
  eventItems: eventList.eventList,
  districtListResponse: eventList.districtList,
});

export default connect(mapStateToProps, null)(ViewAs);
