import React, { useEffect, useState } from 'react';
import { Column, Row } from 'react-table';
import { InputValidationType } from 'utils/validations';

export type ModifiedColumnType = {
  validation?: InputValidationType;
} & Column;

type EditableCellType = {
  value: string;
  row: Row;
  column: ModifiedColumnType;
  updateData?: (rowIndex: any, columnId: any, value: any) => void;
};
export default function EditableCell({
  value: initialValue,
  row: { index },
  column: { id, validation },
  updateData,
}: EditableCellType) {
  const [value, setValue] = useState(initialValue);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChangeHandler = (ev: any) => {
    setValue(ev.target.value);
    if (ev.target.value === '') setIsError(true);
  };

  const onHandleKeydown = (e: any) => {
    if (validation?.value) {
      if (e.key === '.' || e.key === '-') {
        e.preventDefault();
      }
    }
  };

  const onBlurHandler = (ev: any) => {
    const inputValue = ev.target.value;
    if (inputValue !== '' && validation?.pattern) {
      setIsError(!validation?.pattern.test(inputValue));
    } else if (
      (validation?.required && inputValue === '') ||
      inputValue === ' '
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    if (validation?.custom) {
      const hasError = validation.custom(inputValue);
      setIsError(!hasError);
    }

    updateData && updateData(index, id, value);
  };
  return (
    <input
      className={`w-full rounded border  px-2 py-1 text-gray-700 ${
        isError ? 'has-error border-red-600' : 'border-[#B2B4BB]'
      }`}
      value={value}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      // Below properties are based on TYPE InputValidationType
      max={validation?.max}
      min={validation?.min}
      maxLength={Number(validation?.maxLength)}
      minLength={Number(validation?.minLength)}
      required={validation?.required}
      type={validation?.type}
      onKeyDown={onHandleKeydown}
      title={isError ? validation?.errorMessage : value}
      disabled={validation?.disable}
      data-testid='editable-cell'
    />
  );
}
