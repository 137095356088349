export const DateFormatyyyymmdd = (dateValue: string) => {
  let formattedDate = '';
  if (dateValue) {
    const arr = dateValue.split('/');
    const month = arr[0];
    const day = arr[1];
    const year = arr[2];
    formattedDate = `${year}-${month}-${day}`;
  }
  return formattedDate;
};
export const DateFormatmmddyyyy = (dateValue: string) => {
  let formattedDate = '';
  if (dateValue) {
    const arr = dateValue.split('-');
    const year = arr[0];
    const month = arr[1];
    const day = arr[2];
    formattedDate = `${month}/${day}/${year}`;
  }
  return formattedDate;
};
