import { useCallback } from 'react';

import { divisionCommentAction } from 'redux/actions/eventListAction/eventListAction';

import { useAppThunkDispatch } from 'redux/store';

type UseDivCommentProps = {
  eventId: string;
  division: string;
  programSK: string;
} | null;

const useDivComment = () => {
  const dispatch = useAppThunkDispatch();

  const divComment = useCallback((data: UseDivCommentProps) => {
    dispatch(
      divisionCommentAction({
        eventId: data?.eventId ?? '',
        programSK: data?.programSK ?? '',
        division: data?.division ?? '',
      }),
    );
  }, []);

  return divComment;
};

export default useDivComment;
