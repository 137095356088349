import axios from 'utils/interceptor';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { apiBaseURL } from 'utils/apiBaseUrl';
import {
  GET_EVENT_STORE_LIST_SUCCESS,
  TOTAL_BY_DISTRICT_SUCCESS,
} from './types';
import { userId } from 'layouts/Header/Header';

type GetEventStoreListType = {
  eventId: string;
  districtId: string;
  programId: string;
};

export type UpdateStoreItemType = {
  pgm_str_event_sk: null;
  pgm_str_sk: string;
  event_id: string;
  cst_tgt_amt: number;
};

export const getAllDistrictByDiv =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getAllDistrictByDiv?totalType=totalByDistrict&prgmName=&prgmYr=&divNumber=&strNumber=&distNumber=&itemNumber=`,
      );
 const districtArray = [{distName:'All'},...response.data]
      dispatch({
        type: TOTAL_BY_DISTRICT_SUCCESS,
        payload: districtArray,
      });
      return response;
    } catch {
      return new Error(`getAllDistrictByDiv API call failed`);
    }
  };

export const getEventStoreList =
  ({ eventId, districtId, programId }: GetEventStoreListType) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getEventStoreList?eventId=${eventId}&distId=${districtId}&showClosedFlag=&programSK=${programId}`,
      );

      dispatch({
        type: GET_EVENT_STORE_LIST_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventStoreList API call failed`);
    }
  };

export const updateStoreListCost =
  (data: UpdateStoreItemType[]) => async () => {
    try {
      return await axios.post(`${apiBaseURL}/ssa/updateStoreListCost`, data, {
        headers: {
          userName: userId,
        },
      });
    } catch {
      return new Error(`updateStoreListCost API call failed`);
    }
  };
