import { useCallback } from 'react';
import {
  eventItemActionForView,
} from 'redux/actions/eventListAction/eventListAction';
import { useAppThunkDispatch } from 'redux/store';

type UseEventItemListProps = {
  eventId: string;
  programSK?: string;
  pogSk?: string;
  roleForView?: string;
  roleValueForView?:string;
} | null;
const useEventItemForView = () => {
  const dispatch = useAppThunkDispatch();

  const fetchEventItemListForView = useCallback((data: UseEventItemListProps) => {
    dispatch(
        eventItemActionForView({
        eventId: data?.eventId ?? '',
        programSK: data?.programSK ?? '',
        pogSk: data?.pogSk ?? '',
        roleForView: data?.roleForView ?? '',
        roleValueForView:data?.roleValueForView ?? '',
      }),
    );
  }, []);

  
  return fetchEventItemListForView;
};

export default useEventItemForView;
