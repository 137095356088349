/* eslint-disable jsx-a11y/control-has-associated-label */
import Button, { ButtonGroup } from 'components/Button';
import roleCapability from 'constants/roleCapability';
import useNsmComment from 'hooks/useNsmComment';
import React, { useEffect, useState } from 'react';
import userRoleCheck from 'utils/role';

interface IFileUploadModal {
  title: string;
  isModalOpen: boolean;
  nsmCommentTxt: any;
  event: string;
  onClose: () => void;
}

export default function NSMComments({
  title,
  isModalOpen,
  nsmCommentTxt,
  event,
  onClose,
}: IFileUploadModal) {
  const { UserRoleMaintenance } = userRoleCheck(roleCapability);
  const { nsmCommentSave } = useNsmComment();
  const [comment, setComment] = useState('');
  useEffect(() => {
    setComment(nsmCommentTxt ?? '');
  }, [nsmCommentTxt,onClose]);

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" type="button" onClick={onClose} data-testid='modal-close'>
            X
          </button>
        </div>
        <div className="flex flex-row justify-center p-5 pt-[50px]">
          <div className="flex h-full w-full flex-row content-center">
            <textarea
              className="
        form-control
        m-0
        block
        h-full
        w-full
        rounded
        border
        border-solid
        border-gray-300
        bg-white
        bg-clip-padding px-3
        py-1.5 text-base font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-blue-600
        focus:bg-white focus:text-gray-700 focus:outline-none disabled:bg-gray-100
      "
              id="exampleFormControlTextarea1"
              placeholder="Your Comments"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={!UserRoleMaintenance('Events', 'NsmComment')}
              data-testid={`${title}-input`}
            />
          </div>
        </div>
        <div className="-mr-2 flex flex-row items-center justify-end p-5  pt-[25px]">
          <ButtonGroup styles="ml-auto">
            <Button
              text="Clear"
              disabled={!UserRoleMaintenance('Events', 'NsmComment')}
              onClick={() => setComment('')}
            />
            <Button
              type="contained"
              text="Save"
              onClick={() =>
                nsmCommentSave({ commentText: comment, eventId: event })
              }
              disabled={!UserRoleMaintenance('Events', 'NsmComment')}

            />
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}
