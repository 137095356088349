import Button from 'components/Button';
import InputField from 'components/InputField';
import React, { useEffect, useRef, useState } from 'react';
import { saveStoreGroup } from 'redux/actions/storeGroupAction';
import { useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';

interface IAddStoreGroup {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
}

export default function AddStoreGroup({
  title,
  isModalOpen,
  onClose,
}: IAddStoreGroup) {
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const dispatch = useAppThunkDispatch();
  const [groupName, setGroupName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleChange = (e: any) => {
    setGroupName(e.target.value);
  };

  const handleClose = () => {
    onClose();
    setErrorMsg('');
    setGroupName('');
  };

  const handleSave = () => {
    dispatch(saveStoreGroup(groupName, handleClose));
  };

  useEffect(() => {
    if (groupName.trim().length > 0 && groupName.match(/^[a-z0-9 ]+$/i)) {
      setErrorMsg('');
    } else {
      setErrorMsg('Please enter a valid group name (alphanumeric) ');
    }
  }, [groupName]);

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="max-h-[60%] min-h-[20%]  min-w-[30%] overflow-auto rounded-lg border bg-white shadow-2xl">
        <div
          ref={ref}
          className="flex flex-row content-center justify-between border-b p-5 pb-2"
        >
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button
            className=""
            type="button"
            onClick={handleClose}
            data-testid="addModal-close"
          >
            X
          </button>
        </div>
        <div className="flex-1 flex-col p-5">
          {errorMsg && (
            <span className="text-red-600">
              {groupName.trim().length !== 0 ? errorMsg : ''}
            </span>
          )}
          <div className="w-full flex-1 pt-4">
            <InputField
              label="Group Name"
              id="storeGroup"
              name="storeGroup"
              value={groupName}
              isMandatory
              handleChange={handleChange}
              styleClass="flex-1 pb-2"
            />
          </div>
          <div className="mt-4 flex w-full justify-end">
            <Button
              type="contained"
              text="Save"
              onClick={handleSave}
              disabled={errorMsg !== ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
