import { AnyAction } from 'redux';
import {
  CLEAR_ORDER_TABLES,
  DIVISION_ORDER_TOTALS_INITIALIZE,
  DIVISION_ORDER_TOTALS_PROGRAME_YEAR,
  DIVISION_ORDER_TOTALS_SEARCH,
  GET_EVENT_NAME,
  ORDER_TOTALS_CLEAR,
  ORDER_TOTALS_DIVNUMBER_DISTNUMBER_STORENUMBER_SAVE,
} from 'redux/actions/types';
import { AppState } from 'redux/store';

interface IDivisionOrderTotals {
  programList: any | undefined;
  programYearList: any | undefined;
  orderTotalsList: any | undefined;
  divNumber: any | undefined;
  distNumber: any | undefined;
  storeNumber: any | undefined;
  eventNameList: any | undefined;
}

export const orderTotalsInit: IDivisionOrderTotals = {
  programList: undefined,
  programYearList: undefined,
  orderTotalsList: undefined,
  divNumber: undefined,
  distNumber: undefined,
  storeNumber: undefined,
  eventNameList: undefined,
};

const DivisionOrderTotalsReducer = (
  state = orderTotalsInit,
  action: AnyAction,
): AppState => {
  switch (action.type) {
    case DIVISION_ORDER_TOTALS_INITIALIZE:
      return {
        ...orderTotalsInit,
      };

    case CLEAR_ORDER_TABLES:
      return {
        ...state,
        orderTotalsList: undefined,
        divNumber: undefined,
        distNumber: undefined,
        storeNumber: undefined,
        eventNameList: undefined,
      };  

    case ORDER_TOTALS_CLEAR:
      return {
        ...state,
        orderTotalsList: undefined,
      };

    case DIVISION_ORDER_TOTALS_PROGRAME_YEAR:
      return {
        ...state,
        programList: [...action.payload.progList],
        programYearList: { ...action.payload.progmAndYrMap },
      };

    case DIVISION_ORDER_TOTALS_SEARCH:
      return {
        ...state,
        orderTotalsList: [...action.payload],
      };
    case ORDER_TOTALS_DIVNUMBER_DISTNUMBER_STORENUMBER_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_EVENT_NAME:
      return {
        ...state,
        eventNameList: [...action.payload],
      };

    default:
      break;
  }
  return state;
};
export default DivisionOrderTotalsReducer;
