import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import Conatiner from 'components/Container/Container';
import Fileupload from 'components/Modal/Fileupload';
import RTable from 'components/RTable';
import Banner from 'components/Banner';
import {
  TypeItemEventMap,
  TypeUploadedEventList,
} from 'redux/reducers/uploadEventsReducer';
import axios from 'utils/interceptor';

import { apiBaseURL } from 'utils/apiBaseUrl';
import { useDispatch } from 'react-redux';
import { UPLOAD_EVENT_CLEAR } from 'redux/actions/types';
import { toast } from 'react-toastify';
import NoData from 'components/NoData';
import useResizeObserver from 'use-resize-observer';
import { tabHeight } from 'components/Tab/Tab';
import { exportErrorEventUpload } from 'redux/actions/uploadEventsAction';
import { useAppThunkDispatch } from 'redux/store';
import DBSSFileupload from 'components/Modal/DBSSValidation';

type UploadEventsProps = {
  itemEventMap?: TypeItemEventMap[] | null;
  uploadedEventList?: TypeUploadedEventList | null;
  hasError?: boolean;
  eventUploadResObj?: null | Record<string, any>;
};

const columns = [
  {
    Header: 'UPC',
    accessor: 'upc',
  },
  {
    Header: 'Item ID',
    accessor: 'itemNumber',
  },
  {
    Header: 'Description',
    accessor: 'itemName',
  },
  {
    Header: 'Error Message',
    accessor: 'error',
  },
];

export default function UploadEvents({
  itemEventMap,
  uploadedEventList,
  hasError,
  eventUploadResObj,
}: UploadEventsProps) {
  const dispatch = useDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const [isModalOpen, setIsOpen] = useState(false);
  const [isModalOpenDBSSValiadte, setIsModalOpenDBSSValiadte] = useState(false);
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const tabHeightUpdated = parseInt(`${tabHeight}`, 10);

  useEffect(() => {
    itemEventMap && setIsOpen(false);
  }, [itemEventMap]);

  const handleClose = () => {
    setIsOpen(!isModalOpen);
  };
  const handleCloseDBSSValidate = () => {
    setIsModalOpenDBSSValiadte(!isModalOpenDBSSValiadte);
  };

  const onSaveHandler = async () => {
    try {
      const data = {
        ...uploadedEventList,
        strategy: 'StoredProcedure',
      };

      const response = await axios.post(
        `${apiBaseURL}/ssa/createEvent?strategy=StoredProcedure`,
        data,
      );
      if (response.data.status === 'success') {
        dispatch({
          type: UPLOAD_EVENT_CLEAR,
        });
        toast.success('Events uploaded successfully');
      }
      return response;
    } catch {
      toast.error('Failed to upload events');
      return new Error(`createEvent API call failed`);
    }
  };

  const onExportClick = () => {
    thunkDispatch(exportErrorEventUpload(eventUploadResObj));
  };

  return (
    <div className="pt-1">
      <div ref={ref}>
        <Banner title="Upload Events">
          <Conatiner>
            <Button
              text="DBSS file validation"
              onClick={handleCloseDBSSValidate}
              type="contained"
            />
            <Button
              text="Event upload"
              onClick={handleClose}
              type="contained"
            />
            <Button
              text="Export"
              disabled={!hasError}
              onClick={onExportClick}
            />
            <Button
              type="contained"
              text="Save"
              onClick={onSaveHandler}
              disabled={!uploadedEventList || hasError}
            />
            <DBSSFileupload
              title="DBSS file validation:"
              onClose={handleCloseDBSSValidate}
              isModalOpen={isModalOpenDBSSValiadte}
            />
            <Fileupload
              title="File Upload:"
              onClose={handleClose}
              isModalOpen={isModalOpen}
            />
          </Conatiner>
        </Banner>
      </div>
      <section className="p-4">
        {itemEventMap ? (
          <RTable
            columns={columns}
            isfilter
            data={itemEventMap as any}
            isTotal
            height={height + tabHeightUpdated}
            hiddenColumns={[]}
            data-testid="uploadEventsTable"
          />
        ) : (
          <NoData subTitle="Please upload events to see the event items." />
        )}
      </section>
    </div>
  );
}