import { IconHelp, IconNotification, lcon24, AbsLogoIcon } from 'assets';
import Button from 'components/Button';
import IconComponent from 'components/IconComponent';
import { faqExcludedPageListObject } from 'constants/pageListObject';
import FAQShow from 'pages/FAQ/FAQShow';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { authProvider } from 'utils/authProvider';
import getUsernameLogo from 'utils/getUserName';
import { findRole } from 'utils/role';

const auth = authProvider();
export const { userId, name } = auth;
export const headerHeight = 70;
export default function Header() {
  const { userRoleType } = findRole();
  const [logoustStatus, setLogoutStatus] = React.useState(false);
  const username = getUsernameLogo(name);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const logout = async () => {
    auth.logout();
  };

  const handleCloseDBSSValidate = () => {
    const currentUrl = window?.location?.href;
    if (currentUrl) {
      const keys = Object.keys(faqExcludedPageListObject);
      for (let index = 0; index < keys.length; index += 1) {
        // const element = array[index];
        if (currentUrl.includes(keys[index])) {
          const key = keys[index];
          toast.warning(`FAQ is not availble for page ${faqExcludedPageListObject[key]}`)
          return;
        }
      }
    }
    setIsModalOpen(!isModalOpen);
  };

  return (
    <header
      style={{ gridArea: 'header' }}
      className={`flex h-[${headerHeight}px] items-center border-b-2 bg-header py-4 pr-2 pt-5 pl-5`}
    >
      {/* Logo */}
      <div className="flex h-full grid-rows-[70px_1fr] items-center border-r-2 border-r-gray-200 pr-5">
        <img src={AbsLogoIcon} alt="ABS Logo" width="42" />
        <span className="ml-2 text-sm font-semibold uppercase tracking-wider">
          Store Survey
        </span>
      </div>

      {/* Application Name */}
      <h1 className="text-md font-nunito ml-5 w-fit font-semibold">
        Assortment Product
      </h1>

      {/* Action Items */}
      <div className="ml-auto flex items-center justify-between">
        <button
          type="button"
          title='FAQ'
          onClick={handleCloseDBSSValidate}
        ><img
            className="pl-3 h-6 "
            src={IconHelp}
            alt="FAQ"
          /></button>
        <IconComponent styles="pl-3" source={lcon24} />
        {/* <IconComponent styles="pl-3" source={IconHelp} /> */}
        <IconComponent styles="pr-3 pl-3" source={IconNotification} />
        <div className="mt-2 ml-4 mr-2 flex flex-col items-center text-center">
          <button
            className=" relative mx-4 mb-[4px] flex h-9 w-9 items-center justify-center rounded-full bg-primary uppercase text-white"
            type="button"
            onClick={() => setLogoutStatus(!logoustStatus)}
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-testid='logo'
          >
            {username?.logo}
          </button>
          {logoustStatus ? (
            <ul
              className=" absolute rounded-lg bg-gray-200 shadow-lg"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="   mt-3 h-10 ">
                <button type="button" className="mr-2 ml-2" onClick={logout} data-testid='logout-btn'>
                  Logout
                </button>
              </li>
            </ul>
          ) : null}
          <span className="font-small mb-2 text-sm leading-tight">
            <span className="uppercase">{userId}</span>-
            <span className="capitalize">{userRoleType}</span>
          </span>
        </div>
      </div>

      <FAQShow
        title="Frequently Asked Questions(FAQ)"
        onClose={handleCloseDBSSValidate}
        isModalOpen={isModalOpen}
      />
    </header>

  );
}
