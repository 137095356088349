import { ExpandDownIcon } from 'assets';
import useOrderProgram from 'hooks/useOrderProgram';
import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SideMenuContext from './SideMenuContext';
import { toast } from 'react-toastify';
import useEventList from 'hooks/useEventList';

type MenuItemType = {
  icon?: string;
  title: string;
  url?: string;
  children?: React.ReactNode;
};

export default function MenuItem({
  icon,
  title,
  url = '#',
  children,
}: MenuItemType) {
  const { pathname } = useLocation();
  const { open, toggleOpen } = useContext(SideMenuContext);
  const [expanded, setExpanded] = useState(false);
  const { clearOrderAll, clearOrderTables } = useOrderProgram();
  const { clearSavedEventProgram } = useEventList()

  // Setting background color for active menu items
  const activeBgColor = (() => {
    let value = '';
    const isMainMenuActive = pathname
      .split('/')
      .map((item) => item.toLowerCase())
      .includes(title.toLowerCase());
    const isSubMenuActive = pathname === url;

    if (isMainMenuActive) value = 'bg-[#0076D6]';
    if (!children && isSubMenuActive) value = 'bg-[#033B69]';

    return value;
  })();

  // Click handler for menu item click
  const onClickHandler = () => {
    !children ? toggleOpen(!open) : setExpanded(!expanded);
  };

  // UI component for expanded menu items
  function ExpandedView() {
    const isIconClass = icon ? 'ml-3' : 'ml-8';
    return (
      <>
        <span className={`${isIconClass} text-sm text-white`} >{title}</span>
        {children ? (
          <button
            type="button"
            className="ml-auto p-1"
            onClick={onClickHandler}
            data-testid='expand-btn'
          >
            <img
              src={ExpandDownIcon}
              alt="Expand/Collapse icon"
              width="20"
              className={`${expanded && 'rotate-180'}`}
            />
          </button>
        ) : null}
      </>
    );
  }

  return (
    <>
      <Link
        to={url}
        className={`flex h-[60px] items-center border-b border-white/20 px-4 py-3 ${activeBgColor}`}
        onClick={() => {
          try {
            const arrURL = url.split('/').includes('orders');
            const arrURLEvents = url.split('/').includes('events');
            if (arrURL) {
              // clearOrderAll();
              clearOrderTables();
            }
            if (arrURLEvents) {
              clearSavedEventProgram();
            }
          } catch (error) {
            toast.error('Error');
          }
        }}
        data-testid='link'
      >
        {icon ? <img src={icon} alt={`${title} menu icon`} width="20" /> : null}
        {open ? <ExpandedView /> : null}
      </Link>
      {children && expanded && open ? children : null}
    </>
  );
}


