// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    'sidenav header'
    'sidenav main';
  height: 100vh;
}

/* Give every child element its grid name */
.header {
  grid-area: header;
  background-color: #648ca6;
}

.sidenav {
  grid-area: sidenav;
  background-color: #394263;
}

.main {
  grid-area: main;
  background-color: #8fd4d9;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/Layout/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,iCAAiC;EACjC;;kBAEgB;EAChB,aAAa;AACf;;AAEA,2CAA2C;AAC3C;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".grid-container {\n  display: grid;\n  grid-template-columns: 240px 1fr;\n  grid-template-rows: 50px 1fr 50px;\n  grid-template-areas:\n    'sidenav header'\n    'sidenav main';\n  height: 100vh;\n}\n\n/* Give every child element its grid name */\n.header {\n  grid-area: header;\n  background-color: #648ca6;\n}\n\n.sidenav {\n  grid-area: sidenav;\n  background-color: #394263;\n}\n\n.main {\n  grid-area: main;\n  background-color: #8fd4d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
