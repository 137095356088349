/* eslint-disable */
import jwt_decode from 'jwt-decode';
import { authBaseURL } from './apiBaseUrl';

export const authProvider = () => {
  let accessToken: any;
  let decodedIdToken: any;
  let decodedAccessToken: any;

  let group: any;

  accessToken = getCookie('ent-abs-auth');
  const idToken = getCookie('ent-abs-itkn');

  const apiToken = accessToken ? accessToken.split('%22')[3] : null;
  try {
    decodedIdToken = typeof idToken === 'string' ? jwt_decode(idToken) : null;
    decodedAccessToken =
      typeof accessToken === 'string' ? jwt_decode(accessToken) : null;
  } catch (e) {
    decodedIdToken = null;
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(`${authBaseURL}/authenticate`);
  }
  const clearSession = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(`${authBaseURL}/authenticate`);
  };
  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(`${authBaseURL}/logout`);
  };

  return {
    accessToken: accessToken,
    apiToken: apiToken,
    idToken: idToken,
    decodedIdToken: decodedIdToken ? decodedIdToken : null,
    decodedAccessToken: decodedAccessToken ? decodedAccessToken : null,
    name: decodedIdToken ? decodedIdToken.name : null,
    mail: decodedIdToken ? decodedIdToken['preferred_username'] : null,
    userId: decodedIdToken
      ? decodedIdToken['preferred_username'].split('@')[0]
      : null,
    clearSession: clearSession,
    logout: logout,
  };
};

function getCookie(name: string) {
  function escape(s: string) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
  }
  var match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'),
  );
  return match ? match[1] : null;
}
