// eslint-disable-next-line import/prefer-default-export
export { default as logo } from './pngImages/logo.png';
export { default as logoWithoutText } from './pngImages/logo-without-text.png';
export { default as hamburger } from './pngImages/Hamburger.png';
export { default as info24x7 } from './pngImages/info_24x24.png';
export { default as review } from './pngImages/review.png';
export { default as menu } from './pngImages/menu.png';
export { default as clipboardTextClock } from './pngImages/clipboard-text-clock.png';
export { default as close } from './pngImages/close.png';
export { default as Path } from './pngImages/Path.png';
export { default as sort } from './pngImages/sort.png';
export { default as filter } from './pngImages/filter.png';
export { default as settings } from './pngImages/settings.png';

export { default as lcon24 } from './svgImages/help.svg';
export { default as editIcon } from './svgImages/Pen.svg';
export { default as deleteIcon } from './svgImages/Delete.svg';
export { default as failedSVG } from './svgImages/Failed.svg';
export { default as completedSVG } from './svgImages/Completed.svg';
export { default as inprogressSVG } from './svgImages/Inprogress.svg';
export { default as notStartedSVG } from './svgImages/Notstarted.svg';
export { default as scheduledSVG } from './svgImages/Scheduled.svg';
export { default as warningSVG } from './svgImages/Warning.svg';
export { default as success } from './svgImages/Success.svg';

export { default as IconHelp } from './svgImages/help_question_24x24.svg';
export { default as IconNotification } from './svgImages/notification_alert_dark24x24.svg';

// Icons Related Event Item
export { default as lock } from './pngImages/lock.png';
export { default as unLock } from './pngImages/unlock.png';

// Icons Related to Menu
export { default as AbsLogoIcon } from './images/menu/abs-logo.svg';
export { default as CloseIcon } from './images/menu/close.svg';
export { default as ContentPasteGoIcon } from './images/menu/content-paste-go.svg';
export { default as ExpandDownIcon } from './images/menu/expand-down.svg';
export { default as HamburgerIcon } from './images/menu/hamburger.svg';
export { default as LeaderboardIcon } from './images/menu/leaderboard.svg';
export { default as MoreVerticalIcon } from './images/menu/more-vertical.svg';
export { default as SearchIcon } from './images/menu/search.svg';
export { default as EventIcon } from './images/menu/events.svg';
export { default as OrderIcon } from './images/menu/orders.svg';
export { default as ViewAsIcon } from './images/menu/view-as.svg';
export { default as StoreGroupIcon } from './images/menu/store-group.svg';
export { default as question } from './svgImages/Question.svg';

// General Icons
export { default as BackIcon } from './images/back-icon.svg';
export { default as NoDataImage } from './images/no-data.svg';