import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from 'pages/Home';
import Order from 'pages/Order';
import Events from 'pages/Events/Events';

import EventsStoresItems from 'pages/Events/EventList/Admin/EventStoreItems';
import ViewAs from 'pages/View';
import EventItemsForDiv from 'pages/Events/EventList/division/EventItemsForDiv';
import EventItems from 'pages/Events/EventList/Admin/EventItems';
import EventsStoresItemsForDiv from 'pages/Events/EventList/division/EventStoreItemsForDiv';
import ViewStoreListEventItems from 'pages/View/ViewEventItems/StoreList/ViewStoreListEventItems';
import ViewDistrictListEventItems from 'pages/View/ViewEventItems/DistrictList/ViewDistrictListEventItems';
import ViewDistStoreDetaiItems from 'pages/View/ViewEventItems/DistrictList/ViewDistStoreDetaiItems';
import EventItemsForDist from 'pages/Events/EventList/district/EventItemsForDist';
import EventStoreItemsForDist from 'pages/Events/EventList/district/EventStoreItemsForDist';
import EventItemsForStore from 'pages/Events/EventList/Store/EventItemsForStore';
import StoreGroup from 'pages/Store';
import UpdateStoreGroup from 'pages/Store/UpdateStoreGroup';
import EventStoreTabsForDiv from 'pages/Events/EventList/division/EventStoreTabsForDiv';
import StoreGroupQuantity from 'pages/Store/StoreGroupQuantity';
import Faq from 'pages/FAQ';
import FaqAdminEdit from 'pages/FAQ/FAQAdminEdit';
import FAQViewAll from 'pages/FAQ/FAQViewAll';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />

      <Route path="events">
        <Route path="upload-events/:tabIndex" element={<Events />} />
        <Route path="event-list/:tabIndex" element={<Events />} />
        <Route
          path="event-list/:tabIndex/event-items-admin/:eventId/:eventName/:close/:programSK"
          element={<EventItems />}
        />
        <Route
          path="event-list/:tabIndex/event-items-division/:eventId/:eventName/:close/:programSK"
          element={<EventItemsForDiv />}
        />
        <Route path="event-stores-items" element={<EventsStoresItems />} />
        <Route path="update-event-items/:tabIndex" element={<Events />} />
        <Route path="upload-to-spa/:tabIndex" element={<Events />} />
        <Route path="cost-target/:tabIndex" element={<Events />} />
        <Route
          path="event-list/:tabIndex/event-items/:eventId/event-stores-items-admin/:itemNumber/:close"
          element={<EventsStoresItems />}
        />
        <Route
          path="event-list/:tabIndex/event-items/:eventId/event-stores-items-division/:itemNumber/:close/:tabIndexTwo"
          element={<EventStoreTabsForDiv />}
        />
        <Route
          path="event-list/:tabIndex/event-items/:eventId/event-stores-items-division/:itemNumber/:close/:tabIndexTwo"
          element={<EventsStoresItemsForDiv />}
        />
        <Route
          path="event-list/:tabIndex/event-items/:eventId/event-stores-items-division/:itemNumber/:close/:tabIndexTwo"
          element={<StoreGroupQuantity />}
        />

        <Route
          path="event-list/:tabIndex/eventItems-dist/:eventId/:programSK/:eventName/:close"
          element={<EventItemsForDist />}
        />
        <Route
          path="event-list/:tabIndex/event-items-store/:eventId/:eventName/:close/:programSK/:strCostTarget"
          element={<EventItemsForStore />}
        />

        <Route
          path="event-list/:tabIndex/eventItemDetailDist/:lockFlag/:eventId/:itemNumber/:close/:itemType/:pack/:cf/:eachCost"
          element={<EventStoreItemsForDist />}
        />
      </Route>

      <Route path="orders">
        <Route path="division-order-totals/:tabIndex" element={<Order />} />
        <Route path="district-order-totals/:tabIndex" element={<Order />} />
        <Route path="store-order-totals/:tabIndex" element={<Order />} />
        <Route path="item-order-totals/:tabIndex" element={<Order />} />
      </Route>
      <Route path="view">
        <Route path="viewAsDiv/" element={<ViewAs />} />
        <Route
          path="eventItemDistView/:eventId/:seasProgramSk/:eventName/:status/:close/:divId/:dist"
          element={<ViewDistrictListEventItems />}
        />
        <Route
          path="eventItemStoreView/:eventId/:store/:seasProgramSk/:eventName/:status/:divId"
          element={<ViewStoreListEventItems />}
        />
        <Route
          path="eventItemStoreDetailsView/:lockFlag/:eventId/:itemNumber/:close/:itemType/:itemDesc/:cf/:pack/:ibc/:distId/:divId"
          element={<ViewDistStoreDetaiItems />}
        />
      </Route>


      <Route path="store">
        <Route path="storeGroup/" element={<StoreGroup />} />
        <Route
          path="update-group/:storeGroupId/:storeGroupName"
          element={<UpdateStoreGroup />}
        />
      </Route>
      <Route path="/frequently-asked-questions" element={<FaqAdminEdit />} />
      <Route path="/frequently-asked-questions-view" element={<FAQViewAll />} />

    </Routes>
  );
}

export default AppRoutes;
