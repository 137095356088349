import { deleteIcon, editIcon } from 'assets';
import Banner from 'components/Banner';
import Button from 'components/Button';
import AddStoreGroup from 'components/Modal/StoreGroup/AddStoreGroup';
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import roleCapability from 'constants/roleCapability';
import React, { useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import userRoleCheck, { findRole } from 'utils/role';
import { useNavigate } from 'react-router-dom';
import { AppState, useAppThunkDispatch } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getStoreGroups,
  deleteStoreGroup,
} from 'redux/actions/storeGroupAction';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { IStoreGroup } from './interface';
import ImportStoreGroup from 'components/Modal/ImportStoreGroup';
import { toast } from 'react-toastify';
import exportToXLSX from 'utils/exportToXLSX';
import { userId, name } from 'layouts/Header/Header';

function StoreGroup() {
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const { userRoleValue } = findRole();
  const { UserRoleMaintenance } = userRoleCheck(roleCapability);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { storeGroups, errorMsg } = useSelector(
    (state: AppState) => state.storeGroup,
  );
  const [storeGroupList, setStoreGroupList] = useState([]);
  const dispatch = useAppThunkDispatch();
  const [isdeleteModal, setDeleteModal] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState<IStoreGroup>({
    storeGroupId: '',
    storeGroupName: '',
    storeNumber: [],
  });
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  useEffect(() => {
    setStoreGroupList(storeGroups);
  }, [storeGroups]);

  const handleEdit = (row: any) => {
    navigate(
      `/store/update-group/${row?.cell?.row?.original.storeGroupId}/${row?.cell?.row?.original.storeGroupName}`,
    );
  };

  const handleDeleteModal = () => {
    setDeleteModal(!isdeleteModal);
  };
  const handleDelete = (row: any) => {
    setDeleteModal(true);
    const selectedGroup = { ...row?.cell?.row?.original, storeNumber: [''] };
    setDeleteGroup(selectedGroup);
  };
  const deleteSuccess = async () => {
    dispatch(deleteStoreGroup(deleteGroup));
    setDeleteModal(false);
  };

  const handleAddGroup = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getExportArray = (exportData: Array<any>) => {
    try {
      return exportData.map((storeGroup) => ({
        'Division ID': userRoleValue,
        'Store Group ID': storeGroup.storeGroupId,
        'Store Group Name': storeGroup.storeGroupName,
        'Store Numbers': storeGroup.storeNumber.join('-'),
        Username: userId,
      }));
    } catch (error) {
      toast.error('Error in export');
      return [];
    }
  };
  const handleExportStoreGroup = () => {
    if (storeGroupList && storeGroupList.length > 0) {
      exportToXLSX({
        fileName: 'Store_Groups',
        data: getExportArray(storeGroupList),
        column: [
          { wch: 12 },
          { wch: 12 },
          { wch: 24 },
          { wch: 32 },
          { wch: 20 },
        ],
      });
    } else {
      toast.warning('No Data');
    }
  };

  const groupColumns = [
    {
      Header: 'Action',
      accessor: 'col1',
      width: 10,
      Cell: (row: any) => {
        const disabled = false;
        const isDisabledClass = 'opacity-100';
        return (
          <div className="flex justify-center gap-4 p-2 pt-[5px]">
            <button
              type="button"
              onClick={() => handleEdit(row)}
              data-testid="edit-btn"
            >
              <img src={editIcon} alt="edit" title="edit" />
            </button>
            {/* {UserRoleMaintenance('Events', 'DeleteEvent') ? ( */}
            <button
              type="button"
              onClick={() => handleDelete(row)}
              disabled={disabled}
              className={isDisabledClass}
              data-testid="dlt-btn"
            >
              <img src={deleteIcon} alt="delete" title="delete" />
            </button>
            {/* ) : null} */}
          </div>
        );
      },
    },
    {
      Header: 'Group',
      accessor: 'storeGroupName',
      width: 100,
    },
    {
      Header: 'Stores',
      accessor: 'storeNumber',
      Cell: (row: any) =>
        row.cell.row.original.storeNumber.length < 15 ? (
          <span>{row.cell.row.original.storeNumber.join(', ')}</span>
        ) : (
          <span title={row.cell.row.original.storeNumber.join(', ')}>
            {row.cell.row.original.storeNumber
              .slice(0, 15)
              .join(',')
              .concat('...')}
          </span>
        ),
    },
  ];

  useEffect(() => {
    dispatch(getStoreGroups());
  }, []);

  const handleImportStoreGroup = () => {
    setIsImportModalOpen(!isImportModalOpen);
  };

  const handleImportStoreClosed = () => {
    setIsImportModalOpen(!isImportModalOpen);
  };

  return (
    <div>
      <div id="section" ref={ref} className="pt-1">
        <Banner title="Store Group">
          <Button
            type="contained"
            text="Add"
            onClick={handleAddGroup}
            disabled={false}
          />
          <Button
            text="Import Store Group"
            onClick={handleImportStoreGroup}
            disabled={storeGroupList && storeGroupList.length === 0}
            data-testid="Import Store Group"
          />
          <Button
            text="Export Store Group"
            onClick={handleExportStoreGroup}
            disabled={storeGroupList && storeGroupList.length === 0}
            data-testid="Export Store Group"
          />
          <ImportStoreGroup
            title="Import Store Group"
            onClose={handleImportStoreClosed}
            isModalOpen={isImportModalOpen}
          />
          <AddStoreGroup
            title="Add Store Group"
            onClose={handleAddGroup}
            isModalOpen={isModalOpen}
            data-testid="addStoreGroupModal"
          />
        </Banner>
        <ConfirmModal
          title="Confirm"
          onClose={handleDeleteModal}
          onSuccess={() => {
            deleteSuccess();
          }}
          isModalOpen={isdeleteModal}
          message="Are you sure you want to delete?"
        />
      </div>
      <div className="pb-4 pl-4 pr-4 pt-4">
        {storeGroupList && storeGroupList.length > 0 ? (
          <RTable
            isfilter
            columns={groupColumns}
            data={storeGroupList}
            isTotal
            height={height}
            hiddenColumns={[]}
            data-testid="viewTable"
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

export default StoreGroup;
