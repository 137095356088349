import Button, { ButtonGroup } from 'components/Button';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { EventStoreType } from 'redux/reducers/costTargetReducer';
import { regex } from 'utils/validations';
import * as XLSX from 'xlsx';
import axios from 'utils/interceptor';
import { apiBaseURL } from 'utils/apiBaseUrl';
import { userId } from 'layouts/Header/Header';
import { getStoreGroups } from 'redux/actions/storeGroupAction';
import { useAppThunkDispatch } from 'redux/store';
import isJson from 'utils/isValidJson';
import isValidJson from 'utils/isValidJson';

interface IImportStoreGroup {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
}

function ImportStoreGroup({ title, isModalOpen, onClose }: IImportStoreGroup) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppThunkDispatch();

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const onCloseHandler = () => {
    clearInput();
    onClose();
  };

  const handleUploadFile = async (e: any) => {
    try {
      e.preventDefault();

      if (e.target.files) {
        const file = e.target.files[0];
        const bufferData = await file.arrayBuffer();
        /* data is an ArrayBuffer */
        const workbook = XLSX.read(bufferData);
        const columnHeader: any = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 1 },
        )[0];
        const columnData: any[] = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
        );

        // console.log({ columnHeader, columnData });

        // Checks if the excel column headers match the below names
        if (
          columnHeader[0] !== 'Division ID' ||
          columnHeader[1] !== 'Store Group ID' ||
          columnHeader[2] !== 'Store Group Name' ||
          columnHeader[3] !== 'Store Numbers' ||
          columnHeader[4] !== 'Username'
        ) {
          throw new Error(
            'Invalid excel file format. Please review column sequence and name.',
          );
        }
        columnData.forEach((col: any) => {
          const isStoreEmpty =
            col['Store Group ID'] === undefined ||
            col['Store Group Name'] === undefined ||
            col['Store Numbers'] === undefined;
          if (isStoreEmpty) {
            throw new Error(
              'Store Group ID or Store Group Name or Store Numbers cannot be empty',
            );
          }

          // console.log(col['Store Numbers'], 'store nums');

          if (col['Store Numbers'] !== undefined) {
            const stringStore = col['Store Numbers'].toString();
            const allStore = stringStore.split('-');

            const formattedStores = allStore
              .filter((store: string) => store !== '')
              .map((store: string) => store.padStart(4, '0'));

            const areValidStores = formattedStores
              .map((strore: string) => /^(\d{4})*$/.test(strore))
              .find((val: boolean) => val === false);

            if (areValidStores === false) {
              throw new Error(
                'Enter valid Store Numbers seperated by hyphen(-)',
              );
            }
          }
        });

        const storeDetails = columnData.map((col) => ({
          divisionId: col['Division ID'],
          storeGroupId: col['Store Group ID'],
          storeGroupName: col['Store Group Name'],
          storeNumber: col['Store Numbers']
            .toString()
            .split('-')
            .filter((store: string) => store !== '')
            .map((store: string) => store.padStart(4, '0')),
        }));

        // console.log(storeDetails);

        const uploadData = {
          importStoreGroupsList: storeDetails,
        };

        const response = (await axios.post(
          `${apiBaseURL}/ssa/importStoreGroups`,
          uploadData,
          {
            headers: {
              userName: userId,
            },
          },
        )) as any;

        // If server returns 500
        if (response.status === 500 || response?.message) {
          throw new Error('Failed to save the uploaded data');
        }

        if (response?.data?.importStoreGroupsList?.length > 0) {
          toast.success('Store import completed successfully');
          onCloseHandler();
          dispatch(getStoreGroups());
        } else {
          toast.error('Store import failed');
        }
      } else {
        throw new Error('No files uploaded');
      }
    } catch (err: any) {
      let errorMsg;
      if (isValidJson(err?.message)) {
        errorMsg = JSON.parse(err?.message)?.errors[0]?.message;
      } else {
        errorMsg = err?.message;
      }

      toast.error(errorMsg ?? 'Failed to upload/read data from excel file');
      onCloseHandler();
    }
  };

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl ">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button
            className=""
            type="button"
            onClick={onCloseHandler}
            data-testid="importstrgrp-close"
          >
            X
          </button>
        </div>
        <form onSubmit={handleUploadFile}>
          <div className="flex flex-row justify-center p-5 pt-[50px]">
            <div className="flex w-full flex-row content-center">
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                type="file"
                required
                accept=".xlsx"
                onChange={handleUploadFile}
                ref={inputRef}
              />
            </div>
          </div>
          <ButtonGroup styles="justify-end pt-[25px] p-5">
            <Button text="Close" onClick={onCloseHandler} />
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
}

export default ImportStoreGroup;
