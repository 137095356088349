import { userId } from 'layouts/Header/Header';
import { AnyAaaaRecord } from 'node:dns';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { apiBaseURL } from 'utils/apiBaseUrl';
import { DateFormatyyyymmdd } from 'utils/dateFormatter';
import axios, { axiosInstanceWithCustomRole, axiosInstanceWithOutLoader } from 'utils/interceptor';
import {
  DISTRICT_COMMENT,
  DIVISION_COMMENT,
  EVENT_ITEM,
  EVENT_ITEM_DETAILS,
  EVENT_LIST_SEARCH,
  NSM_COMMENT,
  STORE_COMMENT,
  UPDATE_EVENT,
  STORE_LIST,
  DISTRICT_LIST,
  UPDATE_EVENT_DIV,
} from '../types';

export interface IEventListSearchActionProps {
  eventName: string;
  programSK: string;
  showClosedFlag: string;
}
export interface IEventListSearchActionPropsForView {
  eventName: string;
  programSK: string;
  showClosedFlag: string;
  roleForView: string;
  roleValueForView: string;
}
interface IUpdateEventActionProps {
  eventNumber: string;
  programNo?: string;
  programYr?: string;
}
export interface IUpdateEventActionPropsForDiv {
  eventNumber: string;
}
interface IStoreCommentActionProps {
  eventId: string;
  programSK: string;
  storeNumber: string;
}
interface INsmCommentActionProps {
  eventId: string;
  programSK: string;
}
interface IDivCommentActionProps {
  eventId: string;
  programSK: string;
  division: string;
}
interface IDistCommentActionProps {
  eventId: string;
  programSK: string;
  district: string;
}
interface IDeleteEventActionProps {
  programNumber: string;
  programYear: string;
  eventId: string;
  adminEndDate?: string;
  adminStartDate?: string;
  distEndDate?: string;
  distId?: string;
  distStartDate?: string;
  divEndDate?: string;
  divList?: [string];
  divStartDate?: string;
  division?: string;
  eventName?: string;
  pgmStrSk?: string;
  rtlStrId?: string;
  seasProgramSk?: string;
  status?: string;
  storeComment?: string;
  storeCostTarget?: 0;
  storeEndDate?: string;
  storeStartDate?: string;
}
export interface ISaveComments {
  commentText: string | null;
  eventId: string;
}
interface IEventItem {
  eventId: string;
  programSK?: string;
  pogSk?: string;
}
interface IEventItemForView {
  eventId: string;
  roleForView: string;
  roleValueForView: string;
  programSK?: string;
  pogSk?: string;

}
export interface IUpdateSalesHistory {
  eventId: string;
  salesHistoryEndDate: string;
  salesHistoryStartDate: string;
}
export interface IDivList {
  adminClDate?: any;
  adminOpDate?: any;
  corpId?: any;
  divClDate?: any;
  divName?: any;
  divOpDate?: any;
  divsion?: any;
  // editing?: any,
  // editingClDt?: any,
  salesHistoryEndDate?: any;
  salesHistoryStartDate?: any;
}
export interface IUpdateSaveComments {
  adminCloseDt?: string;
  adminOpenDt?: string;
  corp?: string;
  distCloseDt?: string;
  distOpenDt?: string;
  divCloseDtMaster?: string;
  divList?: IDivList[] | null;
  divOpenDtMaster?: string;
  eventId?: string;
  storeCloseDt?: string;
  storeOpenDt?: string;
}
interface IUpdateEventLock {
  adLocation: string;
  adjStoreQty: string;
  childData: [null];
  displayLoc: string;
  divLockFlag: string;
  eventId: string;
  itemAdminComment: string;
  itemComment: string;
  itemDistComment: string;
  itemDivComment: string;
  itemNumber: string;
  itemType: string;
  lockFlag: string;
  modelUpc: string;
  pgmStrSk: string;
  promoPrice: string;
  suggestedAdminQty: string;
}
interface IEventItemDetails {
  eventId: string;
  itemNumber?: string;
}
interface IEventItemDetailsForView {
  eventId: string;
  roleForView:string;
  roleValueForView:string;
  itemNumber?: string;
}

interface UseDistrictListProps {
  totalType: string;
  prgmName: string;
  prgmYr: string;
  divNumber: string;
  strNumber: string;
  distNumber: string;
  itemNumber: string;
}
interface UseStoreListProps {
  eventId: string;
  distId: string;
  showClosedFlag: string;
  programSK: string;
}
export const eventListSearchAction =
  (value: IEventListSearchActionProps) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { eventName, programSK, showClosedFlag } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getEventList?eventName=${eventName}&programSK=${programSK}&showClosedFlag=${showClosedFlag}`,
      );

      dispatch({
        type: EVENT_LIST_SEARCH,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };
export const eventListSearchActionForView =
  (value: IEventListSearchActionPropsForView) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const {
      eventName,
      programSK,
      showClosedFlag,
      roleForView,
      roleValueForView,
    } = value;
    try {
      const response = await axiosInstanceWithCustomRole.get(
        `${apiBaseURL}/ssa/getEventList?eventName=${eventName}&programSK=${programSK}&showClosedFlag=${showClosedFlag}`,
        {
          headers: {
            role: roleForView,
            roleValue: roleValueForView,
          },
        },
      );

      dispatch({
        type: EVENT_LIST_SEARCH,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };
export const eventItemAction =
  (value: IEventItem) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, programSK, pogSk } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getEventItem?eventId=${eventId}&programSK=${programSK}&pogSk=${pogSk}`,
      );
      const modifiedData: any = [];

      response.data.eventItemList.map((event: any) => {
        const final = { ...event, subRows: [] };

        const subRows = response.data.eventItemList.filter(
          (item: any) =>
            item.masterItemId === event.itemNumber &&
            item.masterItemId !== item.itemNumber,
        );

        final.subRows.push(...subRows);

        if (subRows.length > 0 || event.masterItemId === event.itemNumber) {
          modifiedData.push(final);
        }
        return modifiedData;
      });
      const modifiedResponse = {
        modifiedData: [...modifiedData],
        ...response.data,
      };
      dispatch({
        type: EVENT_ITEM,
        payload: modifiedResponse,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };
  export const eventItemActionForView =
  (value: IEventItemForView) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, programSK, pogSk, roleForView, roleValueForView} = value;
    try {
      const response = await axiosInstanceWithCustomRole.get(
        `${apiBaseURL}/ssa/getEventItem?eventId=${eventId}&programSK=${programSK}&pogSk=${pogSk}`,
        {
          headers: {
            role: roleForView,
            roleValue: roleValueForView,
          },
        },);
      const modifiedData: any = [];

      response.data.eventItemList.map((event: any) => {
        const final = { ...event, subRows: [] };

        const subRows = response.data.eventItemList.filter(
          (item: any) =>
            item.masterItemId === event.itemNumber &&
            item.masterItemId !== item.itemNumber,
        );

        final.subRows.push(...subRows);

        if (subRows.length > 0 || event.masterItemId === event.itemNumber) {
          modifiedData.push(final);
        }
        return modifiedData;
      });
      const modifiedResponse = {
        modifiedData: [...modifiedData],
        ...response.data,
      };
      dispatch({
        type: EVENT_ITEM,
        payload: modifiedResponse,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };
// admin role
export const updateEventAction =
  (value: IUpdateEventActionProps) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventNumber, programNo, programYr } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getDivOpenCloseDate?eventNumber=${eventNumber}&programNumber=${programNo}&programYear=${programYr}`,
      );
      const responseArray = response.data.map((item: any) => ({
        ...item,
        divClDate: DateFormatyyyymmdd(item.divClDate),
        divOpDate: DateFormatyyyymmdd(item.divOpDate),
      }));
      dispatch({
        type: UPDATE_EVENT,
        payload: responseArray,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };
// div role
export const updateEventActionDiv =
  (value: IUpdateEventActionPropsForDiv) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { eventNumber } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getOpenCloseDateForDiv?eventNumber=${eventNumber}`,
      );
      const responseArray = {
        ...response.data,
        distCloseDt: DateFormatyyyymmdd(response.data?.distCloseDt),
        distOpenDt: DateFormatyyyymmdd(response.data?.distOpenDt),
        divOpenDtMaster: DateFormatyyyymmdd(response.data?.divOpenDtMaster),
        divCloseDtMaster: DateFormatyyyymmdd(response.data?.divCloseDtMaster),
        storeCloseDt: DateFormatyyyymmdd(response.data?.storeCloseDt),
        storeOpenDt: DateFormatyyyymmdd(response.data?.storeOpenDt),
      };

      dispatch({
        type: UPDATE_EVENT_DIV,
        payload: responseArray,
      });
      return response;
    } catch {
      return new Error(`update event API call failed`);
    }
  };

export const updateEventSaveAction =
  (value: IUpdateSaveComments) => async () => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/ssa/updateDivOpenCloseDate`,
        value,
        {
          headers: {
            userName: userId,
          },
        },
      );
      if (response.data.status === 'true') {
        toast.success('Events Updated Successfully');
      } else {
        toast.error(response.data.status);
      }
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const updateSalesHistoryAction =
  (value: IUpdateSalesHistory) => async () => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/ssa/saveSalesHistory`,
        value,
        {
          headers: {
            userName: userId,
          },
        },
      );
      if (response.data.status === 'success') {
        toast.success('SalesHistory Updated Successfully');
      } else {
        toast.error(response.data.status);
      }
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const storeCommentAction =
  (value: IStoreCommentActionProps) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, programSK, storeNumber } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getStoreComment?eventId=${eventId}&storeNumber=${storeNumber}&programSK=${programSK}`,
      );
      dispatch({
        type: STORE_COMMENT,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const nsmCommentAction =
  (value: INsmCommentActionProps) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, programSK } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getNsmComment?eventId=${eventId}&programSK=${programSK}`,
      );
      dispatch({
        type: NSM_COMMENT,
        payload: response.data,
      });

      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const nsmCommentSaveAction = (value: ISaveComments) => async () => {
  try {
    const response = await axios.post(
      `${apiBaseURL}/ssa/saveNsmComment`,
      value,
      {
        headers: {
          userName: userId,
        },
      },
    );
    if (response.data.status === 'success') {
      toast.success('NSM Comments Saved Successfully');
    } else {
      toast.error(response.data.status);
    }
    return response;
  } catch {
    return new Error(`getEventList API call failed`);
  }
};

export const divisionCommentAction =
  (value: IDivCommentActionProps) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, programSK, division } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getDivisionComment?division=${division}&eventId=${eventId}&programSK=${programSK}`,
      );
      dispatch({
        type: DIVISION_COMMENT,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const districtCommentAction =
  (value: IDistCommentActionProps) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, programSK, district } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getDistrictDivComment?district=${district}&eventId=${eventId}&programSK=${programSK}`,
      );
      dispatch({
        type: DISTRICT_COMMENT,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };
export const deleteEventAction =
  (value: IDeleteEventActionProps) => async () => {
    try {
      return await axios.post(`${apiBaseURL}/ssa/deleteEventByProgram`, value, {
        headers: {
          userName: userId,
        },
      });
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const updateEventItemLockAction =
  (value: IUpdateEventLock) => async () => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/ssa/updateItemLock`,
        value,
        {
          headers: {
            userName: userId,
          },
        },
      );
      if (response.data.status === 'success' && value.lockFlag === 'N') {
        toast.success('Item has been locked successfully.');
      } else if (response.data.status === 'success' && value.lockFlag === 'Y') {
        toast.success('Item has been unlocked successfully.');
      } else {
        toast.error(response.data.status);
      }
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const updateEventItemNewLockTypeAction =
  (data: AnyAaaaRecord) => async () => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/ssa/updateItemLockType`,
        data,
        {
          headers: {
            userName: userId,
          },
        },
      );
      if (response.data.status === 'success') {
        toast.success('Item lock type updated successfully.');
      } else if (response.data.status === 'fail') {
        toast.error(
          'Admin has locked this item. Item lock type update failed.',
        );
      }
      return response;
    } catch {
      return new Error(`updateItemLockType API call failed`);
    }
  };

export const eventItemSaveAction = (value: any) => async () => {
  try {
    return await axiosInstanceWithOutLoader.post(`${apiBaseURL}/ssa/updateEventItem`, value, {
      headers: {
        userName: userId,
      },
    });
  } catch {
    return new Error(`getEventList API call failed`);
  }
};
export const eventItemDetailsAction =
  (value: IEventItemDetails) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, itemNumber } = value;
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getEventItemDetail?eventId=${eventId}&seasItemSk=${itemNumber}`,
      );
      dispatch({
        type: EVENT_ITEM_DETAILS,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventListDetail API call failed`);
    }
  };
  export const eventItemDetailsActionForView =
  (value: IEventItemDetailsForView) => async (dispatch: Dispatch<AnyAction>) => {
    const { eventId, itemNumber,roleForView,roleValueForView } = value;
    try {
      const response = await axiosInstanceWithCustomRole.get(
        `${apiBaseURL}/ssa/getEventItemDetail?eventId=${eventId}&seasItemSk=${itemNumber}`,
        {
          headers: {
            role: roleForView,
            roleValue: roleValueForView,
          },},
      );
      dispatch({
        type: EVENT_ITEM_DETAILS,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventListDetail API call failed`);
    }
  };
export const eventStoreSaveAction = (value: any) => async () => {
  try {
    const response = await axios.post(
      `${apiBaseURL}/ssa/saveEventItem`,
      value,
      {
        headers: {
          userName: userId,
        },
      },
    );
    if (response.data.status === 'success') {
      toast.success('Detail saved successfully.');
    } else {
      toast.error(response.data.status);
    }
    return response;
  } catch {
    return new Error(`saveEventItem API call failed`);
  }
};
export const districtListAction =
  (value: UseDistrictListProps) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getAllDistrictByDiv?totalType=${value.totalType}&prgmName=${value.prgmName}&prgmYr=${value.prgmYr}&divNumber=${value.divNumber}&strNumber=${value.strNumber}&distNumber=${value.distNumber}&itemNumber=${value.itemNumber}`,
      );
      let districtList: any = [];

      districtList = response.data.map((item: any) => ({
        ...item,
        value: item.distName,
      }));
      const modifiedResponse = {
        modifiedData: [...districtList],
        ...response.data,
      };
      dispatch({
        type: DISTRICT_LIST,
        payload: modifiedResponse,
      });
      return response;
    } catch (error: any) {
      return new Error(`getAllDistrictByDiv API call failed`);
    }
  };
export const storeListAction =
  (value: UseStoreListProps) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getDistStoreList?eventId=${value.eventId}&distId=${value.distId}&showClosedFlag=${value.showClosedFlag}&programSK=${value.programSK}`,
      );
      let storeList: any = [];
      const count: any = 0;
      storeList = response.data.map((item: any) => ({
        ...item,
        value: item.storeId,
      }));
      const modifiedResponse = {
        modifiedData: [...storeList],
        ...response.data,
      };
      dispatch({
        type: STORE_LIST,
        payload: modifiedResponse,
      });
      return response;
    } catch (error: any) {
      return new Error(`getAllDistrictByDiv API call failed`);
    }
  };

export const districtCommentSaveAction = (value: ISaveComments) => async () => {
  try {
    const response = await axios.post(
      `${apiBaseURL}/ssa/saveNsmComment`,
      value,
      {
        headers: {
          userName: userId,
        },
      },
    );
    if (response.data.status === 'success') {
      toast.success('Comments Saved Successfully');
    } else {
      toast.error(response.data.status);
    }
    return response;
  } catch {
    return new Error(`getEventList API call failed`);
  }
};
export const divisionCommentSaveAction = (value: ISaveComments) => async () => {
  try {
    const response = await axios.post(
      `${apiBaseURL}/ssa/saveDivisionComment`,
      value,
      {
        headers: {
          userName: userId,
        },
      },
    );
    if (response.data.status === 'Success') {
      toast.success('Division comments saved successfully.');
    } else {
      toast.error(response.data.status);
    }
    return response;
  } catch {
    return new Error(`getEventList API call failed`);
  }
};
