/* eslint-disable camelcase */
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import DropDown, { DDListType } from 'components/Dropdown/Dropdown';
import ImportCostTarget from 'components/Modal/ImportCostTarget';
import NoData from 'components/NoData';
import RTable from 'components/RTable';
import { tabHeight } from 'components/Tab/Tab';
import useEventList from 'hooks/useEventList';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getAllDistrictByDiv,
  getEventStoreList,
  updateStoreListCost,
} from 'redux/actions/costTargetAction';
import { EVENT_STORE_LIST_CLEAR } from 'redux/actions/types';
import { EventStoreType } from 'redux/reducers/costTargetReducer';
import { useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import exportToXLSX from 'utils/exportToXLSX';
import { regex } from 'utils/validations';
import EditableCell, {
  ModifiedColumnType,
} from '../EventList/Admin/EditableCell';

type ProgramListType = {
  id: string;
  value: string;
  year: string;
};

type CostTargetProps = {
  programs?: ProgramListType[];
  eventsDD?: [];
  districtsDD?: DDListType[];
  storeList?: EventStoreType[];
};

const columns: ModifiedColumnType[] = [
  {
    Header: 'Store Number',
    accessor: 'storeId',
    width: 30,
  },
  {
    Header: 'Cost Target',
    accessor: 'cst_tgt_amt',
    Cell: EditableCell,
    width: 30,
    validation: {
      required: true,
      pattern: regex.isDecimal,
      errorMessage: 'Cannot be empty and should contain decimal values only',
    },
  },

  {
    Header: 'Difference(Total Extended Cost - Cost Target)',
    accessor: 'totalExtendedCost',
    width: 30,
    Cell: (row: any) => {
      const { totalExtendedCost, cst_tgt_amt } = row.cell.row.original;
      const difference = totalExtendedCost - cst_tgt_amt;
      return <span>{difference || ''}</span>;
    },
  },
];

export default function CostTarget({
  programs,
  eventsDD,
  districtsDD,
  storeList,
}: CostTargetProps) {
  // Hooks
  const dispatch = useAppThunkDispatch();
  const { fetchEventList } = useEventList();
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();

  // States
  const [selectedProgram, setSelectedProgram] = useState('');
  const [selectedProgramYear, setSelectedProgramYear] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [costTarget, setCostTarget] = useState('');
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [uploadData, setUploadData] = useState(storeList); // State to handle editable data in Table
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabHeightUpdated = parseInt(`${tabHeight}`, 10);

  // Effects
  useEffect(() => {
    dispatch(getAllDistrictByDiv());
    dispatch({ type: EVENT_STORE_LIST_CLEAR });
  }, []);

  // Update table editable data object based on getEventStoreList call
  useEffect(() => {
    if (storeList) {
      setUploadData(storeList);
    }
  }, [storeList]);

  // Disable table refresh on input change
  useEffect(() => {
    setSkipPageReset(false);
  }, [uploadData]);

  useEffect(() => {
    if (selectedProgram && selectedProgramYear && programs) {
      const program = programs.find((item) => item.value === selectedProgram);
      if (program) {
        fetchEventList({ program: program.id });
      }
    }
  }, [selectedProgramYear]);

  // Methods
  const filterProgramYear = () => {
    if (selectedProgram && programs) {
      const program = programs.find((item) => item.value === selectedProgram);
      if (program) {
        return [
          {
            id: program.year,
            value: program.year,
          },
        ];
      }
    }
    return [];
  };

  const searchStoreClickHandler = () => {
    const program = programs?.find((item) => item.value === selectedProgram);
    const event = eventsDD?.find(
      (item: any) => item.value === selectedEvent,
    ) as any;
    setUploadData([]);
    dispatch(
      getEventStoreList({
        eventId: event?.id ?? '',
        districtId: selectedDistrict ?? '',
        programId: program?.id ?? '',
      }),
    );
  };

  const getExportArray = (data: EventStoreType[]) => {
    try {
      return data.map((item) => ({
        'Event Name': `${selectedEvent}`,
        District: `${selectedDistrict}`,
        Store: `${item?.storeId}`,
        'Cost target': `${item?.cst_tgt_amt ?? ''}`,
        value: '',
      }));
    } catch (error) {
      toast.error('Error in export');
      return [];
    }
  };

  const handleExportClick = () => {
    if (uploadData && uploadData.length > 0) {
      exportToXLSX({
        fileName: `${selectedProgram}-${selectedEvent}-Cost Target`,
        data: getExportArray(uploadData),
        column: [{ wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }],
      });
    } else {
      toast.warning('No Data');
    }
  };

  const applyToBelowStoresHandler = () => {
    const updatedData = uploadData?.map((item) => ({
      ...item,
      cst_tgt_amt: costTarget,
    }));
    setUploadData(updatedData);
  };

  const resetCostTargetPage = () => {
    searchStoreClickHandler();
    setCostTarget('');
  };

  const saveCostTargetHandler = async () => {
    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    const { id: eventId } = eventsDD?.find(
      (item: any) => item.value === selectedEvent,
    ) ?? { id: '' };

    const modifiedData = uploadData?.map((item) => ({
      pgm_str_event_sk: item.pgm_str_event_sk,
      pgm_str_sk: item.pgmStrSk,
      event_id: eventId,
      cst_tgt_amt: Number(item.cst_tgt_amt),
    }));
    const res =
      modifiedData &&
      ((await dispatch(updateStoreListCost(modifiedData))) as any);

    if (res?.data?.status === 'success') {
      toast.success('Cost target details saved successfully');
      resetCostTargetPage();
    }
  };

  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setUploadData((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const handleModelState = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="pt-1">
      <div ref={ref} className="pt-1">
        <Banner title="Cost Target">
          <Button
            type="contained"
            text="Save"
            onClick={saveCostTargetHandler}
            disabled={uploadData && uploadData.length === 0}
          />
          <Button
            text="Import Cost Target"
            onClick={handleModelState}
            disabled={uploadData?.length === 0}
            data-testid='Import Cost Target'
          />
          <ImportCostTarget
            title="Import Cost Target"
            onClose={handleModelState}
            isModalOpen={isModalOpen}
            data={uploadData ?? []}
            setData={setUploadData}
            data-testid="importCostTargetModal"
          />
        </Banner>

        <section className="pl-4 pr-4 pt-4">
          <Accordion heading="Select Program and Event">
            <div className="px-2 pb-4">
              <div className="grid grid-cols-3">
                <DropDown
                  List={programs ?? []}
                  isMandatory
                  label="Program Name"
                  value={selectedProgram}
                  onChange={setSelectedProgram}
                />
                <DropDown
                  isMandatory
                  List={filterProgramYear()}
                  label="Program Year"
                  value={selectedProgramYear}
                  onChange={setSelectedProgramYear}
                />

                <div
                  className={`${selectedProgramYear === ''
                    ? 'pointer-events-none opacity-60'
                    : ''
                    }`}
                >
                  <DropDown
                    List={eventsDD ?? []}
                    isMandatory
                    label="Event Name"
                    value={selectedEvent}
                    onChange={setSelectedEvent}
                  />
                </div>
              </div>
            </div>
          </Accordion>

          <div
            className={`${selectedEvent === '' ? 'pointer-events-none opacity-60' : ''
              }`}
          >
            <Accordion heading={`Event: ${selectedEvent}`}>
              <div className="flex gap-14 px-2 pb-4">
                <div className="flex gap-4">
                  <div className="w-64">
                    <DropDown
                      label="District"
                      isMandatory
                      List={districtsDD ?? []}
                      value={selectedDistrict}
                      onChange={setSelectedDistrict}
                    />
                  </div>
                  <Button
                    type="contained"
                    text="Search Store"
                    disabled={selectedDistrict === ''}
                    onClick={searchStoreClickHandler}
                  />
                </div>

                {storeList && storeList.length > 0 ? (
                  <>
                    <div className="flex gap-4">
                      <div className="flex items-center gap-3">
                        <span className="w-32 text-center text-sm font-semibold text-black">
                          Cost Target
                        </span>
                        <input
                          className="rounded border border-solid border-[#B2B4BB] py-1 px-3 text-gray-700 transition focus:outline-none"
                          type="number"
                          onChange={(e) => setCostTarget(e.target.value)}
                          value={costTarget}
                          min={0}
                          data-testid='costTarget'
                        />
                      </div>

                      <Button
                        text="Apply to Below Stores"
                        disabled={
                          selectedDistrict === '' ||
                          !regex.isNumbericDecimal.test(costTarget) ||
                          Number(costTarget) < 0
                        }
                        onClick={applyToBelowStoresHandler}
                        data-testid='applyToAllStoresBtn'
                      />
                    </div>

                    <div className="flex gap-4">
                      <Button
                        text="Export Cost Target"
                        onClick={handleExportClick}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </Accordion>
          </div>
        </section>
      </div>

      <div className="over pb-1 pl-4 pr-4">
        {uploadData && uploadData.length > 0 ? (
          <RTable
            isfilter
            columns={columns}
            data={uploadData as any}
            height={height + tabHeightUpdated}
            updateData={updateTableData}
            skipPageReset={skipPageReset}
            hiddenColumns={[]}
            data-testid="costTargetTable"
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
