export const EVENT_LIST_INITIALIZE = 'EVENT_LIST_INITIALIZE';
export const EVENT_LIST_SEARCH = 'EVENT_LIST_SEARCH';
export const SAVE_EVENT_ITEM_DETAILS = 'SAVE_EVENT_ITEM_DETAILS';
export const EVENT_ITEM = 'EVENT_ITEM';
export const EVENT_ITEM_DETAILS = 'EVENT_ITEM_DETAILS';
export const STORE_EVENT_ITEM_DETAILS = 'STORE_EVENT_ITEM_DETAILS';
export const UPDATE_EVENT = 'UPDATE_EVENT'; // for admin role
export const UPDATE_EVENT_DIV = 'UPDATE_EVENT_DIV'; // for div role
export const DELETE_EVENT = 'DELETE_EVENT';
export const STORE_COMMENT = 'STORE_COMMENT';
export const NSM_COMMENT = 'NSM_COMMENT';
export const SAVE_NSM_COMMENT = 'SAVE_NSM_COMMENT';
export const DIVISION_COMMENT = 'DIVISION_COMMENT';
export const DISTRICT_COMMENT = 'DISTRICT_COMMENT';
export const EVENT_ITEM_LIST_CLEAR = 'EVENT_ITEM_LIST_CLEAR';
export const EVENT_LIST_EVENT_PROGRAME_SAVE = 'EVENT_LIST_EVENT_PROGRAME_SAVE';
export const EVENT_LIST_CLEAR_EVENT_PROGRAME_SAVE = 'EVENT_LIST_CLEAR_EVENT_PROGRAME_SAVE';

export const UPDATE_PENDING_API = 'UPDATE_PENDING_API';

// Division Order Totals Action Types
export const ORDER_TOTALS_DIVNUMBER_DISTNUMBER_STORENUMBER_SAVE =
  'ORDER_TOTALS_DIVNUMBER_DISTNUMBER_STORENUMBER_SAVE';
export const ORDER_TOTALS_CLEAR = 'ORDER_TOTALS_CLEAR';
export const DIVISION_ORDER_TOTALS_INITIALIZE =
  'DIVISION_ORDER_TOTALS_INITIALIZE';
export const CLEAR_ORDER_TABLES =
  'CLEAR_ORDER_TABLES';
export const DIVISION_ORDER_TOTALS_PROGRAME_YEAR =
  'DIVISION_ORDER_TOTALS_PROGRAME_YEAR';
export const DIVISION_ORDER_TOTALS_SEARCH = 'DIVISION_ORDER_TOTALS_SEARCH';
export const GET_EVENT_NAME = 'GET_EVENT_NAME';

// Upload Event Action Types
export const UPLOAD_EVENT_FILE_INIT = 'UPLOAD_EVENT_FILE_INIT';
export const UPLOAD_EVENT_FILE_SUCCESS = 'UPLOAD_EVENT_FILE_SUCCESS';
export const UPLOAD_EVENT_CLEAR = 'UPLOAD_EVENT_FILE_CLEAR';

// Update Event Action Types
export const UPDATE_EVENT_ITEMS_INIT = 'UPDATE_EVENT_ITEMS_INIT';
export const UPDATE_EVENT_ITEMS_SUCCESS = 'UPDATE_EVENT_ITEMS_SUCCESS';
export const UPDATE_EVENT_ITEMS_FAIL = 'UPDATE_EVENT_ITEMS_FAIL';
export const UPDATE_EVENT_LIST_ITEMS = 'UPDATE_EVENT_LIST_ITEMS';

// App wide global Action Types
export const API_IS_PENDING = 'API_IS_PENDING';
export const API_NOT_PENDING = 'API_NOT_PENDING';

// Cost Target TotalByDistrict Action Types
export const TOTAL_BY_DISTRICT_SUCCESS = 'TOTAL_BY_DISTRICT_SUCCESS';
export const GET_EVENT_STORE_LIST_SUCCESS = 'GET_EVENT_STORE_LIST_SUCCESS';
export const EVENT_STORE_LIST_CLEAR = 'EVENT_STORE_LIST_CLEAR';
// view
export const DISTRICT_LIST = 'DISTRICT_LIST';
export const STORE_LIST = 'STORE_LIST';

// Store Group

export const STORE_GROUPS_SUCCESS = 'STORE_GROUPS_SUCCESS';
export const STORE_GROUPS_FAILURE = 'STORE_GROUPS_FAILURE';
export const DELETE_STORE_GROUP = 'DELETE_STORE_GROUP';
export const SAVE_STORE_GROUP_SUCCESS = 'SAVE_STORE_GROUP_SUCCESS';
export const SAVE_STORE_GROUP_FAILURE = 'SAVE_STORE_GROUP_FAILURE';
export const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS';
export const STORE_LIST_FAILURE = 'STORE_LIST_FAILURE';
export const STORE_GROUP_QUANTITY_SUCCESS = 'STORE_GROUP_QUANTITY_SUCCESS';
export const STORE_GROUP_QUANTITY_FAILURE = 'STORE_GROUP_QUANTITY_FAILURE';

// FAQ
export const GET_FAQ = 'GET_FAQ';
