import Banner from 'components/Banner';
import Button from 'components/Button';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getStoreGroups,
  getStores,
  updateStoreGroup,
} from 'redux/actions/storeGroupAction';
import useResizeObserver from 'use-resize-observer';
import { AppState, useAppThunkDispatch } from 'redux/store';
import { useParams } from 'react-router-dom';
import { IStore, IStoreGroup } from './interface';

function UpdateStoreGroup() {
  const { ref, height } = useResizeObserver<HTMLDivElement>();

  function not(a: IStore[], b: IStore[]) {
    return a.filter(
      (value) => b.map((object) => object.id).indexOf(value.id) === -1,
    );
  }

  function intersection(a: IStore[], b: IStore[]) {
    return a.filter(
      (value) => b.map((object) => object.id).indexOf(value.id) !== -1,
    );
  }

  function union(a: IStore[], b: IStore[]) {
    return [...a, ...not(b, a)];
  }

  const dispatch = useAppThunkDispatch();

  const { storeGroupId, storeGroupName } = useParams();
  const { storeList, storeGroups, errorMsg } = useSelector(
    (state: AppState) => state.storeGroup,
  );

  const [checked, setChecked] = useState<IStore[]>([]);
  const [left, setLeft] = useState<IStore[]>([]);
  const [right, setRight] = useState<IStore[]>([]);
  const [addStore, setAddStore] = useState<IStore[]>([]);
  const [deleteStore, setDeleteStore] = useState<IStore[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: IStore) => () => {
    const currentIndex = checked.map((object) => object.id).indexOf(value.id);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: IStore[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: IStore[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setAddStore(leftChecked);
    // setRight(right.concat(leftChecked));
    // setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setDeleteStore(rightChecked);
    // setLeft(left.concat(rightChecked));
    // setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    if (addStore.length > 0) {
      const addedStores = addStore.map((store: IStore) => store.id);

      dispatch(
        updateStoreGroup(
          'add',
          storeGroupId as string,
          storeGroupName as string,
          addedStores,
        ),
      );
    }
  }, [addStore]);

  useEffect(() => {
    if (deleteStore.length > 0) {
      const deletedStores = deleteStore.map((store: IStore) => store.id);

      dispatch(
        updateStoreGroup(
          'delete',
          storeGroupId as string,
          storeGroupName as string,
          deletedStores,
        ),
      );
    }
  }, [deleteStore]);

  useEffect(() => {
    dispatch(getStores());
    dispatch(getStoreGroups());
  }, []);

  useEffect(() => {
    if (storeGroups.length > 0 && storeList.length > 0 && storeGroupId !== '') {
      const selectedGroupstores = storeGroups.filter(
        (storeGroup: IStoreGroup) => {
          const groupId = String(storeGroup.storeGroupId);
          return storeGroupId === groupId;
        },
      )[0]?.storeNumber;

      const selectStores = storeGroups.reduce(
        (acc: any, curr: any) => [...acc, ...curr.storeNumber],
        [],
      );
      // console.log(selectStores, 'sel stores');

      const storesToRemove = new Set(selectedGroupstores);

      const filteredStoreList = storeList.filter(
        (storeNumber: number) => !storesToRemove.has(storeNumber),
      );

      const filteredList = filteredStoreList.map((storeNumber: number) => ({
        id: storeNumber,
        name: storeNumber,
      }));

      const selectedList = selectedGroupstores.map((storeNumber: number) => ({
        id: storeNumber,
        name: storeNumber,
      }));

      setLeft(filteredList);
      setRight(selectedList);
    }
  }, [storeGroups, storeGroupId, storeList]);

  const customList = (title: string, items: IStore[]) => (
    <div>
      <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
        {title}
      </h3>
      <ul className="h-[68vh] w-48 overflow-auto rounded-lg border border-gray-200 bg-white text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white">
        <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
          <div className="flex items-center pl-3">
            <input
              type="checkbox"
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              disabled={items.length === 0}
              data-testid={`${title}-toggleAll`}
            />
            <label className="ml-2 w-full py-3 text-gray-900 dark:text-gray-300">
              {`${numberOfChecked(items)}/${items.length} selected`}{' '}
            </label>
          </div>
        </li>
        {items.map((value: IStore) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <li
              key={value.id}
              className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600"
            >
              <div className="flex items-center pl-3">
                <input
                  type="checkbox"
                  checked={
                    checked.filter((item: IStore) => item.id === value.id)
                      .length > 0
                  }
                  tabIndex={-1}
                  onChange={handleToggle(value)}
                  data-testid={`${title}-checkbox`}
                />
                <label className="ml-2 w-full py-3 text-gray-900 dark:text-gray-300">
                  {value.name}{' '}
                </label>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div>
      <div id="section" ref={ref} className="pt-1">
        <Banner title="Store Group">{ }</Banner>
      </div>
      <div className="pb-4 pl-4 pr-4 pt-4">
        <div className="rounded-md bg-white p-2">
          <div
            id="section"
            ref={ref}
            className="p-4 text-2xl"
            data-testid="str-grpname"
          >
            {storeGroupName}
          </div>
          <div className="pb-4 pl-4 pr-4 pt-4">
            <div className="flex flex-row items-center">
              <div className="flex-col" data-testid="custom-list-left">
                {customList('Stores', left)}
              </div>
              <div className="flex-col items-center p-4">
                <div className="flex-row p-4">
                  <Button
                    text=">>"
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                </div>
                <div className="flex-row p-4">
                  <Button
                    text="<<"
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </div>
              </div>
              <div className="flex-col">{customList('Selected', right)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateStoreGroup;