import { connect } from 'react-redux';

import { updateEventItemDetails } from 'redux/actions/updateEventItemsAction';
import { AppState } from 'redux/store';
import UpdateEventItems from './UpdateEventItems';

const mapStateToProps = ({ eventList, updateEvents }: AppState) => ({
  eventListDD: eventList.updateEventListDD,
  programList: eventList.programList,
  eventSearchItems: updateEvents.updateEventSearchItems,
});

const mapDispatchToProps = () => ({
  updateEventItemDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEventItems);
