import { AnyAction, Dispatch } from 'redux';
import { apiBaseURL } from 'utils/apiBaseUrl';
import axios from 'utils/interceptor';
import {
  UPDATE_EVENT_ITEMS_FAIL,
  UPDATE_EVENT_ITEMS_INIT,
  UPDATE_EVENT_ITEMS_SUCCESS,
  UPDATE_EVENT_LIST_ITEMS,
} from './types';
import { toast } from 'react-toastify';
import { userId } from 'layouts/Header/Header';

export const getOrderEventItemList =
  ({ programNo, eventId }: any) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: UPDATE_EVENT_ITEMS_INIT,
    });

    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/getOrderEventItemList?&programNo=${programNo}&eventId=${eventId}`,
      );

      dispatch({
        type: UPDATE_EVENT_ITEMS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch {
      dispatch({
        type: UPDATE_EVENT_ITEMS_FAIL,
      });
      return new Error(`getOrderEventItemList API call failed`);
    }
  };

export const fetchEventList =
  (programNo: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/ssa/fetchEventList?programNo=${programNo}`,
      );

      dispatch({
        type: UPDATE_EVENT_LIST_ITEMS,
        payload: response.data,
      });
      return response;
    } catch {
      return new Error(`getEventList API call failed`);
    }
  };

export const updateEventItemDetails = (data: any) => async () => {
  try {
    const response = await axios.post(
      `${apiBaseURL}/ssa/updateEventItemDetails`,
      data,
      {
        headers: {
          userName: userId,
        },
      },
    );
    if (response.data.status === 'success') {
      toast.success('Event Item Updated successfully.');
    } else {
      toast.error('Event Item Update failed.');
    }

    return response;
  } catch {
    return new Error(`updateEventItemDetails API call failed`);
  }
};
