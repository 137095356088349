import './style.css';
import 'react-toastify/dist/ReactToastify.css';

import Header from 'layouts/Header';
import Main from 'layouts/Main';
import SideMenu from 'layouts/SideMenu';
import React, { useMemo, useState } from 'react';
import AppRoutes from 'routes';
import { ToastContainer } from 'react-toastify';
import UILoader from 'components/UILoader';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { authProvider } from 'utils/authProvider';
import SideMenuContext from 'layouts/SideMenu/SideMenuContext';

const auth = authProvider();
export default function Layout() {
  const { isAPIPending } = useSelector((state: AppState) => state.appGlobal);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const sideMenuContextValue = useMemo(
    () => ({
      open: isSideMenuOpen,
      toggleOpen: setIsSideMenuOpen,
    }),
    [isSideMenuOpen],
  );
  const sideMenuOpenClass = isSideMenuOpen
    ? 'grid-cols-[250px_1fr]'
    : 'grid-cols-[55px_1fr]';

  const isAPIPendingUI = isAPIPending ? (
    <UILoader info="Please wait while loading..." />
  ) : null;

  return auth.accessToken ? (
    <SideMenuContext.Provider value={sideMenuContextValue}>
      <div
        style={{
          gridTemplateAreas: `
            'sidemenu header'
            'sidemenu main'`,
        }}
        // 55px
        className={`grid h-screen  grid-rows-[70px_1fr] ${sideMenuOpenClass}`}
      >
        <SideMenu />
        <Header />
        <Main>
          <AppRoutes />
        </Main>
        <ToastContainer position="bottom-right" pauseOnHover theme="dark" />
        {isAPIPendingUI}
      </div>
    </SideMenuContext.Provider>
  ) : (
    <>{auth.clearSession()}</>
  );
}
