import { Column } from 'react-table';
import { InputValidationType, regex } from 'utils/validations';
import EditableCell from './EditableCell';

type ModifiedColumnType = {
  validation?: InputValidationType;
} & Column;

const columns: ModifiedColumnType[] = [
  {
    Header: 'S.No',
    accessor: 'item_sl_no',
    width: 40,
  },
  {
    Header: 'UPC',
    accessor: 'upc',
  },
  {
    Header: 'Item Number',
    accessor: 'item_id',
    width: 100,
  },
  {
    Header: 'Item Description',
    accessor: 'item_des',
    width: 600,
    Cell: EditableCell,
    validation: {
      maxLength: '50',
    },
  },

  {
    Header: 'Item Type',
    accessor: 'item_type',
    Cell: EditableCell,
    width: 70,
    validation: {
      maxLength: '1',
      pattern: regex.isType,
      errorMessage: 'Accept only alphabets',
    },
  },
  {
    Header: 'Pack',
    accessor: 'item_pack',
    Cell: EditableCell,
    width: 100,
    validation: {
      pattern: regex.isNumberWithZeroDecimal,
      required: true,
      errorMessage: 'Cannot be blank',
    },
  },
  {
    Header: 'Size',
    accessor: 'item_size',
    Cell: EditableCell,
    width: 70,
    validation: {
      type: 'number',
      min: '0',
      pattern: regex.isNumber,
    },
  },
  {
    Header: 'CF',
    accessor: 'vcf',
    Cell: EditableCell,
    width: 100,
    validation: {
      type: 'number',
      min: '0',
      pattern: regex.isNumber,
      required: true,
      errorMessage: 'Cannot be blank',
    },
  },
  {
    Header: 'Extended Cost',
    accessor: 'extended_cost',
    // 'inv_bill_cst * vcf',
    Cell: EditableCell,
    width: 130,
    validation: {
      pattern: regex.isNumberWithDecimal,
      required: true,
      errorMessage: 'Cannot be blank',
    },
  },
  {
    Header: 'Suggested Retail',
    accessor: 'suggestedRetail',
    Cell: EditableCell,
    width: 120,
    validation: {
      pattern: regex.isNumberWithDecimal,
      required: true,
      maxLength: '10',
      errorMessage: 'Enter values with length 10',
    },
  },
  {
    Header: 'Model UPC',
    accessor: 'modelUpc',
    Cell: EditableCell,
    width: 250,
    validation: {
      type: 'text',
      errorMessage: 'Enter maximum of 6 valid Model UPC seperated by hyphen(-)',

      custom: (value) => {
        if (value !== '' || value !== null || value !== undefined) {
          const allUPC = value.split('-');

          if (allUPC.length > 6) {
            return false;
          }

          const findRes = allUPC
            .map((upc: string) => /^(\d{14})*$/.test(upc))
            .find((val: boolean) => val === false);
          return findRes === undefined;
        }

        return false;
      },
    },
  },
];

export default columns;
