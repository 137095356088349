import { useCallback } from 'react';
import {
  eventItemDetailsActionForView,
} from 'redux/actions/eventListAction/eventListAction';
import { useAppThunkDispatch } from 'redux/store';

type UseEventItemListDetailsProps = {
  eventId: string;
  roleForView: string;
  roleValueForView:string;
  itemNumber?: string;
} | null;

const useEventItemDetailsForView = () => {
  const dispatch = useAppThunkDispatch();

  const fetchEventItemListDetailsForView = useCallback(
    (data: UseEventItemListDetailsProps) => {
      dispatch(
        eventItemDetailsActionForView({
          eventId: data?.eventId ?? '',
          itemNumber: data?.itemNumber ?? '',
          roleForView:data?.roleForView ?? '',
          roleValueForView:data?.roleValueForView ?? '',
        }),
      );
    },
    [],
  );
  return fetchEventItemListDetailsForView;
};

export default useEventItemDetailsForView;
