import { useCallback } from 'react';

import { districtCommentAction } from 'redux/actions/eventListAction/eventListAction';

import { useAppThunkDispatch } from 'redux/store';

type UseDistCommentProps = {
  eventId: string;
  district: string;
  programSK: string;
} | null;

const useDistComment = () => {
  const dispatch = useAppThunkDispatch();

  const distComment = useCallback((data: UseDistCommentProps) => {
    dispatch(
      districtCommentAction({
        eventId: data?.eventId ?? '',
        programSK: data?.programSK ?? '',
        district: data?.district ?? '',
      }),
    );
  }, []);

  return distComment;
};

export default useDistComment;
