import { useCallback } from 'react';
import {
  IUpdateEventActionPropsForDiv,
  updateEventActionDiv,
} from 'redux/actions/eventListAction/eventListAction';
import { useAppThunkDispatch } from 'redux/store';

const useUpdateEventForDiv = () => {
  const dispatch = useAppThunkDispatch();

  const updateEventForDiv = useCallback(
    (data: IUpdateEventActionPropsForDiv) => {
      dispatch(
        updateEventActionDiv({
          eventNumber: data.eventNumber,
        }),
      );
    },
    [],
  );

  return { updateEventForDiv };
};

export default useUpdateEventForDiv;
