import { DDListType } from 'components/Dropdown/Dropdown';
import { AnyAction } from 'redux';
import {
  EVENT_STORE_LIST_CLEAR,
  GET_EVENT_STORE_LIST_SUCCESS,
  TOTAL_BY_DISTRICT_SUCCESS,
} from 'redux/actions/types';
import { AppState } from 'redux/store';

export type TotalByDistrictType = {
  divName: null;
  divId: null;
  distName: string;
  itemNo: null;
  itemDes: null;
  storeNumber: null;
  itemNumber: null;
  itemDesc: null;
  vcf: null;
  itemPackTxt: null;
  itemSizeTxt: null;
  totalPack: null;
  totalDivisionSuggPacks: null;
  suggDivisionCost: number;
  extendedVendCost: number;
  divisionSuggDifference: number;
  extendedIBC: null;
  totalCube: null;
  totalWeight: null;
  upc: null;
  orderDeficit: null;
};

export type EventStoreType = {
  eventId: string;
  suggestedAdminQty: null;
  suggestedDivQty: null;
  suggestedDisQty: null;
  suggestedStoreQty: null;
  adjustedAdminQty: null;
  adjustedDivQty: null;
  adjustedDisQty: null;
  adjustedStoreQty: null;
  pgmStrSk: string;
  storeId: string;
  divId: number;
  seasItemId: null;
  totalCase: null;
  salesHistoryQty: null;
  masterItemId: null;
  itemDesc: null;
  errorMsg: null;
  totalExtendedCost: string;
  pgm_str_event_sk: null;
  cst_tgt_amt: string | null;
  distId: null;
  seasPgmSk: null;
};

type costTargetReducerStateInit = {
  loading: boolean;
  totalByDistrict: TotalByDistrictType[];
  totalByDistrictDD: DDListType[];
  eventStoreList: EventStoreType[];
};

export const initialState: costTargetReducerStateInit = {
  loading: false,
  totalByDistrict: [],
  totalByDistrictDD: [],
  eventStoreList: [],
};

const costTargetReducer = (state = initialState, action: AnyAction): AppState => {
  switch (action.type) {
    case TOTAL_BY_DISTRICT_SUCCESS:
      return {
        ...state,
        totalByDistrict: action.payload,
        totalByDistrictDD: action.payload.map((item: TotalByDistrictType) => ({
          id: item.distName,
          value: item.distName,
        })),
      };

    case GET_EVENT_STORE_LIST_SUCCESS:
      return {
        ...state,
        eventStoreList: action.payload,
      };

    case EVENT_STORE_LIST_CLEAR:
      return {
        ...state,
        eventStoreList: [],
      };

    default:
      return state;
  }
};
export default costTargetReducer;
