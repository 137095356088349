import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import {
  getOrderEventItemList,
  fetchEventList,
  updateEventItemDetails,
} from 'redux/actions/updateEventItemsAction';
import { useAppThunkDispatch } from 'redux/store';

type UseOrderEventistProps = {
  eventId?: string;
  programNo?: string;
} | null;

const useOrderEventList = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppThunkDispatch();

  const getUpdateEventItemList = useCallback((programNo: string) => {
    dispatch(fetchEventList(programNo));
  }, []);

  const getOrderEventList = useCallback((data: UseOrderEventistProps) => {
    dispatch(
      getOrderEventItemList({
        eventId: data?.eventId ?? '',
        programNo: data?.programNo ?? '',
      }),
    );
  }, []);

  const saveEventItems = useCallback(async (data: any) => {
    setLoading(true);
    const res = (await dispatch(updateEventItemDetails(data))) as AxiosResponse;
    setLoading(false);
    return res;
  }, []);

  return {
    loading,
    getUpdateEventItemList,
    getOrderEventList,
    saveEventItems,
  };
};

export default useOrderEventList;
