/* eslint-disable operator-linebreak */
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import Conatiner from 'components/Container/Container';
import DropDown from 'components/Dropdown/Dropdown';
import RTable from 'components/RTable';
import { tabHeight } from 'components/Tab/Tab';
import useEventList from 'hooks/useEventList';
import useOrderEventList from 'hooks/useOrderEventList';
import React, { useCallback, useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { toast } from 'react-toastify';

import { TypeOrderEventItem } from 'redux/reducers/updateEventItemsReducer';
import columns from './columns';
import NoData from 'components/NoData';

export default function UpdateEventItems({
  eventListDD,
  programList,
  eventSearchItems,
}: any) {
  useEventList();
  const { loading, getUpdateEventItemList, getOrderEventList, saveEventItems } =
    useOrderEventList();
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const tabHeightUpdated = parseInt(`${tabHeight}`, 10);

  const [uploadData, setUploadData] = useState<
    TypeOrderEventItem[] | null | undefined
  >(eventSearchItems); // State to handle editable data in Table
  const [program, setProgram] = useState('');
  const [event, setEvent] = useState('');
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [eventsListDDValues, setEventsListDDValues] = useState([]);

  // Populate events dropdown values based onf Program selection
  useEffect(() => {
    if (programList && program) {
      setUploadData(null);
      setEventsListDDValues([]);
      const programId = programList.find((p: any) => p.value === program).id;
      getUpdateEventItemList(programId);
      setEvent('');
    } else {
      getUpdateEventItemList('');
      setEvent('');
    }
  }, [program]);

  useEffect(() => {
    setEvent('');
    setEventsListDDValues([]);
    setEventsListDDValues(eventListDD);
  }, [eventListDD]);

  useEffect(() => {
    if (event) {
      setUploadData(null);
    }
  }, [event]);

  // Update table editable data object based on new getOrderEventList call
  useEffect(() => {
    if (eventSearchItems) {
      const result = eventSearchItems.map((item: any) => ({
        ...item,
        extended_cost: (
          parseFloat(item.inv_bill_cst) * parseFloat(item.vcf)
        ).toFixed(2),
      }));

      setUploadData(result);
    }
  }, [eventSearchItems]);

  // Disable table refresh on input change
  useEffect(() => {
    setSkipPageReset(false);
  }, [uploadData]);

  const getOrderEvents = useCallback(() => {
    setUploadData(null);
    if (event) {
      const programNo =
        programList?.find((p: any) => p.value === program)?.id ?? '';
      const eventId = eventListDD?.find((d: any) => d.value === event).eventId;
      getOrderEventList({ eventId, programNo });
    }
  }, [program, event, eventListDD]);

  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setUploadData((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const onSaveHandler = async () => {
    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
    } else {
      const data = uploadData?.map((item) => ({
        ...item,
        inv_bill_cst: (
          parseFloat(item.extended_cost) / parseFloat(item.vcf)
        ).toFixed(2),
        extended_cost: item.extended_cost,
      }));
      const res = await saveEventItems(data);
      if (res?.status === 200) {
        getOrderEvents();
      } else {
        toast.error('Event items save failed..');
      }
    }
  };

  return (
    <>
      <div ref={ref} className="pt-1">
        <Banner title="Update Event Items">
          <Button
            text="Save"
            type="contained"
            disabled={!uploadData || uploadData?.length < 1}
            onClick={() => onSaveHandler()}
          />
          {/* <Button text="Export" /> */}
        </Banner>
        <section className="pt-4 pl-4 pr-4">
          <Accordion heading="Update Event Items">
            <Conatiner styles="border-t">
              <div className="items start mt-2 mb-2 flex">
                <DropDown
                  List={programList ?? []}
                  label="Program:"
                  onChange={setProgram}
                />
                <div className="items flex pl-4">
                  <DropDown
                    List={eventsListDDValues ?? []}
                    label="Event:"
                    isMandatory
                    onChange={setEvent}
                    value={event}
                  />
                </div>
                <div className="flex items-center pl-4">
                  <Button
                    type="contained"
                    text="Go"
                    onClick={getOrderEvents}
                    disabled={!event || loading}
                  />
                </div>
              </div>
            </Conatiner>
          </Accordion>
        </section>
      </div>
      <div className="over pb-4 pl-4 pr-4">
        {uploadData && uploadData.length > 0 ? (
          <RTable
            columns={columns}
            isfilter
            data={uploadData as any}
            height={height + tabHeightUpdated}
            updateData={updateTableData}
            skipPageReset={skipPageReset}
            isTotal={false}
            hiddenColumns={[]}
          />
        ) : (
          <NoData subTitle="Please select a program and valid events to get all event items." />
        )}
      </div>
    </>
  );
}
