import { combineReducers } from 'redux';
import DivisionOrderTotalsReducer from './divisionOrderTotalsReducer';
import EventListReducer from './eventListReducer';
import updateEventItemsReducer from './updateEventItemsReducer';
import UploadEventsReducer from './uploadEventsReducer';
import appGlobalReducer from './appGlobalReducer';
import costTargetReducer from './costTargetReducer';
import storeGroupReducer from './storeGroupReducer';
import faqReducer from './faqReducer';

const RootReducer: any = combineReducers({
  appGlobal: appGlobalReducer,
  eventList: EventListReducer,
  eventUpload: UploadEventsReducer,
  updateEvents: updateEventItemsReducer,
  divisionOrderTotals: DivisionOrderTotalsReducer,
  costTarget: costTargetReducer,
  storeGroup: storeGroupReducer,
  faq: faqReducer
});

export default RootReducer;
