import { useCallback } from 'react';

import {
  districtCommentSaveAction,
  divisionCommentSaveAction,
  ISaveComments,
  nsmCommentAction,
  nsmCommentSaveAction,
} from 'redux/actions/eventListAction/eventListAction';

import { useAppThunkDispatch } from 'redux/store';

type UseNsmCommentProps = {
  eventId: string;
  programSK: string;
} | null;

const useNsmComment = () => {
  const dispatch = useAppThunkDispatch();

  const nsmComment = useCallback((data: UseNsmCommentProps) => {
    dispatch(
      nsmCommentAction({
        eventId: data?.eventId ?? '',
        programSK: data?.programSK ?? '',
      }),
    );
  }, []);

  const nsmCommentSave = useCallback((data: ISaveComments) => {
    dispatch(nsmCommentSaveAction(data));
  }, []);
  const districtCommentSave = useCallback((data: ISaveComments) => {
    dispatch(districtCommentSaveAction(data));
  }, []);
  const divisionCommentSave = useCallback((data: ISaveComments) => {
    dispatch(divisionCommentSaveAction(data));
  }, []);

  return {
    nsmComment,
    nsmCommentSave,
    districtCommentSave,
    divisionCommentSave,
  };
};

export default useNsmComment;
