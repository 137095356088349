import roleName from 'constants/roleName';
import { toast } from 'react-toastify';
import { authProvider } from './authProvider';

export const role: any = [];
export const roleList: any = [];

let userRoleType = '';
let userRoleValue = '';

export const findRole = () => {
  const auth = authProvider();
  const groupData = auth?.decodedIdToken?.groups?.map((item:string) =>  item.toLowerCase());
  try {
    groupData &&
      groupData.forEach((data: string) => {
        if (data.includes('foss.div')) {
          const result = roleName.find(
            (name: any) => name.roleName === 'foss.div',
          );
          result && role.push(result?.value) && roleList.push(result);
          userRoleType = 'division';
          userRoleValue = data.replace('foss.div.', '');
        } else if (data.includes('foss.dstr')) {
          const resultA = roleName.find(
            (name: any) => name.roleName === 'foss.dstr',
          );
          resultA && role.push(resultA?.value) && roleList.push(resultA);
          userRoleType = 'dist';
          userRoleValue = data.replace('foss.dstr.', '');
        } else if (data.includes('foss.ssa.admin')) {
          userRoleType = 'admin';
          userRoleValue = 'admin';
        } else if (data.includes('foss.ssa.store')) {
          userRoleType = 'store';
          userRoleValue = auth?.decodedIdToken?.preferred_username.slice(1, 5);
        }
        const resultB = roleName.find((name: any) => name.roleName === data);
        resultB && role.push(resultB?.value) && roleList.push(resultB);
      });
  } catch {
    toast.error('Incorrect Token');
  }
  return { roleList, userRoleType, userRoleValue };
};
findRole();
const userRoleCheck = (roleCapability: any) => {
  const commonFunction = (route: string, feature?: string) => {
    const userRole: string[] = [];
    role &&
      role.forEach((roleValue: string) => {
        const user = roleCapability.find(
          (menu: any) => menu.roleName === roleValue,
        );
        user && userRole.push(user);
      });
    const menuRole: any[] = [];
    userRole.forEach((user: any) => {
      if (user?.menuList.find((menu: any) => menu.menuName === route)) {
        menuRole.push(
          user?.menuList.find((menu: any) => menu.menuName === route),
        );
      }
    });
    let permit = false;
    menuRole.forEach((menu: any) => {
      if (
        menu.functionList.find((item: any) => item.functionName === feature)
          ?.functionPermit
      )
        permit = true;
    });
    return permit;
  };
  const commonColFunction = (route: string, feature?: string) => {
    const userRole: string[] = [];
    role &&
      role.forEach((roleValue: string) => {
        const user = roleCapability.find(
          (menu: any) => menu.roleName === roleValue,
        );
        user && userRole.push(user);
      });
    const menuRole: any[] = [];
    userRole.forEach((user: any) => {
      if (user?.menuList.find((menu: any) => menu.menuName === route)) {
        menuRole.push(
          user?.menuList.find((menu: any) => menu.menuName === route),
        );
      }
    });
    let array: string[] = [];
    menuRole.forEach((menu: any) => {
      if (menu.functionList.find((item: any) => item.functionName === feature))
        array = menu.functionList.find(
          (item: any) => item.functionName === feature,
        ).functionPermit;
    });
    return array;
  };
  const UserRoleMaintenance = (route: any, feature: any) =>
    commonFunction(route, feature);
  const UserRoleColMaintenance = (route: any, feature: any) =>
    commonColFunction(route, feature);

  return {
    UserRoleMaintenance,
    UserRoleColMaintenance,
  };
};

export default userRoleCheck;
