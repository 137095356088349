import { settings } from 'assets';
import React from 'react';

interface ISearchProps {
  total: number;
  filterValue: string;
  setFilter: (value: string) => void;
  settingClick?: () => void;
}
export default function Filter(props: ISearchProps) {
  const { total, filterValue, setFilter, settingClick } = props;
  return (
    <div className="flex items-center justify-between px-2 pb-2" id="searchbox">
      <span className="text-md font-bold  ">{total} Items</span>

      <div className="flex items-center gap-3">
        <input
          className="placeholder:font-italitc w-full rounded 
          border border-gray-300 bg-white px-3 py-1 focus:outline-none"
          placeholder="Filter"
          type="text"
          value={filterValue || ''}
          onChange={(e) => setFilter(e.target.value)}
        />
        <div
          role="button"
          className="cursor-pointer"
          onClick={settingClick}
          onKeyDown={settingClick}
          tabIndex={0}
        >
          <img src={settings} alt="settings" />
        </div>
      </div>
    </div>
  );
}
