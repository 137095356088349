import React from 'react';

type UILoaderProps = { info?: string };

function UILoader({ info }: UILoaderProps) {
  return (
    <div className="fixed inset-0 z-[99999] flex items-center justify-center">
      <div className="z-10 flex flex-col items-center justify-center">
        <div className="inner">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
              margin: 'auto',
              display: ' block',
            }}
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              r="32"
              strokeWidth="8"
              stroke="#1172cd"
              strokeDasharray="50.26548245743669 50.26548245743669"
              fill="none"
              strokeLinecap="round"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;1"
                values="0 50 50;360 50 50"
              />
            </circle>
          </svg>
        </div>
        <p className="mt-4 text-2xl font-bold tracking-widest text-gray-700">
          LOADING
        </p>
        <p className="text-lg opacity-50">{info}...</p>
      </div>
      <div className="absolute inset-0 bg-white opacity-50" />
    </div>
  );
}

export default UILoader;
