/* eslint-disable */
import React from 'react';
import Tab from 'components/Tab';
import { useParams } from 'react-router-dom';
import userRoleCheck, { findRole } from 'utils/role';
import roleCapability from 'constants/roleCapability';
import EventsStoresItemsForDiv from './EventStoreItemsForDiv';
import StoreGroupQuantity from 'pages/Store/StoreGroupQuantity';

const { userRoleType } = findRole();
const { UserRoleMaintenance } = userRoleCheck(roleCapability);

export default function EventStoreTabsForDiv() {
  const { tabIndex, tabIndexTwo, eventId, itemNumber, close } = useParams();

  const orderTabsData = [
    {
      index: 0,
      label: 'Event Store Items',
      route: `/events/event-list/${tabIndex}/event-items/${eventId}/event-stores-items-division/${itemNumber}/${close}/0`,
      content: () => <EventsStoresItemsForDiv />,
      visible: true,
      role: 'eventStoreItem',
    },
    {
      index: 1,
      label: 'Store Groups',
      route: `/events/event-list/${tabIndex}/event-items/${eventId}/event-stores-items-division/${itemNumber}/${close}/1`,
      content: () => <StoreGroupQuantity />,
      visible: true,
      role: 'storeGroup',
    },
  ];

  return (
    <div>
      <Tab
        data-testid="events-menu"
        data={orderTabsData}
        activeIndex={Number(tabIndexTwo)}
      />
    </div>
  );
}
