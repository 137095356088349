import Button, { ButtonGroup } from 'components/Button';
import useLoader from 'hooks/useLoader';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { regex } from 'utils/validations';
import { useParams } from 'react-router-dom';
import { useAppThunkDispatch } from 'redux/store';
import { uploadStoreItemsDivision } from 'redux/actions/eventListAction/eventListActionForDiv';


interface IStoreUpload {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
}

function StoreUploadDiv({ title, isModalOpen, onClose }: IStoreUpload) {
  const { eventId = '' } = useParams();
  const { setIsLoading } = useLoader();
  const dispatch = useAppThunkDispatch();
  const [uploadFile, setUploadFile] = useState<FileList | null>(null);
  const [uploadData, setUploadData] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const clearInput = () => {
    setUploadFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleUploadFile = async (e: any) => {
    setIsLoading(true);
    try {
      e.preventDefault();
      const file = e.target.files[0];
      const bufferData = await file.arrayBuffer();
      /* data is an ArrayBuffer */
      const workbook = XLSX.read(bufferData);
      const columnHeader: any = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: 1 },
      )[0];
      const columnData = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
      );

      // Checks if the excel table headers match the below names
      if (
        columnHeader[2] !== 'Store Number' ||
        columnHeader[3] !== 'SPA Item Code' ||
        columnHeader[15] !== 'Suggested Division Quantity' ||
        columnHeader[19] !== 'Adjusted Division Quantity'
      ) {
        throw new Error(
          'Invalid excel file format.  Please review column sequence.',
        );
      }

      // Check if columns not empty and is in valid format
      columnData.forEach((col: any) => {
        const hasEmptyValues =
          col['Store Number'] === undefined ||
          col['SPA Item Code'] === undefined;

        if (hasEmptyValues) {
          throw new Error(
            'Store Number OR SPA Item Code cannot have empty values',
          );
        }

        if (!regex.isNumber.test(col['Store Number'].toString().trim())) {
          throw new Error('Store Number should be digits only');
        }
        if (!regex.isNumber.test(col['SPA Item Code'].toString().trim())) {
          throw new Error('SPA Item Code should be digits only');
        }
      });
      const data: any = [];
      columnData.forEach((col: any) => {
        if (col['Item Type'] !== 'c' && col['Item Type'] !== 'C') {
          data.push({
            storeId: col['Store Number'].toString().padStart(4, '0') ?? '',
            seasItemId: col['SPA Item Code'] ?? '',
            suggestedDivQty: col['Suggested Division Quantity'] ?? '',
            adjustedDivQty: col['Adjusted Division Quantity'] ?? '',
          });
        }
      });

      setUploadData(data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setUploadData([]);
      toast.error(
        error?.message ?? 'Failed to upload/read data from excel file',
      );
    }
  };

  const onCloseHandler = () => {
    clearInput();
    setUploadData([]);
    onClose();
  };

  const handleStoreLevelUpload = async () => {
    try {
      dispatch(uploadStoreItemsDivision(eventId, uploadData));

      toast.info(
        'Store level upload started. Please wait for some time to complete.',
      );

      onCloseHandler();
    } catch (error: any) {
      toast.error(
        error?.message ?? 'Failed to upload/read data from excel file',
      );
    }
  };

  return (
    <div
      id="popup-modal"
      className={` ${
        !isModalOpen && 'hidden'
      } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] rounded-lg border bg-white shadow-2xl ">
        <div className="flex flex-row content-center justify-between border-b p-5 pb-2">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black">
            {title}
          </h1>
          <button className="" type="button" onClick={onCloseHandler}>
            X
          </button>
        </div>
        <form onSubmit={handleUploadFile}>
          <div className="flex flex-row justify-center p-5 pt-[50px]">
            <div className="flex w-full flex-row content-center">
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                type="file"
                required
                accept=".xlsx"
                onChange={handleUploadFile}
                ref={inputRef}
                data-testid='input'
              />
            </div>
          </div>
          <ButtonGroup styles="justify-end pt-[25px] p-5">
            <Button text="Close" onClick={onCloseHandler} />
            <Button
              text="Upload"
              type="contained"
              disabled={uploadData.length === 0 || !!uploadFile}
              onClick={handleStoreLevelUpload}
            />
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
}

export default StoreUploadDiv;
