import React, { useEffect, useState } from 'react';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import DropDown from 'components/Dropdown/Dropdown';
import RTable from 'components/RTable';
import { Link } from 'react-router-dom';
import Conatiner from 'components/Container/Container';
import Banner from 'components/Banner';
import useOrderProgram from 'hooks/useOrderProgram';
import useOrderList from 'hooks/useOrderList';
import { useSelector } from 'react-redux';
import { AppState, useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { tabHeight } from 'components/Tab/Tab';
import exportToXLSX from 'utils/exportToXLSX';
import { toast } from 'react-toastify';
import { wscolsStoreOrder } from './constantsOrder';
import userRoleCheck, { findRole } from 'utils/role';
import roleColCapability from 'constants/roleColumnCapability';
import divisionSugWithMinus from 'utils/checkSignForCostDiff';
import { getEventNameAction } from 'redux/actions/divisionOrderTotalsProgrameAction';
import NoData from 'components/NoData';
import applyLocaleString from 'utils/applyLocaleString';
import InputField from 'components/InputField';
import Select, { OnChangeValue } from 'react-select';
import { ConsoleConstructorOptions } from 'node:console';

export default function StoreOrderTotals() {
  const [selectedProgramValue, setSelectedProgramValue] = useState('');
  const [itemNumber, setItemNumber] = useState('');
  const [arrOrderTotals, setArrOrderTotals] = useState([]);
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [programYear, setProgramYear] = useState([]);
  const [selectedEventNames, setSelectedEventNames] = useState<any>();
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const tabHeightUpdated = parseInt(`${tabHeight}`, 10);
  const [isPageInitialLoad, setIsPageInitialLoad] = useState(true);

  const dispatch = useAppThunkDispatch();

  const { clearOrderList, saveDivNumberDistNumberStoreNumber } =
    useOrderProgram();

  const fectOrderList = useOrderList();
  const { UserRoleColMaintenance } = userRoleCheck(roleColCapability);
  const { userRoleType } = findRole();
  const programList = useSelector(
    (state: AppState) => state.divisionOrderTotals.programList,
  );
  const programYearList = useSelector(
    (state: AppState) => state.divisionOrderTotals.programYearList,
  );
  const OrderTotalsList = useSelector(
    (state: AppState) => state.divisionOrderTotals.orderTotalsList,
  );
  const divNumber = useSelector(
    (state: AppState) => state.divisionOrderTotals.divNumber,
  );
  const distNumber = useSelector(
    (state: AppState) => state.divisionOrderTotals.distNumber,
  );
  const prgmName = useSelector(
    (state: AppState) => state.divisionOrderTotals.prgmName,
  );
  const prgmNameFull = useSelector(
    (state: AppState) => state.divisionOrderTotals.prgmNameFull,
  );
  const prgmYr = useSelector(
    (state: AppState) => state.divisionOrderTotals.prgmYr,
  );
  const eventName = useSelector(
    (state: AppState) => state.divisionOrderTotals.eventName,
  );
  const storedItemNumber = useSelector(
    (state: AppState) => state.divisionOrderTotals.itemNumber,
  );
  const eventNameList = useSelector(
    (state: AppState) => state.divisionOrderTotals.eventNameList,
  );

  const getProgramId = (programName: string) => {
    const programID = programList.find(
      (item: any) => item.value === programName,
    );
    return programID?.id;
  };

  const getEventIds = (tempArr: Array<any>) => tempArr?.map((item: { id: { toString: () => any; }; }) => item?.id.toString());

  useEffect(() => {
    clearOrderList();
    setArrOrderTotals([]);
    setSelectedEventNames([]);
    if (distNumber || prgmYr) {
      fectOrderList({
        divNumber,
        distNumber,
        prgmName,
        prgmYr,
        itemNumber: storedItemNumber,
        totalType: 'totalByStore',
        eventId: eventName ? getEventIds(eventName) : 'all',
      });
    }
  }, []);
  useEffect(() => {
    if (OrderTotalsList && OrderTotalsList.length > 0) {
      const uniqueStore = [
        ...Array.from(
          new Set(OrderTotalsList.map((item: any) => item.storeNumber)),
        ),
      ];

      const uniqueStoreItem = uniqueStore.map((store: any) => {
        const matchItem = OrderTotalsList.filter(
          (item: any) => item.storeNumber === store,
        );
        return {
          totalPack: matchItem
            .map((commonStoreItem: any) => commonStoreItem.totalPack)
            .reduce((a: any, b: any) => b + a, 0),
          extendedVendCost: matchItem
            .map((commonStoreItem: any) => commonStoreItem.extendedVendCost)
            .reduce((a: any, b: any) => b + a, 0),
          totalCube: matchItem
            .map((commonStoreItem: any) => commonStoreItem.totalCube)
            .reduce((a: any, b: any) => b + a, 0),
          totalWeight: matchItem
            .map((commonStoreItem: any) => commonStoreItem.totalWeight)
            .reduce((a: any, b: any) => b + a, 0),
          // costTarget: matchItem[0].costTarget,
          costTarget: matchItem
            .map((commonStoreItem: any) => commonStoreItem.costTarget)
            .reduce((a: any, b: any) => parseFloat(b) + parseFloat(a), 0),
          distName: matchItem[0].distName,
          divName: matchItem[0].divName,

          divId: matchItem[0].divId,
          // divisionSuggDifference: matchItem[0].divisionSuggDifference,
          divisionSuggDifference: matchItem
            .map((commonStoreItem: any) => commonStoreItem.divisionSuggDifference)
            .reduce((a: any, b: any) => b + a, 0),
          eventId: matchItem[0].eventId,
          eventName: matchItem[0].eventName,
          extendedIBC: matchItem[0].extendedIBC,
          itemDes: matchItem[0].itemDes,
          itemNo: matchItem[0].itemNo,
          itemNumber: matchItem[0].itemNumber,
          itemPackTxt: matchItem[0].itemPackTxt,
          itemSizeTxt: matchItem[0].itemSizeTxt,
          orderDeficit: matchItem[0].orderDeficit,
          storeNumber: matchItem[0].storeNumber,
          // suggDivisionCost: matchItem[0].suggDivisionCost,
          suggDivisionCost: matchItem
            .map((commonStoreItem: any) => commonStoreItem.suggDivisionCost)
            .reduce((a: any, b: any) => b + a, 0),
          // totalDivisionSuggPacks: matchItem[0].totalDivisionSuggPacks,
          totalDivisionSuggPacks: matchItem
            .map((commonStoreItem: any) => commonStoreItem.totalDivisionSuggPacks)
            .reduce((a: any, b: any) => b + a, 0),

          upc: matchItem[0].upc,
          vcf: matchItem[0].vcf,
        };
      });

      // const orderTotalStore: any =
      //   selectedEventName === '' ? [...uniqueStoreItem] : [...OrderTotalsList];

      const orderTotalStore: any = [...uniqueStoreItem];

      const result = orderTotalStore.map((item: any) => ({
        ...item,
        divName: `${item?.divId} - ${item?.divName}`,
        extendedVendCostwith$:
          parseFloat(item?.extendedVendCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.extendedVendCost.toFixed(2))}`,
        suggDivisionCostWith$:
          parseFloat(item?.suggDivisionCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.suggDivisionCost.toFixed(2))}`,
        divisionSuggDifferenceWith$: divisionSugWithMinus(
          item?.divisionSuggDifference,
        ),
        totalCubeWithFixed: item.totalCube.toFixed(2),
        totalWeightWithFixed: item.totalWeight.toFixed(2),
      }));

      setArrOrderTotals(result);
    }
  }, [OrderTotalsList]);

  useEffect(() => {
    if ((distNumber || programYear) && programList) {
      setSelectedProgramValue(prgmNameFull);
      setProgramYear(programYearList[getProgramId(prgmNameFull)]);

      if (programYear) {
        setSelectedYearValue(prgmYr);
        setItemNumber(storedItemNumber);
        setSelectedEventNames(eventName);
      }
    }
  }, [programList]);

  useEffect(() => {
    if (programList) {
      setSelectedYearValue('');
      setProgramYear(programYearList[getProgramId(selectedProgramValue)]);
    }
  }, [selectedProgramValue]);

  useEffect(() => {
    // event name dropdown api call
    if (selectedProgramValue && selectedYearValue) {
      dispatch(
        getEventNameAction({
          programYear: selectedYearValue,
          programName: getProgramId(selectedProgramValue),
        }),
      );
    }
  }, [selectedYearValue]);
  const handleGoClick = () => {
    saveDivNumberDistNumberStoreNumber({
      prgmName: String(getProgramId(selectedProgramValue)),
      prgmNameFull: selectedProgramValue,
      eventName: selectedEventNames,
      prgmYr: selectedYearValue,
      divNumber: undefined,
      distNumber: undefined,
      storeNumber: undefined,
      itemNumber: '',
      strNumber: ''
    });
    fectOrderList({
      prgmName: String(getProgramId(selectedProgramValue)),
      prgmYr: selectedYearValue,
      itemNumber,
      totalType: 'totalByStore',
      eventId: selectedEventNames?.length > 0 ? getEventIds(selectedEventNames) : 'all',
    });
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const tempValue = (e.target as HTMLInputElement).value;
    if (/^(\s{1}|\d{0,10})$/.test(tempValue)) {
      setItemNumber(tempValue);
    }
  };

  const onChange = (selectedOptions: OnChangeValue<ConsoleConstructorOptions, true>) => {
    setSelectedEventNames(selectedOptions);
  }

  const handleChangeProgramYear = (ev: any) => {
    if (isPageInitialLoad) {
      setIsPageInitialLoad(false);
      setSelectedEventNames(eventName);
    } else {
      setSelectedEventNames([]);
    }
    setSelectedYearValue(ev);
  }

  const getExportArray = (data: Array<any>) => {
    switch (userRoleType) {
      case 'admin': {
        try {
          const output = data.map((item) => ({
            Division: item?.divName,
            District: item?.distName,
            'Store Number': item?.storeNumber,
            'Total Packs': `${item?.totalPack}`,
            'Extended Cost': `$${applyLocaleString(item?.extendedVendCost)}`,
            'Total Case Cube': `${item?.totalCube}`,
            'Total Case Weight': `${item?.totalWeight}`,
          }));

          let totalPack = 0;
          let totalExtndCost = 0;
          let totalCaseCube = 0;
          let totalCaseWt = 0;

          data.forEach((item) => {
            totalPack += parseFloat(item?.totalPack);
            totalExtndCost += parseFloat(item?.extendedVendCost);
            totalCaseCube += parseFloat(item?.totalCube);
            totalCaseWt += parseFloat(item?.totalWeight);
          });

          output.push({
            Division: 'Total',
            District: '',
            'Store Number': '',
            'Total Packs': totalPack.toFixed(),
            'Extended Cost': `$${applyLocaleString(totalExtndCost.toFixed(2))}`,
            'Total Case Cube': totalCaseCube.toFixed(2),
            'Total Case Weight': totalCaseWt.toFixed(2),
          });

          return output;
        } catch (error) {
          toast.error('Error in export');
          return [];
        }
      }
      case 'dist': {
        try {
          const output = data.map((item) => ({
            Division: item?.divName,
            District: item?.distName,
            'Store Number': item?.storeNumber,
            'Total Packs': `${item?.totalPack}`,
            'Extended Cost': `$${applyLocaleString(item?.extendedVendCost)}`,
            'Cost Difference': divisionSugWithMinus(
              item?.divisionSuggDifference,
            ),
            'Total Case Cube': `${item?.totalCube}`,
            'Total Case Weight': `${item?.totalWeight}`,
            'Cost Target': item?.costTarget,
          }));

          let totalPack = 0;
          let totalExtndCost = 0;
          let costDiff = 0;
          let totalCaseCube = 0;
          let totalCaseWt = 0;
          let totalCostTarget = 0;

          data.forEach((item) => {
            totalPack += parseFloat(item?.totalPack);
            totalExtndCost += parseFloat(item?.extendedVendCost);
            costDiff += parseFloat(item?.divisionSuggDifference);
            totalCaseCube += parseFloat(item?.totalCube);
            totalCaseWt += parseFloat(item?.totalWeight);
            totalCostTarget += parseFloat(item?.costTarget);
          });

          output.push({
            Division: 'Total',
            District: '',
            'Store Number': '',
            'Total Packs': totalPack.toFixed(),
            'Extended Cost': `$${applyLocaleString(totalExtndCost.toFixed(2))}`,
            'Cost Difference': divisionSugWithMinus(costDiff.toFixed(2)),
            'Total Case Cube': totalCaseCube.toFixed(2),
            'Total Case Weight': totalCaseWt.toFixed(2),
            'Cost Target': totalCostTarget.toFixed(2),
          });

          return output;
        } catch (error) {
          toast.error('Error in export');
          return [];
        }
      }
      case 'division': {
        try {
          const output = data.map((item) => ({
            Division: item?.divName,
            District: item?.distName,
            'Store Number': item?.storeNumber,
            'Total Sugg Packs': `${item?.totalDivisionSuggPacks}`,
            'Total Sugg Extended Cost': `$${applyLocaleString(
              item?.suggDivisionCost.toFixed(2),
            )}`,
            'Total Packs': `${item?.totalPack}`,
            'Extended Cost': `$${applyLocaleString(
              item?.extendedVendCost.toFixed(2),
            )}`,
            'Cost Difference': divisionSugWithMinus(
              item?.divisionSuggDifference,
            ),
            'Total Case Cube': `${item?.totalCube}`,
            'Total Case Weight': `${item?.totalWeight}`,
            'Cost Target': item?.costTarget,
          }));

          let totalPack = 0;
          let totalExtndCost = 0;
          let totalCaseCube = 0;
          let totalCaseWt = 0;
          let totalSuggPack = 0;
          let TotalSuggExtndCost = 0;
          let costDiff = 0;
          let totalCostTarget = 0;

          data.forEach((item) => {
            totalSuggPack += parseFloat(item?.totalDivisionSuggPacks);
            TotalSuggExtndCost += parseFloat(item?.suggDivisionCost);
            totalPack += parseFloat(item?.totalPack);
            totalExtndCost += parseFloat(item?.extendedVendCost);
            costDiff += parseFloat(item?.divisionSuggDifference);
            totalCaseCube += parseFloat(item?.totalCube);
            totalCaseWt += parseFloat(item?.totalWeight);
            totalCostTarget += parseFloat(item?.costTarget);
          });

          output.push({
            Division: 'Total',
            District: '',
            'Store Number': '',
            'Total Sugg Packs': `${totalSuggPack.toFixed()}`,
            'Total Sugg Extended Cost': `$${applyLocaleString(
              TotalSuggExtndCost.toFixed(2),
            )}`,
            'Total Packs': totalPack.toFixed(),
            'Extended Cost': `$${applyLocaleString(totalExtndCost.toFixed(2))}`,
            'Cost Difference': divisionSugWithMinus(costDiff.toFixed(2)),
            'Total Case Cube': totalCaseCube.toFixed(2),
            'Total Case Weight': totalCaseWt.toFixed(2),
            'Cost Target': totalCostTarget.toFixed(2),
          });

          return output;
        } catch (error) {
          toast.error('Error in export');
          return [];
        }
      }
      default:
        return [];
    }
  };

  const handleExportClick = () => {
    if (arrOrderTotals && arrOrderTotals.length > 0) {
      exportToXLSX({
        fileName: `${prgmNameFull || selectedProgramValue
          }-Order Total Report By Stores`,
        data: getExportArray(arrOrderTotals),
        column: wscolsStoreOrder,
      });
    } else {
      toast.warning('No Data');
    }
  };
  const columns = [
    {
      Header: 'Store Number',
      accessor: 'storeNumber',
      width: 40,
      Cell: (row: any) => (
        <Link
          data-testid="link"
          className="text-sky-500"
          to="/orders/item-order-totals/3"
          onClick={() => {
            saveDivNumberDistNumberStoreNumber({
              prgmName:
                prgmName !== undefined
                  ? prgmName
                  : String(getProgramId(selectedProgramValue)),
              prgmNameFull:
                prgmNameFull !== undefined
                  ? prgmNameFull
                  : selectedProgramValue,
              eventName:
                eventName !== undefined ? eventName : selectedEventNames,
              prgmYr: prgmYr !== undefined ? prgmYr : selectedYearValue,
              divNumber,
              distNumber,
              storeNumber: row?.row?.original?.storeNumber,
              itemNumber: '',
              strNumber: '',
            });
            clearOrderList();
          }}
        >
          {row?.row?.original?.storeNumber}
        </Link>
      ),
      Footer: <span>Total</span>,
    },
    {
      Header: 'Division',
      accessor: 'divName',
      // Cell: (row: any) =>
      //   `${row?.row?.original?.divId} - ${row?.row?.original?.divName}`,
    },
    {
      Header: 'District',
      accessor: 'distName',
    },
    {
      Header: 'Total Sugg Packs',
      accessor: 'totalDivisionSuggPacks',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum: any, row: any) => row.values.totalDivisionSuggPacks + sum,
              0,
            ),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Total Sugg Extended Cost',
      accessor: 'suggDivisionCostWith$',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            applyLocaleString(
              info.rows
                .reduce(
                  (sum: any, row: any) => row.original.suggDivisionCost + sum,
                  0,
                )
                .toFixed(2),
            ),
          [info],
        );
        return <span>${total}</span>;
      },
    },
    {
      Header: 'Total Packs Ordered',
      accessor: 'totalPack',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum: any, row: any) => row.values.totalPack + sum,
              0,
            ),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Extended Cost',
      accessor: 'extendedVendCostwith$',
      // Cell: (row: any) =>
      //   `$${
      //     parseFloat(row?.row?.original?.extendedVendCost) === 0
      //       ? '0.00'
      //       : row?.row?.original?.extendedVendCost
      //   }`,
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            applyLocaleString(
              info.rows
                .reduce(
                  (sum: any, row: any) => row.original.extendedVendCost + sum,
                  0,
                )
                .toFixed(2),
            ),
          [info],
        );
        return <span>${total}</span>;
      },
    },
    {
      Header: 'Cost Difference',
      accessor: 'divisionSuggDifferenceWith$',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows
              .reduce(
                (sum: any, row: any) =>
                  row.original.divisionSuggDifference + sum,
                0,
              )
              .toFixed(2),
          [info],
        );
        return (
          <span>
            {total < 0
              ? `-$${applyLocaleString(Math.abs(total).toFixed(2))}`
              : `$${applyLocaleString(total)}`}
          </span>
        );
      },
    },
    {
      Header: 'Total Case Cube',
      accessor: 'totalCubeWithFixed',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows
              .reduce(
                (sum: any, row: any) =>
                  Number(row.values.totalCubeWithFixed) + sum,
                0,
              )
              .toFixed(2),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Total Case Weight',
      accessor: 'totalWeightWithFixed',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows
              .reduce(
                (sum: any, row: any) =>
                  Number(row.values.totalWeightWithFixed) + sum,
                0,
              )
              .toFixed(2),
          [info],
        );
        return <span>{total}</span>;
      },
    },
    {
      Header: 'Cost Target',
      accessor: 'costTarget',
      Footer: (info: any) => {
        const total = React.useMemo(
          () =>
            info.rows
              .reduce(
                (sum: any, row: any) =>
                  Number(row.values.costTarget) + sum,
                0,
              )
              .toFixed(2),
          [info],
        );
        return <span>{total}</span>;
      },
    },
  ];

  return (
    <div>
      <div ref={ref} className="pt-1">
        <Banner data-testid="banner" title="Order Totals By Stores">
          <Button
            text="Export"
            onClick={handleExportClick}
            disabled={!arrOrderTotals || arrOrderTotals.length < 1}
            data-testid="export-button"
          />
        </Banner>
        <section className="pt-4 pl-4 pr-4">
          <Accordion heading="Order Totals By Stores">
            <Conatiner styles="border-t">
              <div className="items start mt-2 mb-2 flex">
                <DropDown
                  isMandatory
                  List={programList}
                  label="Program:"
                  value={selectedProgramValue}
                  customStyle={window?.innerWidth < 1538 ? 'ml-3 w-32' : 'ml-3 sm:w-48 md:w-64 lg:w-80 xl:w-96'}
                  onChange={setSelectedProgramValue}
                  data-testid="Program"
                />
                <div className="items flex pl-2">
                  <DropDown
                    isMandatory
                    List={programYear}
                    label="Program Year:"
                    value={selectedYearValue}
                    customStyle='ml-3 w-28'
                    onChange={handleChangeProgramYear}
                    data-testid="ProgramYear"
                  />
                </div>
                <div className='items items-center flex pl-4'>
                  <label htmlFor="event-name"
                    className='font-nunito text-center text-sm font-semibold text-black'>Event Name:
                  </label>
                </div>
                <div
                  className={`${selectedYearValue === ''
                    ? 'items flex pointer-events-none opacity-60 items-center pl-2 '
                    : 'items flex pl-2 items-center'
                    }`}
                >
                  {' '}
                  <Select
                    className="w-[500px] z-20"
                    closeMenuOnSelect={false}
                    defaultValue={selectedEventNames}
                    onChange={onChange}
                    options={eventNameList ?? []}
                    isSearchable
                    value={selectedEventNames}
                    getOptionLabel={(option) => `${option.value}`}
                    name='id'
                    isMulti
                    placeholder='Select Events'
                  />
                </div>
                <div className='items flex pl-5 pt-3'> <InputField
                  id="input-item-number"
                  name="ItemNumber"
                  label="Item Number:"
                  styleClass="xl:w-38 lg:w-18 md:w-30 sm:w-28 max-w-{10}"
                  disabled={selectedYearValue === '' || selectedProgramValue === ''}
                  handleChange={handleChange}
                  value={itemNumber}
                  data-testid="ItemNumber"
                />
                </div>
                <div className="flex items-center pl-4 pt-1">
                  <Button
                    type="contained"
                    text="Go"
                    onClick={handleGoClick}
                    disabled={
                      selectedYearValue === '' || selectedProgramValue === ''
                    }
                    data-testid="Go"
                  />
                </div>
                {/* <div className="flex items-center pl-4">
                  <Button
                    type="contained"
                    text="Clear"
                    onClick={handleClearClick}
                    disabled={
                      selectedYearValue === '' || selectedProgramValue === ''
                    }
                  />
                </div> */}
              </div>
            </Conatiner>
          </Accordion>
        </section>
      </div>
      <div className="pb-4 pl-4 pr-4">
        {arrOrderTotals && arrOrderTotals.length > 0 ? (
          <RTable
            columns={columns}
            isfilter
            data={arrOrderTotals}
            isTotal
            height={height + tabHeightUpdated}
            hiddenColumns={UserRoleColMaintenance(
              'OrderTotals',
              'DivDistStoreOrder',
            )}
            data-testid="StoreOrderTotalsTable"
          />
        ) : (
          <NoData />
        )}{' '}
      </div>
    </div>
  );
}
