export type TypeLockTypeObj = 'NLK' | 'SLK' | 'SDMLK' | 'SDMINC' | 'SINC' | 'SGPLK';

export enum LockTypeEnum {
  NLK = 'No Lock',
  SLK = 'Store Lock Only',
  SDMLK = 'Store and DM Lock',
  SDMINC = 'Store and DM Increase only',
  SINC = 'Store Increase only',
  SGPLK = 'Store Group Lock only',
}
