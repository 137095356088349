/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import Button, { ButtonGroup } from 'components/Button';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppThunkDispatch } from 'redux/store';
import * as XLSX from 'xlsx';
import { failedSVG, notStartedSVG, success } from 'assets';
import { useEffect } from 'react';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { apiBaseURL } from 'utils/apiBaseUrl';
import instance, { axiosInstanceWithOutLoader } from 'utils/interceptor';
import useLoader from 'hooks/useLoader';
import { json } from 'stream/consumers';
import axios from 'axios';


interface IFileUploadModal {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  eventUploadFileAction?: (file: File) => any;
}

export default function DBSSFileupload({
  title,
  isModalOpen,
  onClose,
  eventUploadFileAction,
}: IFileUploadModal) {
  const dispatch = useAppThunkDispatch();
  const [uploadFile, setUploadFile] = React.useState<FileList | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  // status values   1:upload  2:remove empty row andcolumns   3:data transfer   4:validate spa items  5:get missing item  6: save items
  const [status, setStatus] = useState(0);
  // statusImg values 0:Not run, 1:success, 2:failed
  const [statusFileUploadImg, setStatusFileUploadImg] = useState(0);
  const [statusRemoveRowColuImg, setStatusRemoveRowColuImg] = useState(0);
  const [statusTransformDataImg, setStatusTransformDataImg] = useState(0);
  const [statusValidateItemsImg, setStatusValidateItemsImg] = useState(0);
  const [statusGetMissingItemImg, setStatusGetMissingItemImg] = useState(0);
  const [statusSaveDataImg, setStatusSaveDataImg] = useState(0);
  const [programNumber, setProgramNumber] = useState("");
  const [programYear, setProgramYear] = useState("");
  const [btnName, setBtnName] = useState("Upload");
  const [isError, setIsError] = useState(0);
  const [isOnClose, setIsOnClose] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [itemCountSave, setItemCountSave] = useState(0);
  const [errorSuccessMessage, setErrorSuccessMessage] = useState("");
  const [missingSpaItemsInDbssUpload, setMissingSpaItemsInDbssUpload] = useState<any>([]);
  const [deletedCTypeItemsInDbssUpload, setDeletedCTypeItemsInDbssUpload] = useState<any>([]);
  const [addedSpaItemsInDbssUpload, setAddedSpaItemsInDbssUpload] = useState<any>(undefined);
  const { setIsLoading } = useLoader();

  const clearInput = () => {
    setUploadFile(null);
    setItemCountSave(0);
    setIsError(0);
    setIsOnClose(false);
    setMissingSpaItemsInDbssUpload([]);
    setDeletedCTypeItemsInDbssUpload([]);
    setAddedSpaItemsInDbssUpload(undefined)
    setStatus(0);
    setProgramNumber('');
    setProgramYear('');
    setStatusFileUploadImg(0);
    setStatusRemoveRowColuImg(0);
    setStatusTransformDataImg(0);
    setStatusValidateItemsImg(0);
    setStatusGetMissingItemImg(0);
    setStatusSaveDataImg(0);
    setBtnName("Upload");
    setErrorSuccessMessage('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleUploadFile = async () => {

    if (uploadFile) {
      try {
        const file = uploadFile[0];
        const bufferData = await file.arrayBuffer();
        /* data is an ArrayBuffer */
        const workbook = XLSX.read(bufferData);
        const ws = workbook.Sheets[workbook.SheetNames[2]];
        const data = XLSX.utils.sheet_to_json(ws, {});
        const data1 = XLSX.utils.sheet_to_json(ws, { header: 1 });

      } catch (error: any) {
        toast.error(
          error?.message ?? 'Failed to upload/read data from excel file',
        );
      }
    }
  };

  const getImageSource = (statusValue: number) => {
    switch (statusValue) {
      case 0:
        return notStartedSVG;
        break;
      case 1:
        return success;
        break;
      case 2:
        return failedSVG;
        break;

      default:
        return notStartedSVG;
        break;
    }
  }

  const onClearHandler = () => {
    if (status !== 0 && status !== 6) {
      setIsModal(!isModal);
    }
    else {
      clearInput();
    }
  };

  const onConfirmModalClose = () => {
    onClearHandler();
    setIsOnClose(false);
  };

  const uploadFileService = async (statusValue: string) => {
    setIsLoading(true);
    try {
      if (uploadFile !== null && uploadFile !== undefined) {

        const formData = new FormData();
        formData.append('file', uploadFile[0]);
        formData.append('status', statusValue);
        formData.append('programYear', programYear);
        formData.append('programNumber', programNumber);
        const response = await axiosInstanceWithOutLoader.post(
          `${apiBaseURL}/ssa/uploadRawEventFile`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        if (response?.data?.status === 'success') {
          setIsLoading(false);
          setIsError(0)
          if (statusValue !== 'clear') {
            switch (status) {
              case 1:
                setErrorSuccessMessage(`Data scrubbing completed and ${response?.data?.itemCount} items added to the temporary table.\nPlease proceed with next step.`)
                setProgramYear(response?.data?.programYear)
                setProgramNumber(response?.data?.programNumber)
                break;
              case 2:
                setErrorSuccessMessage(`Transforms data completed.\nPlease proceed with next step.`);
                break;
              case 5:
                setErrorSuccessMessage(`Save event data completed.\nDBSS file validation completed for file ${uploadFile?.length > 0 ? uploadFile[0].name : ''}`);
                break;
              case 3: {
                let sucessMessage = '';

                if (response?.data?.uploadValidateItemList) {
                  const uploadValidateItemListOandMType = response.data.uploadValidateItemList;
                  if (uploadValidateItemListOandMType?.length > 0) {
                    for (let index = 0; index < uploadValidateItemListOandMType.length; index += 1) {
                      sucessMessage += `Item id ${uploadValidateItemListOandMType[index]?.spaItemId} replaced by ${uploadValidateItemListOandMType[index]?.dbssItemId} for upc ${uploadValidateItemListOandMType[index]?.dbssUpcId}.\n`
                    }
                  }
                }
                if (response?.data?.ctypeItemInDbssList) {
                  const uploadValidateItemListCType = response.data.ctypeItemInDbssList;
                  if (uploadValidateItemListCType?.length > 0) {
                    for (let index = 0; index < uploadValidateItemListCType.length; index += 1) {
                      sucessMessage += `Item id ${uploadValidateItemListCType[index]?.spaItemIdCtypeList} replaced by ${uploadValidateItemListCType[index]?.spaItemIdOtypeList} for upc ${uploadValidateItemListCType[index]?.dbssUpcId}.\n`
                    }
                  }
                }
                let sucessMessageForChangedCtoMtype = "";
                if (response.data.changedCtoMtype) {
                  const tempChangedCtoMtype = response.data.changedCtoMtype;
                  if (tempChangedCtoMtype?.length > 0) {
                    for (let index = 0; index < tempChangedCtoMtype.length; index += 1) {
                      if (index === 0) {
                        sucessMessageForChangedCtoMtype += `\nThe below M type item${tempChangedCtoMtype.length > 1 ? "s are" : " is"} added:\n${tempChangedCtoMtype[index]}`
                      }
                      else {
                        sucessMessageForChangedCtoMtype += `,${tempChangedCtoMtype[index]}`
                      }
                    }
                  }
                }

                if (response?.data?.missingSpaItemsInDbssUpload) {
                  setMissingSpaItemsInDbssUpload(response.data.missingSpaItemsInDbssUpload);
                }
                if (response?.data?.deletedCTypeItem) {
                  setDeletedCTypeItemsInDbssUpload(response.data.deletedCTypeItem);
                }
                if (response?.data?.addedSpaItemsToStaging) {
                  setAddedSpaItemsInDbssUpload(response?.data?.addedSpaItemsToStaging);
                }
                if (response?.data?.eventItemtotal) {
                  setItemCountSave(response?.data?.eventItemtotal);
                }


                if (sucessMessage !== '') {
                  sucessMessage += sucessMessageForChangedCtoMtype;
                  setErrorSuccessMessage(`Validate SPA items Completed.Please proceed with next step.\n\nThe below items are modified in this step:\n${sucessMessage}`)
                }
                else {
                  setErrorSuccessMessage(`Validate SPA items Completed.\nPlease proceed with next step.\n${sucessMessageForChangedCtoMtype}`)
                }
                break;

              }

              default:
                setErrorSuccessMessage(``);
                break;
            }
          }
        }
        else {
          setIsError(1)
          setIsLoading(false);
          setErrorSuccessMessage(`ERROR: ${response?.data?.error}`)
        }
        return response?.data?.status;

      }
      return new Error(`No file`);
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      toast.error('Step failed');
      setIsError(1)
      let errorMessage = "Step Failed..";
      if (error instanceof Error && error?.message !== '') {
        errorMessage = error.message;
        setErrorSuccessMessage(errorMessage)
      }
      else {
        setErrorSuccessMessage(errorMessage)
      }
      return new Error(`uploadFileService API call failed`);
    }
  }

  const onCloseHandler = () => {
    if (status !== 0 && status !== 6) {
      setIsOnClose(true)
      onClearHandler()
    }
    else {
      clearInput();
      onClose();
    }

  };

  const onConfirmModalCloseSuccess = () => {
    if (status !== 0) {
      uploadFileService('clear')
    }
    clearInput();
    onClearHandler();
    if (isOnClose) {
      onClose();
    }
  };

  const rangeSplitAxiosApiCall = () => {
    if (!uploadFile) {
      return []
    }
    const result: any = []
    const splitValue = 50;
    const value = itemCountSave;
    let rangeCount = 0;
    let reminder = 0;
    let start = 1;
    let end = 0;
    if (itemCountSave > splitValue) {
      rangeCount = Math.floor(value / splitValue);
      reminder = value - rangeCount * splitValue;
      end = splitValue;
    }
    else {
      rangeCount = 0;
      reminder = itemCountSave;
    }


    for (let index = 0; index < rangeCount; index += 1) {
      const formData = new FormData();
      formData.append('file', uploadFile[0]);
      formData.append('status', "save");
      formData.append('programYear', programYear);
      formData.append('programNumber', programNumber);
      formData.append('start', start.toString());
      formData.append('end', end.toString());
      result.push(axiosInstanceWithOutLoader.post(
        `${apiBaseURL}/ssa/uploadRawEventFile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      )
      start += splitValue;
      end += splitValue;
    }
    if (reminder !== 0) {
      end = value
      console.log(`start-end${start}-${end}`)
      const formData = new FormData();
      formData.append('file', uploadFile[0]);
      formData.append('status', "save");
      formData.append('programYear', programYear);
      formData.append('programNumber', programNumber);
      formData.append('start', start.toString());
      formData.append('end', end.toString());
      result.push(axiosInstanceWithOutLoader.post(
        `${apiBaseURL}/ssa/uploadRawEventFile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      )
    }
    return result
  }

  const handleClick = async () => {
    if (uploadFile === null || uploadFile === undefined) {
      toast.warning('Please select file.');
      return
    }

    switch (status) {
      case 0:
        if (uploadFile !== null && uploadFile !== undefined) {
          setStatus(1);
          setIsError(0);
          setStatusFileUploadImg(1);
          setErrorSuccessMessage('File Upload Completed. \nPlease proceed with next step.')

        }
        else {
          setStatusFileUploadImg(2);
          setIsError(1);
          setErrorSuccessMessage('File upload failed.')
        }
        break;
      case 1:
        try {
          if (uploadFile) {
            const response = uploadFileService('upload');
            setIsError(2)
            setErrorSuccessMessage("Removing Empty rows and columns is in progress.Please wait...")
            if (await response === 'success') {
              setStatusRemoveRowColuImg(1)
              setStatus(2)
            }
            else {
              setStatusRemoveRowColuImg(2)
              setStatus(1)
            }
          }
        } catch (error) {
          setIsError(1);
          setStatusRemoveRowColuImg(2)
          setStatus(1)
          setErrorSuccessMessage('Removing Empty rows and columns failed.')
        }
        break;
      case 2:
        try {
          if (uploadFile) {
            const response = uploadFileService('transform');
            setIsError(2)
            setErrorSuccessMessage("Transforms data is in progress.Please wait...")
            if (await response === 'success') {
              setStatusTransformDataImg(1)
              setStatus(3)
            }
            else {
              setStatusTransformDataImg(2)
              setStatus(2)
            }
          }
        } catch (error) {
          setIsError(1);
          setStatusTransformDataImg(2)
          setStatus(2)
          setErrorSuccessMessage('Transforms data failed.')
        }
        break;
      case 3:
        try {
          if (uploadFile) {
            const response = uploadFileService('validate');
            setIsError(2)
            setErrorSuccessMessage("Validate SPA items is in progress.Please wait...")
            if (await response === 'success') {
              setStatusValidateItemsImg(1)
              setStatus(4)
            }
            else {
              setStatusValidateItemsImg(2)
              setStatus(3)
            }
          }
        } catch (error) {
          setIsError(1);
          setStatusValidateItemsImg(2)
          setStatus(3)
          setErrorSuccessMessage('Validate SPA items failed.')
        }
        break;
      case 4:
        try {
          let sucessMessage = '';

          if (missingSpaItemsInDbssUpload?.length > 0) {
            for (let index = 0; index < missingSpaItemsInDbssUpload.length; index += 1) {
              if (index === 0) {
                sucessMessage += `\n\nThe below item${missingSpaItemsInDbssUpload.length > 1 ? "s are" : " is"} missing in DBSS:\n${missingSpaItemsInDbssUpload[index]}`
              }
              else {
                sucessMessage += `,${missingSpaItemsInDbssUpload[index]}`
              }
            }
          }

          if (deletedCTypeItemsInDbssUpload?.length > 0) {
            for (let index = 0; index < deletedCTypeItemsInDbssUpload.length; index += 1) {
              if (index === 0) {
                sucessMessage += `\n\nThe below C type item${deletedCTypeItemsInDbssUpload.length > 1 ? "s are" : " is"} deleted in DBSS:\n${deletedCTypeItemsInDbssUpload[index]}`
              }
              else {
                sucessMessage += `,${deletedCTypeItemsInDbssUpload[index]}`
              }
            }
          }

          if (addedSpaItemsInDbssUpload && JSON.stringify(addedSpaItemsInDbssUpload) !== "{}") {

            let isErrorfound = false;
            sucessMessage += `\n\nThe below items are added in DBSS:`;
            try {
              for (const [key, value] of Object.entries(addedSpaItemsInDbssUpload)) {
                sucessMessage += `\nSPA id ${key} added to dbss with upc id ${value}`;
              }
            } catch (error) {
              isErrorfound = true;
            }
          }

          setIsError(0);
          if (sucessMessage !== '') {
            setErrorSuccessMessage(`Get missing SPA items Completed.Please proceed with next step.${sucessMessage}`)
          }
          else {
            setErrorSuccessMessage("Validate SPA items Completed.\nPlease proceed with next step")
          }

          setStatusGetMissingItemImg(1)
          setStatus(5)
        } catch (error) {
          setIsError(1);
          setStatusGetMissingItemImg(2)
          setStatus(4)
          setErrorSuccessMessage('Get missing SPA items failed.')
        }
        break;
      case 5:
        try {
          if (uploadFile && itemCountSave !== 0) {
            setIsError(2)
            setIsLoading(true);
            setErrorSuccessMessage("Save event data is in progress.Please wait...")
            const concurrentnservice = rangeSplitAxiosApiCall();
            Promise.all(concurrentnservice)
              .then((res) => {
                const resultAll = res;
                if (resultAll?.length > 0) {
                  let isSuccess = false;
                  for (let index = 0; index < resultAll.length; index += 1) {
                    const element = resultAll[index];
                    if (element?.status === 200) {
                      isSuccess = true;
                    }
                    else {
                      isSuccess = false;
                      break;
                    }
                  }
                  if (isSuccess) {
                    setIsError(0);
                    setStatusSaveDataImg(1)
                    setStatus(6);
                    setErrorSuccessMessage("Saved Successfully.")
                  }
                  else {
                    setIsError(1);
                    setStatus(5);
                    setStatusSaveDataImg(2)
                    setErrorSuccessMessage(`Save Failed.`)
                  }
                }
                setIsLoading(false);
              })
          }
        } catch (error) {
          setIsLoading(false);
          setIsError(1);
          setStatusSaveDataImg(2)
          setStatus(5)
          setErrorSuccessMessage('Save event data failed.')
        }
        break;

      default:
        break;
    }
    // if (status === 5) {
    //   setStatus(0)
    // }
    // else {
    //   setStatus(status + 1)
    // }
  }

  useEffect(() => {
    switch (status) {
      case 0:
        setBtnName("Upload")
        break;
      case 1:
        setBtnName("Scrub Data")
        break;
      case 2:
        setBtnName("Transform Data")
        break;
      case 3:
        setBtnName("Validate SPA Items")
        break;
      case 4:
        setBtnName("Get Missing Items")
        break;
      case 5:
        setBtnName("Save Items")
        break;

      default:
        break;
    }

  }, [status])



  return (
    <div
      id="popup-modal"
      className={` ${!isModalOpen && 'hidden'
        } h-modal fixed top-0 right-0 left-0 z-50 flex  flex-col items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-[rgba(0,0,0,.2)] shadow md:inset-0 md:h-full`}
    >
      <div className="min-w-[40%] max-h-[100%] rounded-lg border bg-white shadow-2xl ">
        <div className="max-h-[7%] flex flex-row content-center justify-between border-b pl-5 pr-5 pt-2 pb-1">
          <h1 className="font-nunito-Regular w-fit text-base font-bold text-black pt-2">
            {title}
          </h1>
          <button className="font-bold text-xl bg-white rounded-md p-2 hover:text-black inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" type="button" title="Close" onClick={onCloseHandler} data-testid='cross-button'>
            X
          </button>
        </div>
        <form onSubmit={handleUploadFile} data-testid='submit-form'>
          <div className="flex flex-row justify-center p-5 pt-[20px] border-b">
            <div className="max-h-[10%] flex w-full flex-row content-center">
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                type="file"
                required
                disabled={status !== 0}
                accept=".xlsx"
                onChange={(e) => setUploadFile(e.target.files)}
                ref={inputRef}
                data-testid='input-file'
              />
            </div>
          </div>
          <div className="max-h-[7%] flex flex-row p-5 pt-[10px] item-start align-middle">
            <img className="w-8 h-8  pr-[10px] " src={getImageSource(statusFileUploadImg)} alt="" />
            <label htmlFor="status" className="font-semibold text-lg">File Upload.</label>
          </div>
          <div className=" max-h-[7%] flex flex-row p-5 -mt-[20px] item-start align-middle">
            <img className="w-8 h-8 pr-[10px]" src={getImageSource(statusRemoveRowColuImg)} alt="" />
            <label htmlFor="status" className="font-semibold text-lg">Remove extra rows/columns.</label>
          </div>
          <div className="max-h-[7%] flex flex-row p-5 -mt-[20px] item-start align-middle">
            <img className="w-8 h-8 pr-[10px]" src={getImageSource(statusTransformDataImg)} alt="" />
            <label htmlFor="status" className="font-semibold text-lg">Transforms Data.</label>
          </div>
          <div className="max-h-[7%] flex flex-row p-5 -mt-[20px] item-start align-middle">
            <img className="w-8 h-8 pr-[10px]" src={getImageSource(statusValidateItemsImg)} alt="" />
            <label htmlFor="status" className="font-semibold text-lg">Validate SPA Items.</label>
          </div>
          <div className="max-h-[7%] flex flex-row p-5 -mt-[20px] item-start align-middle">
            <img className="w-8 h-8 pr-[10px]" src={getImageSource(statusGetMissingItemImg)} alt="" />
            <label htmlFor="status" className="font-semibold text-lg">Get Missing SPA Items.</label>
          </div>
          <div className="max-h-[7%] flex flex-row p-5 -mt-[20px] item-start align-middle border-b">
            <img className="w-8 h-8 pr-[10px]" src={getImageSource(statusSaveDataImg)} alt="" />
            <label htmlFor="status" className="font-semibold text-lg">Save Event Data.</label>
          </div>
          <div className="flex h-full w-full flex-row content-center p-5">
            <textarea
              className={`
        form-control
        m-0
        block
        h-full
        w-full
        rounded
        ${window.innerHeight < 700 ? "min-h-[50px]" : window.innerHeight < 750 ? "min-h-[120px]" : "min-h-[200px]"}
        border
        border-solid
        border-gray-300
        bg-white
        bg-clip-padding px-3
        py-1.5 text-base font-bold
        transition
        ${isError === 1 ? 'text-red-600' : isError === 0 ? 'text-green-600' : 'text-yellow-500'}
        ease-in-out
        focus:border-blue-600
        focus:bg-white focus:text-gray-700 focus:outline-none disabled:bg-gray-100
      `}
              id="exampleFormControlTextarea1"
              placeholder="Error/Success Message"
              value={errorSuccessMessage}
              disabled
              title={errorSuccessMessage}
            />
          </div>
          <ButtonGroup styles="max-h-[5%] justify-end pt-[25px] p-5">
            <Button text="Close" onClick={onCloseHandler} />
            <Button text="Clear" onClick={onClearHandler} />
            {/* <Button type="contained" text="Upload" /> */}
            <button
              type='button'
              onClick={handleClick}
              className={`${uploadFile === null || uploadFile === undefined || status === 6 ? 'pointer-events-none opacity-30' : 'pointer-events-auto opacity-100'} 
              font-sm w-[200px]  h-9 rounded-md bg-primary px-6 text-base text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300`}
            >
              {btnName}
            </button>
          </ButtonGroup>
          <ConfirmModal
            title="Confirm"
            onClose={onConfirmModalClose}
            onSuccess={onConfirmModalCloseSuccess}
            isModalOpen={isModal}
            message={isOnClose ? "Are you sure want to clear all the unsaved values and close the window?" : "Are you sure want to clear all the unsaved values?"}
          />
        </form>
      </div>
    </div>
  );
}
