/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { lock, unLock } from 'assets';
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import Button, { ButtonGroup } from 'components/Button';
import DropDown from 'components/Dropdown/Dropdown';
import ItemUploadModal from 'components/Modal/ItemUploadModal';
import StickyRTable from 'components/StickyRTable';
import useEventItem from 'hooks/useEventItem';
import React, { useState, useEffect, useCallback } from 'react';

import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { AppState, useAppThunkDispatch } from 'redux/store';
import useResizeObserver from 'use-resize-observer';
import { imageBaseUrl } from 'utils/imageBaseUrl';
import exportToXLSX from 'utils/exportToXLSX';
import wscolsEventItem from '../../constantsEvent';
import { toast } from 'react-toastify';
import DisplayModal from 'components/Modal/DisplayModal';
import EditableCell from '../../UpdateEventItems/EditableCell';
import useEventItemDetails from 'hooks/useEventItemDetails';
import ItemFileUpload from 'components/Modal/ItemFileUpload';
import { groupWithColorForM, groupWithColorForO } from '../colorGrouping';
import { regex } from 'utils/validations';
import { EVENT_ITEM_LIST_CLEAR } from 'redux/actions/types';
import UILoader from 'components/UILoader';
import NoData from 'components/NoData';
import applyLocaleString from 'utils/applyLocaleString';

interface TypeEventItemList {
  adLocation: string;
  adjStoreQty: string;
  childData: [null];
  displayLoc: string;
  divLockFlag: string;
  eventId: string;
  itemAdminComment: string;
  itemComment: string;
  itemDistComment: string;
  itemDivComment: string;
  itemNumber: string;
  itemType: string;
  lockFlag: string;
  modelUpc: string;
  pgmStrSk: string;
  promoPrice: string;
  suggestedAdminQty: string;
}
function EventItems({
  eventItemList,
  planogramList,
  event,
  eventItemNotModifiedList,
}: any) {
  const { eventId = 0, eventName = '', close } = useParams();
  const dispatch = useAppThunkDispatch();

  const [selectedSpaceValue, setSelectedSpaceValue] = useState('');
  const [isModalOpen, setIsOpen] = useState(false);
  const [isDisplayModalOpen, setIsDisplayModalOpen] = useState(false);
  const [selectedUPC, setUPC] = useState('');
  const [itemUploadModal, setItemUploadModal] = useState(false);
  const { saveEventItemDetails } = useEventItemDetails();
  const [uploadData, setUploadData] = useState<
    TypeEventItemList[] | null | undefined
  >(eventItemList);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [eventListData, setEventListData] = useState([]);
  const [fileUploadSaveRes, setFileUploadSaveRes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => () => {
    // When component unmounts clearing the eventItemList from store,
    // clear the items in store to fix glitch in table showing old values
    if (eventItemList.length !== 0) {
      dispatch({ type: EVENT_ITEM_LIST_CLEAR });
    }
  });

  useEffect(() => {
    if (eventItemList && eventItemList.length > 0) {
      const result = eventItemList.map((item: any) => ({
        ...item,
        eachCostwith$:
          parseFloat(item?.eachCost) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.eachCost)}`,
        suggestedRetailWith$:
          parseFloat(item?.suggestedRetail) === 0
            ? '$0.00'
            : `$${applyLocaleString(item?.suggestedRetail)}`,
        totalRetailWith$:
          item?.pack === null ||
            parseFloat(item?.suggestedRetail) === 0 ||
            null ||
            item?.adjAdminQty === null
            ? '$0.00'
            : `$${applyLocaleString(
              (
                Number(item.pack) *
                Number(item.suggestedRetail) *
                Number(item.adjAdminQty)
              ).toFixed(2),
            )}`,
        extendedCostWith$:
          parseFloat(item.adjAdminQty) === 0 ||
            null ||
            parseFloat(item.ibc) === 0 ||
            null
            ? '$0.00'
            : `$${applyLocaleString(
              (Number(item.ibc) * Number(item.adjAdminQty)).toFixed(2),
            )}`,
      }));

      const colorGroupedResult = groupWithColorForM(result);
      const colorGroupResultWithOAndM = groupWithColorForO(colorGroupedResult);
      const updatedEventItemData = colorGroupResultWithOAndM.map(
        (item: any) => {
          if (item.subRows.length > 0) {
            return {
              ...item,
              subRows: item.subRows.map((subItem: any) => ({
                ...subItem,
                eachCostwith$:
                  parseFloat(subItem?.eachCost) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(subItem?.eachCost)}`,
                suggestedRetailWith$:
                  parseFloat(subItem?.suggestedRetail) === 0
                    ? '$0.00'
                    : `$${applyLocaleString(subItem?.suggestedRetail)}`,
                totalRetailWith$:
                  subItem?.pack === null ||
                    parseFloat(subItem?.suggestedRetail) === 0 ||
                    null ||
                    subItem?.adjAdminQty === null
                    ? '$0.00'
                    : `$${applyLocaleString(
                      (
                        Number(subItem.pack) *
                        Number(subItem.suggestedRetail) *
                        Number(subItem.adjAdminQty)
                      ).toFixed(2),
                    )}`,
                extendedCostWith$:
                  parseFloat(subItem.adjAdminQty) === 0 ||
                    null ||
                    parseFloat(subItem.ibc) === 0 ||
                    null
                    ? '$0.00'
                    : `$${applyLocaleString(
                      (
                        Number(subItem.ibc) * Number(subItem.adjAdminQty)
                      ).toFixed(2),
                    )}`,
              })),
            };
          }
          return item;
        },
      );

      setEventListData(updatedEventItemData);
      setLoading(false);
    }
    if (eventListData) {
      setUploadData(eventListData);
    }
  }, [eventItemList, eventListData]);

  // To calculate Column Total
  const handleTotalRetail = (info: any) => {
    let value = 0;
    // MainRow Total
    const mainRowTotal = React.useMemo(
      () =>
        info.rows
          .reduce(
            (sum: any, row: any) =>
              row.original.pack *
              row.original.adjAdminQty *
              row.original.suggestedRetail +
              sum,
            0,
          )
          .toFixed(2),
      [info],
    );

    // SubRow Total
    const arrSubRowTotal = info.rows.map((item: any) => {
      value = item.subRows
        .reduce(
          (sum: any, subItem: any) =>
            subItem.original.pack *
            subItem.original.adjAdminQty *
            subItem.original.suggestedRetail +
            sum,
          0,
        )
        .toFixed(2);
      return Number(value);
    });
    const subRowTotal = arrSubRowTotal.reduce(
      (sum: any, subRowItem: any) => parseFloat(subRowItem) + sum,
      0,
    );

    return Number(mainRowTotal) + subRowTotal;
  };

  const handleTotalEaches = (info: any) => {
    // MainRow Total
    const value = React.useMemo(
      () =>
        info.rows
          .reduce(
            (sum: any, row: any) =>
              row.original.totalEaches + sum,
            0,
          )
          .toFixed(2),
      [info],
    );
    return value;
  }

  const handleTotalExtendedCost = (info: any) => {
    let value = 0;
    // MainRow Total
    const mainRowTotal = React.useMemo(
      () =>
        info.rows
          .reduce(
            (sum: any, row: any) =>
              row.original.ibc * row.original.adjAdminQty + sum,
            0,
          )
          .toFixed(2),
      [info],
    );

    // SubRow Total
    const arrSubRowTotal = info.rows.map((item: any) => {
      value = item.subRows
        .reduce(
          (sum: any, subItem: any) =>
            subItem.original.ibc * subItem.original.adjAdminQty + sum,
          0,
        )
        .toFixed(2);

      return Number(value);
    });

    const subRowTotal = arrSubRowTotal.reduce(
      (sum: any, subRowItem: any) => parseFloat(subRowItem) + sum,
      0,
    );

    return Number(mainRowTotal) + subRowTotal;
  };
  const { ref, height = 500 } = useResizeObserver<HTMLDivElement>();
  const { fetchEventItemList, updateLock, saveEvent } = useEventItem();
  useEffect(() => {
    setUploadData(null);
    if (event?.eventId) {
      fetchEventItemList({
        eventId: event?.eventId,
        programSK: '',
        pogSk: '',
      });
    } else {
      fetchEventItemList({
        eventId: eventId.toString(),
        programSK: '',
        pogSk: '',
      });
    }
  }, [event, eventId]);
  const handleUpdateLockItem = async (row: any) => {
    const res = await updateLock(row);
    if (res?.status === 200) {
      fetchEventItemList({
        eventId: eventId.toString(),
        programSK: '',
        pogSk: '',
      });
    }
  };
  const updateTableData = (rowIndex: any, columnId: any, value: any) => {
    setSkipPageReset(true);
    setUploadData((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'expander',
        width: 60,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span
            {...getToggleAllRowsExpandedProps({
              style: {
                fontSize: '25px',
              },
            })}
          >
            {isAllRowsExpanded ? '-' : '+'}
          </span>
        ),
        sticky: 'left',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                  fontSize: '25px',
                },
              })}
            >
              {row.isExpanded ? '-' : '+'}
            </span>
          ) : null,
        Footer: <span className="sticky -left-0">Total</span>,
      },
      {
        Header: 'Item Number',
        accessor: 'itemNumber',
        sticky: 'left',
        width: 100,
        Cell: (row: any) =>
          row?.cell?.row?.original.itemType !== 'C' ? (
            <Link
              data-testid="link"
              className="text-sky-500"
              to={`/events/event-list/1/event-items/${row?.cell?.row?.original.eventId}/event-stores-items-admin/${row?.cell?.row?.original.itemNumber}/${close}`}
              onClick={() => {
                saveEventItemDetails({
                  eventRow: row?.cell?.row?.original,
                });
              }}
            >
              {row.value}
            </Link>
          ) : (
            <span> {row.value}</span>
          ),
      },
      {
        Header: 'Item Type',
        accessor: 'itemType',
        sticky: 'left',
        width: 90,
        height: 100,
      },
      {
        Header: 'Image',
        accessor: 'col4',
        sticky: 'left',
        width: 80,

        Cell: (row: any) => (
          <button
            type="button"
            className="flex h-10 w-16 flex-row  justify-center"
            onClick={() => {
              setIsDisplayModalOpen(!isDisplayModalOpen);
              setUPC(row?.cell?.row?.original?.upc.substring(2, 13));
            }}
            data-testid='item-image'
          >
            <img
              className="h-10  w-16 object-contain"
              src={`${imageBaseUrl}/${row?.cell?.row?.original?.upc.substring(
                2,
                13,
              )}.gif`}
              alt="item"
            />
          </button>
        ),
      },
      {
        Header: 'Lock',
        accessor: 'lockFlag',
        sticky: 'left',
        width: 50,
        Cell: (row: any) =>
          row.value === 'Y' ? (
            <button
              type="button"
              className="flex h-4 w-4  flex-row justify-center"
              onClick={() => handleUpdateLockItem(row?.cell?.row?.original)}
              data-testid='locked'
            >
              <img src={lock} alt="lock" />
            </button>
          ) : (
            <button
              type="button"
              className="flex h-4 w-4  flex-row justify-center"
              onClick={() => handleUpdateLockItem(row?.cell?.row?.original)}
              data-testid='unlocked'
            >
              <img src={unLock} alt="unlock" />
            </button>
          ),
      },
      {
        Header: 'Item Description',
        accessor: 'itemDesc',
        sticky: 'left',
        width: '300',
        Cell: (row: any) => (
          <span className={`${row?.cell?.row?.original.groupColor}`}>
            {row?.cell?.row?.original.itemDesc}
          </span>
        ),
      },
      {
        Header: 'UPC',
        accessor: 'upc',
      },
      {
        Header: 'Pack',
        accessor: 'pack',
        width: 60,
      },
      {
        Header: 'Size',
        accessor: 'size',
        width: 60,
      },
      {
        Header: 'CF',
        accessor: 'cf',
        width: 60,
      },
      {
        Header: 'Each Cost',
        accessor: 'eachCostwith$',
      },
      {
        Header: 'Suggested Retail',
        accessor: 'suggestedRetailWith$',
      },
      {
        Header: 'Model UPC',
        accessor: 'modelUpc',
        width: '400',
        Cell: (row: any) => (
          <span className="break-words">
            {row?.cell?.row?.original.modelUpc}
          </span>
        ),
      },
      {
        Header: 'Sales History Taken From Date',
        accessor: 'historyFromDate',
      },
      {
        Header: 'Sales History Taken Until Date',
        accessor: 'historyTillDate',
      },
      {
        Header: 'Aggregate Sales History Quantity',
        accessor: 'aggrSalesHistQty',
      },
      {
        Header: 'National Ad Quantity',
        accessor: 'suggestedAdminQty',
        Cell: EditableCell,
        validation: {
          value: 5,
          type: 'number',
          disable: close === 'true',
          min: 0,
          message: 'National Ad Quantity is more than 5, Are you sure?',
        },
      },
      {
        Header: 'Store Quantity',
        accessor: 'adjStoreQty',
      },
      {
        Header: 'District Quantity',
        accessor: 'adjDisQty',
      },
      {
        Header: 'Division Quantity',
        accessor: 'adjDivQty',
      },
      {
        Header: 'Admin Quantity',
        accessor: 'adjAdminQty',
      },
      {
        Header: 'Total Eaches',
        accessor: 'totalEaches',
        Footer: (info: any) => {
          const total: any = handleTotalEaches(info);
          return <span>{applyLocaleString(total)}</span>;
        },
      },
      {
        Header: 'Total Retail Dollars',
        accessor: 'totalRetailWith$',
        Footer: (info: any) => {
          const total = handleTotalRetail(info);
          return <span>${applyLocaleString(total)}</span>;
        },
      },
      {
        Header: 'Extended Cost',
        accessor: 'extendedCostWith$',
        Footer: (info: any) => {
          const total = handleTotalExtendedCost(info);
          return <span>${applyLocaleString(total)}</span>;
        },
      },
      {
        Header: 'NSM Item Comment',
        accessor: 'itemAdminComment',
        Cell: EditableCell,

        validation: {
          maxLength: 3500,
          disable: close === 'true',
        },
      },
    ],
    [],
  );

  const handleClose = () => {
    setIsOpen(!isModalOpen);
  };

  const getExportArray = (exportData: Array<any>) => {
    try {
      return exportData.map((item) => ({
        Lock: item?.lockFlag === 'Y' ? 'Locked' : 'Unlocked',
        UPC: item?.upc,
        'Item Number': item?.itemNumber,
        'Item Type': item?.itemType,
        'Item Description': item?.itemDesc,
        Pack: item?.pack,
        Size: item?.size,
        CF: item?.cf,
        'Case Cost': item?.ibc && item?.cf ? item.ibc * item.cf : 0,
        'Each Cost': item?.eachCost,
        'Suggested Retails': item?.suggestedRetail,
        'Model UPC': item?.modelUpc,
        'Sales History Taken from date': item?.historyFromDate,
        'Sales History Taken until date': item?.historyTillDate,
        'National Ad Quantity': item?.suggestedAdminQty,
        'Store Quantity': item?.adjStoreQty,
        'District Quantity': item?.adjDisQty,
        'Division Quantity': item?.adjDivQty,
        'Admin Quantity': item?.adjAdminQty,
        'Total Eaches': item?.totalEaches,
        'Total Retails Dollars': item?.totalCost,
        'Extended Cost': item?.extendCost,
        'NSM Comment': item?.itemAdminComment,
      }));
    } catch (error) {
      toast.error('Error in export');
      return [];
    }
  };
  const handleExportClick = () => {
    if (eventListData && eventListData.length > 0) {
      exportToXLSX({
        fileName: 'Event_item_Admin',
        data: getExportArray(eventItemNotModifiedList),
        column: wscolsEventItem,
      });
    } else {
      toast.warning('No Data');
    }
  };

  const checkIfDataIsModified = useCallback(() => {
    const newExtractingArray = [...eventListData];

    const existingDataStrigified = newExtractingArray.sort(
      (a: any, b: any) => a.itemNumber - b.itemNumber,
    );
    const newArray = [...(uploadData ?? [])];

    const uploadedDataStrigified =
      newArray &&
      newArray.sort((a: any, b: any) => a.itemNumber - b.itemNumber);

    const isSame =
      JSON.stringify(existingDataStrigified) ===
      JSON.stringify(uploadedDataStrigified);

    return !isSame;
  }, [eventItemList, uploadData, eventListData]);
  const handleSave = async () => {
    const inputWithErrors = document.querySelector('input.has-error');

    if (inputWithErrors) {
      toast.error('Fix all errors in the table');
      return;
    }

    const dataIsModified = checkIfDataIsModified();
    if (!dataIsModified) {
      toast.info('There are no records to update');
      return;
    }

    const newData = uploadData?.map((item) => ({
      ...item,
      // itemAdminComment: item.itemAdminComment,
      // suggestedAdminQty: item.suggestedAdminQty,
    }));

    try {
      const res = await saveEvent(newData);

      // If server returns 500
      if (res.status === 500) {
        throw new Error('Failed to save the uploaded data');
      }

      if (res.data.status === 'success') {
        toast.success('Detail saved successfully');
        fetchEventItemList({
          eventId: eventId.toString(),
          programSK: '',
          pogSk: '',
        });
      } else {
        const mappedEventList = eventItemNotModifiedList
          .map((item: any) => {
            const errorItem = res.data.itemdDetailsList.find(
              (ErrItem: any) => ErrItem.itemNumber === item.itemNumber,
            );
            return {
              itemNumber: item.itemNumber,
              itemDesc: item.itemDesc,
              upc: item.upc,
              errorMessage: errorItem?.errorMessage ?? null,
            };
          })
          .filter((item: any) => item.errorMessage !== null);

        setFileUploadSaveRes(mappedEventList);

        throw new Error(
          'Failed to save the uploaded data. Please check "Item Upload Errors" for details',
        );
      }
    } catch (error: any) {
      toast.error(
        error?.message ?? 'Failed to upload/read data from excel file',
      );
    }
  };
  return (
    <div>
      <div ref={ref} className="overflow-y-hidden pt-1">
        <Banner title={event?.eventName ? event?.eventName : eventName}>
          <Button
            type="contained"
            text="Save"
            disabled={
              !eventListData || eventListData.length < 1 || close === 'true'
            }
            onClick={() => handleSave()}
            data-testid="save-button"
          />
          <Button
            text="Item Level Export"
            onClick={handleExportClick}
            disabled={!eventListData || eventListData.length < 1}
            data-testid="item-export"
          />
        </Banner>

        <section className="pl-4 pr-4 pt-4">
          <Accordion heading={event?.eventName ? event?.eventName : eventName}>
            <div className="flex flex-col items-start border-t-2 p-5">
              <div className="flex items-center">
                <DropDown
                  List={planogramList}
                  label="Space Available: "
                  onChange={setSelectedSpaceValue}
                  data-testid="space-available"
                />
                <ButtonGroup styles="pl-4">
                  <Button
                    type="contained"
                    text="Item Upload"
                    onClick={() => {
                      setIsOpen(!isModalOpen);
                    }}
                    data-testid="item-upload"
                  />
                  <Button
                    type="outline"
                    text="Item Upload Errors"
                    onClick={() => {
                      setItemUploadModal(!itemUploadModal);
                    }}
                    disabled={fileUploadSaveRes.length === 0}
                    data-testid="item-upload-error"
                  />
                  <ItemFileUpload
                    title="Item File Upload:"
                    onClose={handleClose}
                    isModalOpen={isModalOpen}
                    setSaveResponse={setFileUploadSaveRes}
                    data-testid="item-file-upload"
                  />
                  <ItemUploadModal
                    title="Items Upload Status:"
                    data={fileUploadSaveRes}
                    onClose={() => {
                      setItemUploadModal(!itemUploadModal);
                    }}
                    isModalOpen={itemUploadModal}
                    data-testid="item-upload-modal"
                  />
                </ButtonGroup>
              </div>
              <div className="flex items-center pl-2">
                {selectedSpaceValue ? (
                  <a
                    className="text-sky-500"
                    href={`${imageBaseUrl}/${event?.eventName
                      ? event?.eventName.toLowerCase()
                      : eventName.toLowerCase()
                      }${regex.isFirstTwoNum.test(selectedSpaceValue)
                        ? selectedSpaceValue.trimEnd().toLowerCase()
                        : selectedSpaceValue
                          .trimEnd()
                          .padStart(selectedSpaceValue.trimEnd().length + 1, '0')
                          .toLowerCase()
                      }.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event?.eventName ? event?.eventName : eventName}-
                    {selectedSpaceValue}
                  </a>
                ) : null}
              </div>
            </div>
          </Accordion>
        </section>
      </div>
      <div className="over pl-4 pr-4">
        {eventListData && eventListData.length > 0 ? (
          <StickyRTable
            columns={columns}
            isfilter
            data={eventListData}
            updateData={updateTableData}
            skipPageReset={skipPageReset}
            isTotal
            height={height}
            hiddenColumns={[]}
            data-testid="event-item-table"
          />
        ) : loading ? (
          <UILoader info="Please wait while loading..." />
        ) : (
          <NoData />
        )}
      </div>
      {/* WIP */}
      <DisplayModal
        message={
          <img
            src={`${imageBaseUrl}/${selectedUPC}.gif`}
            className="object-scale-down h-80 w-92"
            alt="ItemImage"
          />
        }
        isModalOpen={isDisplayModalOpen}
        onClose={() => setIsDisplayModalOpen(!isDisplayModalOpen)}
      />
    </div>
  );
}
const mapStateToProps = ({ eventList }: AppState) => ({
  eventItemList: eventList.eventItemList,
  planogramList: eventList.planogramList,
  event: eventList.eventItemDetails?.eventRow,
  eventItemNotModifiedList: eventList.eventItemNotModifiedList,
});

export default connect(mapStateToProps, null)(EventItems);
