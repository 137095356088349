import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import CostTarget from './CostTarget';

const mapStateToProps = ({ eventList, costTarget }: AppState) => ({
  programs: eventList.programList,
  eventsDD: eventList.eventListDD,
  districtsDD: costTarget.totalByDistrictDD,
  storeList: costTarget.eventStoreList,
});

export default connect(mapStateToProps, null)(CostTarget);
