import React from 'react';

interface ICheckBoxProps {
  value?: string | number;
  id: string;
  label?: string;
  handleChange?: (event: React.ChangeEvent<any>) => void;
  disabled?: boolean;
  checked?: boolean;
  name?: string;
}
function CheckBox(props: ICheckBoxProps) {
  const { id, label, handleChange, disabled, value, checked, name } = props;
  return (
    <div className="flex justify-center">
      <input
        disabled={disabled}
        color="primary"
        id={id}
        value={value}
        name={name}
        onChange={handleChange}
        checked={checked}
        className="form-check-input float-left mt-1 mr-2 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
        type="checkbox"
        data-testid={`${label}-check`}
      />
      <label
        className="form-check-label inline-block text-gray-800"
        data-testid={`${label ?? 'noLabel'}-checkbox`}
      >
        {label}
      </label>
    </div>
  );
}
export default CheckBox;
