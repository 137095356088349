const roleCapability = [
  {
    roleSid: 1,
    roleName: 'FOSS Admin',
    menuList: [
      {
        menuSid: 1,
        menuName: 'Events',
        functionList: [
          {
            functionSid: 1,
            functionName: 'EventList',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
          {
            functionSid: 2,
            functionName: 'EventsUpload',
            functionDespTxt: 'upload events',
            functionPermit: true,
          },
          {
            functionSid: 3,
            functionName: 'UploadToSpa',
            functionDespTxt: 'Upload to spa',
            functionPermit: true,
          },
          {
            functionSid: 4,
            functionName: 'UpdateEventItems',
            functionDespTxt: 'Update event items',
            functionPermit: true,
          },
          {
            functionSid: 5,
            functionName: 'CostTarget',
            functionDespTxt: 'Cost target screen',
            functionPermit: false,
          },
          {
            functionSid: 6,
            functionName: 'DeleteEvent',
            functionDespTxt: 'Delete event',
            functionPermit: true,
          },
          {
            functionSid: 6,
            functionName: 'NsmComment',
            functionDespTxt: 'Edit NSM Comment',
            functionPermit: true,
          },
          {
            functionSid: 7,
            functionName: 'AdminDates',
            functionDespTxt: 'Admin,Div,SalesHistory Dates',
            functionPermit: true,
          },
          {
            functionSid: 8,
            functionName: 'DivDates',
            functionDespTxt: 'Dist,Store Dates',
            functionPermit: false,
          },
          {
            functionSid: 9,
            functionName: 'StoreLevelImport/Export',
            functionDespTxt: 'Store Import and Export functionality',
            functionPermit: false,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 2,
        menuName: 'OrderTotals',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivisionOrder',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
          {
            functionSid: 2,
            functionName: 'DistrictOrder',
            functionDespTxt: 'upload events',
            functionPermit: true,
          },
          {
            functionSid: 3,
            functionName: 'StoreOrder',
            functionDespTxt: 'Upload to spa',
            functionPermit: true,
          },
          {
            functionSid: 4,
            functionName: 'ItemOrder',
            functionDespTxt: 'Update event items',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 3,
        menuName: 'ViewsAs',
        menuPermit: false,
      },
      {
        menuSid: 4,
        menuName: 'FAQ',
        functionList: [
          {
            functionSid: 1,
            functionName: 'AdmFAQ',
            functionDespTxt: 'Admin FAQ',
            functionPermit: true,
          }]
      },
    ],
  },
  {
    roleSid: 2,
    roleName: 'FOSS Division',
    menuList: [
      {
        menuSid: 1,
        menuName: 'Events',
        functionList: [
          {
            functionSid: 1,
            functionName: 'EventList',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
          {
            functionSid: 2,
            functionName: 'EventsUpload',
            functionDespTxt: 'upload events',
            functionPermit: false,
          },
          {
            functionSid: 3,
            functionName: 'UploadToSpa',
            functionDespTxt: 'Upload to spa',
            functionPermit: false,
          },
          {
            functionSid: 4,
            functionName: 'UpdateEventItems',
            functionDespTxt: 'Update event items',
            functionPermit: false,
          },
          {
            functionSid: 5,
            functionName: 'CostTarget',
            functionDespTxt: 'Cost target screen',
            functionPermit: true,
          },
          {
            functionSid: 6,
            functionName: 'DeleteEvent',
            functionDespTxt: 'Delete event',
            functionPermit: false,
          },
          {
            functionSid: 7,
            functionName: 'AdminDates',
            functionDespTxt: 'Admin,Div,SalesHistory Dates',
            functionPermit: false,
          },
          {
            functionSid: 8,
            functionName: 'DivDates',
            functionDespTxt: 'Dist,Store Dates',
            functionPermit: true,
          },
          {
            functionSid: 9,
            functionName: 'StoreLevelImport/Export',
            functionDespTxt: 'Store Import and Export functionality',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 2,
        menuName: 'OrderTotals',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivisionOrder',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
          {
            functionSid: 2,
            functionName: 'DistrictOrder',
            functionDespTxt: 'upload events',
            functionPermit: true,
          },
          {
            functionSid: 3,
            functionName: 'StoreOrder',
            functionDespTxt: 'Upload to spa',
            functionPermit: true,
          },
          {
            functionSid: 4,
            functionName: 'ItemOrder',
            functionDespTxt: 'Update event items',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 3,
        menuName: 'ViewAs',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DistStore',
            functionDespTxt: 'Distrcit/Store screen',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 4,
        menuName: 'StoreGroup',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivStoreGroup',
            functionDespTxt: 'Division/Store Group screen',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 5,
        menuName: 'FAQView',
        functionList: [
          {
            functionSid: 1,
            functionName: 'FAQViewAll',
            functionDespTxt: 'FAQ View All',
            functionPermit: true,
          }]
      },
    ],
  },
  {
    roleSid: 3,
    roleName: 'FOSS District',
    menuList: [
      {
        menuSid: 1,
        menuName: 'Events',
        functionList: [
          {
            functionSid: 1,
            functionName: 'EventList',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
          {
            functionSid: 2,
            functionName: 'EventsUpload',
            functionDespTxt: 'upload events',
            functionPermit: false,
          },
          {
            functionSid: 3,
            functionName: 'UploadToSpa',
            functionDespTxt: 'Upload to spa',
            functionPermit: false,
          },
          {
            functionSid: 4,
            functionName: 'UpdateEventItems',
            functionDespTxt: 'Update event items',
            functionPermit: false,
          },
          {
            functionSid: 5,
            functionName: 'CostTarget',
            functionDespTxt: 'Cost target screen',
            functionPermit: false,
          },

          {
            functionSid: 9,
            functionName: 'StoreLevelImport/Export',
            functionDespTxt: 'Store Import and Export functionality',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 2,
        menuName: 'OrderTotals',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DivisionOrder',
            functionDespTxt: ' Event list page',
            functionPermit: false,
          },
          {
            functionSid: 2,
            functionName: 'DistrictOrder',
            functionDespTxt: 'upload events',
            functionPermit: false,
          },
          {
            functionSid: 3,
            functionName: 'StoreOrder',
            functionDespTxt: 'Upload to spa',
            functionPermit: true,
          },
          {
            functionSid: 4,
            functionName: 'ItemOrder',
            functionDespTxt: 'Update event items',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 3,
        menuName: 'ViewAs',
        functionList: [
          {
            functionSid: 1,
            functionName: 'DistStore',
            functionDespTxt: 'Distrcit/Store screen',
            functionPermit: false,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 4,
        menuName: 'FAQView',
        functionList: [
          {
            functionSid: 1,
            functionName: 'FAQViewAll',
            functionDespTxt: 'FAQ View All',
            functionPermit: true,
          }]
      },
    ],
  },
  {
    roleSid: 4,
    roleName: 'FOSS Store',
    menuList: [
      {
        menuSid: 1,
        menuName: 'Events',
        functionList: [
          {
            functionSid: 1,
            functionName: 'EventList',
            functionDespTxt: ' Event list page',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
      {
        menuSid: 2,
        menuName: 'FAQView',
        functionList: [
          {
            functionSid: 1,
            functionName: 'FAQViewAll',
            functionDespTxt: ' FAQ View All',
            functionPermit: true,
          },
        ],
        menuPermit: true,
      },
    ],
  },
];

export default roleCapability;
