/* eslint-disable */
//@ts-nocheck
import Filter from 'components/Filter';
import React, { useEffect } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { headerHeight } from 'layouts/Header/Header.tsx';
import { bannerHeight } from 'components/Banner/Banner';
import DisplayModal from '../Modal/DisplayModal';

interface IHeader {
  Header: string;
  accessor: string;
  Cell?: (props: any) => void;
}
interface IData {
  col1?: string;
  col2?: string;
  col3?: string;
  col4?: string;
  col5?: string;
  col6?: string;
  col7?: string;
  col8?: string;
  col9?: string;
}
interface ITableProps {
  columns: any;
  data: IData[];
  isTotal?: boolean;
  isfilter: boolean;
  height?: number;
  updateData?: (rowIndex: any, columnId: any, value: any) => void;
  skipPageReset?: boolean;
  hiddenColumns?: any;
}

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef();

//     const resolvedRef = ref || defaultRef;

//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate;
//     }, [resolvedRef, indeterminate]);

//     return <input type="checkbox" ref={resolvedRef} {...rest} />;
//   },
// );

const RTable = (props: ITableProps) => {
  const {
    columns,
    data,
    isTotal,
    isfilter,
    height,
    updateData,
    skipPageReset,
    hiddenColumns,
  } = props;
  const updatedHeaderHight = headerHeight;
  const updatedBannerHeight = bannerHeight;
  const [tableHeight, setTableHeight] = React.useState(
    height + updatedHeaderHight + updatedBannerHeight + 100,
  );

  const [isColumnListOpen, setIsColumnListOpen] = React.useState(false);

  React.useEffect(() => {
    setTableHeight(height + updatedHeaderHight + updatedBannerHeight + 72);
  }, [height, updatedHeaderHight, updatedBannerHeight, tableHeight]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable<object>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: data.length,
        hiddenColumns: hiddenColumns,
      },
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateData is not part of the API, but will be
      // automatically availaible on the instance
      updateData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  useEffect(() => {
    setPageSize(data.length);
  }, [data]);
  const calculateRowLength = () => {
    if (rows.length < pageSize) {
      return rows.length;
    } else if (rows.length > pageSize * (pageIndex + 1)) {
      return pageSize * (pageIndex + 1);
    } else {
      return rows.length;
    }
  };

  const toggleColumnListDisplay = () => {
    setIsColumnListOpen(!isColumnListOpen);
  };

  const filteredColumns = allColumns.filter(
    (column) => !hiddenColumns.includes(column.id),
  );

  let columnList = (
    <div className="w-full pl-4">
      <div>
        {/* <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All */}
      </div>

      {filteredColumns.map((column) => (
        <div key={column.id}>
          <label>
            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
            {column.Header}
          </label>
        </div>
      ))}

      <br />
    </div>
  );

  return (
    <div className="rounded-md bg-white p-2">
      <DisplayModal
        message={columnList}
        isModalOpen={isColumnListOpen}
        onClose={toggleColumnListDisplay}
      />

      {isfilter && (
        <Filter
          total={rows.length}
          filterValue={globalFilter || ''}
          setFilter={setGlobalFilter}
          settingClick={toggleColumnListDisplay}
        />
      )}
      <div
        style={{
          height: `calc(100vh - ${tableHeight}px`,
          overflow: 'auto',
        }}
      >
        <table {...getTableProps()} className="w-full">
          <thead className="sticky -top-1 z-10  h-10 bg-[#F1F4F9]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: `${column.width}px` }}
                    className={`border bg-[#F1F4F9] py-4 px-3`}
                  >
                    <span
                      className="text-xs font-bold"
                      data-testid="table-header"
                    >
                      {column.render('Header')}
                    </span>
                    {column.isSorted && (
                      <span>{column.isSortedDesc ? ' 🔽' : ' 🔼'}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className=" even:bg-[#F7F8FA]">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="border p-1 text-center "
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>

          {isTotal && (
            <tfoot className="sticky -bottom-0">
              {footerGroups.map((group) => (
                <tr
                  className="border  bg-[#F1F4F9]"
                  {...group.getFooterGroupProps()}
                >
                  {group.headers.map((column) => (
                    <td
                      className="border p-1 text-center"
                      {...column.getFooterProps()}
                    >
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      <div className="pagination sticky bottom-0 flex  justify-between  bg-white  pt-4 pb-1 ">
        <div className="pt-1">
          <span className="text-sm tracking-wide">Items:</span>
          <select
            className="ml-3 rounded border p-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            data-testid='select-pagesize'
          >
            {[rows.length, 10, 20, 30, 40].map((pageSizeVal, index) => (
              <option
                key={index === 0 ? 'All' : pageSizeVal}
                value={pageSizeVal}
              >
                {index === 0 ? 'All' : pageSizeVal}
              </option>
            ))}
          </select>
          <span className="ml-3 text-sm" data-testid="pageSize">
            {pageIndex * pageSize + 1}-{calculateRowLength()}
            &nbsp;of&nbsp;{rows.length}
          </span>
        </div>
        <div>
          <button
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className={`w-10 border p-1 font-bold ${
              canPreviousPage
                ? 'cursor-pointer hover:bg-[#deeefc]'
                : 'text-[#DADDE0]'
            }`}
            data-testid="firstPage"
          >
            {'<<'}
          </button>
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={`w-10 border p-1 font-bold ${
              canPreviousPage
                ? 'cursor-pointer hover:bg-[#deeefc]'
                : 'text-[#DADDE0]'
            }`}
            data-testid="prevPage"
          >
            {'<'}
          </button>
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={`w-10 border p-1 font-bold ${
              canNextPage
                ? 'cursor-pointer hover:bg-[#deeefc]'
                : 'text-[#DADDE0]'
            }`}
            data-testid="nextPage"
          >
            {'>'}
          </button>
          <button
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className={`w-10 border p-1 font-bold ${
              canNextPage
                ? 'cursor-pointer hover:bg-[#deeefc]'
                : 'text-[#DADDE0]'
            }`}
            data-testid="lastPage"
          >
            {'>>'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RTable;
