import React, { createContext } from 'react';

type SideMenuContextType = {
  open: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SideMenuContext = createContext<SideMenuContextType>({
  open: false,
  toggleOpen: () => false,
});

export default SideMenuContext;
